import dayjs from "dayjs";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import { Button, Icon, Theme, Tooltip, useMediaQuery } from "@mui/material";
import FlexBox from "../../layouts/FlexBox";
import { useAuthContext } from "../../../contexts";
import { IContactLeveEnum } from "../../../models/enums/ContactTypeEnum";
import PhoneNumberFormat from "../../../helps/PhoneNumberFormat";
import { ISale } from "../../../models/Sale";
import CurrencyFormat from "../../../helps/CurrencyFormat";
import { statusBarPayment } from "../../../../pages/vendas/components/StatusBarPayment";
import { IPaymentMethodTypeEnum } from "../../../models/enums/PaymentMethodTypeEnum";

interface detalheSaleModalProps {
  sale: ISale;
};

export const DetalheSaleModal: React.FC<detalheSaleModalProps> = (props) => {
  const { sale } = props;
  const [display, setDisplay] = useState('none');
  const { roles } = useAuthContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handlePrint = async () => {
    if (mdDown) {
      handlePrintMobile()
    } else {
      setDisplay('flex')
      reactToPrintFn()
      setDisplay('none')
    }
  }

  const handlePrintMobile = () => {
    const content = contentRef.current;
    if (content) {
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Imprimir</title>
        </head>
        <body>
          ${content.innerHTML}
        </body>
        </html>
      `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();

        setTimeout(() => {
          printWindow.close();
        }, 500);
      } else {
        alert("Não foi possível abrir a janela de impressão.");
      }
    }
  };

  let total = 0;
  sale.products.forEach(x => total += x.value)
  const role = roles != null ? roles?.filter(role => role.isSelected == true)[0] : '';
  const imgBranch = (role != '') ? role?.branch?.logoUrl : '';
  const address = (role != '' && role?.branch?.address != undefined) ? (role?.branch?.address?.street ?? '') + ', ' + (role?.branch?.address?.number ?? '') : '';
  const contacts = (role != '') ? role?.branch?.contacts.filter(x => x.type == IContactLeveEnum.Telefone || x.type == IContactLeveEnum.WhatsApp).at(0)?.value : '';
  return (
    <>
      <div style={{ overflow: "auto", display: display }} >
        <div ref={contentRef}>
          <div style={wrapperStyle}>
            <FlexBox
              column
              jcCenter
              aiCenter
            >
              <div style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img width='40%' src={imgBranch} alt="logo da marca" />
                <p>Nº: {sale.id}</p>
              </div>
              <div style={{ margin: '5px', padding: '5px', border: 'solid 1px', borderRadius: '5px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p>Nome:  {sale.client.name}</p>
                  <p>End.:  {address}</p>
                </div>
                <div>
                  <p>Data:  {dayjs(sale.dateCreate).format('DD / MM / YYYY')}</p>
                  <p>Fone:  {contacts && PhoneNumberFormat(contacts)}</p>
                </div>
              </div>
              <h4>Produtos</h4>
              <div style={{ margin: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <table style={tableStyle}>
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Quantidade</th>
                    <th>Valor Unit</th>
                    <th>Valor</th>
                  </tr>
                  {sale?.products != undefined && sale.products.map((product) => (
                    <tr>
                      <td style={cellStyle}>{product.name}</td>
                      <td style={cellStyle}>{product.modelProduct}</td>
                      <td style={cellStyle}>{product.quantity}</td>
                      <td style={cellStyle}>{CurrencyFormat(product.value / product.quantity)}</td>
                      <td style={cellStyle}>{CurrencyFormat(product.value)}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <h4>Pagamentos</h4>
              <div style={{ margin: '5px', width: '100%', display: 'flex' }}>
                <table style={tableStyle}>
                  <tr>
                    <th>Forma de pagamento</th>
                    <th>Vencimento</th>
                    <th>Status</th>
                    <th>Parcelas</th>
                    <th>Valor Parcela</th>
                    <th>Valor</th>
                  </tr>
                  {sale?.transactions != undefined && sale.transactions.filter(x => x.status != 3).map((transaction) => (
                    <tr>
                      <td style={cellStyle}>
                        {transaction.paymentMethodType == IPaymentMethodTypeEnum.Dinheiro && 'Dinheiro'}
                        {transaction.paymentMethodType == IPaymentMethodTypeEnum.Cartão && 'Cartão'}
                        {transaction.paymentMethodType == IPaymentMethodTypeEnum.PIX && 'Pix'}
                        {transaction.paymentMethodType == IPaymentMethodTypeEnum.Crediário && 'Crediário'}
                      </td>
                      <td style={cellStyle}>{dayjs(transaction.date).format("DD/MM/YYYY")}</td>
                      <td style={cellStyle}>{statusBarPayment(transaction.status).label}</td>
                      <td style={cellStyle}>{transaction.installments}</td>
                      <td style={cellStyle}>{CurrencyFormat(transaction.value / transaction.installments)}</td>
                      <td style={cellStyle}>{CurrencyFormat(transaction.value)}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <FlexBox fullWidth jcEnd>
                <Typography>Total: <b>{CurrencyFormat(total)}</b></Typography>
              </FlexBox>
            </FlexBox>
          </div>
        </div>
      </div >

      <Tooltip
        title="Imprimir"
        placement="top"
      >
        <Button
          variant="text"
          onClick={() => handlePrint()}
          fullWidth
          sx={{ borderRadius: "22px", marginLeft: -1, marginRight: -1, height: "100%" }}
        >
          <Icon sx={{ fontSize: 42 }} onClick={() => handlePrint()}>local_printshop_sharp</Icon>
        </Button>
      </Tooltip>
    </>
  )
}

const wrapperStyle: React.CSSProperties = {
  fontFamily: "Arial, sans-serif",
  margin: '5px',
  lineHeight: 1,
  padding: "5px",
};

const tableStyle: React.CSSProperties = {
  width: "100%",
  borderCollapse: "collapse",
};

const cellStyle: React.CSSProperties = {
  padding: "5px",
  margin: 0,
  fontSize: "14px",
  textAlign: "center",
  borderBottom: "1px solid #DDD",
};

const Typography = styled.p<{ width?: string, fontSize?: string, italic?: boolean, underline?: boolean }>`
  margin: 0;
  font-weight: 600;
  white-space: pre;
  width: ${({ width }) => width || 'auto'};
  font-size: ${({ fontSize }) => fontSize || 'small'};
  font-style: ${({ italic }) => italic ? 'italic' : 'normal'};
  Typography-decoration: ${({ underline }) => underline ? 'underline' : 'normal'};
`;