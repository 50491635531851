import dayjs, { Dayjs } from "dayjs";




export const setTimeVerification = (timeType: number, dateRange: Dayjs[]) => {
  
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);  ///// Primeiro dia do mês atual
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);  // Último dia do mês atual
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);  ///////////////////// Primeiro dia do ano atual
  const lastDayOfYear = new Date(today.getFullYear(), 11, 31); ///////////////////// Último dia do ano atual

  // Últimos 7 dias
  const firstDayLast7days = new Date(new Date().setDate(today.getDate() - 7));
  const lastDayLast7days = new Date(today);

  // Últimos 30 dias
  const firstDayLast30days = new Date(new Date().setDate(today.getDate() - 30));
  const lastDayLast30days = new Date(today);

  // Próximos 30 dias
  const firstDayNext30days = new Date(today);
  const lastDayNext30days = new Date(new Date().setDate(today.getDate() + 30));

  const timeVerification = {
    first: (
      timeType === 1 ? dayjs(today).format("YYYY-MM-DD") :
        timeType === 2 ? dayjs(firstDayOfMonth).format("YYYY-MM-DD") :
          timeType === 3 ? dayjs(firstDayOfYear).format("YYYY-MM-DD") :
            timeType === 4 ? dayjs(firstDayLast7days).format("YYYY-MM-DD") :
              timeType === 5 ? dayjs(firstDayLast30days).format("YYYY-MM-DD") :
                timeType === 6 ? dayjs(firstDayNext30days).format("YYYY-MM-DD") :
                  timeType === 0 ? dateRange[0].format("YYYY-MM-DD") :
                    dayjs(firstDayOfMonth).format("YYYY-MM-DD")
    ),
    last: (
      timeType === 1 ? dayjs(today).format("YYYY-MM-DD") :
        timeType === 2 ? dayjs(lastDayOfMonth).format("YYYY-MM-DD") :
          timeType === 3 ? dayjs(lastDayOfYear).format("YYYY-MM-DD") :
            timeType === 4 ? dayjs(lastDayLast7days).format("YYYY-MM-DD") :
              timeType === 5 ? dayjs(lastDayLast30days).format("YYYY-MM-DD") :
                timeType === 6 ? dayjs(lastDayNext30days).format("YYYY-MM-DD") :
                  timeType === 0 ? dateRange[1].format("YYYY-MM-DD") :
                    dayjs(firstDayOfMonth).format("YYYY-MM-DD")
    )
  };

  return timeVerification;
}
