import { IOptionNewClient } from "../../../pages/clients/components/listagemDeCLientes/Modal-NovoCLiente2";
import { Environment } from "../../environment";
import { IAddressInsert } from "../../models";
import { IContactInsert } from "../../models/Clients/contact";
import * as CryptoJS from 'crypto-js';
import { IDocument } from "../../models/Clients/document";

const CONFIG_NEW_CLIENT = 'new-client-config';
const Address_NEW_CLIENT = 'new-client-address';
const CONTACT_NEW_CLIENT = 'new-client-contact';
const DOCUMENT_NEW_CLIENT = 'new-client-document';

export class NewClientStorage {
    password = Environment.PASSWORD_CRIPTO;
    constructor() { }

    public saveConfigNewUser(config: IOptionNewClient[]) {
        this.setItem(CONFIG_NEW_CLIENT, JSON.stringify(config));
    }

    public getConfigNewUser() {
        return this.getItem<IOptionNewClient[]>(CONFIG_NEW_CLIENT);
    }

    public saveAddressNewUser(address: IAddressInsert) {
        this.setItem(Address_NEW_CLIENT, JSON.stringify(address));
    }

    public getAddressNewUser() {
        return this.getItem<IAddressInsert>(Address_NEW_CLIENT);
    }

    public removeAddressNewUser() {
        return localStorage.removeItem(Address_NEW_CLIENT);
    }

    public saveContacts(contacts: IContactInsert[]) {
        this.setItem(CONTACT_NEW_CLIENT, JSON.stringify(contacts));
    }

    public getContacts() {
        return this.getItem<IContactInsert[]>(CONTACT_NEW_CLIENT);
    }

    public removeContacts() {
        return localStorage.removeItem(CONTACT_NEW_CLIENT);
    }

    public saveDocuments(documents: IDocument[]) {
        this.setItem(DOCUMENT_NEW_CLIENT, JSON.stringify(documents));
    }

    public getDocuments() {
        return this.getItem<IDocument[]>(DOCUMENT_NEW_CLIENT);
    }

    public removeDocuments() {
        return localStorage.removeItem(DOCUMENT_NEW_CLIENT);
    }

    getItem<T>(key: string): T {
        const criptografed = localStorage.getItem(key);
        if (!criptografed) return null as T;
        const model: any = CryptoJS.AES.decrypt(
            criptografed!,
            this.password
        ).toString(CryptoJS.enc.Utf8);
        if (!this.isJson(model)) return model;
        return model ? (JSON.parse(model) as T) : (null as T);
    }

    setItem(key: string, value: string) {
        const encrypt = CryptoJS.AES.encrypt(value, this.password);
        localStorage.removeItem(key);
        localStorage.setItem(key, encrypt.toString());
    }

    isJson(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}