const GenderFormat = (value: number) =>{
    switch(value) { 
        case 2: { 
           return "Feminino"
        } 
        case 1: { 
           return "Masculino"
        } 
        default: { 
           return "Unissex"
        } 
     } 
}

export default GenderFormat;