import { Avatar, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { CardBox } from '../../../shared/components/layouts/CardBox'
import FlexBox from '../../../shared/components/layouts/FlexBox'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ISale } from '../../../shared/models/Sale';


interface IClientCardContentProps {
  sale: ISale | undefined;
  description: string | null;
}

export const ClientCardContent: React.FC<IClientCardContentProps> = (props) => {
  const { sale, description } = props;

  const smDown = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <FlexBox>
      <CardBox
        sx={smDown ? { marginLeft: 10, } : {}}
        padding={1}
        jcCenter
        aiCenter
        gap={20}

      // sx={{ '&:hover': { transform: 'scale(1.1)', transition: 'all 0.15s ease-in-out', cursor: 'pointer', } }}
      >


        <Link
          target="_blank"
          to={`/clientes/detalhe/${sale?.client?.id}`}
        >
          <Avatar
            src={sale?.client?.imageUrl}
            sx={{
              width: '50px', height: '50px', backgroundColor: 'whitesmoke',
              ':hover': {
                boxShadow: '1px 1px 15px -10px #000000',
                transition: 'all 0.15s ease-in-out',
                transform: 'scale(1.1)',
                cursor: 'pointer'
              }
            }}
          >
          </Avatar>
        </Link>

        <FlexBox>
          <Typography>
            {sale?.client?.name}
          </Typography>
        </FlexBox>


        <Link
          target="_blank"
          to={`https://api.whatsapp.com/send/?phone=55${description?.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")}&text&type=phone_number&app_absent=1`}
        >
          <WhatsAppIcon
            sx={{
              cursor: 'pointer',
              ':hover': {
                transition: 'all 0.15s ease-in-out',
                transform: 'scale(1.15)',
              }
            }}
            fontSize='large'
            color='success'
          />
        </Link>

      </CardBox>
    </FlexBox>
  )
}
