import { Table, TableHead, TableRow, TableCell, Typography, IconButton, Icon, TableBody, useMediaQuery, Theme } from '@mui/material';
import React, { useState } from 'react';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import GUID from '../../../shared/helps/Guid';
import { IStockProduct } from '../../../shared/models/StockProduct';
import { ModalEditStockProduct } from '../components/Modal-EditarStockProduto';
import { ModalInsertStock } from '../components/Modal-InsertStock';
import { IProduct } from '../../../shared/models/Product';


interface IListStockProductProps {
  viewStockProduct: IStockProduct[];
}

export const ListStockProduct: React.FC<IListStockProductProps> = (props) => {
  const { viewStockProduct } = props;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [stockProduct, setStockProduct] = useState<IStockProduct | undefined>();

  const handleEdit = (item: IStockProduct) => {
    setStockProduct(item);
    setIsOpenEdit(true);
  }




  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow key={GUID()}>
            <TableCell align="center"><Typography fontWeight={500}>Cor</Typography></TableCell>
            <TableCell align="center"><Typography fontWeight={500}>Qnt</Typography></TableCell>
            <TableCell align="center"><Typography fontWeight={500}>Valor</Typography></TableCell>
            {!smDown && <TableCell align="center"><Typography fontWeight={500}>Custo</Typography></TableCell>}
            <TableCell align="center"><Typography fontWeight={500}>Ação</Typography></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {viewStockProduct.length > 0 && viewStockProduct.map(stock => (
            <TableRow key={stock.id}>
              <TableCell align="center" sx={{ justifyItems: 'center' }}> {
                stock?.colorProduct?.code ?
                  <FlexBox
                    width={20}
                    height={20}
                    borderRadius={50}
                    border='1px solid black'
                    bgcolor={stock?.colorProduct?.code}
                  /> :
                  <Typography fontWeight={400}>-</Typography>}</TableCell>
              <TableCell align="center"><Typography fontWeight={400}>{stock?.quantity ?? '-'}</Typography></TableCell>
              <TableCell align="center"><Typography fontWeight={400} color={"#4eb34e"}>{CurrencyFormat(stock?.value ?? 0)}</Typography></TableCell>
              {!smDown && <TableCell align="center"><Typography fontWeight={400}>{CurrencyFormat(stock?.cost ?? 0)}</Typography></TableCell>}
              <TableCell align="center">
                <IconButton size='small' onClick={() => handleEdit(stock)}>
                  <Icon>edit</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}


        </TableBody>

        <ModalEditStockProduct
          isOpen={isOpenEdit}
          stock={stockProduct}
          setIsOpenEdit={setIsOpenEdit}
        />



      </Table>

      <FlexBox height="60px" jcAround aiEnd padding={1}>
        {viewStockProduct.length === 0 &&
          <i>
            <Typography color='textDisabled' fontWeight={500}>
              Nenhum Produto Cadastrado no Estoque
            </Typography>
          </i>
        }

      </FlexBox>
    </>
  )
}
