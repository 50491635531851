import { IColorProduct } from "../../../ColorProduct";
import { IColorProductInsert, IColorProductUpdate } from "../../../Dtos/ColorProductDto";

export class ColorProduct implements IColorProduct {
  id: number;
  name: string;
  code: string;
  isActive: boolean;

  constructor({ id = 0, name = "", code = "", isActive = true }: Partial<IColorProduct> = {}) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.isActive = isActive;
  }
};


export class ColorProductInsert implements IColorProductInsert {
  name: string;
  code: string;

  constructor({ name = "", code = "" }: Partial<IColorProductInsert> = {}) {
    this.name = name;
    this.code = code;
  }
};


export class ColorProductUpdate implements IColorProductUpdate {
  id: number;
  name?: string;
  code?: string;
  isActive?: boolean;

  constructor({ id = 0, name = "", code = "", isActive = true }: Partial<IColorProductUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.isActive = isActive;
  }
};