import { Api } from "../axios-config";
import { IResponseAmount } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IMaterialProduct } from "../../../models/MaterialProduct";
import { IMaterialProductInsert, IMaterialProductUpdate } from "../../../models/Dtos/cadastrosDtos/MaterialProductDto";


const insert = async (materialProduct: IMaterialProductInsert): Promise<IResponseAPI<IMaterialProduct[]>> => {

    const { data } = await Api.post('/otica/MaterialProduct', materialProduct).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (materialProduct: IMaterialProductUpdate): Promise<IResponseAPI<IMaterialProduct>> => {

    const { data } = await Api.put('/otica/MaterialProduct', materialProduct).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (): Promise<IResponseAPI<IResponseAmount<IMaterialProduct[]>>> => {

    const { data } = await Api.get("/otica/MaterialProduct?pageSize=50").catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IMaterialProduct[]>>> => {

    const { data } = await Api.get(`/otica/MaterialProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const MaterialProductService = {
    insert,
    update,
    getAll,
    getById,
};