import { Typography, Button, Icon, useTheme, Grid } from '@mui/material';
import React from 'react';

import { CardProductStock } from '../components/NewProduct-CardProductStock';
import { IStockProductInsert } from '../../../shared/models/StockProduct';
import { IColorProduct } from '../../../shared/models/ColorProduct';
import FlexBox from '../../../shared/components/layouts/FlexBox';


interface IEstoqueDataSectionProps {
  removeStock: (index: number) => void;
  stock: IStockProductInsert[];
  handleOpenStock: () => void;
  colors: IColorProduct[];
  smDown: boolean;
};

export const EstoqueDataSection: React.FC<IEstoqueDataSectionProps> = (props) => {
  const { smDown, removeStock, stock, colors, handleOpenStock } = props;

  const theme = useTheme();

  return (
    <FlexBox
      flex={1}
      column
      bgcolor={theme.palette.background.paper}
      boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.137)"
      borderRadius={6}
      boxSizing={"border-box"}
    >

      <FlexBox
        jcBetween aiCenter
        // bgcolor={theme.palette.background.default}
        borderRadius="10px 10px 0px 0px"
        padding={1}
        boxSizing={"border-box"}
      >
        <Typography variant="h6">Estoque</Typography>
        <Button size="small" variant='contained' onClick={handleOpenStock}>
          <Icon>add</Icon>
        </Button>
      </FlexBox>

      <FlexBox fullWidth jcCenter marginTop={.5}>
        <FlexBox column aiCenter jcCenter width={"92%"} gap={20}>

          <Grid container width={"100%"}>
            <Grid xs={1} sm={1} lg={1} item
            // bgcolor={"#333"}
            >
              <FlexBox fullWidth jcCenter>
                <Typography fontWeight={500} fontSize={12} color='textDisabled'>
                  Cor
                </Typography>
              </FlexBox>
            </Grid>
            <Grid xs={2} sm={2} lg={2} item
            // bgcolor={"#c4222262"}
            >
              <FlexBox fullWidth jcCenter>
                <Typography fontWeight={500} fontSize={12} color='textDisabled'>
                  Qnt
                </Typography>
              </FlexBox>
            </Grid>
            <Grid xs={4} sm={3} lg={3} item
            // bgcolor={"#0da818"}
            >
              <FlexBox fullWidth jcStart>
                <Typography fontWeight={500} fontSize={12} color='textDisabled'>
                  Custo
                </Typography>
              </FlexBox>
            </Grid>
            <Grid xs={4} sm={3} lg={3} item
            // bgcolor={"#a71ed9"}
            >
              <FlexBox fullWidth jcStart>
                <Typography fontWeight={500} fontSize={12} color='textDisabled'>
                  Valor
                </Typography>
              </FlexBox>
            </Grid>
            {smDown ? null :
              <Grid xs={2} sm={2} lg={2} item
              // bgcolor={"#8e2059"}
              >
                <FlexBox fullWidth jcCenter>
                  <Typography fontWeight={500} fontSize={12} color='textDisabled'>
                    Estoque
                  </Typography>
                </FlexBox>
              </Grid>
            }
            <Grid xs={1} sm={1} lg={1} item
            // bgcolor={"#b16d6d"}
            >
              <FlexBox fullWidth jcEnd>
                <Typography fontWeight={500} fontSize={12} color='textDisabled'>
                  Ações
                </Typography>
              </FlexBox>
            </Grid>
          </Grid>

          <FlexBox column fullWidth gap={15}>
            {stock.length > 0 &&
              stock.map((item, index) => (

                <CardProductStock
                  key={item.colorProductId}
                  colors={colors}
                  index={index}
                  item={item}
                  removeStock={removeStock}
                  smDown={smDown}
                />
              ))}
          </FlexBox>

        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

