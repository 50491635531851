import { IconButton, Popover, Typography } from '@mui/material';
import React from 'react'
import { HexColorPicker } from 'react-colorful';
import FlexBox from '../layouts/FlexBox';

interface ButtonColorPickerProps {
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

export const ButtonColorPicker: React.FC<ButtonColorPickerProps> = (props) => {
  const { color, setColor } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <FlexBox gap={10} aiCenter>
      <Typography fontWeight={400}>Cor:</Typography>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          backgroundColor: color === '' ? '#6e352363' : color,
          border: '1px solid black',
          width: "20px",
          height: "20px",
          '&:hover': { scale: 1.2, transition: '.2s', backgroundColor: '#4f4f4fd2' },
          '&:active': { scale: 0.9, transition: '.1s' },
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        color='transparent'
      >
        <FlexBox padding={2}>
          <HexColorPicker color={color} onChange={setColor} />
        </FlexBox>
      </Popover>
    </FlexBox>
  )
}
