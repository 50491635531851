import { IPrescription, IPrescriptionUpdate } from "../../Prescription";
import { PrescriptionUpdate } from "./Prescription";



export function IPrescriptionToIprescriptionUpdate( prescription: IPrescription ): IPrescriptionUpdate {
  return new PrescriptionUpdate({
    id: prescription.id,
    farODSpherical: prescription.farODSpherical,
    farOESpherical: prescription.farOESpherical,
    farODCylindrical: prescription.farODCylindrical,
    farOECylindrical: prescription.farOECylindrical,
    farODAxis: prescription.farODAxis,
    farOEAxis: prescription.farOEAxis,
    farODHeight: prescription.farODHeight,
    farOEHeight: prescription.farOEHeight,
    farODDNP: prescription.farODDNP,
    farOEDNP: prescription.farOEDNP,
    nearODSpherical: prescription.nearODSpherical,
    nearOESpherical: prescription.nearOESpherical,
    nearODCylindrical: prescription.nearODCylindrical,
    nearOECylindrical: prescription.nearOECylindrical,
    nearODAxis: prescription.nearODAxis,
    nearOEAxis: prescription.nearOEAxis,
    nearODHeight: prescription.nearODHeight,
    nearOEHeight: prescription.nearOEHeight,
    nearODDNP: prescription.nearODDNP,
    nearOEDNP: prescription.nearOEDNP,
    additionSpherical: prescription.additionSpherical,
  }); 
}