import React from 'react';
import { Button, Stack, Theme, Typography } from '@mui/material';
import FlexBox from '../../layouts/FlexBox';
import { BaseModal } from '../BaseModal';
import { ITransaction } from '../../../models/Transaction';
import dayjs from 'dayjs';
import CurrencyFormat from '../../../helps/CurrencyFormat';
import { ITransactionTypeEnum } from '../../../models/enums/TransactionType';
import { IStatusPaymentEnum } from '../../../models/enums/StatusTransactionEnum';

interface BaseModalProps {
    transaction?: ITransaction;
    handleClose: () => void;
    open: boolean;
};

export const ModalTransactionDesc: React.FC<BaseModalProps> = (props) => {
    const { handleClose, open, transaction } = props;

    return (
        <BaseModal
            handleClose={handleClose}
            open={open}
            title={'Registro'}
            minWidth={320}
        >
            <FlexBox
                column gap={10}
                marginTop={3}
            >
                <FlexBox gap={10}>
                    <FlexBox column gap={5}>
                        <Typography>Tipo:</Typography>
                        <Typography>Descrição:</Typography>
                        <Typography>Data:</Typography>
                        <Typography>Valor:</Typography>
                        <Typography>Status:</Typography>
                        <Typography>Usuario:</Typography>
                    </FlexBox>
                    <FlexBox column gap={5}>
                        <Typography>{ITransactionTypeEnum[transaction?.type ?? 0]}</Typography>
                        <Typography>{transaction?.description ?? '-'}</Typography>
                        <Typography>{dayjs(transaction?.date).format("DD / MM / YYYY")}</Typography>
                        <Typography>{CurrencyFormat(transaction?.value ?? 0)}</Typography>
                        <Typography>{IStatusPaymentEnum[transaction?.status ?? 0]}</Typography>
                        <Typography>{transaction?.user?.name ?? '-'}</Typography>
                    </FlexBox>
                </FlexBox>
                <Stack
                    marginTop={4}
                    direction='row'
                    justifyContent='flex-end'
                >
                    <Button onClick={handleClose}>Fechar</Button>
                </Stack>
            </FlexBox>
        </BaseModal>
    );
};
