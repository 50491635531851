import { ReactNode } from 'react';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useDrawerContext } from '../../../../contexts';

interface VerificationDrawerDownMDProps {
  children: ReactNode
}

export const VerificationDrawerDownMD: React.FC<VerificationDrawerDownMDProps> = ({ children }) => {
  const theme = useTheme();
  const { isDrawerOpen, toggleDrawerOpen, } = useDrawerContext();

  const mdDown = useMediaQuery(theme.breakpoints.down('md')); // sm = 600px / md = 900px lg = 1200px

  if (mdDown) {
    return (
      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawerOpen}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default, // Altera apenas a cor do Drawer
          },
        }}
      >
        {children}
      </Drawer>
    );
  }
  else {
    return (
      <>{children}</>
    )
  };
};
