import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from "react";
import FlexBox from "../../layouts/FlexBox";

interface InputFileProps {
    setImage: React.Dispatch<React.SetStateAction<string>>
    buttonText: string;
}

export const InputFile = ({ setImage, buttonText }: InputFileProps) => {

    const [fileList, setFileList] = useState<FileList | null>();
    const handleImage = (file: FileList | null) => {
        setFileList(file)
        getImg(file)
    }

    function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (event) => {
                img.src = event.target?.result as string;
            };

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                // Calcular nova largura e altura mantendo a proporção
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                // Ajustar o tamanho do canvas
                canvas.width = width;
                canvas.height = height;

                // Desenhar a imagem redimensionada no canvas
                ctx?.drawImage(img, 0, 0, width, height);

                // Converter o canvas para Base64
                const base64String = canvas.toDataURL('image/png').split(',')[1];
                resolve('data:image/png;base64,' + base64String);
            };

            reader.onerror = () => {
                reject(new Error("Erro ao ler o arquivo."));
            };

            reader.readAsDataURL(file);
        });
    }

    const getImg = (file: FileList | null) => {
        if (file != null && file != undefined) {
            resizeImage(file[0], 600, 600).then(base64String => {
                setImage(base64String);
            });
        }
    }

    return (
        <FlexBox gap={4} column>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                {fileList != null && fileList != undefined ? fileList[0].name : buttonText}
                <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleImage(event.target.files)}
                    multiple
                />
            </Button>
            <FlexBox jcBetween>
                {fileList != null && fileList != undefined ? <Typography fontSize={12}>{Math.ceil(fileList[0].size / 1000)} Kb</Typography> : <Typography></Typography>}
                <Typography fontSize={12}>.png .jpeg .jpg</Typography>
            </FlexBox>
        </FlexBox>
    )
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});