import { Button, Stack, Theme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

import { IProductSaleInsert, ProductSaleInsert, ProductSaleInsertNotName } from '../../models/ProductSale';
import { SaleService } from '../../services/api/sale/SaleService';
import { Clientes, ProdutosListagem } from './sections';
import { BaseModal } from '../modals/BaseModal';
import { ISaleInsert } from '../../models/Sale';
import FlexBox from '../layouts/FlexBox';

interface BaseModalProps {
  clientNameParams?: string;
  handleClose: () => void;
  clientIdParams?: number;
  open: boolean;
};

export const ModalAddVendas: React.FC<BaseModalProps> = (props) => {
  const { handleClose, open, clientNameParams, clientIdParams = 0 } = props;

  const productZero = new ProductSaleInsert(0, 0, 0, 'string');
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [clientId, setClientId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [productsSelect, setProductsSelect] = useState<IProductSaleInsert[]>([productZero]);

  function handleAoClicarEmSalvar() {

    const sale: ISaleInsert = {
      clientId: clientId > 0 ? clientId : clientIdParams,
      products: productsSelect.map(p => new ProductSaleInsertNotName(p.stockProductId, p.quantity, p.value, p.saleId))
    }
    SaleService.insert(sale).then(data => {
      if (data.success) {
        alert("Venda cadastrada com sucesso!");
        window.location.reload();
      }
      else {
        alert(data.errors[0]);
      }
    });

  };


  return (
    <BaseModal
      open={open}
      viewButtons
      minWidth={320}
      title={'Adicionar venda'}
      handleClose={handleClose}
      handleSave={handleAoClicarEmSalvar}
    >
      <FlexBox width={smDown ? 320 : 640} column gap={10} marginTop={3}>

        <FlexBox gap={10} row>
          <Clientes
            isLoading={isLoading}
            setClientId={setClientId}
            setIsLoading={setIsLoading}
            clientIdParams={clientIdParams}
            clientNameParams={clientNameParams}
          />
        </FlexBox>

        <FlexBox gap={10} row minHeight={100}>
          <ProdutosListagem
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            productsSelect={productsSelect}
            setProductsSelect={setProductsSelect}
          />
        </FlexBox>

      </FlexBox>
    </BaseModal>
  );
};
