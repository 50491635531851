import { Api } from "../axios-config";
import { ISale } from "../../../models/Sale";
import { IResponseAmount } from "../../../models";
import { Environment } from "../../../environment";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IOrderService, IOrderServiceEdit, IOrderServiceInsert, IOrderServiceSaleInsert } from "../../../models/OrderServiceModel";


const insert = async (order: IOrderServiceInsert): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.post('/otica/OrderService', order).catch(data => {
        return data.response;
    });

    return data;
};

const insertWithSaleId = async (order: IOrderServiceSaleInsert): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.post('/otica/OrderService', order).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (order: IOrderService): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.put('/otica/OrderService', order).catch(data => {
        return data.response;
    });

    return data;
};

const edit = async (order: IOrderServiceEdit): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.put('/otica/OrderService', order).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (pagina: number): Promise<IResponseAPI<IResponseAmount<IOrderService[]>> | IResponseAPI<IResponseAmount<IOrderService[]>>> => {

    const { data } = await Api.get(`/otica/OrderService?page=${pagina}&pageSize=${Environment.LIMITE_DE_LINHAS}`).catch(data => {
        return data.response;
    });

    return data;
};

const getByClient = async (pagina: number, clientId: number): Promise<IResponseAPI<IResponseAmount<IOrderService[]>> | IResponseAPI<IResponseAmount<IOrderService[]>>> => {

    const { data } = await Api.get(`/otica/OrderService?clientId=${clientId}&page=${pagina}&pageSize=${Environment.LIMITE_DE_LINHAS}`).catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IOrderService[]>> | IResponseAPI<IResponseAmount<IOrderService[]>>> => {

    const { data } = await Api.get(`/otica/OrderService/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

const remove = async (id: number): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.delete(`/otica/OrderService/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const OrderServiceService = {
    insert,
    getAll,
    getById,
    getByClient,
    update,
    edit,
    insertWithSaleId,
    remove
};