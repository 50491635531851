import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Icon, InputLabel, MenuItem, Select, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { BaseModal } from '../../../../shared/components/modals/BaseModal';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { BranchService } from '../../../../shared/services/api/branch/BranchService';
import { useAuthContext } from '../../../../shared/contexts';
import dayjs from 'dayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IPlan } from '../../../../shared/models/Plan';
import { PlanService } from '../../../../shared/services/api/plans/PlanService';
import CurrencyFormat from '../../../../shared/helps/CurrencyFormat';
import { ITypeDayPayEnum } from '../../../../shared/models/enums/TypeDayPayEnum';
import { StorageService } from '../../../../shared/services/storage/storageService';
import { ModalTerm } from './ModalTerm';
import axios from 'axios';
import { TermService } from '../../../../shared/services/api/term/TermService';
import { ITerm } from '../../../../shared/models/Term';
import { CardBox } from '../../../../shared/components/layouts/CardBox';

interface IModalNovaBranch {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalNovaBranch: React.FC<IModalNovaBranch> = ({
  isOpen,
  setIsOpen,
}) => {

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { user, setRoles } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [doc, setDoc] = useState('');
  const [docError, setDocError] = useState('');
  const [plans, setPlans] = useState<IPlan[]>([])
  const [planId, setPlanId] = useState<number>(1)
  const [openTerm, setOpenTerm] = useState(false);
  const [term, setTerm] = useState<ITerm>({ id: 0, value: "" });
  const [termAccept, setTermAccept] = useState(false);
  const [ip, setIp] = useState('');
  const [promoCode, setPromoCode] = useState<string>();

  const path = useLocation()

  useEffect(() => {
    getPlans();
    fetchIP();
    getTerm();
  }, [])

  const fetchIP = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      setIp(response.data.ip);
    } catch (error) {
      console.error('Erro ao buscar o IP:', error);
    }
  };

  const getPlans = () => {
    PlanService.getPlans().then((result) => {
      if (result.success) {
        setPlans(result.data);
      } else {
        alert(result.errors[0])
      }
    })
  }

  const getTerm = () => {
    setIsLoading(true)
    TermService.get().then((result) => {
      if (result.success) {
        setTerm(result.data)
      } else {
        alert(result.errors[0])
      }
      setIsLoading(false)
    })
  }

  const handleTerm = () => {
    setTermAccept(prev => !prev)
  }

  const getDayPay = (item?: ITypeDayPayEnum) => {
    switch (item) {
      case ITypeDayPayEnum.Mensal:
        return "mês"
      case ITypeDayPayEnum.Trimestral:
        return "trimestral"
      case ITypeDayPayEnum.Semestral:
        return "semestral"
      case ITypeDayPayEnum.Anual:
        return "ano"
      default:
        return "mensal"
    }
  }

  const handleClose = () => { setTermAccept(false); setIsOpen(false) };

  const handleSubmit = () => {
    if (user.admin) {
      setIsLoading(true);
      var branch = {
        name: name,
        cnpjCpf: doc,
        planSubscriptionId: planId,
        promoCode: promoCode != undefined ? promoCode : null,
        termAcceptance: {
          termId: term.id,
          ipAddress: ip,
          userAgent: navigator.userAgent,
          explicitConsent: termAccept
        }
      }
      BranchService.insertBranch(branch).then(result => {
        if (result.success) {
          const storage = new StorageService();
          storage.saveRoles(result.data)
          setRoles(result.data)
          alert("Assinatura realizada com sucesso!");
          window.location.reload();
        }
        else {
          setNameError(result.errors[0]);
        }
        setIsLoading(false);
      })

    }
    else {
      setNameError("Apenas usuarios adiministradores podem inserir novas pontos de venda!");
    }

  };

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      minWidth={smDown ? 300 : 500}
    >
      <FlexBox column={smDown} gap={20}>
        <CardBox column jcCenter aiCenter paddingY={2}>
          <Icon sx={{ fontSize: 40 }} color='primary'>assignment</Icon>
          <Typography fontSize={13} fontWeight={500}>
            Plano de assinatura:
          </Typography>
          {/* <Typography>
            <strong>{plans.find(x => x.id == planId)?.name}</strong>
          </Typography> */}
          <Typography>
            <strong>{CurrencyFormat(plans.find(x => x.id == planId)?.value ?? 0)} / {getDayPay(plans.find(x => x.id == planId)?.typeDayPay)}</strong>
          </Typography>
        </CardBox>

        <CardBox column padding={1.5}>
          <Typography textAlign='center'>
            <strong>Próxima Fatura</strong>
          </Typography>

          <FlexBox jcBetween marginTop={1}>
            <Typography>
              Data:
            </Typography>
            <Typography>
              <strong>{dayjs(new Date()).add(3, 'day').format('DD/MM/YYYY')}</strong>
            </Typography>
          </FlexBox>

          {plans.find(x => x.id == planId)?.downPayment != undefined && plans.find(x => x.id == planId)?.descriptionDownPayment != undefined &&
            <FlexBox jcBetween>
              <Typography>
                {plans.find(x => x.id == planId)?.descriptionDownPayment}:
              </Typography>
              <Typography>
                <strong>{CurrencyFormat(plans.find(x => x.id == planId)?.downPayment ?? 0)}</strong>
              </Typography>
            </FlexBox>
          }
          <FlexBox jcBetween>
            <Typography>
              Total:
            </Typography>
            <Typography>
              <strong>{CurrencyFormat((plans.find(x => x.id == planId)?.value ?? 0) + (plans.find(x => x.id == planId)?.downPayment ?? 0))}</strong>
            </Typography>
          </FlexBox>
        </CardBox>

        {/* <Typography>
          Valor: <strong>{CurrencyFormat(plans.find(x => x.id == planId)?.value ?? 0)} / {getDayPay(plans.find(x => x.id == planId)?.typeDayPay)}</strong>
        </Typography> */}
      </FlexBox>
      <br />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Plano de assinatura</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={planId}
          label="Plano de assinatura"
          onChange={(e) => setPlanId(parseInt(e.target.value.toString()))}
        >
          {plans.length > 0 ? plans.map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>) : null}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        type='text'
        label='Nome do ponto de venda'
        size="small"
        variant="outlined"
        value={name}
        disabled={isLoading}
        error={!!nameError}
        helperText={nameError}
        onKeyDown={() => setNameError('')}
        onChange={e => setName(e.target.value)}

        sx={{ marginTop: 2 }}
      />
      <TextField
        fullWidth
        type='text'
        label='CNPJ ou CPF'
        size="small"
        variant="outlined"
        value={doc}
        disabled={isLoading}
        error={!!docError}
        helperText={docError}
        onKeyDown={() => setDocError('')}
        onChange={e => setDoc(e.target.value)}

        sx={{ marginTop: 2 }}
      />
      <TextField
        fullWidth
        type='text'
        label='Codigo promocional'
        size="small"
        variant="outlined"
        value={promoCode}
        disabled={isLoading}
        onKeyDown={() => setDocError('')}
        onChange={e => setPromoCode(e.target.value)}

        sx={{ marginTop: 2 }}
      />
      <FlexBox aiCenter marginTop={1.5}>
        <FormControlLabel checked={termAccept} onChange={handleTerm} control={<Checkbox defaultChecked />} label="" />
        <FlexBox marginLeft={-2} gap={10}>
          <Typography>Aceito os </Typography>
          <Typography fontWeight={600} onClick={() => setOpenTerm(true)} sx={{ cursor: 'pointer' }}>termos e condições</Typography>
          <Typography> de uso.</Typography>
        </FlexBox>
      </FlexBox>
      <FlexBox row fullWidth jcBetween marginTop={2}>
        {path.pathname == "/admin/filiais" &&
          <Button
            onClick={handleClose}
          >
            fechar
          </Button>
        }
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={!termAccept}
          endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
        >
          Salvar
        </Button>
      </FlexBox>
        <ModalTerm setIsOpen={setOpenTerm} isOpen={openTerm} term={term?.value} /> 
    </BaseModal>
  )
};
