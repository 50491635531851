import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Icon, Typography, LinearProgress, Pagination, useMediaQuery, Theme, CircularProgress, Tooltip, Chip, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Environment } from '../../../shared/environment';
import { ISale } from '../../../shared/models/Sale';
import dayjs from 'dayjs';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import { ITransaction } from '../../../shared/models/Transaction';
import { EditStatusTransaction } from '../components/EditStatusTransaction';
import { statusBarPayment } from '../components/StatusBarPayment';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { ModalAddTransaction } from '../components/TransactionModal/AddTransaction';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import { useAuthContext } from '../../../shared/contexts';
import { IRoleLeveEnum } from '../../../shared/models/enums/RoleLeveEnum';
import { TransactionService } from '../../../shared/services/api/transaction/TransactionService';
import { SaleService } from '../../../shared/services/api/sale/SaleService';

interface ListTransactionProps {
    sale: ISale | undefined;
    setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
};

export const ListTransaction: React.FC<ListTransactionProps> = ({ sale, setSale }) => {
    const { roles } = useAuthContext();
    const role = roles.find(x => x.isSelected);
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [openAdd, setOpenAdd] = useState(false);
    const [transactionSelect, setTransactionSelect] = useState<ITransaction>();
    const [totalCount, setTotalCount] = useState(sale?.transactions?.length ?? 0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (sale?.transactions?.length) {
            setTotalCount(sale.transactions?.length)
        }
    }, [sale])

    const editPage = (_: any, newPage: number) => {
        setPage(newPage);
    };

    const handleAddClose = () => {
        setOpenAdd(false)
    };

    function handleOpenDialog(event: React.MouseEvent<HTMLElement>, row: ITransaction) {
        if (role?.level != IRoleLeveEnum.Comum && row.toStatus.length > 0) {
            setTransactionSelect(row);
            setAnchorEl(event.currentTarget);
        };
    };

    let totalTransactions = 0;

    sale?.transactions.forEach(a => {
        if(a.status == 4 || a.status == 0)
            totalTransactions += a.value
    });
    const AddButton = sale?.status == 4 && totalTransactions <= sale.total && role?.level != IRoleLeveEnum.Comum;


    return (
        <FlexBox column padding={1}>

            {isLoading && (
                <LinearProgress variant="indeterminate" />
            )}

            <FlexBox row jcBetween marginBottom={2}>
                <Typography variant="h5">
                    Pagamentos
                </Typography>

                {AddButton &&
                    <Button variant='contained'
                        onClick={() => setOpenAdd(true)}
                    >
                        <Icon>
                            add
                        </Icon>
                        Adicionar
                    </Button>
                }
            </FlexBox>

            <TableContainer component={CardBox} column sx={{ width:'auto', overflow: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'><Typography fontSize={smDown ? 14 : 14} fontWeight={600}>Vencimento</Typography></TableCell>
                            <TableCell align='center'><Typography fontSize={smDown ? 14 : 14} fontWeight={600}>Valor</Typography></TableCell>
                            <TableCell align='center'><Typography fontSize={smDown ? 14 : 14} fontWeight={600}>Status</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sale?.transactions?.sort((a, b) => a.id - b.id).slice((page - 1) * Environment.LIMITE_DE_LINHAS, Environment.LIMITE_DE_LINHAS * page).map(row => (
                            <TableRow key={row.id} sx={{ /*cursor: 'pointer', */ '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
                                component={Box}
                            >
                                <TableCell align='center'>
                                    <Typography fontSize={smDown ? 15 : 16}>
                                        {smDown ? dayjs(row.date).format('DD/MM/YYYY') : dayjs(row.date).format('DD / MM / YYYY')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography fontSize={smDown ? 15 : 16}>
                                        {CurrencyFormat(row.value)}
                                    </Typography>
                                </TableCell>
                                <TableCell align='center' padding='none'>
                                    <Typography>
                                        <div>
                                            <Tooltip title="Alterar Status">
                                                <Chip
                                                    variant="filled"
                                                    size={smDown ? 'small' : 'medium'}
                                                    onClick={e => handleOpenDialog(e, row)}
                                                    disabled={row.toStatus.length == 0}
                                                    label={statusBarPayment(row?.status).label}
                                                    sx={{
                                                        backgroundColor: statusBarPayment(row?.status).color, color: 'white',
                                                        '&:hover': { transform: 'scale(1.1)', transition: 'all 0.15s ease-in-out', cursor: 'pointer', boxShadow: '1px 3px 5px 1px #e3e3e3', },
                                                        '&:active': { transform: 'scale(0.9)', transition: 'all 0.05s ease-in-out', boxShadow: '1px 0px 10px 1px #f1f1f1' }
                                                    }}
                                                />
                                            </Tooltip>
                                            {isLoading && <CircularProgress size={20} />}
                                        </div>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {totalCount == 0 && !isLoading && (
                        <caption>{Environment.LISTAGEM_VAZIA}</caption>
                    )}
                </Table>
                <FlexBox padding={2}>
                    {isLoading && (
                        <LinearProgress variant='indeterminate' />
                    )}
                    {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
                        <Pagination
                            page={page}
                            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                            onChange={(_, newPage) => editPage(_, newPage)}
                        />
                    )}
                </FlexBox>
            </TableContainer>

            <EditStatusTransaction
                open={open}
                transaction={transactionSelect}
                setIsLoading={setIsLoading}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                setSale={setSale}
            />

            <ModalAddTransaction
                setSale={setSale}
                saleId={sale?.id as number}
                openModal={openAdd}
                handleClose={handleAddClose}
            />

        </FlexBox>
    )
};