import { Typography, Button, Icon, Stack, Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FlexBox from '../../../../../shared/components/layouts/FlexBox'
import { IOptionNewClient } from '../Modal-NovoCLiente2';
import { LocationService } from '../../../../../shared/services/api/location/LocationService';
import { Env } from '../../../../../shared/environment';
import { NewClientStorage } from '../../../../../shared/services/storage/newClientStorage';

interface SectionAddressProps {
  position: number;
  optionsNew: IOptionNewClient[];
  handleToBack(): void
  handleAdvance: () => void;
  handleSubmit(): void;
}

export const SectionAddress: React.FC<SectionAddressProps> = (props) => {
  const { handleAdvance, handleToBack, position, optionsNew, handleSubmit } = props;
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("BR");
  const [complement, setComplement] = useState("");
  const [reference, setReference] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    getCep()
    getStorage();
  }, [cep])

  const getStorage = () => {
    const storage = new NewClientStorage();
    const address = storage.getAddressNewUser();
    if (!!address) {
      setCep(address.cep);
      setStreet(address.street);
      setNumber(address.number);
      setDistrict(address.district);
      setCity(address.city);
      setState(address.state);
      setCountry(address.country);
      setComplement(address.complement);
      setReference(address.reference);
      setLatitude(address.latitude);
      setLongitude(address.latitude);
      storage.removeAddressNewUser();
    }
  }

  const saveStorage = () => {
    const address = {
      cep: cep,
      street: street,
      number: number,
      district: district,
      city: city,
      state: state,
      country: country,
      complement: complement,
      reference: reference,
      latitude: latitude,
      longitude: longitude,
    }
    const storage = new NewClientStorage();
    storage.saveAddressNewUser(address);
  }

  const getCep = () => {
    if (cep.length > 7) {
      LocationService.getCep(cep).then((data) => {
        if (data.success && !!data.data) {
          setStreet(data.data[0].street)
          setDistrict(data.data[0].district)
          setCity(data.data[0].city)
          setState(data.data[0].state)
          setLatitude(data.data[0].latitude)
          setLongitude(data.data[0].longitude)
          setCountry(data.data[0].country)
        }
      })
    }
  }

  const next = () => {
    saveStorage();
    handleAdvance()
  }
  const back = () => {
    saveStorage();
    handleToBack()
  }
  const submit = () => {
    saveStorage();
    handleSubmit()
  }

  return (
    <FlexBox column gap={1}>
      <Typography>Endereço</Typography>
      <Stack
        direction='column'
        paddingTop={1}
        paddingBlock={1}
      >
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          // border='1px solid black'
          >
            <TextField size="small"
              label='Cep'
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
            <TextField size="small"
              label='Endereço'
              value={street}
              onChange={e => setStreet(e.target.value)}
            />
            <TextField size="small"
              label='Numero'
              value={number}
              onChange={e => setNumber(e.target.value)}
            />
            <TextField size="small"
              label='Bairro'
              value={district}
              onChange={e => setDistrict(e.target.value)}
            />
            <TextField size="small"
              label='Cidade'
              value={city}
              onChange={e => setCity(e.target.value)}
            />
            <TextField size="small"
              label='Estado'
              value={state}
              onChange={e => setState(e.target.value)}
            />
            <TextField size="small"
              label='Complemento'
              value={complement}
              onChange={e => setComplement(e.target.value)}
            />
            <TextField size="small"
              label='Referencia'
              value={reference}
              onChange={e => setReference(e.target.value)}
            />
          </Stack>
        </Box>
      </Stack>
      <FlexBox row jcBetween>
        <Button variant="text" startIcon={<Icon>fast_rewind_rounded</Icon>} onClick={() => back()}>
          Voltar
        </Button>
        {
          (optionsNew.filter(x => x.active)[optionsNew.filter(x => x.active).length - 1]?.position != position) && (
            <Button variant="outlined" endIcon={<Icon>forward_rounded</Icon>} onClick={() => next()}>
              Avançar
            </Button>)
        }
        {
          (optionsNew.filter(x => x.active)[optionsNew.filter(x => x.active).length - 1]?.position == position) && (
            <Button variant="contained" endIcon={<Icon>save_rounded</Icon>} onClick={submit}>
              Salvar
            </Button>)
        }
      </FlexBox>
    </FlexBox>
  )
};