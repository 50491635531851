import { Auth } from "../../../models/Auth";
import { IResponseAmount } from "../../../models/base/IResponseAmount";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { Api } from "../axios-config";

const auth = async (email: string, password: string): Promise<IResponseAPI<Auth>> => {
  try {
    const { data } = await Api.post('/otica/Auth/SignIn', { email, password })
    return data
  } catch (error: any) {
    return error.response.data;
  }
};

const register = async (name: string, email: string, nameCompany: string, password: string, contact: string, hash?: string): Promise<IResponseAPI<boolean>> => {

  const request = {
    name: name,
    email: email,
    password: password,
    company: {
      name: nameCompany,
      phone: contact,
      hash: hash
    }
  }
    const { data } = await Api.post('/otica/Auth/Register', request).catch((data) => {
      return data.response;
    });

    return data;
};

const confirmEmail = async (hash: string): Promise<IResponseAPI<any> | IResponseAPI<any>> => {

  const { data } = await Api.post(`/otica/Auth/ConfirmEmail?hash=${hash}`).catch((data) => {
    return data.response;
  });
  return data;
}

const signinToken = async (): Promise<IResponseAPI<Auth>> => {
  const { data } = await Api.get(`/otica/Auth/SigninToken`).catch((data) => {
    return data.response;
  });
  return data;
}

const forgotPassword = async (email: string): Promise<IResponseAPI<any> | IResponseAPI<any>> => {

  const { data } = await Api.post(`/otica/Auth/ResetPassword?email=${email}`).catch((data) => {
    return data.response;
  });
  return data;
}

const resetPassword = async (password: string, hash: string): Promise<IResponseAPI<any> | IResponseAPI<any>> => {

  const { data } = await Api.post(`/otica/Auth/ChangePassword`, { hash, password }).catch((data) => {
    return data.response;
  });
  return data;
}

const insertUser = async (name: string, email: string, companyId: number, password: string): Promise<IResponseAPI<any> | IResponseAPI<any>> => {
  const request = {
    name: name,
    email: email,
    password: password,
    companyId: companyId
  }
  const { data } = await Api.post(`/otica/Auth/Register`, request).catch((data) => {
    return data.response;
  });
  return data;
}

const getUsers = async (): Promise<IResponseAPI<IResponseAmount<any>> | any> => {
  const { data } = await Api.get(`/otica/Auth/Users`).catch((data) => {
    if (data?.response != undefined)
      return data.response;
    return data;
  });
  return data;
}

const refreshToken = async (refreshToken: string): Promise<IResponseAPI<Auth> | IResponseAPI<Auth>> => {
  const { data } = await Api.get(`/otica/Auth/RefreshToken?refreshToken=${refreshToken}`).catch((data) => {
    return data.response;
  });
  return data
}

export const AuthService = {
  auth,
  register,
  confirmEmail,
  forgotPassword,
  resetPassword,
  insertUser,
  getUsers,
  refreshToken,
  signinToken
};