import { IconButton, Chip } from '@mui/material';
import React from 'react';
import FlexBox from '../layouts/FlexBox';



interface CategoriaMenuAlterarStatusProps {
    setStatus: (value: React.SetStateAction<boolean>) => void;
    status: boolean;
};

export const ButtonStatusUpdate: React.FC<CategoriaMenuAlterarStatusProps> = (props) => {
    const { setStatus, status } = props;

    function handleChangeStatus() {
        setStatus(prev => !prev);
    };

    return (
        <FlexBox>
            <IconButton onClick={handleChangeStatus}>
                <Chip
                    variant={status ? "outlined" : "filled"}
                    size='small'
                    label={status ? "Ativo" : "Desativado"}
                    color={status ? "success" : "error"}
                />
            </IconButton>
        </FlexBox>
    );
};
