import React, { Dispatch, SetStateAction, useState } from 'react';
import { TextField } from '@mui/material';

import { LaboratoryService } from '../../../../../shared/services/api/laboratory/LaboratoryService';
import { LaboratoryInsert } from '../../../../../shared/models/classes/laboratory/Laboratory';
import { InputCellphone } from '../../../../../shared/components/inputs/InputCellphone';
import { BaseModal } from '../../../modals/BaseModal';
import { ILaboratory } from '../../../../../shared/models/Laboratory';
import FlexBox from '../../../layouts/FlexBox';
import { IOptionLaboratory } from '../AutoCompleteLaboratoryAdd';


interface ModalAutoCompleteAddLaboratoryProps {
  isOpenAdd: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsOpenAdd: Dispatch<SetStateAction<boolean>>;
  setRows: Dispatch<SetStateAction<ILaboratory[]>>;
  setOptions: Dispatch<SetStateAction<IOptionLaboratory[]>>
}

export const ModalAutoCompleteAddLaboratory: React.FC<ModalAutoCompleteAddLaboratoryProps> = (props) => {
  const { isOpenAdd, setIsOpenAdd, setRows, isLoading, setIsLoading, setOptions } = props;

  // const modelEmpty = new Laboratory({});

  const [name, setName] = useState<string | undefined>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  function handleSave() {

    const modelInsert = new LaboratoryInsert({ name: name, phoneNumber: phoneNumber });

    setIsLoading(true);

    LaboratoryService.insert(modelInsert).then(result => {
      setIsLoading(false);

      if (result.success) {
        const newLab = result.data;
        setRows(prev => [...prev, newLab]);
        setOptions(prev => [...prev, { id: newLab.id, label: newLab.name }]);

        handleClose();
      }
      else {
        alert(result.errors[0]);
      }
    });
  };

  function handleClose() {
    setIsOpenAdd(false);
  };


  return (
    <BaseModal
      viewButtons
      minWidth={300}
      open={isOpenAdd}
      isLoading={isLoading}
      handleSave={handleSave}
      handleClose={handleClose}
      title='Adicionar Laboratório'
    >
      <FlexBox column gap={10}>
        <TextField
          fullWidth
          label='Nome'
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <InputCellphone
          value={phoneNumber ?? ""}
          label='Telefone'
          setValue={setPhoneNumber}
        />
      </FlexBox>
    </BaseModal>
  )
}
