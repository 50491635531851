import React, { useState } from 'react';
import { Button, Stack, Theme, useMediaQuery } from '@mui/material';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { BaseModal } from '../../../../shared/components/modals/BaseModal';
import { MeioDePagamento } from '../../../../shared/components/addVendasModal/sections';
import { IPaymentSaleInsert } from '../../../../shared/models/PaymentSale';
import { TransactionService } from '../../../../shared/services/api/transaction/TransactionService';
import { ISale } from '../../../../shared/models/Sale';



interface BaseModalProps {
  handleClose: () => void;
  openModal: boolean;
  saleId: number
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
};

export const ModalAddTransaction: React.FC<BaseModalProps> = (props) => {
  const { handleClose, openModal, saleId, setSale } = props;
  const [payments, setPayments] = useState<IPaymentSaleInsert>();

  function handleAoClicarEmSalvar() {
    if (payments != undefined)
      TransactionService.insert(payments).then((result) => {
        if (result.success) {
          alert("Pagamento cadastrado com sucesso!")
          setSale(result.data)
          setPayments(undefined);
          handleClose()
        } else {
          alert(result.errors[0])
        }
      })
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <BaseModal
      handleClose={handleClose}
      open={openModal}
      title={'Editar Pagamento'}
      minWidth={320}
    >
      <FlexBox width={smDown ? 320 : 640}
        column gap={10}
        marginTop={3}
      >
        <FlexBox gap={10} row minHeight={100}>
          <MeioDePagamento
            saleId={saleId}
            payments={payments}
            setPayments={setPayments}
          />
        </FlexBox>
        <Stack
          marginTop={2}
          direction='row'
          justifyContent='space-between'
        >
          <Button onClick={handleClose}>Fechar</Button>
          <Button variant='contained' onClick={handleAoClicarEmSalvar}>Salvar</Button>
        </Stack>

      </FlexBox>
    </BaseModal>
  );
};
