import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import * as yup from 'yup';

import Logo from "../../shared/assets/images/OticaSystems_roxo.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../shared/contexts";

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(8).matches(/.*[!@#$%^&*(),.?":{}|<>].*/, 'A senha deve conter pelo menos um caractere especial'),
})

export const Login: React.FC = () => {
  const { login, handleSigninToken } = useAuthContext();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { hash } = useParams();


  if (hash != undefined) {
    handleSigninToken(hash).then(response => {
      if (response.success) {
        navigate('/painel')
      }
    });
  }

  const handleSubmitLogin = () => {
    setIsLoading(true);
    loginSchema
      .validate({ email, password }, { abortEarly: false })
      .then(dadosValidados => {
        login(dadosValidados.email, dadosValidados.password)
          .then((data) => {
            setIsLoading(false);
            if (data.errors?.length > 0) {
              setPasswordError(data.errors[0])
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach(error => {
          if (error.path == 'email') {
            setEmailError(error.message);
          } else if (error.path == 'password') {
            setPasswordError(error.message);
          }
        });
      });
  };

  const handleToggleVisibility = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <FlexBox
      jcCenter aiCenter height="100vh" fullWidth
      sx={{ background: "#F4F7FE" }}

    >
      <form onSubmit={e => { e.preventDefault(); handleSubmitLogin() }}>
        <FlexBox jcCenter aiCenter column gap={20} paddingX={4}>
          <img style={{ marginBottom: 10 }} width={200} src={Logo} />

          <TextField
            fullWidth
            type='email'
            label='Email'
            size="small"
            value={email}
            disabled={isLoading}
            error={!!emailError}
            helperText={emailError}
            onKeyDown={() => setEmailError('')}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label='Senha'
            type={showPassword ? "text" : "password"}
            size="small"
            value={password}
            disabled={isLoading}
            error={!!passwordError}
            helperText={passwordError}
            onKeyDown={() => setPasswordError('')}
            onChange={e => setPassword(e.target.value)}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleToggleVisibility}
                    onMouseDown={(event) => event.preventDefault()} // Evita perder o foco ao clicar
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Typography fontSize='14px' textAlign='center' component="a" href="/forgotPassword">
            Esqueci minha senha
          </Typography>

          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
            size="large"
          >
            Entrar
          </Button >

          <Typography fontSize='14px' textAlign='center' marginTop={0}>
            Não tem uma conta? <Typography component='a' href="/register">Cadastre-se aqui</Typography>
          </Typography>
        </FlexBox>
      </form>
    </FlexBox>
  )
}