import { IImageProduct } from "./ImageProduct";

export interface IProductSale {
  id: number;
  name?:string;
  categoryProduct?: string;
  markProduct?: string;
  modelProduct?: string;
  colorProduct?: string;
  codeColorProduct?: string;
  materialProduct?: string;
  gender?: number;
  imageProducts?: IImageProduct[];
  quantity: number;
  cost: number;
  value: number;
  saleId: number;
};

export interface IProductSaleInsert {
  name?: string;
  stockProductId: number;
  quantity: number;
  value: number;
  saleId?: number;
};

export interface IProductSaleInsertNotName {
  stockProductId: number;
  quantity: number;
  value: number;
  saleId?: number;
};


export class ProductSale implements IProductSale {
  id: number;
  name?: string;
  quantity: number;
  cost: number;
  value: number;
  saleId: number;

  constructor(id: number, quantity: number, cost: number, value: number, saleId: number) {
    this.id = id;
    this.quantity = quantity;
    this.cost = cost;
    this.value = value;
    this.saleId = saleId;
  }

  // Método pra calcular o valor total de venda
  calculateTotal(): number {
    return this.quantity * this.value;
  }
};

export class ProductSaleInsertNotName implements IProductSaleInsertNotName {
  quantity: number;
  stockProductId: number;
  value: number;
  saleId?: number;

  constructor(stockProductId: number, quantity: number, value: number, saleId?: number) {
    this.quantity = quantity;
    this.stockProductId = stockProductId;
    this.value = value;
    this.saleId = saleId;
  }

  calculateTotal(): number {
    return this.quantity * this.value;
  }
};


export class ProductSaleInsert implements IProductSaleInsert {
  name?: string;
  quantity: number;
  stockProductId: number;
  value: number;
  saleId?: number;

  constructor(stockProductId: number, quantity: number, value: number, name?: string, saleId?: number) {
    this.name = name;
    this.quantity = quantity;
    this.stockProductId = stockProductId;
    this.value = value;
    this.saleId = saleId;
  }

  calculateTotal(): number {
    return this.quantity * this.value;
  }
};