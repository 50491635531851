import { Button, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import React, { FormEvent, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { TransactionService } from '../../../shared/services/api/transaction/TransactionService';
import { InputCurrency } from '../../../shared/components/inputs/InputCurrency';
import { IRoleLeveEnum } from '../../../shared/models/enums/RoleLeveEnum';
import { BaseModal } from '../../../shared/components/modals/BaseModal';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { useAuthContext } from '../../../shared/contexts';


interface BaseModalProps {
  handleClose: () => void;
  openModal: boolean;
};

export const ModalAddTransaction: React.FC<BaseModalProps> = (props) => {
  const { handleClose, openModal } = props;

  const { roles } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState(1);
  const [description, setDescription] = useState('')
  const role = roles.filter(x => x.isSelected)[0];
  const [value, setValue] = useState(0);
  const [valueErr, setValueErr] = useState<boolean>(false);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const options = role.level == IRoleLeveEnum.Administrador ? [
    {
      value: 1,
      label: 'Despesa',
    },
    {
      value: 2,
      label: 'Retirada',
    }
  ] : [
    {
      value: 1,
      label: 'Despesa',
    }
  ];


  function handleAoClicarEmSalvar(e: FormEvent) {
    e.preventDefault();
      setIsLoading(true);
      TransactionService.insertTransaction({
        value: value/100,
        date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        description: description,
        type: option,
        paymentMethodType: 0,
      }).then(
        result => {
          if (result.success && result.data) {
            alert("Registro inserido com sucesso!");
            window.location.reload();
          } else {
            alert(result.errors[0]);
          }
          setIsLoading(false);
        }
      );
  };


  return (
    <BaseModal
      handleClose={handleClose}
      open={openModal}
      title={'Adicionar Registro'}
      minWidth={300}
    >
      <FlexBox width={smDown ? 300 : 400}
        column gap={10}
        marginTop={3}
      >
        <FlexBox gap={10} column minHeight={100}>

          <TextField
            label='Tipo de registro'
            select
            SelectProps={{ native: true }}
            defaultValue={option}
            onChange={(e) => setOption(parseInt(e.target.value))}
            required
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          <TextField
            type="text"
            name="description"
            label="Descrição"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <InputCurrency
            label="Valor"
            errorValue={valueErr}
            value={value}
            setErrorValue={setValueErr}
            setValue={setValue}
          />

        </FlexBox>


        <Stack marginTop={2} direction='row' justifyContent='space-between'>

          <Button disabled={isLoading} onClick={handleClose}>Fechar</Button>
          <Button variant='contained' onClick={(e) => handleAoClicarEmSalvar(e)}>Salvar</Button>
          
        </Stack>

      </FlexBox>
    </BaseModal>
  );
};
