import React, { useState } from 'react';
import { Box, Divider, Paper, useTheme } from '@mui/material';

import { ProductReportPage, VendasPage } from '.';
import { ButtonCell } from './components/ButtonCell';
import { LayoutBaseDePagina } from '../../shared/layouts';
import FlexBox from '../../shared/components/layouts/FlexBox';
import { CardBox } from '../../shared/components/layouts/CardBox';
import { useListagemDeRelatoriosContext } from '../../shared/contexts/pages/ListagemDeRelatoriosContext';


interface ListagemDeRelatoriosProps {

}

export const ListagemDeRelatorios: React.FC<ListagemDeRelatoriosProps> = (props) => {
  const { } = props;
  const { pageName, title } = useListagemDeRelatoriosContext();
  const theme = useTheme();

  return (
    <LayoutBaseDePagina
      titulo={title ?? "Relatórios"}
      buttonToBack
    >
      <FlexBox column
      // CardBox
      >


        <CardBox paddingY={1} paddingX={2} gap={10} flexWrap="wrap" aiCenter >
          <ButtonCell value={pageName} defaultValue={'produtos'} title='Produtos' />|
          <ButtonCell value={pageName} defaultValue={'vendas'} title='Vendas' />
        </CardBox>


        {/* <Divider variant='fullWidth' /> */}

        <FlexBox paddingTop={1}>
          {pageName === 'produtos' && <ProductReportPage />}
          {pageName === 'vendas' && <VendasPage />}
        </FlexBox>

      </FlexBox>
    </LayoutBaseDePagina>
  )
};