import { Dispatch, FormEvent, SetStateAction, useRef, useState } from "react";
import { BaseModal } from "../../../shared/components/modals/BaseModal"
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { Button, CircularProgress, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { CashRegisterService } from "../../../shared/services/api/CashRegister/CashRegister";
import { ICashRegister } from "../../../shared/models/CashRegister";
import CurrencyFormat from "../../../shared/helps/CurrencyFormat";
import { InputNumericRef } from "../../../shared/components/inputs/InputNumericRef";
import { InputCurrency } from "../../../shared/components/inputs/InputCurrency";

interface ModalNovoProdutoProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string
  cashRegister: ICashRegister | undefined;
}

export const ModalCashRegister: React.FC<ModalNovoProdutoProps> = ({ isOpen, setIsOpen, title, cashRegister }) => {

  function handleClose() {
    setIsOpen(false);
  };

  const receita = cashRegister?.transactions?.filter(x => x.type == 0).reduce((sum, nr) => sum + nr.value, 0);
  const despesa = cashRegister?.transactions?.filter(x => x.type == 1).reduce((sum, nr) => sum + nr.value, 0);
  const retirada = cashRegister?.transactions?.filter(x => x.type == 2).reduce((sum, nr) => sum + nr.value, 0);
  const valueExist = cashRegister?.valueClose ?? ((cashRegister?.valueOpen ?? 0) + (receita ?? 0) - (despesa ?? 0) - (retirada ?? 0));

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(valueExist);
  const [valueErr, setValueErr] = useState<boolean>(false);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (value != undefined) {
      CashRegisterService.insert(value/100).then(
        result => {
          setIsLoading(true);
          if (result.success && result.data) {
            alert("Registro inserido com sucesso!");
            window.location.reload();
          } else {
            alert(result.errors + result.message);
          }
          setIsLoading(false);
        }
      );
    }else{
      setValueErr(true);
    };
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={handleClose}
      title={title}
    >
      <FlexBox
        minHeight="100px"
        column gap={10} marginTop={4}
      >
        {value != undefined && cashRegister != undefined ? (

          <Typography>
            Valor: {CurrencyFormat(valueExist)}
          </Typography>
        ) : (
          <InputCurrency
            label="Valor"
            errorValue={valueErr}
            value={value}
            setErrorValue={setValueErr}
            setValue={setValue}
          />
        )}

        <FlexBox row jcBetween marginTop={4} >
          <Button variant="outlined"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button variant="contained"
            type="submit"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </FlexBox>
      </FlexBox>
    </BaseModal>
  )
}
