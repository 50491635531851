import { Autocomplete, TextField, CircularProgress, Typography, Divider } from '@mui/material';
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { IProduct } from '../../../../shared/models/Product';
import { EstoquesService } from '../../../../shared/services/api/estoques/EstoquesService';
import { IStockProduct } from '../../../../shared/models/StockProduct';
import { useDebounce } from '../../../../shared/hooks';
import { CardProduto } from '../../../vendas/components/addVendasModal/components/CardProduto';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import GenderFormat from '../../../../shared/helps/GenderFormat';
import imgSemFoto from '../../../../shared/assets/images/sem-imagem.jpg';

interface Option {
  product: IProduct
  label: string,
  id: number
};

interface AutoCompleteProdutosProps {
  setProductSelect: Dispatch<SetStateAction<IProduct | undefined>>;
}

export const AutoCompleteProdutos: React.FC<AutoCompleteProdutosProps> = ({ setProductSelect }: AutoCompleteProdutosProps) => {

  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [produtoName, setProdutoName] = useState('');
  const [dbProdutos, setDbProdutos] = useState<IProduct[]>([]);
  const [options, setOptions] = useState<Option[]>([])


  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      EstoquesService.getAll(1, produtoName, 20)
        .then((result) => {
          if (result.success) {
            const products = result!.data.data;
            setDbProdutos(products);
            setOptions(products.filter(x => x.isActive).map(p => {
              return { id: p.id, label: p.name, product: p }
            }))
          } else {
            alert(result.errors[0]);
          };
          setIsLoading(false);
        });
    });
  }, [produtoName, debounce])


  return (
    <Autocomplete
      disablePortal
      disableListWrap
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      options={options}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props} key={option.id}  style={{ display: 'flex' }}>
          <OptionProduct product={option.product} />
        </li>
      )}
      sx={{ width: '100%' }}
      onChange={(_, value) => {
        if (value) {
          setProductSelect(dbProdutos.find(x => x.id == value.id))
        };
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Pesquisar...'
          variant='outlined'
          size='small'
          value={produtoName}
          onChange={(e) => {
            setProdutoName(e.target.value);
          }}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
};

interface OptionProductProps {
  product: IProduct;
}

export const OptionProduct: React.FC<OptionProductProps> = ({ product }) => {
  return (
    <FlexBox fullWidth aiCenter gap={5} paddingY={1}>
        <FlexBox>
          {product.imageProducts?.at(0)?.imageUrl != undefined ? (
            <img width={100} src={product.imageProducts?.at(0)?.imageUrl} />
          ) : (
            <img width={100} src={imgSemFoto} />
          )}
        </FlexBox>
        <FlexBox fullWidth column gap={1}>
          <Typography fontSize={11}>Codigo: <b>{product.id}</b></Typography>
          <Typography fontSize={11}>Nome: <b>{product.name}</b></Typography>
          <Typography fontSize={11}>Categoria: {product.categoryProduct?.name}</Typography>
          {product.modelProduct?.name != undefined && <Typography fontSize={11}>Modelo: {product.modelProduct?.name}</Typography>}
          {product.materialProduct?.name != undefined && <Typography fontSize={11}>Material: {product.materialProduct?.name}</Typography>}
          {product.markProduct?.name != undefined && <Typography fontSize={11}>Marca: {product.markProduct?.name}</Typography>}
          {product.gender != undefined && <Typography fontSize={11}>Gênero: {GenderFormat(product.gender)}</Typography>}
        </FlexBox>
    </FlexBox>
  );
}