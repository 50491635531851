import { Box, Button, CircularProgress, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { ModalAddPrescrition } from "../../../pages/clients/components/detalheDeClientes/modals/ModalAddPrescrition";
import { OrderServiceService } from "../../services/api/orderService/OrderServiceService";
import { IOrderService, OrderServiceEdit } from "../../models/OrderServiceModel";
import { IPrescription } from "../../models/Prescription";
import { InputFile } from "../inputs/input-file/InputFile";
import { BaseModal } from "../modals/BaseModal";
import { ISale } from "../../models/Sale";
import { Env } from "../../environment";
import { AutoCompleteLaboratory } from "./components/AutoCompleteLaboratory";
import { ILaboratory } from "../../models/Laboratory";
import { IPrescriptionToIprescriptionUpdate } from "../../models/classes/prescription/Mapper";

interface BaseModalProps {
  sale: ISale;
  open: boolean;
  order: IOrderService;
  handleClose: () => void;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>
};


export const ModalEditOrder: React.FC<BaseModalProps> = (props) => {
  const { handleClose, setSale, sale, open, order } = props;

  const [cost, setCost] = useState(order.cost.toString());
  const [description, setDescription] = useState(order.description);
  const [laboratorySelect, setLaboratorySelect] = useState<ILaboratory | undefined>(order.laboratory);
  const [openPrescrition, setOpenPrescrition] = useState(false);
  const [prescrition, setPrescrition] = useState<IPrescription | undefined>(order.prescription);
  const [forecast, setForecast] = useState(dayjs(order.forecast).format('YYYY-MM-DD')); // new Date().toLocaleDateString()
  const [base64, setBase64] = useState<string>('');
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=> {
    setLaboratorySelect(order.laboratory)
  },[])
  const handleClosePrecristion = () => {
    setOpenPrescrition(false)
  };

  const handlerAddPrescrition = (prescriptionAdd: IPrescription | undefined) => {
    setPrescrition(prescriptionAdd);
  };

  function handleAoClicarEmSalvar() {

    if (prescrition !== undefined && laboratorySelect !== undefined) {
      const model = new OrderServiceEdit(
        {
          id: order.id,
          cost: Number(cost),
          status: order.status,
          forecast: new Date(forecast),
          imageUrl: base64,
          description: description,
          laboratoryId: laboratorySelect.id,
          prescription: IPrescriptionToIprescriptionUpdate(prescrition),
        }
      );
        

      OrderServiceService.edit(model).then(data => {
        setIsLoading(true);

        if (data.success) {
          alert("O.S editada com sucesso!");
          setSale(data!.data);
          closeModal();
        }
        else {
          alert(data.errors[0]);
        }

        setIsLoading(false);
      });
    }
    else {
      alert("Obrigatório inserir Receita!");
    }
  };

  const closeModal = () => {
    handleClose();
  };

  return (
    <BaseModal
      handleClose={closeModal}
      open={open}
      title={'Editar O.S'}
      minWidth={350}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600} marginTop={2}
        paddingTop={1}
        paddingBlock={1}
      >
        <Stack
          direction='column'
          overflow='auto'
          width={"100%"}
          marginBottom={2}
          alignItems={"end"}
        >
        </Stack>
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <TextField size="small"
              type="Date"
              label='Previsão'
              value={forecast}
              onChange={e => setForecast(e.target.value)}
            />
            <TextField size="small"
              label='Descrição'
              multiline
              rows={4}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />

            <AutoCompleteLaboratory
              laboratory={laboratorySelect}
              setLaboratorySelect={setLaboratorySelect}
            />

            {/* <TextField size="small"
              label='Custo'
              value={cost}
              onChange={e => setCost(e.target.value)}
            /> */}
            <InputFile buttonText="Adicionar imagem" setImage={setBase64} />
          </Stack>
        </Box>
      </Stack>
      <Box display='flex' justifyContent='start' width='100%'>
        <Button
          variant="outlined"
          onClick={() => setOpenPrescrition(true)}
        >
          {prescrition ? 'Editar receita' : 'Adicionar receita'}
        </Button>
      </Box>
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={1}
      >
        <Button onClick={closeModal}>Fechar</Button>
        <Button
          variant='contained'
          disabled={isLoading}
          onClick={handleAoClicarEmSalvar}
          endIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : null}
        >
          Editar O.S
        </Button>
      </Stack>

      <ModalAddPrescrition
        addMode
        open={openPrescrition}
        prescrition={prescrition}
        handleClose={handleClosePrecristion}
        setPrescrition={handlerAddPrescrition}
      />
    </BaseModal>
  )
};