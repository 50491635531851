import { DarkTheme, LightTheme } from "../themes";
import { ThemeProvider } from "@emotion/react";
import { ReactNode, useCallback, useContext, useMemo, useState, createContext } from "react";
import { Box, Typography } from "@mui/material";
import { useAuthContext } from "./AuthContext";
import FlexBox from "../components/layouts/FlexBox";

interface IThemeContextData {
    themeName: 'light' | 'dark';
    toggleTheme: () => void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
    return useContext(ThemeContext);
}

interface IAppThemeProviderProps {
    children: ReactNode
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {
    const [themeName, setThemeName] = useState<'light' | 'dark'>('light');
    const { roles, isAuthenticated } = useAuthContext();
    const branch = roles.find(x => x.isSelected)?.branch;
    const toggleTheme = useCallback(() => {
        setThemeName(oldThemeName => oldThemeName == 'light' ? 'dark' : 'light');
    }, []);

    const theme = useMemo(() => {
        if (themeName == 'light') return LightTheme;

        return DarkTheme;
    }, [themeName]);

    return (
        <ThemeContext.Provider value={{ themeName, toggleTheme }}>
            <ThemeProvider theme={theme}>
                {isAuthenticated && !branch?.isActive && !branch?.freeTier && <FlexBox width={'100%'} height={'30px'} jcCenter aiCenter sx={{ backgroundColor: 'red' }}>
                    <Typography color="white" fontWeight={700}>
                        Bloqueio por atraso no pagamento!
                    </Typography>
                </FlexBox>}
                {branch?.freeTier && branch?.freeTierDays > 0 && <FlexBox width={'100%'} height={'30px'} jcCenter aiCenter sx={{ backgroundColor: 'orange' }}>
                    <Typography color="white" fontWeight={700}>
                        Período de teste acaba em {branch?.freeTierDays} Dias!
                    </Typography>
                </FlexBox>}
                {!branch?.isActive && branch?.freeTier && <FlexBox width={'100%'} height={'30px'} jcCenter aiCenter sx={{ backgroundColor: 'orange' }}>
                    <Typography color="white" fontWeight={700}>
                        Seu período de teste acabou!
                    </Typography>
                </FlexBox>}
                <FlexBox jcCenter width="100%" minHeight='100vh' sx={{ background: "linear-gradient(to right, #ead4ec92, #c9d6ff)" }}>
                    {children}
                </FlexBox>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};