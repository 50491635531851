import React, { useState } from 'react';
import { Button, Fade, Menu, MenuItem, TextField } from '@mui/material';
import FlexBox from '../../../layouts/FlexBox';
import { InputCurrency } from '../../../inputs/InputCurrency';

interface CrediarioPaymentSectionProps {
  value: number;
  dateDue: string;
  quantity: number;
  errorValue: boolean;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  setErrorValue: React.Dispatch<React.SetStateAction<boolean>>;
  setDateDue: React.Dispatch<React.SetStateAction<string>>;
}

export const CrediarioPaymentSection: React.FC<CrediarioPaymentSectionProps> = (props) => {
  const { setValue, setQuantity, value, errorValue, setErrorValue, dateDue, setDateDue, quantity } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonValue, setButtonValue] = useState(1);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (numeroParcelas: number) => {
    setAnchorEl(null);
    setButtonValue(numeroParcelas);
    setQuantity(numeroParcelas);
  };

  const handleSaveChange = (index: number) => {
    setQuantity(index + 1);
    handleClose(index + 1);
  }

  return (
    <FlexBox column gap={10}>
      <FlexBox row gap={10}>
        <Button
          id="fade-button"
          variant='outlined'
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {buttonValue}X
        </Button>

        <InputCurrency
          setErrorValue={setErrorValue}
          errorValue={errorValue}
          setValue={setValue}
          value={value}
        />

        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(quantity)}
          TransitionComponent={Fade}
        >
          {[...Array(12)].map((_, index) => (
            <MenuItem
              key={index + 1}
              onClick={() => handleSaveChange(index)}
            >
              {index + 1}X
            </MenuItem>
          ))}
        </Menu>

      </FlexBox>

      <TextField
        label='Vencimento'
        type='date'
        value={dateDue}
        onChange={event => { setDateDue(event.target.value); }}
        error={errorValue}
      />
    </FlexBox>
  )
}
