import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FlexBox from '../layouts/FlexBox';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IBaseDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  textButtonSave?: string;
  textButtonBack?: string;
  textValues: string[];
  title: string;
  open: boolean;
  onClick: () => void;
};


export const BaseConfirmDialog: React.FC<IBaseDialogProps> = (props) => {
  const { title, textValues, open, onClick, setOpen, 
    textButtonBack = "Voltar", textButtonSave = "Continuar"
  } = props;

  // const [open, setOpen] = React.useState(false);


  function handleClose() {
    setOpen(false);
  };
  
//   const formatText = (text: string) => {
    
//     const regex = /\*(.*?)\*/g; // Expressão para encontrar texto entre asteriscos

//     // Substitui o texto entre asteriscos por <strong>
//     const formattedText = text.replace(regex, (match, p1) => {
//       return <strong>`${p1}`</strong>;
//     });

//     return formattedText;
//   };

const formatText = (text: string) => {
    const regex = /(\*\*(.*?)\*\*|\*(.*?)\*)/g; // Captura **texto** ou *texto*
    const parts = [];
    let lastIndex = 0;
  
    text.replace(regex, (match, _boldText, doubleAsteriskText, singleAsteriskText, offset) => {
      // Adiciona texto normal antes do match
      if (offset > lastIndex) {
        parts.push(text.slice(lastIndex, offset));
      }
  
      // Adiciona o texto formatado, dependendo do tipo de asterisco encontrado
      if (doubleAsteriskText) {
        parts.push(
          <Typography
            key={offset}
            component="span"
            fontWeight="bold"
            fontSize="large"
          >
            {doubleAsteriskText}
          </Typography>
        );
      } else if (singleAsteriskText) {
        parts.push(
          <Typography
            key={offset}
            component="span"
            fontWeight="bold"
            fontSize="15px"
          >
            {singleAsteriskText}
          </Typography>
        );
      }
  
      // Atualiza o índice do último caractere processado
      lastIndex = offset + match.length;
  
      return ""; // Retorno irrelevante para replace
    });
  
    // Adiciona o texto restante após o último match
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }
  
    return parts;
  };



  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          {textValues.map((text) => (
            <Typography gutterBottom>
              
              {formatText(text)}
            </Typography>
          ))}
        </DialogContent>

        <DialogActions>
          <FlexBox gap={40} fullWidth jcEnd>
            <Button autoFocus onClick={handleClose}>
                {textButtonBack}
            </Button>
            <Button autoFocus onClick={onClick} variant='outlined'>
                {textButtonSave}
            </Button>      
          </FlexBox>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}