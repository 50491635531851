import { TabelaProdutos } from "./sections/TabelaProdutos";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { CardBox, FerramentasDaListagem, TableCreateTool } from "../../shared/components";
import { Button, Icon, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { useProductsContext } from "../../shared/contexts/pages/ProductsContext";
import { useNavigate } from "react-router-dom";
import { TableProducts } from "./sections/ListingProducts-TableProducts";
import { CardProductAllDatas } from "./components/Card-ProductAllData";
import { ListStockProduct } from "./sections/ListagemDeProdutos-ListStockProduct";
import { IProduct } from "../../shared/models/Product";
import { StockProductSection } from "./sections/ListagemDeProdutos-StockProductSection";



const emptyProduct: IProduct = {
  id: 0,
  name: "",
  stockProducts: []
}


export const ListagemDeProdutos: React.FC = () => {
  const {
    busca,
    setSearchParams,
    totalCount,
    viewStockSection,
    setViewStockSection,
    setViewProductSection,
    viewProductSection,
  } = useProductsContext()

  const navigate = useNavigate();

  const productVerification = viewProductSection == null;

  const lgDown = useMediaQuery(useTheme().breakpoints.down("lg"));
  const smDown = useMediaQuery(useTheme().breakpoints.down("sm"));

  function handleNewProduct() {
    navigate('/produtos?new')
  };

  function handleMinimize() {
    setViewStockSection([]);
    setViewProductSection(undefined);
  };

  return (
    <LayoutBaseDePagina
      titulo="Produtos"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo="Produto"
          aoClicarEmNovo={handleNewProduct}
          aoMudarTextDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >

      <FlexBox gap={20} marginTop={3} sx={lgDown ? { flexDirection: "column-reverse" } : {}}>

        <FlexBox column fullWidth={lgDown ? true : (productVerification)} width={"55%"}
          sx={{ transition: 'all 0.5s ease-in-out' }}
        >
          <Typography variant="h6">
            Produtos
          </Typography>

          <TableProducts />

          {/* <TabelaProdutos /> */}
          {/* <TableCreateTool /> */}
        </FlexBox>




        <FlexBox column width={lgDown ? (smDown ? "100%" : "80%") : (productVerification ? "20%" : "45%")}
          sx={{ transition: 'all 0.5s ease-in-out', }}
        >
          <FlexBox gap={5} aiCenter>
            <Typography variant="h6">
              Estoque
            </Typography>

            {!productVerification && // Botão de minimizar
              <Tooltip
                title={"Minimizar"}
                placement={"top"}
              >
                <IconButton onClick={handleMinimize}>
                  <Icon>do_not_disturb_on</Icon>
                </IconButton>
              </Tooltip>
            }

          </FlexBox>

          <StockProductSection
            productVerification={productVerification}
            viewProductSection={viewProductSection}
            viewStockSection={viewStockSection}
            emptyProduct={emptyProduct}
          />

        </FlexBox>

      </FlexBox>

      <FlexBox padding={1} jcEnd>
        <Typography variant='subtitle2'>
          Foram encontrados um total de {totalCount} produtos
        </Typography>
      </FlexBox>

    </LayoutBaseDePagina>
  )
}




