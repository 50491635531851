import { TextField } from '@mui/material';
import React from 'react'
import FlexBox from '../../../layouts/FlexBox';
import { InputCurrency } from '../../../inputs/InputCurrency';


interface IOthersPaymentsSectionProps {
  value: number;
  errorValue: boolean;
  dateDue: string;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  setDateDue: React.Dispatch<React.SetStateAction<string>>;
  setErrorValue: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OthersPaymentsSection: React.FC<IOthersPaymentsSectionProps> = (props) => {
  const { errorValue, setErrorValue, setValue, value, dateDue, setDateDue } = props;

  return (
    <FlexBox column gap={10}>

      <InputCurrency 
        setErrorValue={setErrorValue}
        errorValue={errorValue}
        setValue={setValue}
        value={value}
      />

      <TextField
        label='Vencimento'
        type='date'
        value={dateDue}
        onChange={event => { setDateDue(event.target.value); }}
        error={errorValue}
      />
    </FlexBox>
  )
}
