import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Icon, Typography, Box, LinearProgress, Pagination, Avatar } from "@mui/material";
import { Link, SetURLSearchParams, useNavigate } from "react-router-dom";
import { Environment } from "../../../shared/environment";
import { IClient } from "../../../shared/models/Clients/client";
import { CardBox } from "../../../shared/components/layouts/CardBox";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FlexBox from "../../../shared/components/layouts/FlexBox";
import PhoneNumberFormat from "../../../shared/helps/PhoneNumberFormat";

interface SectionTableProps {
  rows: IClient[] | undefined;
  isLoading: boolean;
  totalCount: number;
  setSearchParams: SetURLSearchParams;
  busca: string;
  status: string;
  pagina: number;
  dateInit: string;
  dateEnd: string;
}

export const SectionTable: React.FC<SectionTableProps> = ({
  rows, isLoading, totalCount, setSearchParams, busca, pagina, status, dateInit, dateEnd }) => {
  const navigate = useNavigate();

  const styleCell = { display: 'flex', flexDirection: 'row', alignItems: 'center' };

  const handleDetail = (id: number | undefined) => {
    navigate(`/clientes/detalhe/${id}`)
  };
  return (

    <TableContainer component={CardBox} column sx={{ width: 'auto', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={10}></TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Contato</TableCell>
            <TableCell>Endereço</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TableRow key={row.id} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d3d' }, transition: '.3s' }}
            >
              <TableCell component={Box}
                onClick={() => handleDetail(row.id)}
              >
                {row.imageUrl ? (
                  <Avatar
                    src={row.imageUrl}
                    sx={{
                      width: '60px',
                      height: '60px',
                      backgroundColor: 'whitesmoke'
                    }}
                  >
                  </Avatar>

                ) : (
                  <Typography textAlign={'center'}>
                    <Icon
                      sx={{
                        fontSize: '40px'
                      }}
                    >people</Icon>
                  </Typography>
                )}


              </TableCell>
              <TableCell component={Box}
                onClick={() => handleDetail(row.id)}>
                <Typography fontWeight={500}>{row.name}</Typography>
                {row.branch?.name &&
                  <Typography component={Box} fontSize={12} sx={styleCell}>
                    <Icon >location_city</Icon>
                    {row.branch.name}
                  </Typography>}
              </TableCell>
              <TableCell>
                <FlexBox aiCenter gap={5}>
                  <Typography>
                    {
                      (row.contacts.filter(x => x.isMain).length != 0) ?
                        row.contacts.filter(x => x.isMain && (x.type == 0 || x.type == 1)).length != 0 ?
                          PhoneNumberFormat(row.contacts.filter(x => x.isMain && (x.type == 0 || x.type == 1))[0].value) :
                          row.contacts.filter(x => x.isMain)[0].value :
                        "(XX) XXXXX-XXXX"
                    }
                  </Typography>
                  {row.contacts.find(x => x.isMain == true)?.type == 1 &&
                    <Link
                      target="_blank"
                      to={`https://api.whatsapp.com/send/?phone=55${row.contacts.find(x => x.isMain == true)?.value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")}&text&type=phone_number&app_absent=1`}
                    >
                      <WhatsAppIcon
                        fontSize='large'
                        color='success'
                        sx={{
                          transition: 'all 0.1s ease-in-out',
                          '&:hover': { transform: 'scale(1.2)', boxShadow: '1px 3px 5px 1px #e3e3e3', },
                          '&:active': { transform: 'scale(0.9)', transition: 'all 0.05s ease-in-out', boxShadow: '1px 0px 20px 20px #b3b3b3' }
                        }}
                      />
                    </Link>
                  }
                </FlexBox>
              </TableCell>
              <TableCell component={Box}
                onClick={() => handleDetail(row.id)}>
                <Icon>location_on</Icon>
                {
                  row.address == null ? "------- ------" : row?.address?.city
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {totalCount == 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
      </Table>

      <FlexBox padding={2}>
        {isLoading && (
          <TableRow>
            <LinearProgress variant='indeterminate' />
          </TableRow>
        )}
        {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString(), status: status, dateInit: dateInit, dateEnd: dateEnd }, { replace: true })}
          />
        )}
      </FlexBox>

    </TableContainer>
  )
}
