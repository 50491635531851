import { IconButton, Icon, Typography, useTheme } from '@mui/material';
import React from 'react';
import FlexBox from '../../layouts/FlexBox';


interface IIconSelectTypeContentProps {
  handleToggle: (select: number) => void;
  typePaymentSelected: number;
  selected: number;
  title: string;
  icon: string;
};

export const IconSelectTypeContent: React.FC<IIconSelectTypeContentProps> = (props) => {
  const { handleToggle, selected, icon, title, typePaymentSelected } = props;

  const theme = useTheme();
  const areEquals = (selected == typePaymentSelected);

  return (
    <FlexBox column aiCenter>

      <IconButton aria-label="dinheiro"
        onClick={() => handleToggle(typePaymentSelected)}
        sx={{
          background: (areEquals ? 'rgba(0, 0, 0, 0.07)' : '')
        }}
      >

        <Icon sx={{ fontSize: '45px', color: areEquals ? '' : theme.palette.primary.main }}>
          {icon}
        </Icon>

      </IconButton>

      <Typography
        fontSize={12}
        fontWeight={800}
        visibility={areEquals ? 'initial' : 'hidden'}
        marginTop={1}
      >
        {title}
      </Typography>

    </FlexBox>
  )
}
