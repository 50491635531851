import { Button, Chip, IconButton, Switch, TextField, Tooltip, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import FlexBox from '../../../../../shared/components/layouts/FlexBox';
import { IColorProduct } from '../../../../../shared/models/ColorProduct';
import { useListagemDeCadastrosContext } from '../../../../../shared/contexts';
import { BaseModal } from '../../../../../shared/components/modals/BaseModal';
import { ButtonColorPicker } from '../../../../../shared/components/buttons/ButtonColorPicker';
import { ColorProductService } from '../../../../../shared/services/api/cadastros/ColorProductService';
import { ColorProduct, ColorProductInsert, ColorProductUpdate } from '../../../../../shared/models/classes/categoryModels/colorProducts/ColorProduct';
import { ButtonStatusUpdate } from '../../../../../shared/components/buttons/ButtonStatusUpdate';


interface ModalAddEditColorProps {
  isOpenAdd: boolean;
  rowEdit: ColorProduct;
  setIsOpenAdd: Dispatch<SetStateAction<boolean>>;
  setRows: Dispatch<SetStateAction<IColorProduct[]>>
}

export const ModalAddEditColor: React.FC<ModalAddEditColorProps> = (props) => {
  const { isOpenAdd, setIsOpenAdd, setRows, rowEdit } = props;
  const { isLoading, startLoading, stopLoading } = useListagemDeCadastrosContext();

  const modelEmpty = new ColorProduct({});

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [color, setColor] = useState("#000");

  const isUpdate = rowEdit.id !== 0;


  useEffect(() => {
    if (isUpdate) {
      setName(rowEdit.name);
      setColor(rowEdit.code);
      setStatus(rowEdit.isActive);
    } else {
      setName(modelEmpty.name);
      setColor(modelEmpty.code);
      setStatus(modelEmpty.isActive);
    }
  }, [rowEdit]);


  function handleSave() {
    const modelInsert = new ColorProductInsert({ name: name, code: color });
    const modelUpdate = new ColorProductUpdate({ name: name, code: color, isActive: status, id: rowEdit.id });
    startLoading();

    if (isUpdate) {
      ColorProductService.update(modelUpdate).then(result => {  // Update
        stopLoading();

        if (result.success) {
          setRows(prev => [...prev.map(item => item.id === result.data.id ? result.data : item)]);
          handleClose();
        }
        else {
          alert(result.errors[0]);
        }
      });
    }
    else {
      ColorProductService.insert(modelInsert).then(result => {  // Insert
        stopLoading();

        if (result.success) {
          setRows(result.data);
          handleClose();
        }
        else {
          alert(result.errors[0]);
        }
      });
    }

  };

  function handleClose() {
    setIsOpenAdd(false);
  };

  function handleStatus() {
    setStatus(prev => !prev)
  }


  return (
    <BaseModal
      viewButtons
      minWidth={300}
      open={isOpenAdd}
      isLoading={isLoading}
      handleSave={handleSave}
      handleClose={handleClose}
      title={isUpdate ? 'Editar cor' : 'Adicionar cor'}
    >
      <FlexBox column gap={10}>
        <TextField
          fullWidth
          label='Nome da Cor'
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        {!isUpdate &&
          <ButtonColorPicker
            color={color}
            setColor={setColor}
          />}

        {isUpdate &&
          <FlexBox fullWidth jcBetween>
            <ButtonColorPicker
              color={color}
              setColor={setColor}
            />
            <ButtonStatusUpdate setStatus={setStatus} status={status} />
          </FlexBox>
        }


        <FlexBox
          marginY={-1.5}
        />
      </FlexBox>
    </BaseModal>
  )
}
