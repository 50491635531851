import { Chip, CircularProgress, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react'
import { ISale } from '../../../shared/models/Sale';
import dayjs from 'dayjs';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import { statusBar } from '../components';
import { MenuAlterarStatus } from '../components/MenuAlterarStatus';


interface ISalesDetailToolbarContentProps {
  handleOpenDialog(event: React.MouseEvent<HTMLElement>): void;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setSale: Dispatch<SetStateAction<ISale | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
  sale: ISale | undefined;
  id: string | undefined;
  isLoading: boolean;
  open: boolean;
  // openConfirmDialog: boolean;
  // setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;

};

export const SalesDetailToolbarContent: React.FC<ISalesDetailToolbarContentProps> = (props) => {
  const { anchorEl, id, isLoading, open, sale, handleOpenDialog, setAnchorEl, setIsLoading, 
    setSale, // openConfirmDialog, setOpenConfirmDialog
  } = props;

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  


  return (
    <FlexBox paddingX={smDown ? 1 : 0}>
      <CardBox marginBottom={3} padding={2} sx={{ width: '100%' }}>
        <FlexBox row aiCenter fullWidth jcBetween gap={10}>
          <FlexBox column aiStart gap={5}>
            <Typography>Status:</Typography>
            <div>
              <Tooltip title="Alterar Status">
                <Chip
                  variant="filled"
                  onClick={handleOpenDialog}
                  size={smDown ? 'small' : 'medium'}
                  label={statusBar(sale?.status).label}
                  color={statusBar(sale?.status).color}
                  disabled={sale?.toStatus.length == 0}
                  sx={{
                    '&:hover': { transform: 'scale(1.1)', transition: 'all 0.15s ease-in-out', cursor: 'pointer', boxShadow: '1px 3px 5px 1px #e3e3e3', },
                    '&:active': { transform: 'scale(0.9)', transition: 'all 0.05s ease-in-out', boxShadow: '1px 0px 10px 1px #f1f1f1' }
                  }}
                />
              </Tooltip>
              {isLoading && <CircularProgress size={20} />}
            </div>

            <MenuAlterarStatus
              setIsLoading={setIsLoading}
              setAnchorEl={setAnchorEl}
              saleId={Number(id)}
              anchorEl={anchorEl}
              setSale={setSale}
              open={open}
              toStatus={sale?.toStatus}
              // openConfirmDialog={openConfirmDialog}
              // setOpenConfirmDialog={setOpenConfirmDialog}
            />
            
          </FlexBox>

          <FlexBox column gap={10}>
            <FlexBox row gap={10}>
              <Typography>Data:</Typography>
              {isLoading && <CircularProgress size={20} />}
              {!isLoading && <Typography fontWeight={500}>{dayjs(sale?.dateCreate).format('DD-MMM-YYYY')}</Typography>}
            </FlexBox>

            <FlexBox row gap={10}>
              <Typography>Total:</Typography>
              {isLoading && <CircularProgress size={20} />}
              {!isLoading && <Typography fontWeight={600} color={theme.palette.success.light}>{sale && CurrencyFormat(sale?.total)}</Typography>}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </CardBox>
    </FlexBox>
  )
}
