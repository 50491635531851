import { Api } from "../axios-config";
import { IResponseAmount } from "../../../models";
import { IReason } from "../../../models/Reason";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IReasonInsert, IReasonUpdate } from "../../../models/Dtos/cadastrosDtos/ReasonDto";


const insert = async (reason: IReasonInsert): Promise<IResponseAPI<IReason>> => {

    const { data } = await Api.post('/otica/Reason', reason).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (reason: IReasonUpdate): Promise<IResponseAPI<IReason>> => {

    const { data } = await Api.put('/otica/Reason', reason).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (): Promise<IResponseAPI<IResponseAmount<IReason[]>>> => {

    const { data } = await Api.get("/otica/Reason?pageSize=50").catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IReason[]>>> => {

    const { data } = await Api.get(`/otica/Reason/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const ReasonService = {
    insert,
    update,
    getAll,
    getById,
};