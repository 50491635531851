import { Grid, Typography, IconButton, Icon } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { ClearIcon } from '@mui/x-date-pickers';

import { IStockProductInsert } from "../../../shared/models/StockProduct";
import { IColorProduct } from "../../../shared/models/ColorProduct";
import CurrencyFormat from "../../../shared/helps/CurrencyFormat";
import FlexBox from "../../../shared/components/layouts/FlexBox";


interface ICardProductStockProps {
  item: IStockProductInsert;
  index: number;
  smDown: boolean;
  removeStock: (index: number) => void;
  colors: IColorProduct[];
}

export const CardProductStock: React.FC<ICardProductStockProps> = (props) => {
  const { item, smDown, removeStock, index, colors } = props;

  return (
    <FlexBox fullWidth jcCenter>
      <FlexBox
        fullWidth
        bgcolor={'white'}
        padding={1} borderRadius={2}
        boxShadow={"0px 0px 10px 1px #45454518"}
      >


        <Grid container width={"100%"}>

          <Grid item xs={1} sm={1} lg={1}>
            <FlexBox jcCenter fullWidth>
              {item.colorProductId != undefined && item.colorProductId > 0 ?
                <FlexBox
                  width={20}
                  height={20}
                  borderRadius={50}
                  border='1px solid black'
                  bgcolor={colors.find(x => x.id == item.colorProductId)?.code}
                /> :
                <Typography fontWeight={400}>-</Typography>
              }
            </FlexBox>
          </Grid>

          <Grid item xs={2} sm={2} lg={2}>
            <FlexBox jcCenter fullWidth>
              <Typography>{item.quantity}</Typography>
            </FlexBox>
          </Grid>

          <Grid item xs={4} sm={3} lg={3}>
            <FlexBox jcStart fullWidth>
              <Typography>{CurrencyFormat(item.cost)}</Typography>
            </FlexBox>
          </Grid>

          <Grid item xs={4} sm={3} lg={3}>
            <FlexBox jcStart fullWidth>
              <Typography>{CurrencyFormat(item.value)}</Typography>
            </FlexBox>
          </Grid>

          {smDown ? null :
            <Grid item xs={2} sm={2} lg={2}>
              <FlexBox jcCenter fullWidth>
                <Typography>{item.notStock ? <ClearIcon color='error' /> : <CheckIcon color='success' />}</Typography>
              </FlexBox>
            </Grid>
          }

          <Grid item xs={1} sm={1} lg={1}>
            <FlexBox jcCenter fullWidth>
              <IconButton size='small'
                onClick={() => removeStock(index)}
                sx={{ color: "#d62727", background: '#ffe8cf99', '&:hover': { color: '#a7a7a7', transition: '.2s' } }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </FlexBox>
          </Grid>

        </Grid>


      </FlexBox>
    </FlexBox>
  );
};
