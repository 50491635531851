import styled from "styled-components";
import { FormControl, FormControlLabel, FormGroup, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Switch, TextField, Theme, useMediaQuery } from "@mui/material";
import { IContactInsert } from "../../../shared/models/Clients/contact";
import { useEffect, useState } from "react";
import { IContactLeveEnum } from "../../../shared/models/enums/ContactTypeEnum";
import { InputCellphone } from "../../../shared/components/inputs/InputCellphone";

interface CardCellphoneProps {
  indice: number;
  handleDeleteCellphone: (indice: number) => void;
  rowsCellphones: IContactInsert[];
  setRowsCellphones: React.Dispatch<React.SetStateAction<IContactInsert[]>>
}

export const CardCellphone: React.FC<CardCellphoneProps> = (props) => {
  const { indice, handleDeleteCellphone, setRowsCellphones, rowsCellphones } = props;

  const [value, setValue] = useState<string>('');
  const [type, setType] = useState<number>(0);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isCellphone = (type === IContactLeveEnum['Telefone'] || type === IContactLeveEnum['WhatsApp']);


  useEffect(() => {
    getValues()
  }, []);


  const handleChangeNumero = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isCellphone: boolean) => {
    if (isCellphone)
      setValue(e.target.value);

    setRowsCellphones(prevListTel => {
      const novaArray = prevListTel.map((celular, index) => {
        if (index == indice) {
          celular.value = e.target.value;
          celular.type = type as number;
          return { ...celular };
        } else {
          return celular;
        }
      });
      return novaArray;
    });
  };

  const getValues = () => {
    setValue(rowsCellphones[indice].value)
    setType(rowsCellphones[indice].type)
  };

  const handleChangeType = (e: string) => {
    setValue("");
    setType(parseInt(e))
    setRowsCellphones(prevListTel => {
      const novaArray = prevListTel.map((celular, index) => {
        if (index == indice) {
          celular.value = value as string;
          celular.type = parseInt(e);
          return { ...celular };
        } else {
          return celular;
        }
      });
      return novaArray;
    });
  };

  const handleMainCell = () => {
    setRowsCellphones(prevListCell => {
      const updatedArray = prevListCell.map((cellphone, index) => {
        if (index == indice && cellphone.isMain == false) {
          return { ...cellphone, isMain: true }
        } else {
          return { ...cellphone, isMain: false };
        }
      });

      return updatedArray;
    })
  };


  return (
    <Paper sx={{ width: "100%", boxShadow: '0 0 30px 1px #3737371f', borderRadius: '20px' }}>

      <CardContainer>
        <div className='input-div'>

          {!isCellphone &&
            <TextField
              required
              size="small"
              value={value}
              onChange={(e) => handleChangeNumero(e, true)}
              label={type == undefined ? 'Valor' : IContactLeveEnum[type]}

              slotProps={{
                input: {
                  inputProps: { maxLength: 50 },
                  endAdornment: (
                    <IconButton
                      onClick={() => setValue("")}
                      onMouseDown={(event) => event.preventDefault()} // Evita perder o foco ao clicar
                      edge="end"
                      // sx={{fontSize: "20px"}}
                      size="small"
                    >
                      <InputAdornment position="end">
                        <Icon sx={{ fontSize: "20px" }}>
                          clear
                        </Icon>
                      </InputAdornment>
                    </IconButton>
                  ),
                },
              }}
            />
          }

          {isCellphone &&
            <InputCellphone
              required
              value={value}
              setValue={setValue}
              handleChangeNumero={(e) => handleChangeNumero(e, false)}
              label={type == undefined ? 'Valor' : IContactLeveEnum[type]}
            />
          }

          <FormControl sx={{ m: 1, minWidth: smDown ? 100 : 150 }} size="small">
            <InputLabel id="simple-select-label">Tipo</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={type}
              onChange={(e) => handleChangeType(e.target.value.toString())}
            >
              <MenuItem value={0}>{IContactLeveEnum[0]}</MenuItem>
              <MenuItem value={1}>{IContactLeveEnum[1]}</MenuItem>
              <MenuItem value={2}>{IContactLeveEnum[2]}</MenuItem>
              <MenuItem value={3}>{IContactLeveEnum[3]}</MenuItem>
              <MenuItem value={4}>{IContactLeveEnum[4]}</MenuItem>
              <MenuItem value={5}>{IContactLeveEnum[5]}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='button-div'>
          <FormGroup>
            <FormControlLabel
              label="Principal"
              control={
                <Switch
                  checked={rowsCellphones[indice].isMain}
                  onChange={handleMainCell}
                  size="small"
                />
              }
            />
          </FormGroup>

          <IconButton size='small'
            onClick={() => handleDeleteCellphone(indice)}
            sx={{ color: "#d62727", background: '#ffe8cf99', '&:hover': { color: '#a7a7a7', transition: '.2s' } }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </div>
      </CardContainer>
    </Paper>
  )
}

const CardContainer = styled.div`
  
  width: 100%;
  min-height: 100px;
  border-radius: 10px;

  box-sizing: border-box;
  padding: 5px 15px;

  .text-div{
    width: 100%;
   // min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;

    input{
      width: 150px;
    }
  }

  .input-div{
    width: 100%;
    min-height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .button-div{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
  
  .button-div-1{
    display: flex;
    flex-direction: row;
  }

    input{
      width: 150px;
    }
  }
`