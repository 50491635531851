import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { BaseModal } from "../../../shared/components/modals/BaseModal";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { Button, MenuItem, Switch, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import { useProductsContext } from "../../../shared/contexts/pages/ProductsContext";
import { IStockProductInsert } from "../../../shared/models/StockProduct";
import { IProduct } from "../../../shared/models/Product";
import { EstoquesService } from "../../../shared/services/api/estoques/EstoquesService";
import { InputNumericState } from "../../../shared/components/inputs/InputNumericState";
import { InputCurrency } from "../../../shared/components/inputs/InputCurrency";

interface ModalInsertStockProdutoProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  product: IProduct | undefined;
}

export const ModalInsertStock: React.FC<ModalInsertStockProdutoProps> = ({ isOpen, setIsOpen, product }) => {
  function handleClose() {
    setIsOpen(false);
  };
  const { colors, startLoading, stopLoading, setProducts, setViewStockSection } = useProductsContext()

  const [cor, setCor] = useState<number | undefined>();
  const [notStock, setNotStock] = useState(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [cost, setCost] = useState<number>(0);
  const [value, setValue] = useState<number>(0);


  const [isProduct, setIsProduct] = useState(true);

  function handleAlignment(_: React.MouseEvent<HTMLElement>, newAlignment: boolean | null) {

    if (isProduct) {
      setNotStock(false);
      setCor(undefined);
      setQuantity(0);
    }

    if (newAlignment !== null)
      setIsProduct(newAlignment);
  };

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (product != undefined && product.stockProducts.filter(x => x.colorProduct?.id == cor).length > 0) {
      alert("Existe um estoque com a mesmas caracteristicas!")
    } else if (product != undefined) {
      startLoading();

      const insertStock = {
        productId: product.id,
        colorProductId: cor,
        notStock: notStock,
        quantity: quantity,
        cost: cost / 100,
        value: value / 100,
      };

      EstoquesService.insertStock(insertStock).then((result) => {
        if (result.success) {
          setProducts(prev => {
            const response = prev.filter(x => x.id != product.id)
            return [...response,result.data];
          })
          setViewStockSection(result.data.stockProducts)
          handleClose()
        } else {
          alert(result.errors[0])
        }
        stopLoading();
      });
    }

    handleClose();
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotStock(event.target.checked);
    setQuantity(1);
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={handleClose}
      title="Inserir estoque"
    >

      <FlexBox jcEnd fullWidth marginTop={-5}>
        <ToggleButtonGroup
          value={isProduct} // Passa o estado booleano
          exclusive
          onChange={(e, value) => handleAlignment(e, value)}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton value={true} aria-label="Produto">
            <Typography fontWeight={600} fontSize={13}>
              Produto
            </Typography>
          </ToggleButton>

          <ToggleButton value={false} aria-label="Lente">
            <Typography fontWeight={600} fontSize={13}>
              Lente
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </FlexBox>


      <FlexBox
        minHeight="100px"
        column gap={10} marginTop={4}
      >
        {isProduct && (
          <TextField
            select
            size="small"
            name="color"
            label="Cor"
            value={cor}
            onChange={e => setCor(parseInt(e.target.value))}
          >
            {colors.filter(x => x.isActive).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        {isProduct && (!notStock && (

          <InputNumericState
            required
            name="quantity"
            label="Quantidade"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
        ))}

        <InputCurrency
          value={cost}
          label="Custo"
          setValue={setCost}
        />

        <InputCurrency
          value={value}
          setValue={setValue}
          label="Valor de Venda"
        />


        {isProduct && (
          <FlexBox aiCenter jcBetween>
            <Typography>Venda sem estoque:  </Typography>
            <Switch
              size="small"
              checked={notStock}
              onChange={handleCheck}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </FlexBox>
        )}

        <FlexBox row jcBetween marginTop={3} >
          <Button variant="outlined"
            size="small"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button variant="contained"
            type="submit"
            size="small"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </FlexBox>
      </FlexBox>
    </BaseModal>
  );
}
