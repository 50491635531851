import { ReactNode, useContext, createContext, useState, useEffect, useMemo } from "react";
import { IColorProduct } from "../../models/ColorProduct";
import { ColorProductService } from "../../services/api/colorProduct/ColorProductService";
import { useDebounce } from "../../hooks";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { IProduct } from "../../models/Product";
import { EstoquesService } from "../../services/api/estoques/EstoquesService";
import { ICategoryProduct } from "../../models/CategoryProduct";
import { CategoryProductService } from "../../services/api/cadastros/CategoryProductService";
import { MarkProductService } from "../../services/api/cadastros/MarkProductService";
import { IMarkProduct } from "../../models/MarkProduct";
import { ModelProductService } from "../../services/api/cadastros/ModelProductService";
import { IModelProduct } from "../../models/ModelProduct";
import { MaterialProductService } from "../../services/api/cadastros/MaterialProductService";
import { IMaterialProduct } from "../../models/MaterialProduct";
import { NewProduct } from "../../../pages/produtos/product/NewProduct";
import { EditProduct } from "../../../pages/produtos/product/EditProduct";
import { IStockProduct } from "../../models/StockProduct";


interface IProductsContextData {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  colors: IColorProduct[];
  categories: ICategoryProduct[];
  brands: IMarkProduct[];
  models: IModelProduct[];
  materials: IMaterialProduct[];
  products: IProduct[];
  setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
  totalCount: number;
  setSearchParams: SetURLSearchParams;
  busca: string;
  pagina: number;
  editProductId: number;
  viewProductSection: IProduct | undefined;
  setViewProductSection: React.Dispatch<React.SetStateAction<IProduct | undefined>>;
  viewStockSection: IStockProduct[];
  setViewStockSection: React.Dispatch<React.SetStateAction<IStockProduct[]>>;
};

export const ProductsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);
  const [colors, setColors] = useState<IColorProduct[]>([]);
  const [categories, setCategories] = useState<ICategoryProduct[]>([]);
  const [brands, setBrands] = useState<IMarkProduct[]>([]);
  const [models, setModels] = useState<IModelProduct[]>([]);
  const [materials, setMaterials] = useState<IMaterialProduct[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce(800, true);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const newProduct = useMemo(() => {
    return searchParams.get('new') == null ? false : (Boolean(searchParams.get('new')) || Boolean('false'));
  }, [searchParams]);

  const editProductId = useMemo(() => {
    return Number(searchParams.get('edit') || null);
  }, [searchParams]);

  const [viewProductSection, setViewProductSection] = useState<IProduct>();
  const [viewStockSection, setViewStockSection] = useState<IStockProduct[]>([]);

  useEffect(() => {
    startLoading();
    debounce(() => {
      EstoquesService.getAll(pagina, busca)
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          }
          else if (result.data?.amount != undefined && result.data.data != undefined) {
            setTotalCount(result.data.amount);
            setProducts(result.data.data);
          }
          stopLoading();
        }
        );
    });
  }, [busca, pagina, debounce]);


  useEffect(() => {
    getColor();
    getCategory();
    getBrands();
    getModels();
    getMaterials();
  }, []);

  const getColor = () => {
    startLoading();
    ColorProductService.getAll().then((result) => {
      if (result.success) {
        setColors(result.data.data)
      }
      stopLoading();
    })
  }

  const getCategory = () => {
    startLoading();
    CategoryProductService.getAll().then((result) => {
      if (result.success) {
        setCategories(result.data.data)
      }
      stopLoading();
    })
  }

  const getBrands = () => {
    startLoading();
    MarkProductService.getAll().then((result) => {
      if (result.success) {
        setBrands(result.data.data)
      }
      stopLoading();
    })
  }

  const getModels = () => {
    startLoading();
    ModelProductService.getAll().then((result) => {
      if (result.success) {
        setModels(result.data.data)
      }
      stopLoading();
    })
  }

  const getMaterials = () => {
    startLoading();
    MaterialProductService.getAll().then((result) => {
      if (result.success) {
        setMaterials(result.data.data)
      }
      stopLoading();
    })
  }


  return (
    <ProductsContext.Provider value={{
      isLoading,
      stopLoading,
      startLoading,
      colors,
      products,
      setProducts,
      totalCount,
      setSearchParams,
      busca,
      pagina,
      categories,
      brands,
      models,
      materials,
      editProductId,
      viewProductSection,
      setViewProductSection,
      viewStockSection,
      setViewStockSection,
    }}>
      {newProduct ?
        <NewProduct /> :
        editProductId != 0 ?
          <EditProduct /> :
          children}
    </ProductsContext.Provider>
  );
};

const ProductsContext = createContext({} as IProductsContextData);

export const useProductsContext = () => {
  return useContext(ProductsContext);
};