import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IPaymentSaleInsert } from "../../../models/PaymentSale";
import { ISale, } from "../../../models/Sale";
import { ITransaction, ITransactionInsert, ITransactionStatusUpdate } from "../../../models/Transaction";
import { Api } from "../axios-config";


const insert = async (payment: IPaymentSaleInsert): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.post('/otica/Sale/Payment', payment).catch(data => {
        return data.response;
    });

    return data;
};

const remove = async (id: number): Promise<IResponseAPI<boolean> | IResponseAPI<boolean>> => {

    const { data } = await Api.delete(`/otica/Transaction/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

const insertTransaction = async (payment: ITransactionInsert): Promise<IResponseAPI<any> | IResponseAPI<any>> => {

    const { data } = await Api.post('/otica/Transaction', payment).catch(data => {
        return data.response;
    });

    return data;
};

const updateStatus = async (transaction: ITransactionStatusUpdate): Promise<IResponseAPI<ITransaction> | IResponseAPI<ITransaction>> => {

    const { data } = await Api.put('/otica/Transaction', transaction).catch(data => {
        return data.response;
    });

    return data;
};

export const TransactionService = {
    insert,
    updateStatus,
    insertTransaction,
    remove
};