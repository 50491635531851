import { Environment } from "../../../environment";
import { IResponseAmount, IResponseAPI } from "../../../models";
import { IProduct, IProductInsert, IProductUpdate } from "../../../models/Product";
import { IStockInsert, IStockProduct, IStockProductUpdate } from "../../../models/StockProduct";
import { Api } from "../axios-config";

const getAll = async (page = 1, filter = '', pageSize?: number): Promise<IResponseAPI<IResponseAmount<IProduct[]>> | IResponseAPI<IResponseAmount<IProduct[]>>> => {

  const { data } = await Api.get(`/otica/Product`,
    {
      params: {
        page: page,
        pageSize: pageSize ?? Environment.LIMITE_DE_LINHAS,
        name: filter,
      },
    }
  ).catch((data) => {
    return data.response;
  });

  return data;
};

const getById = async (id: number): Promise<IResponseAPI<IProduct> | IResponseAPI<IProduct>> => {
  const { data } = await Api.get(`/otica/Product/${id}`).catch(data => {
    return data.response;
  })
  return data;
};

const create = async (produto: IProductInsert): Promise<IResponseAPI<IProduct> | IResponseAPI<IProduct>> => {
  const { data } = await Api.post(`/otica/Product`, produto).catch((data) => {
    return data.response;
  });
  return data;
};

const insertStock = async (stock: IStockInsert): Promise<IResponseAPI<IProduct> | IResponseAPI<IProduct>> => {
  const { data } = await Api.post(`/otica/Product/StockProduct`, stock).catch((data) => {
    return data.response;
  });
  return data;
};

const updateById = async (dados: IProductUpdate): Promise<IResponseAPI<IProduct> | IResponseAPI<IProduct>> => {
  const { data } = await Api.put(`/otica/Product`, dados).catch((data) => {
    return data.response;
  });
  return data;
};

const updateStockProduct = async (dados: IStockProductUpdate): Promise<IResponseAPI<IProduct> | IResponseAPI<IProduct>> => {
  const { data } = await Api.put(`/otica/Product/StockProduct`, dados).catch((data) => {
    return data.response;
  });
  return data;
};

const deleteById = async (id: number): Promise<IResponseAPI<IProduct> | IResponseAPI<IProduct>> => {
  const { data } = await Api.delete(`/otica/Product/${id}`).catch(data => {
    return data.response;
  });
  return data;
};


export const EstoquesService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  updateStockProduct,
  insertStock
};