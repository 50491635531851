import { IMaterialProduct } from "../../../MaterialProduct";
import { IMaterialProductInsert, IMaterialProductUpdate } from "../../../Dtos/cadastrosDtos/MaterialProductDto";

export class MaterialProduct implements IMaterialProduct {
  id: number;
  name: string;
  isActive: boolean;

  constructor({ id = 0, name = "", isActive = true}: Partial<IMaterialProduct> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};


export class MaterialProductInsert implements IMaterialProductInsert {
  name: string;

  constructor({ name = "" }: Partial<IMaterialProductInsert> = {}) {
    this.name = name;
  }
};


export class MaterialProductUpdate implements IMaterialProductUpdate {
  id: number;
  name?: string;
  isActive?: boolean;

  constructor({ id = 0, name = "", isActive = true}: Partial<IMaterialProductUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};