import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { OrdemDeServicoContent } from './OrdemDeServicoContent';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { ComentariosContent } from './ComentariosContent';
import { ISale } from '../../../shared/models/Sale';
import { ListTransaction } from './ListTransaction';
import { ProdutoContent } from './ProdutoContent';
import { ClientContent } from './ClientContent';



interface ISaleSalesContentSectionsProps {
  setSale: Dispatch<SetStateAction<ISale | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  sale: ISale | undefined;
  isLoading: boolean;
};

export const SalesContentSections: React.FC<ISaleSalesContentSectionsProps> = (props) => {
  const { isLoading, sale, setIsLoading, setSale } = props;

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));


  return (
    <FlexBox>

      <Grid container
        direction="row"
        sx={{
          justifyContent: "space-between",
        }}
        spacing={7}
      >
        
        {lgDown && !smDown || !xsDown ? (
          <>
            <Grid item xs={12} sm={12} lg={6}>
              <ProdutoContent
                sale={sale}
                setSale={setSale}
                isLoading={isLoading}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} lg={6}>
              <ProdutoContent
                sale={sale}
                setSale={setSale}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <ClientContent
                isLoading={isLoading}
                sale={sale}
              />
            </Grid>
          </>
        )}


        <Grid item xs={12} sm={12} lg={6}>
          <OrdemDeServicoContent
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setSale={setSale}
            sale={sale}
          />
        </Grid>


        <Grid item xs={12} sm={12} lg={6}>
          <ListTransaction
            sale={sale}
            setSale={setSale}
          />
        </Grid>


        <Grid item xs={12} sm={12} lg={6}>
          <ComentariosContent
            isLoading={isLoading}
            sale={sale}
          />
        </Grid>

      </Grid>

    </FlexBox>
  );
};
