import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, Button, Card, CardContent, Icon, IconButton, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { ClientesService } from "../../shared/services/api/clientes/ClientesService";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { IClient, IClientUpdate } from '../../shared/models/Clients/client';
import FlexBox from '../../shared/components/layouts/FlexBox';
import { SectionCellphone } from './components/listagemDeCLientes/sections/Modal-NovoCliente-3-contato';
import { IContact, IContactInsert } from '../../shared/models/Clients/contact';
import { SectionDocument } from './components/listagemDeCLientes/sections/Modal-NovoCliente-3-document';
import { IDocument } from '../../shared/models/Clients/document';
import { IAddress } from "../../shared/models";
import { ModalAddAddressClient } from "./components/ModalAddAddressClient";
import { ModalEditAddressClient } from "./components/ModalEditAddressClient";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "styled-components";
import { InputFile } from "../../shared/components/inputs/input-file/InputFile";
import { CardBox } from "../../shared/components";

export const EditarClientes: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [client, setClient] = useState<IClient>();
  const [name, setName] = useState('');
  const [dateBirth, setDateBirth] = useState<Dayjs>();
  const [address, setAddress] = useState<IAddress>();
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [contacts, setContacts] = useState<IContactInsert[]>([]);
  const [rowsDocuments, setRowsDocuments] = useState<IDocument[]>([]);
  const [base64, setBase64] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));


  const handleAddAddressClose = () => {
    setOpenAddAddress(false)
  }
  const handleEditAddressClose = () => {
    setOpenEditAddress(false)
  }

  useEffect(() => {
    setIsLoading(true);
    ClientesService.getById(Number(id)).then(
      (result) => {
        setIsLoading(false);

        if (result.success) {
          setContacts(result.data.contacts);
          if (result.data.birthDate != undefined && result.data.birthDate != null)
            setDateBirth(dayjs(result.data.birthDate))
          setClient(result.data);
          setName(result.data.name);
          setAddress(result.data.address);
          setRowsDocuments(result.data.documents)
        } else {
          alert(result.errors[0]);
          navigate('/clientes/detalhe/' + id);
        }
      }
    );
  }, [id, navigate]);

  function handleEdit() {
    const modelEdit: IClientUpdate = {
      id: Number(id),
      name: name,
      birthDate: dateBirth != undefined && dateBirth != null ? dayjs(dateBirth).format('YYYY-MM-DD') : null,
      imageUrl: base64,
      documents: rowsDocuments as IDocument[],
      contacts: contacts as IContact[],
      address: address,
    };
    ClientesService.update(modelEdit).then((result) => {
      if (result.success) {
        alert("Cliente atualizado com sucesso!")
        navigate(`/clientes/detalhe/${id}/`);
      }
      else {
        alert(result.errors[0]);
      }
    });

  };

  function handleDelete() {
    if (window.confirm('Realmente deseja excluir?')) {
      ClientesService.deleteById(Number(id)).then((data) => {
        if (data.success) {
          alert("Perfil removido com sucesso!")
          navigate(`/clientes`);
        } else {
          alert(data.errors[0])
        }
      })
    }
  };




  return (
    <LayoutBaseDePagina
      titulo={'Editar cliente'}
      buttonToBack
      barraDeFerramentas={
        <CardBox minHeight={20} // boxShadow={"0px 0px 10px -5px #919191"}
          padding={2} borderRadius={3} jcBetween aiCenter
        >
          <FlexBox aiCenter gap={20}>

            <Avatar
              src={base64 == undefined || base64 == '' ? client?.imageUrl : base64}
              sx={{
                width: smDown ? 58: 70,
                height: smDown ? 58: 70,
                backgroundColor: '#9b9b9b'
              }}
            >
            </Avatar>

            <Typography 
              variant='h6'
              fontSize={smDown ? 15 : 23}
            >
              {client?.name}
            </Typography>

          </FlexBox>
          <FlexBox gap={10} column={smDown}>
            <Button variant='outlined' color="error" onClick={handleDelete}>
              Excluir
            </Button>
            <Button variant='contained' onClick={handleEdit}>
              Atualizar
            </Button>
          </FlexBox>
        </CardBox>
      }
    >
      <FlexBox column fullWidth marginTop={3} gap={50}>

        <FlexBox padding={1} row={!smDown} column={smDown} aiStart gap={50}>

          <FlexBox
            minHeight={100}
            fullWidth
            borderRadius={5}
            gap={20}
            column
          >
            <TextField
              fullWidth
              label="Nome Completo"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <LocalizationProvider dateFormats={{ fullDate: "DD/MM/YYYY" }} adapterLocale='pt-BR' dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data de nascimento"
                value={dateBirth == undefined || dateBirth == null ? null : dayjs(dateBirth)}
                format='DD/MM/YYYY'
                onChange={(dateInit) => dateInit != undefined && dateInit != null ? setDateBirth(dateInit) : null}

              />
            </LocalizationProvider>

            <InputFile
              buttonText="Adicionar Foto"
              setImage={setBase64}
            />

          </FlexBox>


          <FlexBox fullWidth row gap={15}>
            {!!client?.address ?
              <Card sx={{ boxShadow: 'none' }}>
                <CardContent>
                  <Typography sx={{ marginTop: 2 }} id="modal-modal-title" component="div">
                    Endereço
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <div>
                      <Typography sx={{ fontSize: 12, maxWidth: 150 }} id="modal-modal-title" component="text">
                        {client.address.street}, {client.address.number} - {client.address.district} - {client.address.city} - {client.address.state}
                      </Typography>
                    </div>
                    <div>
                      <Typography sx={{ fontSize: 12, maxWidth: 150 }} id="modal-modal-title" component="text">
                        <IconButton size='small' onClick={() => setOpenEditAddress(true)}>
                          <Icon>edit</Icon>
                        </IconButton>
                      </Typography>
                    </div>
                  </Box>
                </CardContent>
              </Card> :
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <Button disabled={isLoading} sx={{ maxWidth: 250 }} variant='contained' onClick={() => setOpenAddAddress(true)}>Adicionar endereço</Button>
              </Box>}
          </FlexBox>

        </FlexBox>


        <FlexBox row={!smDown} column={smDown} fullWidth gap={50} padding={1} boxSizing={"border-box"}>

          <SectionCellphone
            rowsCellphones={contacts}
            setRowsCellphones={setContacts}
          />

          <SectionDocument
            rowsDocuments={rowsDocuments}
            setRowsDocuments={setRowsDocuments}
            errorDocument={false}
          />

        </FlexBox>

      </FlexBox>

      <ModalAddAddressClient
        handleClose={handleAddAddressClose}
        open={openAddAddress}
        setClient={setClient}
        client={client}
      />

      {client?.address &&
        <ModalEditAddressClient
          handleClose={handleEditAddressClose}
          open={openEditAddress}
          client={client}
          setClient={setClient}
        />}
    </LayoutBaseDePagina>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});