import React, { useEffect, useState } from 'react';
import { Button, Stack, Theme, useMediaQuery } from '@mui/material';
import { ProdutosListagem } from './sections';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { BaseModal } from '../../../../shared/components/modals/BaseModal';
import { IProductSale, IProductSaleInsert, ProductSale, ProductSaleInsert, ProductSaleInsertNotName } from '../../../../shared/models/ProductSale';
import { ProductSaleService } from '../../../../shared/services/api/productSale/ProductSaleService';

interface BaseModalProps {
  handleClose: () => void;
  saleId: number;
  openModal: boolean;
};

export const ModalAddProdutoVendas: React.FC<BaseModalProps> = (props) => {
  const { handleClose, openModal, saleId } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [saleProducts, setSaleProducts] = useState<IProductSaleInsert[]>([new ProductSaleInsert(0, 0, 0, 'string')]);
  const [productsSelect, setProductsSelect] = useState<IProductSaleInsert[]>([new ProductSaleInsert(0, 0, 0, 'string')]);

  useEffect(() => {
    productsSelect.map(prod => {
      const product = new ProductSaleInsert(prod.stockProductId, prod.quantity, prod.value, prod.name, saleId);

      if (saleProducts[0].stockProductId == 0) {
        setSaleProducts([product]);
      }
      else
        setSaleProducts(oldRows =>
          oldRows.find(x => x.stockProductId == product.stockProductId) ? [...oldRows] : [...oldRows, product]
        );
    });
  }, [productsSelect]);

  function handleAoClicarEmSalvar() {
    const request = saleProducts.map(p =>
      new ProductSaleInsertNotName(p.stockProductId, p.quantity, p.value, p.saleId))
    ProductSaleService.insert(request).then(result => {
      if (result.success) {
        alert("Produto cadastrado com sucesso!");
        window.location.reload();
      }
      else {
        alert(result.errors[0]);
      }
    })
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <BaseModal
      handleClose={handleClose}
      open={openModal}
      title={'Adicionar Produto'}
      minWidth={320}
    >
      <FlexBox width={smDown ? 320 : 640}
        column gap={10}
        marginTop={3}
      >
        <FlexBox gap={10} row minHeight={100}>
          <ProdutosListagem
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            productsSelect={productsSelect}
            setProductsSelect={setProductsSelect}
          />
        </FlexBox>
        <Stack
          marginTop={2}
          direction='row'
          justifyContent='space-between'
        >
          <Button onClick={handleClose}>Fechar</Button>
          <Button variant='contained' onClick={handleAoClicarEmSalvar}>Salvar</Button>
        </Stack>

      </FlexBox>
    </BaseModal>
  );
};
