import {Typography, Button, Icon, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ISale } from '../../../shared/models/Sale';
import { ModalAddProdutoVendas } from '../components/addVendasModal/ModalAddProdutoVendas';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { CardProductVenda } from '../components';

interface ProdutoContentProps {
  isLoading: boolean;
  sale: ISale | undefined;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>
};

export const ProdutoContent: React.FC<ProdutoContentProps> = ({ isLoading, sale, setSale }) => {

  const [openAddModal, setOpenAddModal] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);

  useEffect(() => {
    if (sale != undefined)
      setDeleteButton(sale.status == 0)
  }, [sale])

  return (
    <FlexBox column fullWidth borderRadius={5} gap={10}
    >

      <FlexBox jcBetween padding={1} row gap={10}>
        <Typography variant="h5">Produtos</Typography>

        <FlexBox gap={10}>
          {sale?.status == 0 && <Button color="primary"
            disableElevation
            variant='contained'
            disabled={sale?.status != 0}
            onClick={() => setOpenAddModal(true)}
          >
            <Icon>add</Icon>Adicionar
          </Button>}

        </FlexBox>
      </FlexBox>

      {isLoading && (
        <FlexBox>
          <CircularProgress size={90} />
        </FlexBox>
      )}

      <Stack direction="column" padding={1} gap={1}>

        {!isLoading && sale?.products && (

          sale.products.map(product => (
            <CardProductVenda
              setSale={setSale}
              product={product}
              deleteButton={deleteButton}
            />
          ))
        )}

      </Stack>

      <ModalAddProdutoVendas openModal={openAddModal} handleClose={() => setOpenAddModal(false)}
        saleId={sale ? sale?.id : 0}
      />
    </FlexBox>
  )
};
