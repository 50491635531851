import { Api } from "../axios-config";
import { IResponseAmount } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ILaboratory } from "../../../models/Laboratory";
import { ILaboratoryInsert, ILaboratoryUpdate } from "../../../models/Dtos/LaboratoryDtos";


const insert = async (laboratory: ILaboratoryInsert): Promise<IResponseAPI<ILaboratory>> => {

  const { data } = await Api.post('/otica/Laboratory', laboratory).catch(data => {
    return data.response;
  });

  return data;
};

const update = async (laboratory: ILaboratoryUpdate): Promise<IResponseAPI<ILaboratory>> => {

  const { data } = await Api.put('/otica/Laboratory', laboratory).catch(data => {
    return data.response;
  });

  return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<ILaboratory[]>>> => {

  const { data } = await Api.get(`/otica/Laboratory/${id}`).catch(data => {
    return data.response;
  });

  return data;
};

const getFiltered = async (
  name?: string,
  isActive?: boolean,
  page: number = 1,
  pageSize: number = 10
): Promise<IResponseAPI<IResponseAmount<ILaboratory[]>>> => {

  const { data } = await Api.get('/otica/Laboratory', {
    params: {
      name,
      isActive,
      page,
      pageSize,
    },
  }).catch(data => {
    return data.response;
  });

  return data;
};

export const LaboratoryService = {
  insert,
  update,
  getById,
  getFiltered,
};