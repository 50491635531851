import { IModelProduct } from "../../../ModelProduct";
import { IModelProductInsert, IModelProductUpdate } from "../../../Dtos/cadastrosDtos/ModelProductDto";

export class ModelProduct implements IModelProduct {
  id: number;
  name: string;
  isActive: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<IModelProduct> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};


export class ModelProductInsert implements IModelProductInsert {
  name: string;

  constructor({ name = "" }: Partial<IModelProductInsert> = {}) {
    this.name = name;
  }
};


export class ModelProductUpdate implements IModelProductUpdate {
  id: number;
  name?: string;
  isActive?: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<IModelProductUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};