import React, { useState } from 'react';
import { LinearProgress, Typography, Button, Icon } from '@mui/material';
import { ISale } from '../../../shared/models/Sale';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { OrderServiceCard } from '../../ordensDeServicos/components/OrderServiceCard';
import { ModalAddOrder } from '../../../shared/components/addOrderServiceModal/ModalAddOrder';

interface OrdemDeServicoContentProps {
  isLoading: boolean;
  sale: ISale | undefined;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OrdemDeServicoContent: React.FC<OrdemDeServicoContentProps> = (props) => {
  const { isLoading, sale, setSale, setIsLoading } = props;

  const [openAddOrder, setOpenAddOrder] = useState(false);


  function handleOpenAddOrder() {
    setOpenAddOrder(true);
  };
  function handleCloseAddOrder() {
    setOpenAddOrder(false);
  };


  return (
    <FlexBox column padding={1} fullWidth boxSizing={'border-box'}>
      {isLoading && (
        <LinearProgress variant="indeterminate" />
      )}
      <FlexBox row jcBetween
      >
        <Typography variant="h5">
          Ordem de serviço
        </Typography>

        <Button variant='contained'
          onClick={handleOpenAddOrder}
        >
          <Icon>add</Icon>Adicionar
        </Button>

      </FlexBox>
      <FlexBox column paddingTop={3} gap={20}>
        {sale?.orderServices && sale.orderServices.map((order) => (
          <OrderServiceCard
            sale={sale}
            order={order}
            setSale={setSale}
            key={order.id}
            setIsLoading={setIsLoading}
          />

        ))}
      </FlexBox>

      {sale &&
        <ModalAddOrder
          sale={sale}
          setSale={setSale}
          open={openAddOrder}
          handleClose={handleCloseAddOrder}
        />
      }
    </FlexBox>
  );
};


