import { Environment } from "../../../environment";
import { IResponseAmount } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IProductSale, IProductSaleInsertNotName } from "../../../models/ProductSale";
import { ISale } from "../../../models/Sale";
import { Api } from "../axios-config";


const insert = async (product: IProductSaleInsertNotName[]): Promise<IResponseAPI<IProductSale[]> | IResponseAPI<IProductSale[]>> => {

    const { data } = await Api.post('/otica/ProductSale', product).catch(data => {
        return data.response;
    });

    return data;
};

const deleteById = async (id: number): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.delete(`/otica/ProductSale/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (pagina: number): Promise<IResponseAPI<IResponseAmount<IProductSale[]>> | IResponseAPI<IResponseAmount<IProductSale[]>>> => {

    const { data } = await Api.get(`/otica/ProductSale?page=${pagina}&pageSize=${Environment.LIMITE_DE_LINHAS}`).catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IProductSale[]>> | IResponseAPI<IResponseAmount<IProductSale[]>>> => {

    const { data } = await Api.get(`/otica/ProductSale/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const ProductSaleService = {
    insert,
    getAll,
    getById,
    deleteById,
};