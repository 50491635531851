import { IStatusOrder } from "./enums/StatusOrder";
import { IPrescription, IPrescriptionUpdate } from "./Prescription";
import { ILaboratory } from "./Laboratory";
import { PrescriptionUpdate } from "./classes/prescription/Prescription";

export interface IOrderService {
    id: number;
    cost: number;
    saleId: number;
    forecast: Date;
    imageUrl?: string;
    description: string;
    status: IStatusOrder;
    laboratory?: ILaboratory;
    prescription: IPrescription;
};

export interface IOrderServiceInsert {
    prescription?: IPrescription;
    laboratoryId?: number;
    description?: string;
    imageUrl?: string;
    forecast?: Date;
    saleId: number;
    cost?: number;
};

export interface IOrderServiceSaleInsert {
    prescription?: IPrescription;
    laboratoryId?: number;
    description?: string;
    imageUrl?: string;
    forecast?: Date;
    saleId: number;
    cost?: number;
};


export interface IOrderServiceEdit {
    id: number;
    cost?: number;
    imageUrl?: string;
    forecast?: Date;
    description?: string;
    status?: IStatusOrder;
    laboratoryId?: number;
    prescription?: IPrescriptionUpdate;
};

export interface IOrderServiceUpdate {
    id: number;
    status: IStatusOrder;
};



export class OrderServiceUpdate {
    id: number;
    status: IStatusOrder;

    constructor(id: number, status: IStatusOrder) {
        this.id = id;
        this.status = status;
    }
};

export class OrderServiceEdit implements IOrderServiceEdit {
    id: number;
    cost?: number;
    imageUrl?: string;
    forecast?: Date;
    description?: string;
    status?: IStatusOrder;
    laboratoryId?: number;
    prescription?: IPrescriptionUpdate;

    constructor({
        id = 0,
        cost = 0,
        imageUrl = "",
        forecast = new Date(),
        description = "",
        status = 0,
        laboratoryId = 0,
        prescription = new PrescriptionUpdate(),

    }: Partial<IOrderServiceEdit> = {}) {

        this.id = id;
        this.cost = cost;
        this.status = status;
        this.imageUrl = imageUrl;
        this.forecast = forecast;
        this.description = description;
        this.laboratoryId = laboratoryId;
        this.prescription = prescription;
    }
};



export class OrderServiceSaleInsert implements IOrderServiceSaleInsert {
    saleId: number;
    cost?: number;
    forecast?: Date;
    laboratoryId?: number;
    description?: string;
    prescription?: IPrescription;
    imageUrl?: string;

    constructor(
        saleId: number,
        cost?: number,
        forecast?: Date,
        laboratoryId?: number,
        description?: string,
        prescription?: IPrescription,
        imageUrl?: string,
    ) {
        this.saleId = saleId;
        this.cost = cost;
        this.forecast = forecast;
        this.imageUrl = imageUrl;
        this.laboratoryId = laboratoryId;
        this.description = description;
        this.prescription = prescription;
    };
};



export class OrderServiceInsert {
    cost: number;
    saleId: number;
    forecast: Date;
    description: string;
    status: IStatusOrder;
    laboratoryId: number;
    prescription: IPrescription;

    constructor(
        cost: number,
        saleId: number,
        forecast: Date,
        description: string,
        status: IStatusOrder,
        laboratoryId: number,
        prescription: IPrescription,
    ) {
        this.cost = cost;
        this.status = status;
        this.saleId = saleId;
        this.forecast = forecast;
        this.laboratoryId = laboratoryId;
        this.description = description;
        this.prescription = prescription;
    };
};

export class OrderService implements IOrderService {
    id: number;
    cost: number;
    saleId: number;
    forecast: Date;
    description: string;
    status: IStatusOrder;
    laboratory: ILaboratory;
    prescription: IPrescription;
    imageUrl?: string;


    constructor(
        id: number,
        cost: number,
        saleId: number,
        forecast: Date,
        description: string,
        status: IStatusOrder,
        laboratory: ILaboratory,
        prescription: IPrescription,
        imageUrl?: string,
    ) {
        this.id = id;
        this.cost = cost;
        this.status = status;
        this.saleId = saleId;
        this.forecast = forecast;
        this.laboratory = laboratory;
        this.description = description;
        this.prescription = prescription;
        this.imageUrl = imageUrl;
    }
    ;
};