import { Typography, Icon } from '@mui/material';
import React from 'react';

import { statusBarPayment } from '../../vendas/components/StatusBarPayment';
import { PriceDisplay } from '../../../shared/helps/Dashboard-PriceDisplay';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { IReport } from '../../../shared/models/Report';
import { CardBox } from '../../../shared/components';
import { blue, green, red } from '@mui/material/colors';


interface IVariantProps {
  color: string;
  name: string;
  icon: string;
  value: number;
}

interface ITotalCardProps {
  variant: "success" | "error" | "totalValue";
  report: IReport | undefined;
}

export const TotalCard: React.FC<ITotalCardProps> = (props) => {
  const { report, variant } = props;

  const totalValue = report?.totalRevenue ?? 0;
  const totalCostValue = report?.totalExpense ?? 0;

  const variantSelect = (variantt: "success" | "error" | "totalValue"): IVariantProps => {
    switch (variantt) {
      case "success":
        return { color: green[700], name: "Receita", icon: "trending_up", value: totalValue ?? 0 };
      case "error":
        return { color: red[700], name: "Despesa", icon: "trending_down", value: totalCostValue ?? 0 };
      case "totalValue":
        return { color: blue[700], name: "Lucro", icon: "paid", value: totalValue - totalCostValue };
    };
  };

  const variantSelected = variantSelect(variant);

  return (
    <CardBox
      column
      padding={2.5}
      fullWidth
      sx={{ height: 'auto' }}
      boxSizing={"border-box"}
      gap={15}
    >

      <FlexBox fullWidth jcBetween>
        <Typography
          color={variantSelected.color}
          fontWeight={500}
        >
          {variantSelected.name}
        </Typography>

        <Icon sx={{ color: variantSelected.color, fontSize: 26 }}>
          {variantSelected.icon}
        </Icon>
      </FlexBox>

      <FlexBox>
        <PriceDisplay
          value={variantSelected.value}
          color={variantSelected.color}
        />
      </FlexBox>

    </CardBox>
  )
}
