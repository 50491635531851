import { TextField } from '@mui/material';
import React from 'react'


interface IInputNumericStateProps {
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    helperText?: React.ReactNode;
    defaultValue?: unknown;
    required?: boolean;
    startIcon?: string;
    endIcon?: string;
    error?: boolean;
    label?: string;
    value: number;
    name?: string;
};

export const InputNumericState: React.FC<IInputNumericStateProps> = (props) => {
    const { value, label, required = false, onChange, defaultValue, endIcon, startIcon, name } = props;


    return (
        <TextField
            size="small"
            name={name}
            type={value < 1 ? 'text' : 'number'}
            label={label}
            value={value < 1 ? '' : value}
            required={required}
            onChange={onChange}
            defaultValue={defaultValue}
            onInput={(e) => {
                const input = e.target as HTMLInputElement;
                const value = input.value;
                const validValue = value.replace(/[^0-9]/g, ''); // Valida o input em: (somente números inteiros e não negativos)
                input.value = validValue;
            }}
        />
    );
};