import { Menu, MenuItem, Icon, Typography, useTheme } from '@mui/material';
import React, { SetStateAction } from 'react';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { TransactionService } from '../../../shared/services/api/transaction/TransactionService';
import { ISale } from '../../../shared/models/Sale';
import { blue, blueGrey, green, orange, purple, red } from '@mui/material/colors';
import { ITransaction } from '../../../shared/models/Transaction';

interface EditStatusTransactionProps {
  open: boolean;
  transaction: ITransaction | undefined;
  anchorEl: HTMLElement | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
};

export const EditStatusTransaction: React.FC<EditStatusTransactionProps> = (props) => {
  const { anchorEl, open, transaction, setSale, setIsLoading, setAnchorEl } = props;

  function handleChangeStatus(statusType: number) {
    TransactionService.updateStatus({ id: transaction?.id as number, status: statusType }
    ).then((result) => {

      setIsLoading(true);
      if (result.success) {
        setSale((s) => {
          s?.transactions.forEach(x => x.status = x.id == transaction?.id ? result.data.status : x.status)
          return s
        });
        window.location.reload()
      } else {
        alert(result.errors[0]);
      }
      setIsLoading(false);
    });
  };

  const menuItems = [
    { icon: 'download_done', title: 'Confirmado', statusType: 0 },
    { icon: 'cached', title: 'Estornado', statusType: 1 },
    { icon: 'clear', title: 'Atrasado', statusType: 2 },
    { icon: 'clear', title: 'Cancelado', statusType: 3 },
    { icon: 'pending', title: 'Pendente', statusType: 4 }
  ];

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 100,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <FlexBox column gap={5}>
        {menuItems.filter(x => transaction?.toStatus.includes(x.statusType)).map((menuItem, index) => (
          <MenuItemStatusChange
            key={index}
            icon={menuItem.icon}
            title={menuItem.title}
            setAnchorEl={setAnchorEl}
            handle={handleChangeStatus}
            statusType={menuItem.statusType}
          /> 
        ))}
      </FlexBox>
    </Menu>
  );
};


interface MenuItemStatusChangeProps {
  icon: string;
  title: string;
  statusType: number;

  setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
  handle(statusType: number): void;
};

const MenuItemStatusChange: React.FC<MenuItemStatusChangeProps> = (props) => {
  const { icon, title, statusType, setAnchorEl, handle } = props;

  const colorSituation = () => {
    switch (statusType) {
      case 0:
        return green[700];
      case 1:
        return purple[600];
      case 2:
        return orange[700];
      case 3:
        return red[700];
      case 4:
        return blue[700];
      default:
        return blueGrey[500];
    };
  };

  const handlerOption = () => {
      handle(statusType);
      setAnchorEl(null);
  }

  return (
    <MenuItem
      onClick={handlerOption}
    >
      <FlexBox gap={10}>
        <Icon sx={{ color: colorSituation() }} >{icon}</Icon>
        <Typography>{title}</Typography>
      </FlexBox>
    </MenuItem>
  );
};
