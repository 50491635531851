import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import React, { Dispatch, SetStateAction } from 'react';
import { MobileStepper, Button } from '@mui/material';

import { InputFiles } from '../../../shared/components/inputs/input-file/InputFiles';
import imgSemFoto from '../../../shared/assets/images/sem-imagem.jpg';
import FlexBox from '../../../shared/components/layouts/FlexBox';


interface IInputImagemSectionProps {  
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setImages: Dispatch<SetStateAction<string[]>>;
  handleNext: () => void;
  handleBack: () => void;
  activeStep: number;
  isLoading: boolean;
  images: string[];
}

export const InputImagemSection: React.FC<IInputImagemSectionProps> = (props) => {
  const { setIsLoading, handleNext, handleBack, setImages, activeStep, isLoading, images } = props;

  return (

    <FlexBox column width={210}>

      <FlexBox fullWidth jcCenter minHeight={185} boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.137)" borderRadius={3}>

        {images && images.length > 0 ? (
          <img width={200} src={images[activeStep]} />
        ) : (
          <img width={200} src={imgSemFoto} />
        )}

      </FlexBox>

      <FlexBox
        fullWidth
        marginTop={.5}
      >

        <MobileStepper
          variant="dots"
          steps={images.length}
          position="static"
          activeStep={activeStep}
          sx={{ flexGrow: 1, backgroundColor: "#F4F7FE" }}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === (images.length === 0 ? images.length : images.length - 1)}>
              {images && images.length > 0
                ?
                <KeyboardArrowRight />
                :
                <div />
              }
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {images && images.length > 0
                ?
                <KeyboardArrowLeft />
                :
                <div />
              }
            </Button>
          }
        />

      </FlexBox>

      <InputFiles
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setImages={setImages}
        buttonText={'Fotos'}

      />

    </FlexBox>
  )
}
