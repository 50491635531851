import React, { useState } from 'react';
import { CardProductAllDatas } from '../components/Card-ProductAllData';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { IProduct } from '../../../shared/models/Product';
import { CardBox } from '../../../shared/components';
import { Button, Icon } from '@mui/material';
import { ListStockProduct } from './ListagemDeProdutos-ListStockProduct';
import { IStockProduct } from '../../../shared/models/StockProduct';
import { ModalInsertStock } from '../components/Modal-InsertStock';

interface IStockProductSectionProps {
  viewProductSection: IProduct | undefined;
  viewStockSection: IStockProduct[];
  productVerification: boolean;
  emptyProduct: IProduct;
};


export const StockProductSection: React.FC<IStockProductSectionProps> = (props) => {
  const { productVerification, viewProductSection, emptyProduct, viewStockSection } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenInsert = () => {
    setIsOpen(true);
  };

  return (
    <>
      {productVerification &&
        <CardProductAllDatas
          product={emptyProduct}
        />
      }

      <FlexBox gap={20} column>
        {viewProductSection &&
          <CardProductAllDatas
            product={viewProductSection}
          />
        }


        <CardBox column gap={50} sx={{
          transition: 'all 0.5s ease-in-out',
          display: productVerification ? "none" : "flex",
        }}
        >



          <FlexBox column gap={10}>
            <FlexBox fullWidth jcEnd padding={2} boxSizing={"border-box"}>

              <Button variant="outlined" onClick={handleOpenInsert}>
                <Icon>add</Icon>Estoque
              </Button>

            </FlexBox>

            <ListStockProduct
              viewStockProduct={viewStockSection}
            />
            
          </FlexBox>
        </CardBox>

        <ModalInsertStock
          isOpen={isOpen}
          product={viewProductSection}
          setIsOpen={setIsOpen}
        />

      </FlexBox>
    </>
  )
}
