import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import TextField from '@mui/material/TextField';



interface IInputCurrencyProps {
  value: number;
  name?: string;
  label?: string;
  required?: boolean;
  errorValue?: boolean;
  setValue: Dispatch<SetStateAction<number>>;
  setErrorValue?: Dispatch<SetStateAction<boolean>>;
};

export const InputCurrency: React.FC<IInputCurrencyProps> = (props) => {
  const { value, setValue, label, errorValue, setErrorValue, required, name } = props;

  /**
   * Formata um valor numérico como moeda brasileira (R$).
   * @param value Valor bruto em centavos.
   * @returns Valor formatado como moeda brasileira.
   */
  function formatCurrency(value: number): string {
    return (value / 100).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  function handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
    const input = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const numericValue = parseInt(input || "0", 10); // Converte para número

    if (setErrorValue)
      setErrorValue(false);

    setValue(numericValue);
  };



  return (
    <TextField
      fullWidth
      name={name}
      variant="outlined"
      error={errorValue}
      label={label ?? "Valor"}
      required={required ?? false}
      onChange={handleInputChange}
      value={formatCurrency(value)}
      inputProps={{
        maxLength: 15,
        inputMode: "numeric", // Sugere teclado numérico em dispositivos móveis
      }}
    />
  );
};

