import { Icon, ListItemButton, ListItemIcon, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Env } from "../../../../environment";

interface IListItemLinkProps {
  to?: string; // Caminho opcional
  icon: string;
  label: string;
  onClick: () => void | undefined;
  selectable?: boolean; // Novo: Controla se o item é selecionável
}

export const ListItemLink: React.FC<IListItemLinkProps> = ({
  to,
  icon,
  label,
  onClick,
  selectable = true, // Por padrão, é selecionável
}) => {

  const navigate = useNavigate();
  
  const mdDown = useMediaQuery(useTheme().breakpoints.down("md"));

  // Hooks sempre fora de condicionais
  const resolvedPath = useResolvedPath(to || ""); // Se `to` for undefined, usa uma string vazia
  const match = useMatch({ path: resolvedPath.pathname, end: false }) && selectable;

  const handleClick = () => {
    if (to) navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton
      selected={!!match} // Aplica o "selected" apenas se for selecionável
      onClick={handleClick}
      sx={{
        borderRadius: Env.BD_RADIUS - 6,
        '&.Mui-selected': { backgroundColor: '#aeaeaeccc' },
        '&:hover': { backgroundColor: selectable ? '#2c175612' : '#2c175636' }, // Diferencia hover para selecionáveis
      }}
    >
      <ListItemIcon sx={{ color: mdDown ? "#fff" :  "#5e2bab"}}>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <Typography
        sx={{
          color: mdDown ? "#fff" : "#5e2bab",
        }}
      >
        {label}
      </Typography>
    </ListItemButton>
  );
};
