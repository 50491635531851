import React, { useEffect, useState } from 'react';
import {Icon, Button} from '@mui/material';

import { LaboratoryService } from '../../../../shared/services/api/laboratory/LaboratoryService';
import { useListagemDeCadastrosContext } from '../../../../shared/contexts';
import { LaboratoryTableSection } from './sections/LaboratoryTableSection';
import { ModalAddEditLaboratory } from './modals/ModalAddEditLaboratory';
import { ILaboratory } from '../../../../shared/models/Laboratory';
import FlexBox from '../../../../shared/components/layouts/FlexBox';


export const LaboratoriosPage: React.FC = () => {

  const { isLoading, startLoading, stopLoading } = useListagemDeCadastrosContext();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [rows, setRows] = useState<ILaboratory[] | null>();
  const [rowEdit, setRowEdit] = useState<ILaboratory | null>();
  const [totalCount, setTotalCount] = useState<number | null>();


  useEffect(() => {
    startLoading();
    LaboratoryService.getFiltered().then(result => {
      stopLoading();
      if (result.success) {
        setRows(result.data.data);
        setTotalCount(result.data.amount);
      }
      else {
        alert(result.errors[0]);
      }
    });
  }, [])


  function handleIsOpenInsert() {
    setRowEdit(null);
    setIsOpenAdd(true);
  };

  function handleIsOpenEdit(row: ILaboratory) {
    setRowEdit(row);
    setIsOpenAdd(true);
  };


  return (
    <FlexBox fullWidth column gap={20} padding={1}>
      <FlexBox row jcEnd fullWidth>
        <Button
          variant='contained'
          size='small'
          onClick={handleIsOpenInsert}
        >
          <Icon>add</Icon>Laboratório
        </Button>
      </FlexBox>

      <FlexBox>

        <LaboratoryTableSection
          handleIsOpenEdit={handleIsOpenEdit}
          totalCount={totalCount}
          isLoading={isLoading}
          rows={rows}
        />
        
      </FlexBox>

      {isOpenAdd &&
        <ModalAddEditLaboratory
          rowEdit={rowEdit as ILaboratory}
          setRows={setRows as React.Dispatch<React.SetStateAction<ILaboratory[]>>}
          isOpenAdd={isOpenAdd}
          setIsOpenAdd={setIsOpenAdd}
        />
      }
    </FlexBox>
  )
}