import { Box, Typography } from "@mui/material"
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface DadosUsuarioSectionProps {
  title: string;
  description: string;
}

export const DadosUsuarioSection: React.FC<DadosUsuarioSectionProps> = ({ title, description }) => {
  return (
    <Box>
      <FlexBox jcBetween>
        
        <FlexBox column>
          <Typography fontSize='13px' fontWeight={700}>
            {title}
          </Typography>
          <Typography variant='subtitle2' marginLeft={1}>
            {description}
          </Typography>
        </FlexBox>

        <FlexBox marginRight={3} marginTop={1}>
          {title == "WhatsApp:" && description != undefined &&
            <Link target="_blank" to={`https://api.whatsapp.com/send/?phone=55${description.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")}&text&type=phone_number&app_absent=1`}>
              <WhatsAppIcon fontSize='large' color='success' />
            </Link>
          }
        </FlexBox>

      </FlexBox>
    </Box>
  )
};