import { IPrescriptionInsert, IPrescriptionUpdate } from "../../Prescription";



export class PrescriptionUpdate implements IPrescriptionUpdate {
  id: number;
  farODSpherical?: string;
  farOESpherical?: string;
  farODCylindrical?: string;
  farOECylindrical?: string;
  farODAxis?: string;
  farOEAxis?: string;
  farODHeight?: string;
  farOEHeight?: string;
  farODDNP?: string;
  farOEDNP?: string;
  nearODSpherical?: string;
  nearOESpherical?: string;
  nearODCylindrical?: string;
  nearOECylindrical?: string;
  nearODAxis?: string;
  nearOEAxis?: string;
  nearODHeight?: string;
  nearOEHeight?: string;
  nearODDNP?: string;
  nearOEDNP?: string;
  additionSpherical?: string;

  constructor({
    id = 0,
    farODSpherical = "",
    farOESpherical = "",
    farODCylindrical = "",
    farOECylindrical = "",
    farODAxis = "",
    farOEAxis = "",
    farODHeight = "",
    farOEHeight = "",
    farODDNP = "",
    farOEDNP = "",
    nearODSpherical = "",
    nearOESpherical = "",
    nearODCylindrical = "",
    nearOECylindrical = "",
    nearODAxis = "",
    nearOEAxis = "",
    nearODHeight = "",
    nearOEHeight = "",
    nearODDNP = "",
    nearOEDNP = "",
    additionSpherical = "",
  }: Partial<IPrescriptionUpdate> = {}) {
    this.id = id;
    this.farODSpherical = farODSpherical;
    this.farOESpherical = farOESpherical;
    this.farODCylindrical = farODCylindrical;
    this.farOECylindrical = farOECylindrical;
    this.farODAxis = farODAxis;
    this.farOEAxis = farOEAxis;
    this.farODHeight = farODHeight;
    this.farOEHeight = farOEHeight;
    this.farODDNP = farODDNP;
    this.farOEDNP = farOEDNP;
    this.nearODSpherical = nearODSpherical;
    this.nearOESpherical = nearOESpherical;
    this.nearODCylindrical = nearODCylindrical;
    this.nearOECylindrical = nearOECylindrical;
    this.nearODAxis = nearODAxis;
    this.nearOEAxis = nearOEAxis;
    this.nearODHeight = nearODHeight;
    this.nearOEHeight = nearOEHeight;
    this.nearODDNP = nearODDNP;
    this.nearOEDNP = nearOEDNP;
    this.additionSpherical = additionSpherical;
  }
}

export class PrescriptionInsert implements IPrescriptionInsert {
  farODSpherical?: string;
  farOESpherical?: string;
  farODCylindrical?: string;
  farOECylindrical?: string;
  farODAxis?: string;
  farOEAxis?: string;
  farODHeight?: string;
  farOEHeight?: string;
  farODDNP?: string;
  farOEDNP?: string;
  nearODSpherical?: string;
  nearOESpherical?: string;
  nearODCylindrical?: string;
  nearOECylindrical?: string;
  nearODAxis?: string;
  nearOEAxis?: string;
  nearODHeight?: string;
  nearOEHeight?: string;
  nearODDNP?: string;
  nearOEDNP?: string;
  additionSpherical?: string;

  constructor({
    farODSpherical = "",
    farOESpherical = "",
    farODCylindrical = "",
    farOECylindrical = "",
    farODAxis = "",
    farOEAxis = "",
    farODHeight = "",
    farOEHeight = "",
    farODDNP = "",
    farOEDNP = "",
    nearODSpherical = "",
    nearOESpherical = "",
    nearODCylindrical = "",
    nearOECylindrical = "",
    nearODAxis = "",
    nearOEAxis = "",
    nearODHeight = "",
    nearOEHeight = "",
    nearODDNP = "",
    nearOEDNP = "",
    additionSpherical = "",
  }: Partial<IPrescriptionInsert> = {}) {
    this.farODSpherical = farODSpherical;
    this.farOESpherical = farOESpherical;
    this.farODCylindrical = farODCylindrical;
    this.farOECylindrical = farOECylindrical;
    this.farODAxis = farODAxis;
    this.farOEAxis = farOEAxis;
    this.farODHeight = farODHeight;
    this.farOEHeight = farOEHeight;
    this.farODDNP = farODDNP;
    this.farOEDNP = farOEDNP;
    this.nearODSpherical = nearODSpherical;
    this.nearOESpherical = nearOESpherical;
    this.nearODCylindrical = nearODCylindrical;
    this.nearOECylindrical = nearOECylindrical;
    this.nearODAxis = nearODAxis;
    this.nearOEAxis = nearOEAxis;
    this.nearODHeight = nearODHeight;
    this.nearOEHeight = nearOEHeight;
    this.nearODDNP = nearODDNP;
    this.nearOEDNP = nearOEDNP;
    this.additionSpherical = additionSpherical;
  }
}