import { FormControl, FormControlLabel, Icon, Menu, Radio, RadioGroup } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { Dayjs } from 'dayjs';

import { InputDateRangePickerValue } from '../inputs/InputDateRangePickerValue';
import { IRoleLeveEnum } from '../../../shared/models/enums/RoleLeveEnum';
import FlexBox from '../layouts/FlexBox';
import { useAuthContext } from '../../../shared/contexts';


interface ButtonFilterDateProps {
  valueDateFilter: number;
  setValueDateFilter: React.Dispatch<React.SetStateAction<number>>;
  dateRange: Dayjs[];
  setDateRange: React.Dispatch<React.SetStateAction<Dayjs[]>>;
  handleClick: () => void;
};

export const ButtonFilterDate: React.FC<ButtonFilterDateProps> = (props) => {
  const { valueDateFilter, setValueDateFilter, dateRange, setDateRange, handleClick } = props;
  
  const { roles } = useAuthContext();
  const role = roles.find(x => x.isSelected);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = (event.target as HTMLInputElement).value;
    setValueDateFilter(Number(value));
  };

  function handleMenuItemClick(event: React.MouseEvent<HTMLElement>) {
    if (role?.level == IRoleLeveEnum.Administrador)
      setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
    handleClick()
  };

  const options = [
    "Persolanizado",
    "Hoje",
    "Este mês",
    "Este ano",
    "Últimos 7 dias",
    "Últimos 30 dias",
    "Próximos 30 dias",
  ];

  return (
    <>

      <Button
        size="medium"
        variant='text'
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuItemClick}
      >
        <Icon sx={{ marginRight: 1 }}>calendar_today</Icon>

        {options[valueDateFilter]}
        {role?.level == IRoleLeveEnum.Administrador &&
          <Icon>arrow_drop_down</Icon>}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FormControl sx={{ padding: 2 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={5}
            name="radio-buttons-group"
            value={valueDateFilter}
            onChange={handleChange}
          >

            <FlexBox row>
              <FlexBox column>
                <FormControlLabel value={4} control={<Radio />} label="Últimos 7 dias" />
                <FormControlLabel value={5} control={<Radio />} label="Últimos 30 dias" />
                <FormControlLabel value={6} control={<Radio />} label="Próximos 30 dias" />
              </FlexBox>
              <FlexBox column>
                <FormControlLabel value={1} control={<Radio />} label="Hoje" />
                <FormControlLabel value={2} control={<Radio />} label="Este mês" />
                <FormControlLabel value={3} control={<Radio />} label="Este ano" />
              </FlexBox>
            </FlexBox>

            <FlexBox marginY={2.5} jcCenter aiCenter>
              <FormControlLabel value={0} control={<Radio />} label="Persolanizado" />
            </FlexBox>

          </RadioGroup>
          {valueDateFilter == 0 ?
            <InputDateRangePickerValue setDateRange={setDateRange} dateRange={dateRange} /> : null
          }

          <Button
            startIcon={<Icon>search</Icon>}
            onClick={handleClose}
            sx={{ marginTop: 1 }}
            variant='contained'
          >
            Buscar
          </Button>
          
        </FormControl>
      </Menu>
    </>
  );
};