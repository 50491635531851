import { LayoutBaseDePagina } from "../../shared/layouts";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { CardBox } from "../../shared/components/layouts/CardBox";
import { Divider } from "@mui/material";
import { useStockContext } from "../../shared/contexts/pages/StockContext";
import { EntradasEstoque } from "./entradas/EntradasEstoque";
import { SaidasEstoque } from "./saidas/SaidasEstoque";
import { ButtonCellEstoque } from "./components/ButtonCellEstoque";

export const Estoque: React.FC = () => {
  const { pageName } = useStockContext();

  return (
    <LayoutBaseDePagina
      titulo="Movimentação de Estoque"
      buttonToBack
    >
      <CardBox column>
        <FlexBox flexWrap={"wrap"} paddingY={1} paddingX={2} gap={10} aiCenter>
          {/* <ButtonCellEstoque value={pageName} defaultValue={'relatorios'} title='Relatorios' />| */}
          <ButtonCellEstoque value={pageName} defaultValue={'entradas'} title='Entradas' />|
          <ButtonCellEstoque value={pageName} defaultValue={'saidas'} title='Saidas' />
        </FlexBox>
        <Divider variant='fullWidth' />
        <FlexBox paddingTop={1}>
          {/* {pageName === 'relatorios' && <EntradasEstoque />} */}
          {pageName === 'entradas' && <EntradasEstoque />}
          {pageName === 'saidas' && <SaidasEstoque />}
        </FlexBox>
      </CardBox>
    </LayoutBaseDePagina>
  )
}