import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { IReport, ISituation } from "../../shared/models/Report";
import { ReportService } from "../../shared/services/api/report/ReportService";
import { OverViewCard } from "../dashboard/sections/OverViewCard";
import { Transaction } from "../dashboard/sections";
import FlexBox from "../../shared/components/layouts/FlexBox";

export const DashboardAdmin = () => {
  const [situations, setSituations] = useState<ISituation[]>();
  const [report, setReport] = useState<IReport>();
  const [page, setPage] = useState(1);

  useEffect(() => {
    getInfos()
  }, [page]);

  const getInfos = () => {
    const request = {
      page: page
    }
    ReportService.getBranch(request).then((result) => {
      if (result.success) {
        setSituations(result.data.situations)
        setReport(result.data)
      }
    })
  }

  return (
    <LayoutBaseDePagina
      titulo="Página inicial"
    >
      <Box display='flex' >
        <Grid container>

          <Grid item container spacing={2}>
            {situations && situations.sort((a, b) => b.value - a.value).map(
              (situation, index) => (

                (index < 4) && (
                  <Grid item xs={11} sm={6} lg={3}>
                    <OverViewCard
                      sx={{ height: '100%' }}
                      situation={situation}
                    />
                  </Grid>
                )
              )
            )}
          </Grid>

          <FlexBox marginTop={2} fullWidth>
            <Transaction
              page={page}
              setPage={setPage}
              report={report}
            />
          </FlexBox>
        </Grid>

      </Box>
    </LayoutBaseDePagina>
  );
};