import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TextField, FormGroup, FormControlLabel, Checkbox, Icon, Button, Typography, Avatar } from "@mui/material";
import FlexBox from "../../../../../shared/components/layouts/FlexBox";
import { IOptionNewClient } from "../Modal-NovoCLiente2";
import { NewClientStorage } from "../../../../../shared/services/storage/newClientStorage";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "styled-components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

interface SectionNameProps {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  errorName: string;
  optionsNew: IOptionNewClient[];
  setOptionsNew: Dispatch<SetStateAction<IOptionNewClient[]>>;
  position: number;
  handleClose: () => void;
  handleSubmit(): void;
  handleAdvance: () => void;
  file: FileList | null | undefined;
  base64: string | undefined;
  handlerImage: (file: FileList | null) => void;
  dateBirth: Dayjs | undefined;
  setDateBirth: Dispatch<SetStateAction<Dayjs | undefined>>;
}


export const SectionName: React.FC<SectionNameProps> = (props) => {
  const { base64, setDateBirth, dateBirth, file, handlerImage, name, setName, optionsNew, position, setOptionsNew, handleClose, handleAdvance, handleSubmit, errorName
  } = props;

  const [options, setOptions] = useState<IOptionNewClient[]>([]);
  const [reloadEffect, setReloadEffect] = useState(0);
  const serviceStorage = new NewClientStorage()


  useEffect(() => {
    const itens = serviceStorage.getConfigNewUser();
    if (itens) {
      setOptions(itens)
    } else {
      setOptions(optionsNew)
    }
  }, [reloadEffect])

  function handleChangeOption(index: number) {
    const modify = optionsNew;
    modify[index].active = options[index].active ? false : true;
    serviceStorage.saveConfigNewUser(modify);
    setOptions(modify)
    setOptionsNew(modify)
    setReloadEffect(x => ++x);
  };

  function isCheck(index: number) {
    return optionsNew[index].active
  };

  function selectSubmit() {
    if (optionsNew.filter(x => x.active).length > position + 1) {
      handleAdvance();
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <FlexBox
        minHeight="100px"
        column gap={10}
        marginTop="40px"
        onSubmit={selectSubmit}
      >
        <FlexBox aiCenter jcCenter>
          <Avatar src={base64} sx={{ width: 100, height: 100, backgroundColor: '#9b9b9b' }}>
          </Avatar>
        </FlexBox>
        <TextField
          fullWidth
          required
          label="Nome Completo"
          value={name}
          error={!!errorName}
          helperText={errorName}
          onChange={e => setName(e.target.value)}
        />
        <LocalizationProvider dateFormats={{ fullDate: "DD/MM/YYYY" }} adapterLocale='pt-BR' dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Data de nascimento"
            value={dateBirth == undefined ? null : dayjs(dateBirth)}
            format='DD/MM/YYYY'
            onChange={(dateInit) => dateInit != undefined && dateInit != null ? setDateBirth(dateInit) : {}}

          />
        </LocalizationProvider>
        <FlexBox column>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {file != null && file != undefined ? file[0].name : "Adicionar foto de perfil"}
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => handlerImage(event.target.files)}
              multiple
            />
          </Button>
          <FlexBox jcEnd>
            <Typography fontSize={12}>.png .jpeg . jpg</Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox row jcBetween>
          <FormGroup>
            <FormControlLabel control={<Checkbox size="small"
              checked={isCheck(1)}
              onChange={() => handleChangeOption(1)} />} label="Contato"
            />
            <FormControlLabel control={<Checkbox size="small"
              checked={isCheck(2)}
              onChange={() => handleChangeOption(2)} />} label="Endereço"
            />
            <FormControlLabel control={<Checkbox size="small"
              checked={isCheck(3)}
              onChange={() => handleChangeOption(3)} />} label="Documento"
            />
          </FormGroup>
        </FlexBox>
      </FlexBox>

      <FlexBox row jcBetween marginTop="70px">
        <Button variant="text" onClick={handleClose}>
          Fechar
        </Button>

        {(optionsNew.filter(x => x.active).length > position + 1) && (
          <Button variant="outlined" endIcon={<Icon>forward_rounded</Icon>}
            type="submit" onClick={selectSubmit}
          >
            Avançar
          </Button>
        )}

        {(optionsNew.filter(x => x.active).length == position + 1) && (
          <Button variant="contained"
            type="submit" onClick={selectSubmit}
          >
            Salvar
          </Button>
        )}
      </FlexBox>
    </>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});