import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FerramentasDaListagem } from "../../../shared/components";
import { ModalNewUser } from "./components/ModalNewUser";
import { UserTable } from "./components/UserTable";
import { AuthService } from "../../../shared/services/api/auth/AuthService";
import { IUser } from "../../../shared/models/User";
import { LayoutBaseDePagina } from "../../../shared/layouts";

export const UserList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [rows, setRows] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [concluido, setConcluido] = useState(false);
  const [forShow, setForShow] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  useEffect(() => {
    setIsLoading(true)
    AuthService.getUsers().then((data) => {
      if (data != undefined &&data?.success && data?.data != undefined) {
        setRows(data.data.data)
        setTotalCount(data.data.amount)
      }
    })
    setIsLoading(false)
  }, [open, openEdit])

  const handleOpen = () => {
    if (concluido) {
      setForShow(0);
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <LayoutBaseDePagina
      titulo="Usuarios"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo="Usuario"
          aoClicarEmNovo={handleOpen}
          aoMudarTextDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >
      <ModalNewUser
        handleClose={handleClose}
        open={open}
        title="Cadastrar usuario"
        concluido={concluido}
        setConcluido={setConcluido}
        forShow={forShow}
        setForShow={setForShow}
      />
      <UserTable
        pagina={pagina}
        busca={busca}
        isLoading={isLoading}
        rows={rows}
        setRows={setRows}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        setSearchParams={setSearchParams}
        totalCount={totalCount}
      />
    </LayoutBaseDePagina>
  )
}