import { Environment } from "../../../environment";
import { IAddress } from "../../../models";
import { IResponseAmount } from "../../../models/base/IResponseAmount";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IClient, IClientInsert, IClientUpdate } from "../../../models/Clients/client";
import { Api } from "../axios-config";


const get = async (page = 1, filter = '', status = "0", dateInit?: string, dateEnd?: string): Promise<IResponseAPI<IResponseAmount<IClient[]>> | IResponseAPI<IResponseAmount<IClient[]>>> => {

  const urlRelativa = `/otica/client/branch?search=${encodeURIComponent(filter)}&page=${page}&pageSize=${Environment.LIMITE_DE_LINHAS}&status=${status}&dateInit=${dateInit == undefined ? '' : dateInit}&dateEnd=${dateEnd == undefined ? '' : dateEnd}`;
  const { data } = await Api.get(urlRelativa).catch(data => {
    return data.response
  });
  return data;
};

const create = async (client: IClientInsert): Promise<IResponseAPI<IClient> | IResponseAPI<IClient>> => {

  const { data } = await Api.post('/otica/Client', client).catch(data => {
    return data.response;
  });

  return data;
};

const getComum = async (page = 1, filter = '', status = "0", dateInit?: string, dateEnd?: string): Promise<IResponseAPI<IResponseAmount<IClient[]>> | IResponseAPI<IResponseAmount<IClient[]>>> => {

  const urlRelativa = `/otica/client/branch?search=${encodeURIComponent(filter)}&page=${page}&pageSize=${Environment.LIMITE_DE_LINHAS}&status=${status}&dateInit=${dateInit == undefined ? '' : dateInit}&dateEnd=${dateEnd == undefined ? '' : dateEnd}`;
  const { data } = await Api.get(urlRelativa).catch(data => {
    return data.response;
  });

  return data;
};



const getById = async (id: number): Promise<IResponseAPI<IClient> | IResponseAPI<IClient>> => {
  const { data } = await Api.get(`/otica/client/${id}`).catch((data) => {
    return data.response;
  });
  return data;
};

const updateById = async (id: number, dados: IClient): Promise<void | Error> => {
  try {
    await Api.put<IClient>(`/otica/clientes/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar os registros.');
  }
};

const update = async (dados: IClientUpdate): Promise<IResponseAPI<IClient> | IResponseAPI<IClient>> => {
  const { data } = await Api.put(`/otica/Client`, dados).catch((data) => {
    return data.response;
  });
  return data;
};

const deleteById = async (id: number): Promise<IResponseAPI<IClient> | IResponseAPI<IClient>> => {
  const { data } = await Api.delete(`/otica/client/${id}`).catch((data) => {
    return data.response;
  });
  return data;
};
const insertAddress = async (address: object): Promise<IResponseAPI<IAddress> | IResponseAPI<IAddress>> => {
  const { data } = await Api.post(`/otica/Client/Address`, address).catch((data) => {
    return data.response;
  });
  return data;
};


export const ClientesService = {
  get,
  update,
  create,
  getById,
  getComum,
  updateById,
  deleteById,
  insertAddress
};