import { Theme, useMediaQuery } from '@mui/material';
import { Dayjs } from 'dayjs';
import React from 'react';

import { ButtonFilterDate } from '../../../shared/components/buttons/ButtonFilterDate';
import { FilterButton } from '../components/Dashboard-Filter';
import FlexBox from '../../../shared/components/layouts/FlexBox';

interface FilterSectionProps {
    dateRange: Dayjs[];
    statusFilter: number[];
    handleClick: () => void;
    valueDateFilter: number;
    setDateRange: React.Dispatch<React.SetStateAction<Dayjs[]>>;
    setStatusFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setValueDateFilter: React.Dispatch<React.SetStateAction<number>>;
};

export const FilterSection: React.FC<FilterSectionProps> = (props) => {
    const { handleClick, valueDateFilter, setValueDateFilter, setDateRange, dateRange, setStatusFilter, statusFilter } = props;

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <FlexBox column paddingY={2} minWidth={'100%'} boxSizing={'border-box'}>
            <FlexBox row
                jcEnd
                gap={0} paddingTop={3}
                marginTop={smDown ? "-50px" : mdDown ? "-100px" : "-120px"}
                paddingRight={smDown ? 1 : 0}
            >
                <ButtonFilterDate
                    valueDateFilter={valueDateFilter}
                    setValueDateFilter={setValueDateFilter}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    handleClick={handleClick}
                />
                <FilterButton
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    handleClick={handleClick}
                />
            </FlexBox>
        </FlexBox>
    );
};
