import React, { useState } from 'react'
import { CardBox } from '../../layouts'
import { LinearProgress, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Chip, CircularProgress, TableFooter, Pagination } from '@mui/material';
import dayjs from 'dayjs';
import { report } from 'process';
import { statusBarPayment } from '../../../../pages/vendas/components/StatusBarPayment';
import { Env, Environment } from '../../../environment';
import CurrencyFormat from '../../../helps/CurrencyFormat';
import { ITransactionTypeEnum } from '../../../models/enums/TransactionType';
import FlexBox from '../../layouts/FlexBox';
import { ModalTransactionDesc } from '../../modals';


function createProducts(name: string, value: number, status: boolean) {
  return {
    name: name,
    value: value,
    status: status,
  };
};


interface ITableCreateToolProps {
  sx?: React.CSSProperties;
  onClick?: () => void | undefined;
  setPage?: (value: React.SetStateAction<number>) => void
  // columns: string[]; 
}

export const TableCreateTool: React.FC<ITableCreateToolProps> = (props) => {
  const { sx, onClick, setPage } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  function handleClick() {
    onClick?.();
  };

  // function editPage(value: number) {
  //   setPage(value);
  // }

  const columns = ["Nome", "Valor", "Status", "Ações"];
  const rows = [
    ["Gabriel", "Legal", "Aprovado", ""], // primeira linha
    ["Marcos", "Introvertido", "Aprovado", ""], // segunda linha
    ["Stefany", "Chata", "Reprovada", ""], // terceira linha
  ]

  // CurrencyFormat(totalValue)

  return (
    <CardBox padding={2.5} fullWidth sx={sx} boxSizing={"border-box"}>
      <FlexBox column paddingY={2} minWidth={'100%'} boxSizing={'border-box'} marginTop={5}>
        {isLoading && (
          <LinearProgress variant="indeterminate" />
        )}

        <FlexBox paddingX={2} row jcBetween>
          <Typography variant="h5">
            Extrato
          </Typography>
        </FlexBox>
        <FlexBox column paddingTop={2}>

          <TableContainer component={CardBox} column sx={{ width: 'auto', overflow: 'auto' }}>
            <Table size={'medium'}>

              <TableHead>
                <TableRow>

                  {columns.map(nameColumn => (
                    <TableCell align='center'>
                      {nameColumn}
                    </TableCell>
                  ))
                  }

                </TableRow>
              </TableHead>


              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    component={Box}
                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
                    onClick={handleClick}
                  >
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex} align="center">
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>

              {(totalCount == 0 && !isLoading) && (
                <caption>{Environment.LISTAGEM_VAZIA}</caption>
              )}

            </Table>


            {/* <FlexBox padding={2}>
              {isLoading && (
                <TableRow>
                  <LinearProgress variant='indeterminate' />
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
                <Pagination
                  page={page}
                  count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                  onChange={(_, newPage) => editPage(newPage)}
                />
              )}
            </FlexBox> */}

          </TableContainer>

        </FlexBox>
      </FlexBox>
    </CardBox>
  )
}
