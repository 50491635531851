import { Typography, Tooltip, Chip, useMediaQuery, Theme } from '@mui/material';
import React, { useState } from 'react';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { IOrderService } from '../../../../shared/models/OrderServiceModel';
import { statusBar } from '../statusBar';
import { MenuAlterarStatusOS } from '../MenuAlterarStatusOS';
import { ISale } from '../../../../shared/models/Sale';

interface StatusSectionSectionProps {
  order: IOrderService;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StatusSection: React.FC<StatusSectionSectionProps> = (props) => {
  const { order, setSale, setIsLoading } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  function handleOpenDialog(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  };

  return (
    <FlexBox row aiCenter gap={5}>
      <Typography fontSize='13px' fontWeight={700}>
        {"Status: "}
      </Typography>

      <Tooltip title="Alterar Status">
        <Chip
          variant="filled"
          onClick={handleOpenDialog}
          size={smDown ? 'small' : 'medium'}
          label={statusBar(order.status).label}
          color={statusBar(order.status).color}
          sx={{
            '&:hover': { transform: 'scale(1.1)', transition: 'all 0.15s ease-in-out', cursor: 'pointer', boxShadow: '1px 3px 5px 1px #e3e3e3', },
            '&:active': { transform: 'scale(0.9)', transition: 'all 0.05s ease-in-out', boxShadow: '1px 0px 10px 1px #f1f1f1' }
          }}
        />
      </Tooltip>

      <MenuAlterarStatusOS
        open={open}
        order={order}
        anchorEl={anchorEl}
        setIsLoading={setIsLoading}
        setAnchorEl={setAnchorEl}
        setSale={setSale}
      />
    </FlexBox>
  );
};