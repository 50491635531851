import { IClient } from "./Clients/client";
import { ICommit } from "./Commit";
import { IOrderService, IOrderServiceInsert } from "./OrderServiceModel";
import { IProductSale, IProductSaleInsertNotName } from "./ProductSale";
import { ITransaction } from "./Transaction";

export interface ISale {
    id: number;
    dateCreate: string;
    client: IClient;
    products: IProductSale[];
    orderServices: IOrderService[];
    commits: ICommit[];
    totalCost: number;
    total: number;
    status: number;
    toStatus: number[];
    transactions: ITransaction[];
};

// export interface ISaleInsert {
//     clientId: number;
//     products?: IProductSaleInsertNotName[];
//     orderServices?: IOrderServiceInsert[];
// };

export interface ISaleInsert {
    clientId: number;
    products?: IProductSaleInsertNotName[];
    orderServices?: IOrderServiceInsert[];
};

export interface ISaleStatusUpdate {
    id: number;
    status: number;
};





/////////////////////////////////////////////////     Classes   //////////////////////////////////

export class SaleStatusUpdate implements ISaleStatusUpdate {
    id: number;
    status: number;

    constructor(id: number, status: number) {
        this.id = id;
        this.status = status;
    };
}