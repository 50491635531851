import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ButtonCellProps {
  title: string;
  value: string;
  defaultValue: string;
};

export const ButtonCellEstoque: React.FC<ButtonCellProps> = (props) => {
  const { title, defaultValue, value } = props;
  const nav = useNavigate();

  return (
    <Button
      size='small'
      onClick={() => nav(`/estoque/${defaultValue}`)}
      variant={value === defaultValue ? "contained" : "text"}
      sx={{border: value !== defaultValue ? '5px solid transparent' : 'none'}}
    >
      {title}
    </Button>
  )
};