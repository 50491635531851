import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, TableContainer, Theme, Typography, useMediaQuery } from '@mui/material';
import { BaseModal } from '../../../../shared/components/modals/BaseModal';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { TermService } from '../../../../shared/services/api/term/TermService';
import styled from 'styled-components';
import { ITerm } from '../../../../shared/models/Term';


interface IModalNovaBranch {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    term: string;
}

export const ModalTerm: React.FC<IModalNovaBranch> = ({
    isOpen,
    setIsOpen,
    term
}) => {

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => { setIsOpen(false)  };
    return (
        <BaseModal
            open={isOpen}
            handleClose={handleClose}
            minWidth={smDown ? 300 : 500}
        >
            <TermContent>
                <div
                    dangerouslySetInnerHTML={{ __html: term }}
                />
            </TermContent>
            <FlexBox row fullWidth jcBetween marginTop={5}>
                <Button
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    Fechar
                </Button>
            </FlexBox>

        </BaseModal>
    )
};

const TermContent = styled.div`
    font-family: Arial, sans-serif; line-height: 1.6; margin: 20px;
    overflow: auto;
    height: 500px;
    h1{
        text-align: center;
    }
    p{
        margin: 10px 0;
    }
    ul{
        margin: 10px 20px; padding: 0;
    }
    ul li{
        margin-bottom: 5px;
    }
`;