import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { ModalAddVendas } from "../../shared/components/addVendasModal/ModalAddVenda";
import { SectionTableVendas } from "../clients/components/detalheDeClientes/sections";
import { ButtonFilterDate } from "../../shared/components/buttons/ButtonFilterDate";
import { SaleService } from "../../shared/services/api/sale/SaleService";
import { FerramentasDaListagem, setTimeVerification } from "../../shared/components";
import { SaleFilterButton } from "./components/Sale-Filter";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { useDebounce } from "../../shared/hooks";
import { ISale } from "../../shared/models/Sale";


export const ListagemDeVendas: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce(800, true);

  const [rows, setRows] = useState<ISale[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState<number[]>([]);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [reload, setReload] = useState(0);
  const [valueDateFilter, setValueDateFilter] = useState(5);
  const [dateRange, setDateRange] = useState<Dayjs[]>([dayjs(new Date()), dayjs(new Date())]);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const handleFilter = () => {
    setReload(reload + 1)
  }

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      
      const timeVerification = setTimeVerification(valueDateFilter, dateRange);

      SaleService.getAll(
        busca,
        dayjs(timeVerification.first).format("YYYY-MM-DD"),
        dayjs(timeVerification.last).format("YYYY-MM-DD"),
        status,
        pagina
      ).then((result) => {
        setIsLoading(false);
        if (result.success) {
          setTotalCount(result.data.amount);
          setRows(result.data.data);
        }
      });
    });

  }, [busca, pagina, reload]);


  return (
    <LayoutBaseDePagina
      titulo="Vendas"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo="Venda"
          placeBusca={'Cod, CPF, Nome do cliente'}
          aoClicarEmNovo={() => setOpenAddClient(true)}
          aoMudarTextDeBusca={texto => setSearchParams({ busca: texto, pagina: pagina.toString() }, { replace: true })}
        />
      }
    >

      <FlexBox column paddingY={2} minWidth={'100%'} boxSizing={'border-box'}>
        <FlexBox row jcEnd>

          <ButtonFilterDate
            setValueDateFilter={setValueDateFilter}
            valueDateFilter={valueDateFilter}
            setDateRange={setDateRange}
            handleClick={handleFilter}
            dateRange={dateRange}
          />

          <SaleFilterButton
            setStatusFilter={setStatus}
            handleClick={handleFilter}
            statusFilter={status}
          />
        </FlexBox>
      </FlexBox>

      <SectionTableVendas
        rows={rows}
        busca={busca}
        pagina={pagina}
        setRows={setRows}
        isLoading={isLoading}
        totalCount={totalCount}
        setSearchParams={setSearchParams}
      />


      <ModalAddVendas
        open={openAddClient}
        handleClose={() => setOpenAddClient(false)}
      />

    </LayoutBaseDePagina>
  )
};