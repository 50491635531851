import { Button, CircularProgress, Icon } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'
import FlexBox from '../../../../shared/components/layouts/FlexBox'
import { SetURLSearchParams } from 'react-router-dom'
import { useListagemDeRelatoriosContext } from '../../../../shared/contexts/pages/ListagemDeRelatoriosContext'


interface ISearchSectionProps {
  dateEnd: string;
  dateInit: string;
  isLoading: boolean;
  handleReportRequest(): void;
  setSearchParams: SetURLSearchParams;
}

export const SearchSection: React.FC<ISearchSectionProps> = (props) => {
  const { setSearchParams, dateEnd, dateInit, handleReportRequest, isLoading } = props;
  const { smDown } = useListagemDeRelatoriosContext();

  return (
    <FlexBox fullWidth jcEnd marginBottom={1} aiCenter gap={10} column={smDown}>
      <LocalizationProvider dateFormats={{ fullDate: "DD/MM/YYYY" }} adapterLocale='pt-BR' dateAdapter={AdapterDayjs}>
        <FlexBox gap={10}>
          <DatePicker
            label="Inicio"
            value={dateInit == '' ? null : dayjs(dateInit)}
            format='DD/MM/YYYY'
            onChange={(dateInit) => setSearchParams(
              {
                dateInit: dateInit?.format('YYYY-MM-DD') ?? '',
                dateEnd: dateEnd
              },
              {
                replace: true
              }
            )}
          />

          <DatePicker
            label="Fim"
            value={dateEnd == '' ? null : dayjs(dateEnd)}
            format='DD/MM/YYYY'
            onChange={(dateEnd) => setSearchParams(
              {
                dateInit: dateInit,
                dateEnd: dateEnd?.format('YYYY-MM-DD') ?? ''
              },
              {
                replace: true
              }
            )}
          />

        </FlexBox>
      </LocalizationProvider>

      <FlexBox width={150} jcCenter aiCenter>
        <Button
          startIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <Icon>search</Icon>}
          variant='contained'
          onClick={handleReportRequest}
          disabled={isLoading}
        >
          Buscar
        </Button>
      </FlexBox>

    </FlexBox>
  )
}
