import { IResponseAPI } from "../../../models";
import { IColorProduct } from "../../../models/ColorProduct";
import { Api } from "../axios-config";


const getAll = async (): Promise<IResponseAPI<IResponseAPI<IColorProduct[]>>> => {

  const { data } = await Api.get(`/otica/ColorProduct?pageSize=50`).catch(data => {
    return data.response;
  })
  return data;
};


export const ColorProductService = {
  getAll,
};