import { blueGrey, green, orange, red, blue, purple } from "@mui/material/colors";

interface StatusBar {
  label: string;
  color: string; 
  bgColor: string;
}

export const statusBarPayment = (value: number | undefined): StatusBar => {
  switch (value) {
    case 0:
      return { label: "Confirmado", bgColor: green[100], color: green[500] };
    case 1:
      return { label: "Estornado", bgColor: purple[100], color: purple[500] };
    case 2:
      return { label: "Atrasado", bgColor: orange[100], color: orange[500] };
    case 3:
      return { label: "Cancelado", bgColor: red[100], color: red[500] };
    case 4:
      return { label: "Pendente", bgColor: blue[100], color: blue[500] };
    default:
      return { label: "----", bgColor: blueGrey[100], color: blueGrey[500] };
  }
};
