
import React, { ReactNode, useContext, createContext, useState, Dispatch, SetStateAction } from "react";
import dayjs, { Dayjs } from "dayjs";

import { IRoleLeveEnum } from "../../models/enums/RoleLeveEnum";
import { IReport, ISituation } from "../../models/Report";
import { useAuthContext } from "../AuthContext";
import { IRole } from "../../models";


interface IDashboardContextData {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;

  role: IRole | undefined;
  page: number;
  report: IReport | undefined;
  valueDateFilter: number;
  situations: ISituation[] | undefined;
  dateRange: dayjs.Dayjs[];
  reload: number;
  statusFilter: number[];
  setPage: Dispatch<SetStateAction<number>>;
  setReport: Dispatch<SetStateAction<IReport | undefined>>;
  setValueDateFilter: Dispatch<SetStateAction<number>>;
  setSituations: Dispatch<SetStateAction<ISituation[] | undefined>>;
  setDateRange: Dispatch<SetStateAction<dayjs.Dayjs[]>>;
  serReload: Dispatch<SetStateAction<number>>;
  setStatusFilter: Dispatch<SetStateAction<number[]>>;
  status: number[];
  setStatus: Dispatch<SetStateAction<number[]>>;
};

export const DashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const { roles } = useAuthContext();
  const role = roles.find(x => x.isSelected);

  const [isLoading, setIsLoading] = useState(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  const [valueDateFilter, setValueDateFilter] = useState(role?.level == IRoleLeveEnum.Administrador ? 5 : 1);
  const [dateRange, setDateRange] = React.useState<Dayjs[]>([dayjs(new Date()), dayjs(new Date())]);
  const [statusFilter, setStatusFilter] = useState<number[]>([]);
  const [situations, setSituations] = useState<ISituation[]>();
  const [report, setReport] = useState<IReport>();
  const [reload, serReload] = useState(0);
  const [page, setPage] = useState(1);

  const [status, setStatus] = React.useState(statusFilter); 
  // Esse State, representa os CHECKBOX's do filtro por status [0, 1, 2, 3, 4]


  return (
    <DashboardContext.Provider value={{
      isLoading,
      stopLoading,
      startLoading,
      role,
      page,
      report,
      valueDateFilter,
      situations,
      dateRange,
      reload,
      statusFilter,
      setPage,
      setReport,
      setValueDateFilter,
      setSituations,
      setDateRange,
      serReload,
      setStatusFilter,
      status,
      setStatus,
    }}>
      {children}
    </DashboardContext.Provider>
  );
};

const DashboardContext = createContext({} as IDashboardContextData);

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

