import { Box, Icon, LinearProgress, Pagination, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { Env, Environment } from "../../../shared/environment";
import { IClient } from "../../../shared/models/Clients/client";
import PhoneNumberFormat from "../../../shared/helps/PhoneNumberFormat";
import { Link, SetURLSearchParams, useNavigate } from "react-router-dom";
import { CardBox } from "../../../shared/components/layouts/CardBox";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FlexBox from "../../../shared/components/layouts/FlexBox";

interface SectionCardTableMobileProps {
  rows: IClient[] | undefined;
  isLoading: boolean;
  totalCount: number;
  setSearchParams: SetURLSearchParams;
  busca: string;
  status: string;
  pagina: number;
  dateInit: string;
  dateEnd: string;
}

export const SectionCardTableMobile: React.FC<SectionCardTableMobileProps> = ({
  rows, isLoading, totalCount, setSearchParams, busca, pagina, status, dateInit, dateEnd }) => {

  const navigate = useNavigate();

  return (<>
    <TableContainer component={FlexBox} column sx={{ width: 'auto', overflow: 'auto' }}>
      <Table>
        <TableBody>
          {rows?.map(row => (
            <TableRow key={row.id} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d3d' }, transition: '.3s' }}
            >
              <FlexBox marginTop={2}>
                <CardBox key={row.id}
                  gap={5}
                  paddingX={2}
                  paddingY={1}
                  onClick={() => navigate(`/clientes/detalhe/${row.id}`)}
                >
                  <Box
                    width={'65%'}
                    sx={Env.FLEX_COLUMN}
                    gap={1}
                  >
                    <Box
                      paddingX={2}
                      paddingY={1}
                      width={'100%'}
                    >
                      <Box display={'flex'} >
                        <Icon color="primary" sx={{ fontSize: 18, marginRight: 1 }}>people</Icon>
                        <Typography fontSize={14} fontWeight={600}>
                          {row.name}
                        </Typography>
                      </Box>
                      <Typography fontSize={14} fontWeight={500}>
                        {row.contacts.find(x => x.isMain == true)?.type == 0 || row.contacts.find(x => x.isMain == true)?.type == 1 ? PhoneNumberFormat(row.contacts.find(x => x.isMain == true)?.value as string) : row.contacts.find(x => x.isMain == true)?.value}
                      </Typography>
                      {row.branch?.name &&
                        <Typography fontSize={14} fontWeight={500}>
                          {row.branch.name}
                        </Typography>}
                    </Box>
                  </Box>

                </CardBox>
                <Box
                  flex={1}
                  //border={'1px solid red'} 
                  sx={Env.FLEX_COLUMN}
                  justifyContent={'center'}
                  gap={1}
                  marginLeft={-10}
                >
                  {row.contacts.find(x => x.isMain == true)?.type == 1 &&
                    <Link target="_blank" to={`https://api.whatsapp.com/send/?phone=55${row.contacts.find(x => x.isMain == true)?.value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")}&text&type=phone_number&app_absent=1`}><WhatsAppIcon fontSize='large' color='success' /></Link>
                  }
                </Box>
              </FlexBox>
            </TableRow>
          ))}
        </TableBody>
        {totalCount == 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
      </Table>
      <FlexBox marginTop={2}>
        {isLoading && (
          <LinearProgress variant='indeterminate' />
        )}
        {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString(), status: status, dateInit: dateInit, dateEnd: dateEnd }, { replace: true })}
          />
        )}
      </FlexBox>
    </TableContainer>
  </>
  )
}
