import { Box, Card, Icon, IconButton, Paper, TableContainer, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { useDrawerContext } from "../contexts";
import { CardBox } from "../components/layouts/CardBox";
import FlexBox from "../components/layouts/FlexBox";
import { ButtonToBack } from "../components";

interface ILayoutBaseDePaginaProps {
    children: ReactNode;
    titulo: string;
    barraDeFerramentas?: ReactNode;
    buttonToBack?: boolean;
}

export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, titulo, barraDeFerramentas, buttonToBack }) => {
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const theme = useTheme();

    const { toggleDrawerOpen } = useDrawerContext();
    const { } = useDrawerContext();

    const handleDrawerContext = () => {
        toggleDrawerOpen();
    };

    return (
        <TableContainer sx={{ borderRadius: 8, paddingX: smDown ? 0 : 2, backgroundColor: "#F4F7FE" }}>
            <FlexBox jcBetween>
                <Box display={'flex'} gap={1} alignItems={'center'} boxSizing={'border-box'} height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
                    maxWidth={'max-content'}
                    borderRadius={3}
                >
                    {mdDown && (
                        <IconButton onClick={handleDrawerContext}>
                            <Icon>menu</Icon>
                        </IconButton>
                    )}
                    <Typography
                        variant={smDown ? 'subtitle1' : mdDown ? 'h5' : 'h5'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        fontFamily='Montserrat, sans-serif'
                        fontWeight={700}
                    >
                        {titulo}
                    </Typography>
                </Box>

                {buttonToBack &&
                    <ButtonToBack />
                }

            </FlexBox>

            {barraDeFerramentas && (
                <Box>
                    {barraDeFerramentas}
                </Box>
            )}
            
            <Box paddingTop={1} flex={1} height='min-content'>
                {children}
            </Box>

        </TableContainer>
    );
};