import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

import { IReportUser } from '../../../shared/models/Report';
import { ReportService } from '../../../shared/services/api/report/ReportService';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { useListagemDeRelatoriosContext } from '../../../shared/contexts/pages/ListagemDeRelatoriosContext';



export const VendasPage: React.FC = () => {
  const { setTitle } = useListagemDeRelatoriosContext();

  const [reports, setReports] = useState<IReportUser[]>([]);

  useEffect(() => {
    getReports();
    setTitle("Ranking de vendedores")
  }, []);


  function getReports() {
    ReportService.getUsers('', '').then(
      result => {
        if (result.success) {
          setReports(result.data);
        }
      }
    )
  };


  return (
    <FlexBox fullWidth>
      {reports.length > 0 ?
        <LineChart
          height={400}
          series={reports.map((report) => {
            return {
              data: report.sales.map(x => x.value) as number[],
              label: report.name as string
            }
          })}
          xAxis={[{ scaleType: 'point', data: reports.at(0)?.sales.map(x => { return dayjs(x.date).format('DD') }) }]}
        /> :
        <LineChart
          height={400}
          series={[{
            data: [0],
            label: ''
          }]
          }
          xAxis={[{ scaleType: 'point', data: [''] }]}
        />}
    </FlexBox>
  )
}
