import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography, Button, useMediaQuery, Theme } from '@mui/material';
import React from 'react';
import { ISubscription } from '../../../../shared/models/Subscription';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale'
import { IStatusPaymentEnum } from '../../../../shared/models/enums/StatusPaymentEnum';
import { CardBox } from '../../../../shared/components/layouts/CardBox';
import CurrencyFormat from '../../../../shared/helps/CurrencyFormat';

interface SubscriptionTableProps {
  subscription: ISubscription,
};

export const SubscriptionTable = ({ subscription }: SubscriptionTableProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  dayjs.extend(updateLocale)

  dayjs.updateLocale('en', {
    months: [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
      "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
  })

  return (
    <TableContainer component={CardBox} sx={{ width: 'auto', overflow: 'auto', mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>{subscription.branch.name}</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Valor</TableCell>
            {!smDown && <TableCell align='center'>Ação</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {subscription.payments?.filter(x => x.status != 2 && x.status != 3).map(row => (
            <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
              component={Box}
              onClick={() => { row.status == 3 || row.status == 2 ? console.log() : window.open(row.invoiceURL,'_blank', 'rel=noopener noreferrer')}}
            >
              <TableCell align='center'>
                <Typography>
                  {dayjs(row.dueDate).format('DD/MM/YYYY')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography color={row.status == 3 || row.status == 2 ? 'green' : row.status == 5 || row.status == 6 ? 'red' : 'orange'}>
                  {IStatusPaymentEnum[row.status]}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>
                  {CurrencyFormat(row.value)}
                </Typography>
              </TableCell>
              {!smDown && <TableCell align='center'>
                <Button variant='contained' size='small' color='success'>
                  Pagar
                </Button>
              </TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};