import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { MobileStepper, Button, TextField, MenuItem, useMediaQuery, Theme } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { InputFiles } from '../../../shared/components/inputs/input-file/InputFiles';
import { ICategoryProduct } from '../../../shared/models/CategoryProduct';
import { IMaterialProduct } from '../../../shared/models/MaterialProduct';
import imgSemFoto from '../../../shared/assets/images/sem-imagem.jpg';
import { IModelProduct } from '../../../shared/models/ModelProduct';
import { IMarkProduct } from '../../../shared/models/MarkProduct';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { InputImagemSection } from './NewProduct-InputData-InputImagem';


interface IInputDataSectionProps {
  setMaterialId: Dispatch<SetStateAction<number | undefined>>;
  setModelId: Dispatch<SetStateAction<number | undefined>>;
  setMarkId: Dispatch<SetStateAction<number | undefined>>;
  setErrCategory: Dispatch<SetStateAction<boolean>>;
  setCategoryId: Dispatch<SetStateAction<number>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setErrName: Dispatch<SetStateAction<boolean>>;
  setImages: Dispatch<SetStateAction<string[]>>;
  setGender: Dispatch<SetStateAction<number>>;
  setName: Dispatch<SetStateAction<string>>;
  genders: { id: number; name: string; }[];
  categories: ICategoryProduct[];
  materialId: number | undefined;
  materials: IMaterialProduct[];
  modelId: number | undefined;
  markId: number | undefined;
  models: IModelProduct[];
  brands: IMarkProduct[];
  handleNext: () => void;
  handleBack: () => void;
  errCategory: boolean;
  activeStep: number;
  categoryId: number;
  isLoading: boolean;
  images: string[];
  errName: boolean;
  gender: number;
  name: string;
}

export const InputDataSection: React.FC<IInputDataSectionProps> = (props) => {
  const {
    images, activeStep, isLoading, handleNext, handleBack, setIsLoading, errCategory, setErrCategory,
    brands, categories, categoryId, gender, genders, markId, materialId, setErrName,
    materials, modelId, models, setCategoryId, setGender, setMarkId,
    setImages, setName, setModelId, setMaterialId, name, errName, 
  } = props;

  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <FlexBox gap={10} column width={lgDown ? "100%" : "50%"}>

      <TextField
        size="small"
        type="text"
        name="name"
        sx={{ width: '100%' }}
        label="Nome"
        value={name}
        onChange={e => {setErrName(false); setName(e.target.value)}}
        helperText={errName && "O Campo Nome é Obrigatório"}
        error={errName}
        required
      />

      <TextField
        select
        size="small"
        name="category"
        sx={{ width: '100%' }}
        label="Categoria"
        value={categoryId}
        onChange={e => {setErrCategory(false); setCategoryId(parseInt(e.target.value))}}
        required
        error={errCategory}
        helperText={errCategory && "O Campo Categoria é Obrigatório"}
      >
        {categories.filter(x => x.isActive).map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>



      <FlexBox fullWidth row gap={10}>
        <FlexBox>
          <InputImagemSection
            setIsLoading={setIsLoading}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            setImages={setImages}
            isLoading={isLoading}
            images={images}
          />
        </FlexBox>

        <FlexBox fullWidth column gap={10}>
          {brands.length > 0 ?
            <TextField
              select
              sx={{ width: '100%' }}
              size="small"
              name="mark"
              label="Marca"
              value={markId}
              onChange={e => setMarkId(parseInt(e.target.value))}
            >
              {brands.filter(x => x.isActive).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> :
            <TextField
              sx={{ width: '100%' }}
              size="small"
              label="Marca"
              disabled
            />
          }
          {models.length > 0 ?
            <TextField
              select
              size="small"
              name="model"
              sx={{ width: '100%' }}
              label="Modelo"
              value={modelId}
              onChange={e => setModelId(parseInt(e.target.value))}
            >
              {models.filter(x => x.isActive).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> :
            <TextField
              sx={{ width: '100%' }}
              size="small"
              label="Modelo"
              disabled
            />
          }
          {materials.length > 0 ?
            <TextField
              select
              size="small"
              name="material"
              sx={{ width: '100%' }}
              label="Material"
              value={materialId}
              onChange={e => setMaterialId(parseInt(e.target.value))}
            >
              {materials.filter(x => x.isActive).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            :
            <TextField
              sx={{ width: '100%' }}
              size="small"
              label="Material"
              disabled
            />
          }
          <TextField
            select
            size="small"
            name="gender"
            sx={{ width: '100%' }}
            label="Gênero"
            value={gender}
            onChange={e => setGender(parseInt(e.target.value))}
          >
            {genders.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FlexBox>

      </FlexBox>

    </FlexBox>
  )
}
