import { Icon, IconButton, LinearProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { Environment } from "../../../shared/environment";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { CardBox } from "../../../shared/components/layouts/CardBox";
import CollapsibleTableProduct from "./CollapseTableProduct";
import { useProductsContext } from "../../../shared/contexts/pages/ProductsContext";
import GUID from "../../../shared/helps/Guid";
import React from "react";
import ImgNoProduct from "../../../shared/assets/images/sem-imagem.jpg";
import { IProduct } from "../../../shared/models/Product";
import { useNavigate } from "react-router-dom";


export const TableProducts = () => {
  const {
    busca,
    pagina,
    setSearchParams,
    products,
    isLoading,
    totalCount,
    viewStockSection,
    setViewStockSection,
    setViewProductSection,
    viewProductSection,
  } = useProductsContext();

  const navigate = useNavigate();

  function handleClick(productSeted: IProduct) {
    setViewStockSection(productSeted.stockProducts);
    setViewProductSection(productSeted);
  };

  function handleEditProduct (id: number) {
    navigate(`/produtos?edit=${id}`)
  }

  return (
    <CardBox>
      <TableContainer component={CardBox} column sx={{ width: "auto" }}>
        <Table size="small">

          <TableHead>
            <TableRow key={GUID()}>
              <TableCell align="center">
                <Icon>
                  sell
                </Icon>
              </TableCell>

              <TableCell>
                <Typography fontWeight={500}>
                  Nome
                </Typography>
              </TableCell>

              <TableCell width={70}>
                <Typography fontWeight={500}>
                  Categoria
                </Typography>
              </TableCell>

              <TableCell align="center" width={50}>
                <Typography fontWeight={500}>
                  Ações
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>


          <TableBody>
            {products?.map((productRow, index) => (

              <TableRow key={GUID()}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d3d' }, transition: '.3s' }}
              >
                <TableCell align="center">
                  {productRow?.imageProducts ? (
                    <img
                      src={productRow.imageProducts[0]?.imageUrl ?? ImgNoProduct}
                      style={{ width: '50px' }}
                    />
                  ) : (
                    <img
                      src={ImgNoProduct}
                      style={{ width: '50px' }}
                    />
                  )}
                </TableCell>
                <TableCell onClick={() => handleClick(productRow)}><Typography fontWeight={400} >{productRow?.name}</Typography></TableCell>
                <TableCell onClick={() => handleClick(productRow)}><Typography fontWeight={400} >{productRow?.categoryProduct?.name}</Typography></TableCell>

                <TableCell align="center" >
                  <IconButton
                    size='small'
                    onClick={() => handleEditProduct(productRow.id)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>


          {totalCount == 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}


        </Table>

        <FlexBox padding={2}>

          {isLoading && (
            <TableRow>
              <LinearProgress variant='indeterminate' />
            </TableRow>
          )}

          {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
            <Pagination
              page={pagina}
              count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
              onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
            />
          )}

        </FlexBox>
      </TableContainer>
    </CardBox>
  )
}
