import { Dispatch, FormEvent, SetStateAction, useState, useEffect } from "react";
import { BaseModal } from "../../../shared/components/modals/BaseModal";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { Button, MenuItem, Switch, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { EstoquesService } from "../../../shared/services/api/estoques/EstoquesService";
import { IStockProduct } from "../../../shared/models/StockProduct";
import { useProductsContext } from "../../../shared/contexts/pages/ProductsContext";
import { ButtonStatusUpdate } from "../../../shared/components/buttons/ButtonStatusUpdate";

interface ModalEditarStockProdutoProps {
  isOpen: boolean;
  setIsOpenEdit: Dispatch<SetStateAction<boolean>>;
  stock: IStockProduct | undefined;

}

export const ModalEditStockProduct: React.FC<ModalEditarStockProdutoProps> = ({ isOpen, setIsOpenEdit, stock }) => {
  const { setProducts } = useProductsContext();
  function handleClose() {
    setIsOpenEdit(false);
  };
  const { colors } = useProductsContext()

  const [isLoading, setIsLoading] = useState(false);

  const [cor, setCor] = useState(0);
  const [notStock, setNotStock] = useState(stock?.notStock);
  const [cost, setCost] = useState(stock?.cost);
  const [value, setValue] = useState(stock?.value);
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {

    if (stock != undefined) {
      setNotStock(stock.notStock);
      setCor(stock.colorProduct?.id ?? 0);
      setCost(stock.cost);
      setValue(stock.value);
      setIsActive(stock.isActive as boolean);
    };

  }, [stock]);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const updatedStockProduto = {
      id: stock?.id,
      colorProductId: cor == 0 ? undefined : cor,
      notStock: notStock as boolean,
      cost: cost as number,
      value: value as number,
      isActive: isActive
    };

    setIsLoading(true);
    EstoquesService.updateStockProduct(updatedStockProduto).then(
      result => {
        setIsLoading(false);
        if (result.success && result.data) {
          alert("Estoque atualizado com sucesso!");
          setProducts(prev => {
            var response = prev.filter(x => x.id != result.data.id)
            response.push(result.data);
            return response
          })
          handleClose();
        } else {
          alert(result.errors + result.message);
        }
      }
    );
  };
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotStock(event.target.checked);
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={handleClose}
      handleSave={handleSubmit}
      viewButtons
      title="Editar estoque de produto"
    >
      <FlexBox
        minHeight="100px"
        column gap={10} marginTop={2}
      >
        {colors.length > 0 ?
          <TextField
            select
            size="small"
            name="color"
            label="Cor"
            value={cor}
            onChange={e => setCor(parseInt(e.target.value))}
          >
            {colors.filter(x => x.isActive).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField> :
          <TextField
            size="small"
            label="Cor"
            disabled
          />
        }
        <TextField
          type="number"
          size="small"
          name="cost"
          label="Custo"
          value={cost}
          onChange={e => setCost(parseInt(e.target.value))}
          required
        />
        <TextField
          type="number"
          size="small"
          name="value"
          label="Valor de Venda"
          value={value}
          onChange={e => setValue(parseInt(e.target.value))}
          required
        />
        <FlexBox aiCenter jcBetween>
          <Typography>Venda sem estoque: </Typography>
          <Switch
            size="small"
            checked={notStock}
            onChange={handleCheck}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </FlexBox>
        <FlexBox aiCenter jcEnd>
          <ButtonStatusUpdate status={isActive} setStatus={setIsActive} />
        </FlexBox>
      </FlexBox>
    </BaseModal>
  );
}
