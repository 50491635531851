import React, { useState } from 'react';
import {
  Box, Divider, Icon, List, ListItemButton, ListItemIcon, ListItemText,
  useMediaQuery, useTheme
} from '@mui/material';

import { useDrawerContext, useAuthContext } from '../../../../contexts';
// import Logo from '../../../../assets/images/OticaSystems_full_branco.png';
import Logo from '../../../../assets/images/OticaSystems_roxo.png';
import LogoWhite from '../../../../assets/images/OticaSystems_full_branco.png';
import { ListItemLink } from '../components/ListItemLink';
import { ModalFilialSelect } from '../components';
import FlexBox from '../../../layouts/FlexBox';
import { useNavigate } from 'react-router-dom';
import { Env } from '../../../../environment';


interface MenuLateralContentProps {
  pathAdmin: boolean;
}

export const MenuLateralContent: React.FC<MenuLateralContentProps> = ({ pathAdmin }) => {

  const theme = useTheme();
  const nav = useNavigate();
  const { logout, roles, user } = useAuthContext();
  const { toggleDrawerOpen, drawerOptions, drawerOptionsAdmin } = useDrawerContext();

  const [isOpen, setIsOpen] = useState(false);


  function handleNavigatePainel() {
    !pathAdmin && nav('/admin');
    pathAdmin && nav('/painel');
  };

  function handleOpenModal() {
    setIsOpen(true);
  };

  function handleLogoutDialog() {
    const userConfirmed = window.confirm("Você deseja SAIR ?")
    if (userConfirmed) {
      logout();
    };
  };


  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const role = roles?.filter(role => role.isSelected)[0];
  const branchName = roles && roles?.filter(role => role.isSelected == true)[0]?.branch?.name;

  return (
    <FlexBox width={theme.spacing(30)} column

      // marginTop={3} // Menu Lateral mais pra baixo

      height="100%"
      maxHeight="800px"
    // boxSizing={"border-box"}
    // sx={{ background: '#46178cef', borderRadius: 20, padding: 8 }}
    >
      <Box minHeight='50px' width="100%" display='flex' alignItems='center' justifyContent={'center'}>

        <img
          style={{ margin: '10px' }}
          width={150}
          src={mdDown ? LogoWhite : Logo}
        />

      </Box>

      <Divider />

      <Box flex={1}
        borderRadius={Env.BD_RADIUS}
        sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}
      >
        {
          pathAdmin && (
            <List component='nav'>
              {drawerOptionsAdmin.map((drawerOption) => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={toggleDrawerOpen}
                />
              ))}
            </List>
          )
        }
        {
          !pathAdmin && (
            <List component='nav' sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
              {drawerOptions.filter(x => role?.level >= x.level).map((drawerOption: any) => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={toggleDrawerOpen}
                />))}
            </List>
          )
        }
      </Box>
      <Box>
        <List component='nav' sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          {user.admin &&
            // <ListItemButton onClick={handleNavigatePainel}>
            //   <ListItemIcon>
            //     <Icon>app_registration</Icon>
            //   </ListItemIcon>
            //   <ListItemText primary={pathAdmin ? "Painel Empresa" : "Painel Admin"} />
            // </ListItemButton>
            <ListItemLink
              selectable={false}
              icon={"app_registration"}
              onClick={toggleDrawerOpen}
              to={pathAdmin ? "/painel" : "/admin"}
              label={pathAdmin ? "Painel Empresa" : "Painel Admin"}
            />
          }
          {
            (roles.length > 1 && !pathAdmin) && (
              <ListItemLink
                to={""}
                icon={"repeat_on"}
                label={branchName}
                selectable={false}
                onClick={handleOpenModal}
              />
            )
          }
          <ListItemLink
            to={""}
            icon={"logout"}
            label={"Sair"}
            selectable={false}
            onClick={handleLogoutDialog}
          />
        </List>
      </Box>

      {isOpen &&
        <ModalFilialSelect
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />}
    </FlexBox >
  );
};
