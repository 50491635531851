import { useState } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { SectionName } from "./sections/Section-Name";
import { SectionContact } from "./sections/Section-Contact";
import { SectionAddress } from "./sections/Section-Address";
import { useAuthContext } from "../../../../shared/contexts";
import { BaseModal } from "../../../../shared/components/modals/BaseModal";
import { ClientesService } from "../../../../shared/services/api/clientes/ClientesService";
import { IClient, IClientInsert } from "../../../../shared/models/Clients/client";
import { IContactInsert } from "../../../../shared/models/Clients/contact";
import { SectionDocuments } from "./sections/Section-Documents";
import { IDocument, IDocumentInsert } from "../../../../shared/models/Clients/document";
import { NewClientStorage } from "../../../../shared/services/storage/newClientStorage";
import dayjs, { Dayjs } from "dayjs";

interface ModalNovoCLiente2Props {
  setRows: React.Dispatch<React.SetStateAction<IClient[]>>;
  handleClose: () => void;
  open: boolean;
}

const options = [
  {
    name: "name",
    position: 0,
    active: true,
  }, {
    name: "contact",
    position: 1,
    active: true,
  },
  {
    name: "address",
    position: 2,
    active: true,
  }, {
    name: "document",
    position: 3,
    active: false,
  }
];

const cell: IContactInsert = {
  value: '',
  type: 0,
  isMain: false
};
const doc: IDocumentInsert = {
  value: '',
  type: 0
};

export const ModalNovoCLiente2: React.FC<ModalNovoCLiente2Props> = (props) => {
  const { handleClose, open, setRows } = props;

  const { roles } = useAuthContext();

  const branchId = roles != null && roles?.filter(role => role.isSelected == true)[0]?.branch?.id;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [page, setPage] = useState(0);
  const [optionsNew, setOptionNew] = useState<IOptionNewClient[]>(options);
  const [position, setPosition] = useState(0);
  const [errorName, setErrorName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [contacts, setContacts] = useState<IContactInsert[]>([cell]);
  const [documents, setDocuments] = useState<IDocument[]>([doc]);
  const [base64, setBase64] = useState<string>('');
  const [file, setFile] = useState<FileList | null>();
  const [dateBirth, setDateBirth] = useState<Dayjs>();


  function handleAdvance() {
    const item = optionsNew.find(x => x.position == position + 1 && x.active);
    if (item != undefined) {
      setPosition(item.position)
      setPage(item.position)
    } else {
      const item2 = optionsNew.find(x => x.position == position + 2 && x.active);
      if (item2 != undefined) {
        setPosition(item2.position)
        setPage(item2.position)
      } else {
        const item3 = optionsNew.find(x => x.position == position + 3 && x.active);
        if (item3 != undefined) {
          setPosition(item3.position)
          setPage(item3.position)
        }
      }
    }
  }

  function handleToBack() {
    const item = optionsNew.find(x => x.position == position - 1 && x.active);
    if (item != undefined) {
      setPosition(item.position)
      setPage(item.position)
    } else {
      const item2 = optionsNew.find(x => x.position == position - 2 && x.active);
      if (item2 != undefined) {
        setPosition(item2.position)
        setPage(item2.position)
      } else {
        const item3 = optionsNew.find(x => x.position == position - 3 && x.active);
        if (item3 != undefined) {
          setPosition(item3.position)
          setPage(item3.position)
        }
      }
    }
  };

  function handleCloseReset() {
    setContacts([cell])
    setDocuments([doc])
    const storage = new NewClientStorage();
    storage.removeContacts();
    storage.removeAddressNewUser();
    storage.removeDocuments();
    setErrorName('');
    setPage(0);
    handleClose();
  };

  function handleSubmit() {
    if (branchId) {
      if (name.length > 1) {
        const storage = new NewClientStorage();
        const request: IClientInsert = {
          name: name,
          imageUrl: base64,
          birthDate: dateBirth != undefined && dateBirth != null ? dayjs(dateBirth).format('YYYY-MM-DD') : null,
          branchId: branchId,
          contacts: contacts,
          address: storage.getAddressNewUser(),
          documents: documents
        }

        ClientesService.create(request).then(
          result => {
            if (result.success) {
              setRows(oldRow => [...oldRow, result.data])
              handleCloseReset()
            } else {
              alert(result.errors[0]);
            };
          }
        );

      } else {
        setErrorName("Compo obrigatório")
      }
    }
  };

  const handlerImage = (file: FileList | null) => {
    setFile(file)
    getImg(file)
  }

  const getImg = (file: FileList | null) => {
    if (file != null && file != undefined) {
      resizeImage(file[0], 600, 600).then(base64String => {
        setBase64(base64String);
      }).catch(error => {
        console.error(error);
      });
    }
  }

  function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target?.result as string;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        // Calcular nova largura e altura mantendo a proporção
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Ajustar o tamanho do canvas
        canvas.width = width;
        canvas.height = height;

        // Desenhar a imagem redimensionada no canvas
        ctx?.drawImage(img, 0, 0, width, height);

        // Converter o canvas para Base64
        const base64String = canvas.toDataURL('image/png').split(',')[1];
        resolve('data:image/png;base64,' + base64String);
      };

      reader.onerror = () => {
        reject(new Error("Erro ao ler o arquivo."));
      };

      reader.readAsDataURL(file);
    });
  }

  return (
    <BaseModal
      handleClose={handleCloseReset}
      open={open}
      title={'Adicionar Cliente'}
      minWidth={smDown ? 310 : 400}
    >
      {
        page == 0 && (
          <SectionName
            name={name}
            setName={setName}
            optionsNew={optionsNew}
            setOptionsNew={setOptionNew}
            position={position}
            handleClose={handleCloseReset}
            handleAdvance={handleAdvance}
            handleSubmit={handleSubmit}
            errorName={errorName}
            handlerImage={handlerImage}
            file={file}
            setDateBirth={setDateBirth}
            dateBirth={dateBirth}
            base64={base64}
          />
        )
      }

      {
        page == 1 && (
          <SectionContact
            position={position}
            optionsNew={optionsNew}
            handleToBack={handleToBack}
            handleAdvance={handleAdvance}
            contacts={contacts}
            setContacts={setContacts}
            handleSubmit={handleSubmit}
          />
        )
      }

      {
        page == 2 && (
          <SectionAddress
            position={position}
            optionsNew={optionsNew}
            handleToBack={handleToBack}
            handleAdvance={handleAdvance}
            handleSubmit={handleSubmit}
          />
        )
      }

      {
        page == 3 && (
          <SectionDocuments
            handleToBack={handleToBack}
            rowsDocuments={documents}
            setRowsDocuments={setDocuments}
            handleAdvance={handleAdvance}
            handleSubmit={handleSubmit}
          />
        )
      }



    </BaseModal>
  )
};

export interface IOptionNewClient {
  name: string;
  position: number;
  active: boolean;
}
