import React, { useEffect, useState } from 'react';
import { Button, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material';
import FlexBox from '../../layouts/FlexBox';
import { BaseModal } from '../../modals/BaseModal';
import { IPaymentSale, IPaymentSaleInsert } from '../../../models/PaymentSale';
import { IPaymentMethodTypeEnum } from '../../../models/enums/PaymentMethodTypeEnum';
import CurrencyFormat from '../../../helps/CurrencyFormat';
import dayjs from 'dayjs';
import { IconSelectTypeContent } from '../components/IconSelectTypeContent';
import { CrediarioPaymentSection } from './sections';
import { OthersPaymentsSection } from './sections/OthersPaymentsSection';

interface MeioDePagamentoProps {
  saleId: number;
  payments: IPaymentSaleInsert | undefined;
  setPayments: React.Dispatch<React.SetStateAction<IPaymentSaleInsert | undefined>>;
};

export const MeioDePagamento: React.FC<MeioDePagamentoProps> = ({ payments, setPayments, saleId }) => {

  const theme = useTheme();
  const [openModalPagamento, setOpenModalPagamento] = useState(false);
  const [paymentsList, setPaymentsList] = useState<IPaymentSale[]>([]);
  useEffect(() => {
    if (payments != undefined)
      setPaymentsList(payments.payments)
  }, [payments, openModalPagamento])

  function handleClose() {
    setOpenModalPagamento(false);
  };

  function handleOpen() {
    setOpenModalPagamento(true);
  };

  function handler2() {

  };
  function handleExcluir(index: number) {
    // if (window.confirm('Deseja APAGAR?'))
    setPayments(oldRows => {
      if (oldRows != undefined) {
        var itens = oldRows.payments.filter((_, ind) => ind != index);
        oldRows.payments = itens;
        return oldRows
      }
      return oldRows
    })
    setPaymentsList(oldRows => {
      if (oldRows != undefined) {
        var itens = oldRows.filter((_, ind) => ind != index);
        oldRows = itens;
        return oldRows
      }
      return oldRows
    })
  };

  return (
    <FlexBox flex={1} borderRadius={4.7} padding={2} paddingTop={1} boxSizing={"border-box"}
      column
    >
      <FlexBox row jcBetween>
        <Typography fontSize={15}>Pagamento</Typography>
        <Button
          variant='outlined'
          size='small'
          onClick={handleOpen}
        >
          Adicionar
          <Icon>
            add
          </Icon>
        </Button>
      </FlexBox>


      <FlexBox jcStart aiStart gap={25} marginTop={1.5} //bgcolor={'#333'}
      >

        {
          paymentsList?.map((pagamento, index) => {
            return (
              <FlexBox column
                marginTop={-1}
                marginBottom={-3}
                key={index}
              >
                <FlexBox fullWidth row jcEnd>
                  <IconButton sx={{ width: '20px', height: '20px' }}
                    onClick={() => handleExcluir(index)}
                  >
                    <Icon sx={{ fontSize: '15px', fontWeight: 800 }}>remove_circle_outline</Icon>
                  </IconButton>
                </FlexBox>
                <Icon sx={{ fontSize: '45px', color: theme.palette.primary.main }}
                  onClick={handler2}
                >
                  {pagamento.type == IPaymentMethodTypeEnum.Dinheiro && 'payments'}
                  {pagamento.type == IPaymentMethodTypeEnum.Cartão && 'payment'}
                  {pagamento.type == IPaymentMethodTypeEnum.PIX && 'pix'}
                  {pagamento.type == IPaymentMethodTypeEnum.Crediário && 'receipt'}
                </Icon>

                {pagamento.totalValue > 0 &&
                  <Typography fontSize={13} fontWeight={600}>
                    R$ {CurrencyFormat(pagamento.totalValue)}
                  </Typography>}

                {pagamento.installments > 0 &&
                  <Typography fontSize={13} fontWeight={600}>
                    {pagamento.installments}X
                  </Typography>}

                {pagamento.dateDue != '' &&
                  <Typography fontSize={13} fontWeight={600}>
                    {dayjs(pagamento.dateDue).format('DD/MM/YYYY')}
                  </Typography>}
              </FlexBox>
            )
          })
        }

      </FlexBox>

      <ModalAddPagamento
        saleId={saleId}
        handleClose={handleClose}
        setPagamentoSelect={setPayments}
        openModalPagamento={openModalPagamento}
      />
    </FlexBox>
  )
};







interface ModalAddPagamentoProps {
  saleId: number,
  setPagamentoSelect: React.Dispatch<React.SetStateAction<IPaymentSaleInsert | undefined>>,
  openModalPagamento: boolean,
  handleClose: () => void,
};


const ModalAddPagamento: React.FC<ModalAddPagamentoProps> = ({ openModalPagamento, handleClose, setPagamentoSelect, saleId }) => {

  const [selected, setSelected] = useState(0);
  const [errorValue, setErrorValue] = useState(false);
  const [value, setValue] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [dateDue, setDateDue] = useState<string>(dayjs(new Date()).format('YYYY-MM-DD'));

  const isSelected = (selected < 0 || selected > 4);

  function handleAoClicarEmSalvar() {
    const payment = {
      type: selected,
      dateDue: dateDue,
      installments: quantity == 0 ? 1 : quantity,
      totalValue: value / 100,
    };

    setPagamentoSelect((item) => {
      if (item == undefined) {
        return {
          saleId: saleId,
          payments: [payment]
        }
      } else if (item != undefined) {
        item.payments.push(payment)
      }
      return item
    })

    handleCloseAddPagamentoModal();
  };

  function handleCloseAddPagamentoModal() {
    setValue(0);
    setQuantity(0);
    handleClose();
  }

  const handleToggle = (select: number) => {
    setSelected((oldValue) => oldValue == select ? -1 : select);
    setErrorValue(false);
  };



  return (
    <BaseModal
      handleClose={handleCloseAddPagamentoModal}
      open={openModalPagamento}
      title={'Adicionar Modo de Pagamento'}
      minWidth={320}
    >


      <FlexBox jcCenter aiCenter gap={15} marginTop={4}>

        <IconSelectTypeContent
          typePaymentSelected={IPaymentMethodTypeEnum.Dinheiro}
          handleToggle={handleToggle}
          selected={selected}
          title='Dinheiro'
          icon='payments'
        />

        <IconSelectTypeContent
          typePaymentSelected={IPaymentMethodTypeEnum.PIX}
          handleToggle={handleToggle}
          selected={selected}
          title='Pix'
          icon='pix'
        />

        <IconSelectTypeContent
          typePaymentSelected={IPaymentMethodTypeEnum.Cartão}
          handleToggle={handleToggle}
          selected={selected}
          title='Cartão'
          icon='payment'
        />

        <IconSelectTypeContent
          typePaymentSelected={IPaymentMethodTypeEnum.Crediário}
          handleToggle={handleToggle}
          selected={selected}
          title='Crediário'
          icon='receipt'
        />

      </FlexBox>


      <FlexBox column gap={15} marginTop={4}>
        {isSelected &&
          <Typography textAlign={"center"} color={'textDisabled'} fontWeight={500}>
            Selecione um Meio de pagamento
          </Typography>
        }
        {!isSelected && ((selected === IPaymentMethodTypeEnum.Crediário || selected === IPaymentMethodTypeEnum.Cartão) ? (

          <CrediarioPaymentSection
            setErrorValue={setErrorValue}
            setQuantity={setQuantity}
            errorValue={errorValue}
            setDateDue={setDateDue}
            setValue={setValue}
            quantity={quantity}
            dateDue={dateDue}
            value={value}
          />
        ) : (
          <OthersPaymentsSection
            setErrorValue={setErrorValue}
            setDateDue={setDateDue}
            errorValue={errorValue}
            setValue={setValue}
            dateDue={dateDue}
            value={value}
          />
        ))}
      </FlexBox>


      <Stack
        marginTop={5}
        direction='row'
        justifyContent='space-between'
      >

        <Button onClick={handleClose}>Fechar</Button>
        <Button
          variant='contained'
          onClick={handleAoClicarEmSalvar}
          disabled={isSelected ? true : false}
        >
          Salvar
        </Button>
      </Stack>
    </BaseModal>
  )
}

