import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Icon, Typography, IconButton, LinearProgress, useMediaQuery, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Environment } from '../../../../shared/environment';
import { IBranch } from '../../../../shared/models/Branch';
import { BranchService } from '../../../../shared/services/api/branch/BranchService';
import { useNavigate } from 'react-router-dom';
import { CardBox } from '../../../../shared/components/layouts/CardBox';

export const BanchListTable = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [openEdit, setOpenEdit] = useState(false);
  const [rows, setRows] = useState<IBranch[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    
    BranchService.getBranchs()
      .then((result) => {
        if (result != undefined && result?.success && result?.data != undefined) {
          setRows(result.data);
          setTotalCount(result.data.length)
          setIsLoading(false)
        }
      });
  }, [openEdit]);


  const handleDetail = (id: number) => {
    navigate(`/admin/filial/${id}`)
  };


  return (
    <TableContainer component={CardBox} sx={{ width: 'auto', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Nome</TableCell>
            {!smDown ? <TableCell>Endereço</TableCell> : null}
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
              component={Box}
            >
              <TableCell>
                <Icon sx={{ fontSize: smDown ? 25 : 35 }}>location_city</Icon>
              </TableCell>
              <TableCell>
                <Typography>
                  {row.name}
                </Typography>
              </TableCell>
              {!smDown ?
                <TableCell>
                  <Typography>
                    {row.address ? row.address.city : "-"}
                  </Typography>
                </TableCell> : null}
              <TableCell>
                <IconButton size='small' onClick={() => handleDetail(row.id)}>
                  <Icon>edit</Icon>
                </IconButton>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
        {totalCount == 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
        {isLoading && (

          <LinearProgress variant='indeterminate' />
        )}
      </Table>
    </TableContainer>
  )
};