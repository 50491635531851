import { IReason } from "../../../Reason";
import { IReasonInsert, IReasonUpdate } from "../../../Dtos/cadastrosDtos/ReasonDto";
import { IReasonTypeEnum } from "../../../enums/ReasonTypeEnum";

export class Reason implements IReason {
  id: number;
  name: string;
  isActive: boolean;
  type: IReasonTypeEnum;

  constructor({ id = 0, name = "", isActive = true, type = 0 }: Partial<IReason> = {}) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.isActive = isActive;
  }
};


export class ReasonInsert implements IReasonInsert {
  name: string;
  type: IReasonTypeEnum;

  constructor({ name = "", type = 0 }: Partial<IReasonInsert> = {}) {
    this.name = name;
    this.type = type;
  }
};


export class ReasonUpdate implements IReasonUpdate {
  id: number;
  name?: string;
  isActive?: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<IReasonUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};