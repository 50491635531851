import FlexBox from "../../shared/components/layouts/FlexBox";
import { useAuthContext } from "../../shared/contexts";

export const NotRole = () => {
    const { user } = useAuthContext();

    return (
        <>
            {
                user.admin ?
                    <FlexBox fullWidth jcCenter aiCenter>
                        Adicione permissão em um ponto de venda no seu usuario!
                    </FlexBox>
                    :
                    <FlexBox fullWidth jcCenter aiCenter>
                        Solicite ao administrador do sistema permissão em um ponto de venda no seu usuario!
                    </FlexBox>
            }
        </>
    );
};