import { IResponseAmount } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IColorProduct } from "../../../models/ColorProduct";
import { IColorProductInsert, IColorProductUpdate } from "../../../models/Dtos/ColorProductDto";
import { ISale } from "../../../models/Sale";
import { Api } from "../axios-config";


const insert = async (colorProduct: IColorProductInsert): Promise<IResponseAPI<IColorProduct[]>> => {

    const { data } = await Api.post('/otica/ColorProduct', colorProduct).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (colorProduct: IColorProductUpdate): Promise<IResponseAPI<IColorProduct>> => {

    const { data } = await Api.put('/otica/ColorProduct', colorProduct).catch(data => {
        return data.response;
    });

    return data;
};

const deleteById = async (id: number): Promise<IResponseAPI<ISale>> => {

    const { data } = await Api.delete(`/otica/ColorProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (): Promise<IResponseAPI<IResponseAmount<IColorProduct[]>>> => {

    const { data } = await Api.get("/otica/ColorProduct?pageSize=50").catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IColorProduct>>> => {

    const { data } = await Api.get(`/otica/ColorProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const ColorProductService = {
    insert,
    update,
    getAll,
    getById,
    deleteById,
};