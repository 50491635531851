import { Typography, Stack, Paper } from '@mui/material';
import React from 'react'
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { IOrderService } from '../../../../shared/models/OrderServiceModel';

interface DescriptionSectionProps {
  order: IOrderService;
};

export const DescriptionSection: React.FC<DescriptionSectionProps> = (props) => {
  const { order } = props;

  return (
    <FlexBox column gap={2}>
      <Typography fontSize='13px' fontWeight={700}>
        {"Descrição: "}
      </Typography>

      <Stack width='100%'
        padding={1} maxHeight={100}
        overflow='auto'
      >
        <Typography>{order.description}</Typography>
      </Stack>
    </FlexBox>
  )
}