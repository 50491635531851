import React, { useEffect, useState } from "react";
import { Button, Stack, Table, TableBody, TableCell, TableHead, Theme, Typography, useMediaQuery } from "@mui/material"
import { BaseModal } from "../../../../../shared/components/modals/BaseModal";
import { IPrescription } from "../../../../../shared/models/Prescription";
import { CellSection } from "../../../../../shared/components/addOrderServiceModal/components/CellSection";


interface BaseModalProps {
  open: boolean;
  addMode?: boolean;
  viewMode?: boolean;
  handleClose: () => void;
  prescrition: IPrescription | undefined;
  setPrescrition?: (prescription: IPrescription | undefined) => void;
};

export const ModalAddPrescrition: React.FC<BaseModalProps> = (props) => {
  const { handleClose, open, prescrition, setPrescrition, addMode, viewMode } = props;

  const [farODSpherical, setFarODSpherical] = useState('');
  const [farOESpherical, setFarOESpherical] = useState('');
  const [farODCylindrical, setFarODCylindrical] = useState('');
  const [farOECylindrical, setFarOECylindrical] = useState('');
  const [farODAxis, setFarODAxis] = useState('');
  const [farOEAxis, setFarOEAxis] = useState('');
  const [farODHeight, setFarODHeight] = useState('');
  const [farOEHeight, setFarOEHeight] = useState('');
  const [farODDNP, setFarODDNP] = useState('');
  const [farOEDNP, setFarOEDNP] = useState('');
  const [nearODSpherical, setNearODSpherical] = useState('');
  const [nearOESpherical, setNearOESpherical] = useState('');
  const [nearODCylindrical, setNearODCylindrical] = useState('');
  const [nearOECylindrical, setNearOECylindrical] = useState('');
  const [nearODAxis, setNearODAxis] = useState('');
  const [nearOEAxis, setNearOEAxis] = useState('');
  const [nearODHeight, setNearODHeight] = useState('');
  const [nearOEHeight, setNearOEHeight] = useState('');
  const [nearODDNP, setNearODDNP] = useState('');
  const [nearOEDNP, setNearOEDNP] = useState('');
  const [additionSpherical, setAdditionSpherical] = useState('');

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (prescrition != undefined) {
      setFarODSpherical(prescrition.farODSpherical);
      setFarOESpherical(prescrition.farOESpherical);
      setFarODCylindrical(prescrition.farODCylindrical);
      setFarOECylindrical(prescrition.farOECylindrical);
      setFarODAxis(prescrition.farODAxis);
      setFarOEAxis(prescrition.farOEAxis);
      setFarODHeight(prescrition.farODHeight);
      setFarOEHeight(prescrition.farOEHeight);
      setFarODDNP(prescrition.farODDNP);
      setFarOEDNP(prescrition.farOEDNP);
      setNearODSpherical(prescrition.nearODSpherical);
      setNearOESpherical(prescrition.nearOESpherical);
      setNearODCylindrical(prescrition.nearODCylindrical);
      setNearOECylindrical(prescrition.nearOECylindrical);
      setNearODAxis(prescrition.nearODAxis);
      setNearOEAxis(prescrition.nearOEAxis);
      setNearODHeight(prescrition.nearODHeight);
      setNearOEHeight(prescrition.nearOEHeight);
      setNearODDNP(prescrition.nearODDNP);
      setNearOEDNP(prescrition.nearOEDNP);
      setAdditionSpherical(prescrition.additionSpherical);
    }
  }, [prescrition])

  function handleAoClicarEmSalvar() {
    const prescri = {
      id: 0,
      farODSpherical: farODSpherical,
      farOESpherical: farOESpherical,
      farODCylindrical: farODCylindrical,
      farOECylindrical: farOECylindrical,
      farODAxis: farODAxis,
      farOEAxis: farOEAxis,
      farODHeight: farODHeight,
      farOEHeight: farOEHeight,
      farODDNP: farODDNP,
      farOEDNP: farOEDNP,
      nearODSpherical: nearODSpherical,
      nearOESpherical: nearOESpherical,
      nearODCylindrical: nearODCylindrical,
      nearOECylindrical: nearOECylindrical,
      nearODAxis: nearODAxis,
      nearOEAxis: nearOEAxis,
      nearODHeight: nearODHeight,
      nearOEHeight: nearOEHeight,
      nearODDNP: nearODDNP,
      nearOEDNP: nearOEDNP,
      additionSpherical: additionSpherical,
    }
    setPrescrition && setPrescrition(prescri);
    handleClose();
  };

  const handleClear = () => {
    setPrescrition && setPrescrition(undefined);
    setFarODSpherical('');
    setFarOESpherical('');
    setFarODCylindrical('');
    setFarOECylindrical('');
    setFarODAxis('');
    setFarOEAxis('');
    setFarODHeight('');
    setFarOEHeight('');
    setFarODDNP('');
    setFarOEDNP('');
    setNearODSpherical('');
    setNearOESpherical('');
    setNearODCylindrical('');
    setNearOECylindrical('');
    setNearODAxis('');
    setNearOEAxis('');
    setNearODHeight('');
    setNearOEHeight('');
    setNearODDNP('');
    setNearOEDNP('');
    setAdditionSpherical('');
    handleClose();
  };

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={'Adicionar receita'}
      minWidth={350}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 800} marginTop={2}
        maxWidth={smDown ? 400 : 800}
      >
        <Table padding={smDown ? 'none' : 'normal'} >
          <TableHead>
            <TableCell padding="none" width={smDown ? 80 : 100} align="center"></TableCell>
            <TableCell padding="none" width={smDown ? 80 : 100} align="center"><Typography>{smDown ? 'ESF' : 'ESFÉRICO'}</Typography></TableCell>
            <TableCell padding="none" width={smDown ? 80 : 100} align="center"><Typography>{smDown ? 'CIL' : 'CILÍNDRICO'}</Typography></TableCell>
            <TableCell padding="none" width={smDown ? 80 : 100} align="center"><Typography>EIXO</Typography></TableCell>
            <TableCell padding="none" width={smDown ? 80 : 100} align="center"><Typography>{smDown ? 'ALT' : 'ALTURA'}</Typography></TableCell>
            <TableCell padding="none" width={smDown ? 80 : 100} align="center"><Typography>DNP</Typography></TableCell>
          </TableHead>
          <TableBody>
            <TableCell>
              <Typography>
                {smDown ? 'OD L.' : 'OD LONGE'}
              </Typography>
            </TableCell>
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farODSpherical}
              setCellValue={setFarODSpherical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farODCylindrical}
              setCellValue={setFarODCylindrical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farODAxis}
              setCellValue={setFarODAxis}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farODHeight}
              setCellValue={setFarODHeight}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farODDNP}
              setCellValue={setFarODDNP}
            />
          </TableBody>
          <TableBody>
            <TableCell>
              <Typography>
                {smDown ? 'OE L.' : 'OE LONGE'}
              </Typography>
            </TableCell>
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farOESpherical}
              setCellValue={setFarOESpherical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farOECylindrical}
              setCellValue={setFarOECylindrical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farOEAxis}
              setCellValue={setFarOEAxis}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farOEHeight}
              setCellValue={setFarOEHeight}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={farOEDNP}
              setCellValue={setFarOEDNP}
            />
          </TableBody>
          <TableBody>
            <TableCell>
              <Typography>
                {smDown ? 'OD P.' : 'OD PERTO'}
              </Typography>
            </TableCell>
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearODSpherical}
              setCellValue={setNearODSpherical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearODCylindrical}
              setCellValue={setNearODCylindrical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearODAxis}
              setCellValue={setNearODAxis}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearODHeight}
              setCellValue={setNearODHeight}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearODDNP}
              setCellValue={setNearODDNP}
            />
          </TableBody>
          <TableBody>
            <TableCell>
              <Typography>{smDown ? 'OE P.' : 'OE PERTO'}
              </Typography>
            </TableCell>

            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearOESpherical}
              setCellValue={setNearOESpherical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearOECylindrical}
              setCellValue={setNearOECylindrical}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearOEAxis}
              setCellValue={setNearOEAxis}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearOEHeight}
              setCellValue={setNearOEHeight}
            />
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={nearOEDNP}
              setCellValue={setNearOEDNP}
            />
          </TableBody>
          <TableBody>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center">
              <Typography>
                {smDown ? 'ADC' : 'ADIÇÃO'}
              </Typography>
            </TableCell>
            <CellSection
              addMode={addMode}
              viewMode={viewMode}
              cellValue={additionSpherical}
              setCellValue={setAdditionSpherical}
            />
          </TableBody>
        </Table>
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={1}
      >
        <Button onClick={handleClose}>Voltar</Button>
        {addMode && (
          <>
            <Button variant='outlined' onClick={handleClear}>Limpar e Votlar</Button>
            <Button variant='contained' onClick={handleAoClicarEmSalvar}>
              {prescrition ? 'Editar receita' : 'Adicionar receita'}
            </Button>
          </>
        )}
      </Stack>
    </BaseModal>
  )
};

