import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ITerm } from "../../../models/Term";
import { Api } from "../axios-config";

const get = async (): Promise<IResponseAPI<ITerm> | IResponseAPI<ITerm>> => {
  const { data } = await Api.get(`/otica/Term`).catch((data) => {
    return data.response;
  });
  return data;
};

export const TermService = {
  get
};