export enum IStatusPaymentEnum {
    'Novo',
    'Processando',
    'Confirmado',
    'Recebido',
    'Estornada',
    'Atrasado',
    'Reprovado',
    'Manual',
    'Pendente',
}