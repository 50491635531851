import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Icon, Typography, LinearProgress, useMediaQuery } from '@mui/material'
import React from 'react'
import { CardBox } from '../../../../shared/components/layouts/CardBox'
import { ShowColor } from '../../../../shared/components/layouts/ShowColor'
import { IReportProduct } from '../../../../shared/models/Report';
import FlexBox from '../../../../shared/components/layouts/FlexBox';


interface TableReportProductProps {
  rows: IReportProduct[] | undefined;
  smDown: boolean;
  isLoading: boolean;
};

export const TableReportProduct: React.FC<TableReportProductProps> = (props) => {
  const { rows, smDown, isLoading } = props;

  return (
    <TableContainer component={CardBox} column>
      <Table size='medium'>
        <TableHead>

          <TableRow>
            <TableCell align="left" width={100} sx={{ paddingLeft: 4 }}>
              <Icon fontSize='small'>
                sell
              </Icon>
            </TableCell>
            <TableCell align="left" width={350}>Nome</TableCell>
            <TableCell align="left">Cor</TableCell>
            <TableCell align="center">{smDown ? 'Qnt' : 'Quantidade'}</TableCell>
          </TableRow>

        </TableHead>


        <TableBody>
          {rows && rows.map((row, index) => (

            <TableRow key={row.name}>
              <TableCell align="left" sx={{ paddingLeft: 4 }}>
                <Typography fontSize='17px' fontWeight={600}>
                  {index + 1}º
                </Typography>
              </TableCell>

              <TableCell align="center">
                <FlexBox jcStart>
                  <Typography>
                    {row.name}
                  </Typography>
                </FlexBox>
              </TableCell>

              <TableCell align="center">
                <FlexBox fullWidth jcStart>
                  <ShowColor
                    colorCode={row.colorCode}
                    colorName={row.name}
                    isColor={row.name}
                  />
                </FlexBox>
              </TableCell>

              <TableCell align="center">
                <Typography fontWeight={500}>
                  {row.quantity}
                </Typography>
              </TableCell>
            </TableRow>

          ))}
        </TableBody>
      </Table>

      {isLoading &&
        <LinearProgress variant='indeterminate' />
      }

    </TableContainer>
  )
}
