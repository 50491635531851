import { Button, MenuItem, TextField, Theme, useMediaQuery } from "@mui/material";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import dayjs from "dayjs";

import { StockMovementService } from "../../../../shared/services/api/stockMovement/StockMovementService";
import { InputNumericState } from "../../../../shared/components/inputs/InputNumericState";
import { InputCurrency } from "../../../../shared/components/inputs/InputCurrency";
import { useStockContext } from "../../../../shared/contexts/pages/StockContext";
import { BaseModal } from "../../../../shared/components/modals/BaseModal";
import { IStockMovement } from "../../../../shared/models/StockMovement";
import FlexBox from "../../../../shared/components/layouts/FlexBox";
import { AutoCompleteProdutos } from "./AutoCompleteProdutos";
import { IProduct } from "../../../../shared/models/Product";
import { Environment } from "../../../../shared/environment";
import { IResponseAPI } from "../../../../shared/models";


interface ModalInsertStockProdutoProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setStock: Dispatch<SetStateAction<IStockMovement[] | undefined>>;
  type: number;
}

export const ModalAddEstoque: React.FC<ModalInsertStockProdutoProps> = (props) => {
  const { isOpen, setIsOpen, setStock, type } = props;

  const { reasons } = useStockContext();
  const [productSeelect, setProductSelect] = useState<IProduct>()
  const [stockProductId, setStockProductId] = useState<number>(0);
  const [reasonId, setReasonId] = useState<number | undefined>();
  const [quantity, setQuantity] = useState<number>(1);
  const [value, setValue] = useState<number>(0);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));


  function handleClose() {
    setProductSelect(undefined)
    setIsOpen(false);
  };

  function toReorganizeResponse(result: IResponseAPI<IStockMovement[]>) {
    setStock((prev) => {

      if (prev !== undefined) {

        if (prev.length >= Environment.LIMITE_DE_LINHAS)
          return [result.data[0], ...prev.slice(0, 9)]; // Remove o último item e adiciona o novo item no início
        else
          return [result.data[0], ...prev];

      } else {

        return [result.data[0]];
      }
    });

    handleClose();
  };


  function handleSubmit(e: FormEvent) {

    e.preventDefault();

    if (reasonId === undefined) {
      alert("Motivo é obrigatorio!")
    }

    if (productSeelect !== undefined && reasonId !== undefined) {

      const insertStockProduto = {
        productId: productSeelect.id,
        stockProductId: stockProductId,
        reasonId: reasonId,
        date: dayjs(new Date()).format("YYYY-MM-DD"),
        type: type,
        quantity: quantity,
        value: value / 100,
      };

      StockMovementService.create(insertStockProduto).then((result) => {
        if (result.success) {
          toReorganizeResponse(result);
          window.location.reload();

        } else {
          alert(result.errors[0]);
        }
      });
    }
  };


  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={handleClose}
      title={type === 0 ? "Inserir estoque" : "Retirar estoque"}
    >
      <FlexBox
        minHeight="100px"
        column gap={10}
      >
        <AutoCompleteProdutos
          setProductSelect={setProductSelect}
        />

        <TextField
          select
          size="small"
          name="color"
          label="Cor"
          required
          disabled={productSeelect != undefined ? false : true}
          value={stockProductId}
          onChange={e => setStockProductId(parseInt(e.target.value))}
        >
          {productSeelect?.stockProducts.filter(x => x.isActive).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.colorProduct?.name ?? 'Sem cor'}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          size="small"
          name="reason"
          label="Motivo"
          required
          value={reasonId}
          onChange={e => setReasonId(parseInt(e.target.value))}
        >
          {reasons.filter(x => x.isActive).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        {/* <AutoCompleteReasonAdd 
          setReasonSelect={setReasonId}
        /> */}

        <InputNumericState
          required
          name="quantity"
          label="Quantidade"
          value={quantity}
          onChange={e => setQuantity(parseInt(e.target.value))}
        />

        <InputCurrency
          required
          name="value"
          label="Valor"
          value={value}
          setValue={setValue}
        />

        <FlexBox row jcBetween marginTop={3} >
          <Button variant="outlined"
            size="small"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button variant="contained"
            type="submit"
            size="small"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </FlexBox>

      </FlexBox>
    </BaseModal>
  );
}
