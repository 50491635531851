import dayjs from "dayjs";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import { Button, Icon, useMediaQuery, Theme } from "@mui/material";
import FlexBox from "../../layouts/FlexBox";
import { useAuthContext } from "../../../contexts";
import { IOrderService } from "../../../models/OrderServiceModel";
import { IContactLeveEnum } from "../../../models/enums/ContactTypeEnum";
import PhoneNumberFormat from "../../../helps/PhoneNumberFormat";
import { ISale } from "../../../models/Sale";

interface detalheOSModalProps {
  sale: ISale;
  order: IOrderService;
};

export const DetalheOSModal: React.FC<detalheOSModalProps> = (props) => {
  const { sale, order } = props;
  const [display, setDisplay] = useState('none');
  const { roles } = useAuthContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handlePrint = async () => {
    if (mdDown) {
      handlePrintMobile()
    } else {
      setDisplay('flex')
      reactToPrintFn()
      setDisplay('none')
    }
  }

  const handlePrintMobile = () => {
    const content = contentRef.current;
    if (content) {
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Imprimir</title>
        </head>
        <body>
          ${content.innerHTML}
        </body>
        </html>
      `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();

        setTimeout(() => {
          printWindow.close();
        }, 500);
      } else {
        alert("Não foi possível abrir a janela de impressão.");
      }
    }
  };

  const prescription = order.prescription;

  const role = roles != null ? roles?.filter(role => role.isSelected == true)[0] : '';
  const imgBranch = (role != '') ? role?.branch?.logoUrl : '';
  const address = (role != '' && role?.branch?.address != undefined) ? (role?.branch?.address?.street ?? '') + ', ' + (role?.branch?.address?.number ?? '') : '';
  const contacts = (role != '') ? role?.branch?.contacts.filter(x => x.type == IContactLeveEnum.Telefone || x.type == IContactLeveEnum.WhatsApp)[0]?.value : '';
  return (
    <>
      <div style={{ overflow: "auto", display: display }} >
        <div ref={contentRef}>
          <div style={wrapperStyle}>
            <FlexBox
              column
              jcCenter
              aiCenter
            >
              <div style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img width='60%' src={imgBranch} alt="logo da marca" />
                <p>TSO <b>Nº: {order.id}</b></p>
              </div>
              <div style={{ margin: '5px', padding: '5px', border: 'solid 1px', borderRadius: '5px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p>Nome:  {sale.client.name}</p>
                  <p>End.:  {address}</p>
                </div>
                <div>
                  <p>Data:  {dayjs(sale.dateCreate).format('DD / MM / YYYY')}</p>
                  <p>Fone:  {contacts && PhoneNumberFormat(contacts)}</p>
                </div>
              </div>
              <h4>Receita</h4>
              <div style={{ margin: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <table style={tableStyle}>
                  <tr>
                    <th style={cellStyle}></th>
                    <th style={cellStyle}>Esférico</th>
                    <th style={cellStyle}>Cilíndrico</th>
                    <th style={cellStyle}>Eixo</th>
                    <th style={cellStyle}>Altura</th>
                    <th style={cellStyle}>DNP</th>
                  </tr>
                  <tr>
                    <td style={cellStyleLeft}><b>OD Longe</b></td>
                    <td style={cellStyle}>{prescription?.farODSpherical || ''}</td>
                    <td style={cellStyle}>{prescription?.farODCylindrical || ''}</td>
                    <td style={cellStyle}>{prescription?.farODAxis || ''}</td>
                    <td style={cellStyle}>{prescription?.farODHeight || ''}</td>
                    <td style={cellStyle}>{prescription?.farODDNP || ''}</td>
                  </tr>
                  <tr>
                    <td style={cellStyleLeft}><b>OE Longe</b></td>
                    <td style={cellStyle}>{prescription?.farOESpherical || ''}</td>
                    <td style={cellStyle}>{prescription?.farOECylindrical || ''}</td>
                    <td style={cellStyle}>{prescription?.farOEAxis || ''}</td>
                    <td style={cellStyle}>{prescription?.farOEHeight || ''}</td>
                    <td style={cellStyle}>{prescription?.farOEDNP || ''}</td>
                  </tr>
                  <tr>
                    <td style={cellStyleLeft}><b>OD Perto</b></td>
                    <td style={cellStyle}>{prescription?.nearODSpherical || ''}</td>
                    <td style={cellStyle}>{prescription?.nearODCylindrical || ''}</td>
                    <td style={cellStyle}>{prescription?.nearODAxis || ''}</td>
                    <td style={cellStyle}>{prescription?.nearODHeight || ''}</td>
                    <td style={cellStyle}>{prescription?.nearODDNP || ''}</td>
                  </tr>
                  <tr>
                    <td style={cellStyleLeft}><b>OE Perto</b></td>
                    <td style={cellStyle}>{prescription?.nearOESpherical || ''}</td>
                    <td style={cellStyle}>{prescription?.nearOECylindrical || ''}</td>
                    <td style={cellStyle}>{prescription?.nearOEAxis || ''}</td>
                    <td style={cellStyle}>{prescription?.nearOEHeight || ''}</td>
                    <td style={cellStyle}>{prescription?.nearOEDNP || ''}</td>
                  </tr>
                  <tr>
                    <td style={cellStyleLeft}><b>Adição</b></td>
                    <td style={cellStyle}>{prescription?.additionSpherical || ''}</td>
                    <td style={cellStyle}></td>
                    <td style={cellStyle}></td>
                    <td style={cellStyle}></td>
                    <td style={cellStyle}></td>
                  </tr>
                </table>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '100%' }}>
                <h4>Observação:</h4>
                <div style={{ display: 'flex', justifyContent: 'left', width: '100%', border: '1px solid', borderRadius: '5px' }}>
                  <p>
                    {order.description}
                  </p>
                </div>
              </div>
            </FlexBox>
          </div>
        </div>
      </div>
      <Button
        variant="outlined"
        onClick={() => handlePrint()}
        startIcon={<Icon>local_printshop_sharp</Icon>}
      >
        Imprimir
      </Button>
    </>
  )
}


const wrapperStyle: React.CSSProperties = {
  fontFamily: "Arial, sans-serif",
  margin: '5px',
  lineHeight: 1,
  padding: "5px",
};

const tableStyle: React.CSSProperties = {
  width: "100%",
  borderCollapse: "collapse",
};

const cellStyle: React.CSSProperties = {
  padding: "10px",
  margin: 0,
  fontSize: "14px",
  textAlign: "center",
  borderBottom: "1px solid #DDD",
};

const cellStyleLeft: React.CSSProperties = {
  padding: "10px",
  margin: 0,
  fontSize: "14px",
  textAlign: "left",
  borderBottom: "1px solid #DDD",
};