import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';

import FlexBox from '../layouts/FlexBox';


interface IInputDateRangePickerValue {
  setDateRange: React.Dispatch<React.SetStateAction<dayjs.Dayjs[]>>;
  dateRange: Dayjs[];
};

export function InputDateRangePickerValue(props: IInputDateRangePickerValue) {
  const { dateRange, setDateRange } = props;

  
  return (
    <LocalizationProvider dateFormats={{ fullDate: "DD/MM/YYYY" }} adapterLocale='pt-BR' dateAdapter={AdapterDayjs}>
      <FlexBox maxWidth={300} minWidth={300}>
        <DatePicker
          label="Inicio"
          value={dateRange[0]}
          format='DD/MM/YYYY'
          onChange={(newValue) => setDateRange(prev => {
            if (newValue)
              prev[0] = newValue
            return prev
          })}

        />
        <DatePicker
          label="Fim"
          value={dateRange[1]}
          format='DD/MM/YYYY'
          onChange={(newValue) => setDateRange(prev => {
            if (newValue)
              prev[1] = newValue
            return prev
          })}
        />
      </FlexBox>
    </LocalizationProvider>
  );
}