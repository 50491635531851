import { ILaboratoryInsert, ILaboratoryUpdate } from "../../Dtos/LaboratoryDtos";
import { ILaboratory } from "../../Laboratory";




export class Laboratory implements ILaboratory {
  id: number;
  name: string;
  isActive: boolean;
  phoneNumber: string;

  constructor({ id = 0, name = "", phoneNumber = "", isActive = false}: Partial<ILaboratory> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
    this.phoneNumber = phoneNumber;
  }
  
}


export class LaboratoryInsert implements ILaboratoryInsert {
  name: string;
  phoneNumber: string;

  constructor({ name = "", phoneNumber = "" }: Partial<ILaboratoryUpdate> = {}) {
    this.name = name;
    this.phoneNumber = phoneNumber;
  }
}


export class LaboratoryUpdate implements ILaboratoryUpdate {
  id: number;
  name: string;
  isActive: boolean;
  phoneNumber: string;

  constructor({ id = 0, name = "", isActive = true, phoneNumber = "" }: Partial<ILaboratoryUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
    this.phoneNumber = phoneNumber;
  }
}