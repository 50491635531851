import React from 'react'
import FlexBox from '../../layouts/FlexBox';
import { Typography, Avatar, useMediaQuery, Theme } from '@mui/material';
import { AutoCompleteClientes } from '../components/AutoCompleteClientes';

interface ClientesProps {
  isLoading: boolean;
  clientIdParams: number;
  clientNameParams?: string;
  setClientId: React.Dispatch<React.SetStateAction<number>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};


export const Clientes: React.FC<ClientesProps> = (props) => {
  const { isLoading, setIsLoading, setClientId, clientIdParams, clientNameParams } = props

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <FlexBox width={smDown ? "100%" : 380} height={100} bgcolor={"rgba(222, 229, 239, 0.4)"} borderRadius={4.7} padding={2} paddingTop={1} boxSizing={"border-box"}
      column
    >
      <Typography>Cliente</Typography>
      {clientIdParams == 0 &&
        <FlexBox row gap={10} jcCenter aiCenter>
          <Avatar sx={{ width: '30px', height: '30px', marginTop: '20px' }} />
          <AutoCompleteClientes
            isLoading={isLoading}
            setClientId={setClientId}
            setIsLoading={setIsLoading}
          />
        </FlexBox>
      }
      {clientIdParams > 0 &&
        <FlexBox jcStart aiCenter row gap={10}>
          <Avatar sx={{ width: '30px', height: '30px', marginTop: '20px' }} />
          <Typography sx={{marginTop: '20px' }}>
            {clientNameParams}
          </Typography>
        </FlexBox>
      }
    </FlexBox>
  )
}
