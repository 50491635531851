import { Menu, MenuItem, Icon, Typography, useTheme } from '@mui/material';
import React, { SetStateAction } from 'react';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { IOrderService, OrderService, OrderServiceEdit } from '../../../shared/models/OrderServiceModel';
import { OrderServiceService } from '../../../shared/services/api/orderService/OrderServiceService';
import { ISale } from '../../../shared/models/Sale';
interface MenuAlterarStatusOSProps {
  open: boolean;
  order: IOrderService;
  anchorEl: HTMLElement | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
};

export const MenuAlterarStatusOS: React.FC<MenuAlterarStatusOSProps> = (props) => {
  const { anchorEl, open, order, setSale, setIsLoading, setAnchorEl } = props;

  function handleChangeStatus(statusType: number) {

    const model = new OrderServiceEdit({
      id: order.id,
      cost: order.cost,
      imageUrl: order.imageUrl,
      forecast: order.forecast,
      description: order.description,
      status: statusType,
      laboratoryId: order.laboratory?.id,
      prescription: order.prescription,
    });

    OrderServiceService.edit(model).then((result) => {
      setIsLoading(true);
      if (result.success) {
        setSale(result.data);
      } else {
        alert(result.errors[0]);
      };
      setIsLoading(false);
    });
  };


  const menuItems = [
    { icon: 'download_done', title: 'Envio Pendente', statusType: 0 },
    { icon: 'pending', title: 'Em Andamento', statusType: 1 },
    { icon: 'download_done', title: 'Concluída', statusType: 2 },
    { icon: 'clear', title: 'Cancelada', statusType: 3 },
  ];

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 100,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <FlexBox column gap={5}>
        {menuItems.map((menuItem, index) => (
          <MenuItemStatusChange
            key={index}
            icon={menuItem.icon}
            title={menuItem.title}
            setAnchorEl={setAnchorEl}
            handle={handleChangeStatus}
            statusType={menuItem.statusType}
          />
        ))}
      </FlexBox>
    </Menu>
  );
};


interface MenuItemStatusChangeProps {
  icon: string;
  title: string;
  statusType: number;
  setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
  handle(statusType: number): void;
};

const MenuItemStatusChange: React.FC<MenuItemStatusChangeProps> = (props) => {
  const { icon, title, statusType, setAnchorEl, handle } = props;

  const theme = useTheme();

  const colorSituation = () => {
    switch (statusType) {
      case 0:
        return theme.palette.secondary.main;
      case 1:
        return theme.palette.warning.main;
      case 2:
        return theme.palette.success.main;
      case 3:
        return theme.palette.error.main;
      default:
        return theme.palette.grey[500];
    }
  };

  return (
    <MenuItem
      onClick={() => { handle(statusType); setAnchorEl(null); }}
    >
      <FlexBox gap={10}>
        <Icon sx={{ color: colorSituation() }} >{icon}</Icon>
        <Typography>{title}</Typography>
      </FlexBox>
    </MenuItem>
  );
};
