import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useListagemDeRelatoriosContext } from '../../../shared/contexts/pages/ListagemDeRelatoriosContext';
import { ReportService } from '../../../shared/services/api/report/ReportService';
import { TableReportProduct } from './sections/TableReportProduct';
import { IReportProduct } from '../../../shared/models/Report';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { SearchSection } from './sections/SearchSection';



export const ProductReportPage: React.FC = () => {

  const { smDown, isLoading, startLoading, stopLoading, setTitle } = useListagemDeRelatoriosContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const dateInit = useMemo(() => {
    return searchParams.get('dateInit') || '';
  }, [searchParams]);

  const dateEnd = useMemo(() => {
    return searchParams.get('dateEnd') || '';
  }, [searchParams]);



  const [rows, setRows] = useState<IReportProduct[]>();


  useEffect(() => {
    handleReportRequest();
    setTitle("Produtos mais vendidos")
  }, []);

  function handleReportRequest() {
    startLoading();

    ReportService.getProducts(dateInit, dateEnd).then(
      result => {

        if (result.success) {
          setRows(result.data)
        }
        else {
          alert(result.errors[0])
        }
      }
    );

    stopLoading();
  }

  return (
    <FlexBox column padding={1} fullWidth gap={10}>
      <SearchSection
        dateEnd={dateEnd}
        dateInit={dateInit}
        isLoading={isLoading}
        setSearchParams={setSearchParams}
        handleReportRequest={handleReportRequest}
      />

      <TableReportProduct
        rows={rows}
        smDown={smDown}
        isLoading={isLoading}
      />
    </FlexBox>
  )
}
