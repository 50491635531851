


/**
* Formata o RG.
* @param rawValue Valor bruto (string contendo apenas números).
* @returns RG formatado (XX.XXX.XXX-X).
*/

export function formatRG(rawValue: string): string {
  return rawValue.replace(
    /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,1})$/,
    (_, p1, p2, p3, p4) =>
      `${p1}${p2 ? `.${p2}` : ""}${p3 ? `.${p3}` : ""}${p4 ? `-${p4}` : ""}`
  );
};