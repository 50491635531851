import { Button, Grid, Icon } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

import { CashRegisterService } from "../../shared/services/api/CashRegister/CashRegister";
import { ModalAddTransaction } from "./components/AddTransaction";
import { ICashRegister } from "../../shared/models/CashRegister";
import FlexBox from "../../shared/components/layouts/FlexBox";
import CollapsibleTable from "./sections/CollapsibleTable";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { ModalCashRegister } from "./components";
import { FilterSection } from "./sections";
import { setTimeVerification } from "../../shared/components";


export const Caixa = () => {

  const [cashRegisters, setCashRegisters] = useState<ICashRegister[]>([]);
  const [lastCashRegister, setLastCashRegister] = useState<ICashRegister>()
  const [valueDateFilter, setValueDateFilter] = useState(5);
  const [dateRange, setDateRange] = React.useState<Dayjs[]>([dayjs(new Date()), dayjs(new Date())]);
  const [reload, serReload] = useState(0);
  const [openCash, setOpenCash] = useState(false);
  const [openTrasaction, setOpenTrasaction] = useState(false);
  const [isOpenTextCashRegister, setIsOpenTextCashRegister] = useState('Abrir Caixa');

  useEffect(() => {
    getInfos(valueDateFilter)
  }, [reload]);


  function getInfos(timeType: number) {

    const timeVerification = setTimeVerification(timeType, dateRange);

    CashRegisterService.get(timeVerification.first, timeVerification.last).then((result) => {
      if (result.success) {
        setCashRegisters(result.data.cashRegisters)
        setLastCashRegister(result.data.lastCashRegister)
        setIsOpenTextCashRegister(result.data.lastCashRegister == null ? 'Abrir Caixa' : result.data.lastCashRegister?.dateClose == null ? 'Fechar Caixa' : 'Abrir Caixa');
      }
      if (!result.success) {
        alert(result.errors[0])
      }
    });
  }

  function handleCosole() {
    serReload(prev => ++prev);
  };

  const handleCloseModal = () => {
    setOpenTrasaction(false)
  }

  return (
    <LayoutBaseDePagina
      titulo="Caixa"
    >
      <FilterSection
        setValueDateFilter={setValueDateFilter}
        valueDateFilter={valueDateFilter}
        setDateRange={setDateRange}
        handleClick={handleCosole}
        dateRange={dateRange}
      />

      <FlexBox>
        <Grid container>
          <FlexBox column paddingY={0} minWidth={'100%'} boxSizing={'border-box'} marginTop={0}>
            <FlexBox paddingX={2} gap={10} row jcEnd>

              <Button
                variant='contained'
                onClick={() => setOpenCash(true)}
              >
                {isOpenTextCashRegister}
              </Button>

              {isOpenTextCashRegister == 'Fechar Caixa' &&
                <Button variant='contained'
                  onClick={() => setOpenTrasaction(true)}
                ><Icon>add</Icon>Registro</Button>
              }

            </FlexBox>

            <FlexBox column paddingTop={2}>

              <CollapsibleTable
                cashRegisters={cashRegisters}
              />

            </FlexBox>
          </FlexBox>
        </Grid>


        <ModalAddTransaction
          openModal={openTrasaction}
          handleClose={handleCloseModal}
        />

        <ModalCashRegister
          isOpen={openCash}
          setIsOpen={setOpenCash}
          title={isOpenTextCashRegister}
          cashRegister={lastCashRegister}
        />

      </FlexBox>
    </LayoutBaseDePagina>
  );
}
