import React, { ReactNode, useEffect } from "react";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../../contexts";
import FlexBox from "../../layouts/FlexBox";
import { Env } from "../../../environment";
import { VerificationDrawerDownMD } from "./sections/MenuLateralContainer";
import { MenuLateralContent } from "./sections/MenuLateralContent";


interface IAppThemeProviderProps {
  children: ReactNode
}

export const MenuLateral: React.FC<IAppThemeProviderProps> = ({ children }) => {
  const location = useLocation();
  const pathAdmin = location.pathname.includes('admin');
  const { globalLoading } = useAuthContext();

  const down600 = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <FlexBox
      fullWidth
      jcCenter
      paddingY={1}
      paddingBottom={10}
      // sx={{ overflowX: 'auto', overflowY: 'auto', background: 'radial-gradient(circle, #843af2, #0f123fb9)' }}
      // sx={{ overflowX: 'auto', overflowY: 'auto', background: '#843af2' }}
       sx={{ overflowX: 'auto', overflowY: 'auto', background: '#F4F7FE' }}
    >
      <FlexBox
        maxWidth='1480px'
        row
        sx={{ width: '100vw' }}
        gap={20}
      >

        <VerificationDrawerDownMD>
          <MenuLateralContent
            pathAdmin={pathAdmin}
          />
        </VerificationDrawerDownMD>


        <FlexBox
          fullWidth
       //   bgcolor={"#f3f4f6"}
        >
          <FlexBox
            borderRadius={Env.BD_RADIUS}
            gap={2}
            fullWidth
            paddingX={down600 ? 0 : 2}
            sx={down600 ? { backgroundColor: "transparent" } : {}}
          >
            {
              globalLoading && (
                <FlexBox jcCenter aiCenter height="500px">
                  <CircularProgress size={100} />
                </FlexBox>
              )
            }
            {
              !globalLoading && children
            }
          </FlexBox>

        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
};