import { Avatar, Box, Breadcrumbs, Button, CircularProgress, Divider, Grid, Icon, Link, Paper, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import TabContext from '@mui/lab/TabContext';
import { useEffect, useState } from "react";
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';

import { ModalAddOrder } from '../../shared/components/addOrderServiceModal/ModalAddOrder';
import { ModalAddVendas } from '../../shared/components/addVendasModal/ModalAddVenda';
import { ClientesService } from "../../shared/services/api/clientes/ClientesService";
import { IDocumentTypeEnum } from '../../shared/models/enums/DocumentTypeEnum';
import { IContactLeveEnum } from '../../shared/models/enums/ContactTypeEnum';
import PhoneNumberFormat from '../../shared/helps/PhoneNumberFormat';
import { CardBox } from '../../shared/components/layouts/CardBox';
import { IClient } from '../../shared/models/Clients/client';
import FlexBox from '../../shared/components/layouts/FlexBox';
import { LayoutBaseDePagina } from "../../shared/layouts";
import { Vendas } from "./components/detalheDeClientes";
import { DadosUsuarioSection } from "./components";
import { Env } from "../../shared/environment";
import { useVForm } from "../../shared/forms";

export const DetalheDeClientes: React.FC = () => {
  const { formRef } = useVForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openAddOrder, setOpenAddOrder] = useState(false);
  const [client, setClient] = useState<IClient>();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    setIsLoading(true);
    ClientesService.getById(Number(id)).then(
      (result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
          navigate('/clientes');
        } else {
          formRef.current?.setData(result.data);
          setClient(result.data)
        }
      }
    );
  }, [id, navigate, formRef]);

  const handlerAddItem = () => {
    if (value == "0")
      setOpenAddClient(true)
    if (value == "1")
      setOpenAddOrder(true)
  };

  const [value, setValue] = useState('0');

  const buttonName = ["Venda", "O.S", "Receita", "Crediario"]

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <LayoutBaseDePagina
      titulo={'Perfil do cliente'}
      buttonToBack
      barraDeFerramentas={
        <Button
          sx={{ margin: 1 }}
          variant='contained'
          onClick={handlerAddItem}
        >{"Adicionar " + buttonName[parseInt(value)]}</Button>
      }
    >
      <Box width='100%'
        sx={Env.FLEX_COLUMN} gap={4}
      >
        <FlexBox paddingX={1} jcBetween>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/pagina-inicial">
              Página inicial
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/clientes"
            >
              Clientes
            </Link>
            <Typography color="text.primary">Perfil do Cliente</Typography>
          </Breadcrumbs>

          <FlexBox>
            <Button onClick={() => navigate(`/clientes/editar/${id}`)}>
              <FlexBox gap={10}>
                <Icon>edit</Icon>
                cliente
              </FlexBox>
            </Button>
          </FlexBox>
        </FlexBox>

        <Grid container direction={mdDown || smDown || lgDown ? 'column-reverse' : 'row'} spacing={2} >
          <Grid item
            lg={8}
            xl={8}
          >
            <Paper component={CardBox} variant="outlined" sx={{ width: '100%', typography: 'body1', borderRadius: 5 }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(_, newValue) => handleChange(newValue)} aria-label="lab API tabs example">
                    <Tab label="Vendas" value="0" icon={<Icon>sell</Icon>} iconPosition="start" />
                  </TabList>
                </Box>
                <TabPanel value="0">
                  <Vendas client={client} />
                </TabPanel>
              </TabContext>
            </Paper>
          </Grid>

          <Grid item
            lg={4}
            xl={4}
          >
            <CardBox paddingBottom={3}
              padding={3}
              jcCenter
              aiCenter
              gap={4}>
              {isLoading && <FlexBox fullWidth> <CircularProgress size={90} /> </FlexBox>}

              {!isLoading &&
                <FlexBox column aiCenter jcCenter
                  gap={20} fullWidth
                >
                  <FlexBox column aiCenter jcCenter minWidth={150}>
                    <Avatar src={client?.imageUrl} sx={{ width: '80px', height: '80px', backgroundColor: 'whitesmoke' }}>
                    </Avatar>

                    <Typography fontWeight={600}>
                      {client?.name}
                    </Typography>
                  </FlexBox>

                  <Stack
                    gap={1}
                    width='100%'
                    direction="column"
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Box />

                    {(client?.contacts?.length != undefined && client.contacts.filter(x => x.isMain).length > 0) && (

                      <DadosUsuarioSection
                        title={
                          IContactLeveEnum[client.contacts.filter(x => x.isMain)[0]?.type ?? 0] + ":"
                        }
                        description={
                          ((client.contacts.filter(x => x.isMain)[0].type == 0 || client.contacts.filter(x => x.isMain)[0].type == 1)
                            ?
                            PhoneNumberFormat(client.contacts.filter(x => x.isMain)[0]?.value)
                            :
                            client.contacts.filter(x => x.isMain)[0]?.value
                          )
                        }
                      />

                    )}

                    {client?.address != undefined &&

                      <DadosUsuarioSection
                        title="Endereço:"
                        description={client?.address.street + ", " + client?.address.number + " - " + client?.address.district}
                      />
                    }

                    {client?.contacts?.length != undefined && client.contacts.filter(x => !x.isMain).length > 0 &&

                      <DadosUsuarioSection
                        title={IContactLeveEnum[client.contacts.filter(x => !x.isMain)[0]?.type ?? 0] + ":"}
                        description={(client.contacts.filter(x => !x.isMain)[0]?.type == 0 || client.contacts.filter(x => !x.isMain)[0]?.type == 1 ?
                          PhoneNumberFormat(client.contacts.filter(x => !x.isMain)[0]?.value) :
                          client.contacts.filter(x => !x.isMain)[0]?.value)}
                      />
                    }

                    {client?.documents?.length != undefined && client.documents.length > 0 &&

                      client?.documents.map(document =>
                        <DadosUsuarioSection
                          title={IDocumentTypeEnum[document.type] + ":"}
                          description={document.value as string}
                        />
                      )
                    }

                  </Stack>
                </FlexBox>
              }
            </CardBox>
          </Grid>
        </Grid>
      </Box>

      <ModalAddVendas
        open={openAddClient}
        handleClose={() => setOpenAddClient(false)}
        clientIdParams={Number(id)}
        clientNameParams={client && client.name}
      />
      
    </LayoutBaseDePagina>
  );
};