import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BaseModal } from '../../../modals/BaseModal';
import FlexBox from '../../../layouts/FlexBox';
import { Box, Button } from '@mui/material';
import { useAuthContext } from '../../../../contexts';
import { RoleService } from '../../../../services/api/role/RoleService';
import { IRole } from '../../../../models';
import { StorageService } from '../../../../services/storage/storageService';

interface IModalFilialSelectProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalFilialSelect: React.FC<IModalFilialSelectProps> = ({
  isOpen,
  setIsOpen
}) => {
  const { roles, setRoles} = useAuthContext();
  const [role, setRole] = useState<IRole>();
  const [value, setValue] = useState("");

  useEffect(() => {
    getRoles()
  }, [roles])

  const getRoles = () => {
    if (roles != undefined && roles.length > 0) {
      const roleIsSelectedTrue = roles.filter(role => role.isSelected == true)[0];
      if (roleIsSelectedTrue != undefined) setRole(roleIsSelectedTrue)
      const roleNameTrue = roleIsSelectedTrue?.branch?.name;
      if (roleNameTrue != undefined)
        setValue(roleNameTrue)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setValue(role?.branch?.name as string);
    setIsOpen(!isOpen);
  };

  function handleSubmit() {
    var roleSelect = roles.filter(x => x.branch?.name == value)[0];
    if (roleSelect != undefined)
      
      RoleService.selectRole(roleSelect.id).then(
        result => {
          if (result.success == true) {
            const storage = new StorageService();
            storage.saveRoles(result.data)
            setRoles(result.data)
            window.location.reload();
          } else {
            alert(result.errors[0])
          }
        }
      );

  };


  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      title="Trocar Ponto de Venda"
      minWidth={300}
    >
      <FlexBox column gap={30} marginTop='30px' maxWidth={350} >
        <FormControl component={Box} maxHeight="300px" overflow='auto'>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            sx={{ marginLeft: '25px' }}
          >
            {
              roles != undefined && roles.length > 0 ? roles.map((role) =>
                <FormControlLabel value={role?.branch?.name} control={<Radio />} label={role?.branch?.name} key={role?.branch?.id} />
              ) : null
            }
          </RadioGroup>
        </FormControl>
        <FlexBox row jcBetween>
          <Button variant='outlined' onClick={handleClose}>Fechar</Button>
          <Button variant='contained' onClick={(e) => {e.preventDefault(); handleSubmit();}}>Salvar</Button>
        </FlexBox>
      </FlexBox>
    </BaseModal>
  );
};

