import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { Chip, Icon, Stack, styled, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ISituation } from '../../../shared/models/Report';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import { statusBarPayment } from '../../vendas/components/StatusBarPayment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import React, { Dispatch, SetStateAction } from 'react';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import { blue, blueGrey, green, orange, purple, red } from '@mui/material/colors';
import { PriceDisplay } from '../../../shared/helps/Dashboard-PriceDisplay';
import { useDashboardContext } from '../../../shared/contexts';


const CashTypography = styled(Typography)(({ theme }) => ({
  // letterSpacing: '0.05em',
  //lineHeight: 1.5,
  fontWeight: 600,
  fontSize: 23,
  // transform: 'scaleX(0.82)',
}));

interface OverviewBudgetProps {
  sx?: React.CSSProperties;
  situation: ISituation | undefined;
  setStatusFilter?: Dispatch<SetStateAction<number[]>>
};

export const OverViewCard: React.FC<OverviewBudgetProps> = (props) => {
  const { sx, situation, setStatusFilter } = props;

  const { serReload, setStatus } = useDashboardContext();

  const handleReload = () => {
    setStatusFilter?.([situation?.status ?? 0]);
    setStatus([situation?.status ?? 0]);
    serReload(prev => ++prev);
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const iconChange = () => {
    switch (situation?.status) {
      case 0:
        return { label: "check_circle", color: green[700] };
      case 1:
        return { label: "replay_circle_filled", color: purple[600] };
      case 2:
        return { label: "error_outline", color: orange[700] };
      case 3:
        return { label: "highlight_off", color: red[700] };
      case 4:
        return { label: "hourglass_empty", color: blue[700] };
      default:
        return { label: "----", color: blueGrey[500] };
    };
  }



  // "Confirmado"
  // "Estornada"
  // "Atrasado"
  // "Cancelado"
  // "Pendente"

  return (
    <CardBox padding={2.5} fullWidth sx={sx} boxSizing={"border-box"}>
      <Stack width={'100%'}>

        <FlexBox row fullWidth jcBetween aiStart>

          <Typography fontWeight={600} fontSize={15} color={statusBarPayment(situation?.status).color}
          >
            {statusBarPayment(situation?.status).label}
          </Typography>

          <FlexBox column>
            <Tooltip title="Clientes" placement="left">
              <Typography
                color={statusBarPayment(situation?.status).color}
                variant="body2"
              >
                <FlexBox gap={5}>

                  <PersonOutlineOutlinedIcon fontSize='small' />


                  {situation?.clientNumber ?? 0}

                  {
                    // (situation?.clientNumber ?? 0) > 1 ? 'clientes' : 'cliente'
                  }
                </FlexBox>
              </Typography>
            </Tooltip>

            <Tooltip title="Transações" placement="left">
              <Typography
                color={statusBarPayment(situation?.status).color}
                variant="body2"
              >
                <FlexBox gap={5}>

                  <LocalAtmOutlinedIcon fontSize='small' />

                  {situation?.transactionNumber ?? 0}

                  {
                    //  (situation?.transactionNumber ?? 0) > 1 ? ' transações' : ' transação'
                  }
                </FlexBox>
              </Typography>
            </Tooltip>
          </FlexBox>

        </FlexBox>

        <FlexBox column={smDown ? true : false} jcStart
          aiCenter
          gap={smDown || (((situation?.value ?? 0)) > 10000) ? 4 : 10}
        >

          <FlexBox column>

            <Tooltip
              title={"Filtrar por " + statusBarPayment(situation?.status).label}
              placement="bottom"
            >
              <Icon
                onClick={handleReload}
                sx={{
                  color: iconChange().color + "cc",
                  fontSize: "45px",
                  // boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  // backgroundColor: iconChange().color + "20",
                  borderRadius: 20,
                  // padding: 0.3,
                  cursor: "pointer",
                  transition: 'all 0.17s ease-in-out',
                  '&:hover': { transform: 'scale(1.2)', boxShadow: '1px 3px 5px 1px #e3e3e3', },
                  '&:active': { transform: 'scale(0.9)', transition: 'all 0.05s ease-in-out', boxShadow: '1px 0px 10px 1px #f1f1f1' }
                }}
              >
                {iconChange().label}
              </Icon>

            </Tooltip>

          </FlexBox>

          <CashTypography variant="h6" color={statusBarPayment(situation?.status).color}
            marginTop={1.5}
          >
            <PriceDisplay
              value={(situation?.value ?? 0)}
              color={statusBarPayment(situation?.status).color}
            />
          </CashTypography>

        </FlexBox>

      </Stack>
    </CardBox >
  );
};