import React from 'react';
import { ISale } from '../../../shared/models/Sale';
import { Box, Grid, LinearProgress, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { CardBox } from '../../../shared/components/layouts/CardBox';

interface ComentariosContentProps {
  isLoading: boolean;
  sale: ISale | undefined;
}

export const ComentariosContent: React.FC<ComentariosContentProps> = ({ isLoading, sale }) => {
  return (

    <FlexBox column padding={1} fullWidth boxSizing={'border-box'}>

      {isLoading && (
        <LinearProgress variant="indeterminate" />
      )}

      <FlexBox row jcBetween
      >
        <Typography variant="h5">
          Comentarios
        </Typography>

        {/* <Button variant='contained'>
          <Icon>add</Icon>Adicionar
        </Button> */}
      </FlexBox>


      <FlexBox column paddingTop={3}>
        <CardBox column boxSizing='border-box'
          padding={1} borderRadius={5}
          maxHeight={350}  //   <------- Limitador de Tamanho do Comentário
          overflow='auto'  //   <------- Limitador de Tamanho do Comentário
        >
          <Box display="flex" flexDirection="column">
            <Grid container direction="column" padding={0} marginY={0}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant="indeterminate" />
                </Grid>
              )}
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} >
                  <List
                    aria-labelledby="ellipsis-list-demo"
                  >
                    {sale && sale.commits.map((commit) => (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary={commit.userName}
                            secondary={
                              <React.Fragment>
                                {commit.message}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        {
                          sale.commits[sale.commits.length - 1]?.id != commit.id &&
                          <Divider component="li" />
                        }
                      </>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardBox>
      </FlexBox>
    </FlexBox>
  )
};
