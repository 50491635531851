import { ReactNode, useCallback, useContext, useState, createContext } from "react";
interface IDrawerOptionsAdmin {
    icon: string;
    path: string;
    label: string;
}
interface IDrawerOptions {
    icon: string;
    path: string;
    label: string;
    level: number;
}
interface IDrawerContextData {
    isDrawerOpen: boolean;
    toggleDrawerOpen: () => void;
    drawerOptions: IDrawerOptions[];
    setDrawerOptions: (newDrawerOptions: IDrawerOptions[]) => void;
    drawerOptionsAdmin: IDrawerOptionsAdmin[];
    setDrawerOptionsAdmin: (newDrawerOptionsAdmin: IDrawerOptionsAdmin[]) => void;
}

const DrawerContext = createContext({} as IDrawerContextData);

export const useDrawerContext = () => {
    return useContext(DrawerContext);
};

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [drawerOptions, setDrawerOptions] = useState<IDrawerOptions[]>([]);

    const [drawerOptionsAdmin, setDrawerOptionsAdmin] = useState<IDrawerOptionsAdmin[]>([]);
    const handleSetDrawerOptionsAdmin = useCallback((newDrawerOptionsAdmin: IDrawerOptionsAdmin[]) => {
        setDrawerOptionsAdmin(newDrawerOptionsAdmin);
    }, []);

    const toggleDrawerOpen = useCallback(() => {
        setDrawerOpen(oldDrawerOpen => !oldDrawerOpen);
    }, []);

    const handleSetDrawerOptions = useCallback((newDrawerOptions: IDrawerOptions[]) => {
        setDrawerOptions(newDrawerOptions);
    }, []);

    return (
        <DrawerContext.Provider value={{
            isDrawerOpen,
            drawerOptions,
            toggleDrawerOpen,
            setDrawerOptions: handleSetDrawerOptions,
            drawerOptionsAdmin, 
            setDrawerOptionsAdmin: handleSetDrawerOptionsAdmin,
        }}>
            {children}
        </DrawerContext.Provider>
    );
};
