import { Button, Icon, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { AutoCompleteProdutos } from '../../../../pages/estoque/entradas/components/AutoCompleteProdutos';
import { IProductSaleInsert, ProductSaleInsert } from '../../../models/ProductSale';
import { InputNumericState } from '../../inputs/InputNumericState';
import { InputCurrency } from '../../inputs/InputCurrency';
import { CardProduto } from '../components/CardProduto';
import { BaseModal } from '../../modals/BaseModal';
import { IProduct } from '../../../models/Product';
import { ShowColor } from '../../layouts/ShowColor';
import FlexBox from '../../layouts/FlexBox';


interface ProdutosListagemProps {
  isLoading: boolean;
  productsSelect: IProductSaleInsert[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsSelect: React.Dispatch<React.SetStateAction<IProductSaleInsert[]>>;
}

export const ProdutosListagem: React.FC<ProdutosListagemProps> = ({ isLoading, setIsLoading, productsSelect, setProductsSelect }) => {

  const [openModalProduct, setOpenModalProduct] = useState(false);

  function openModal() {
    setOpenModalProduct(true);
  };

  function handleClose() {
    setOpenModalProduct(false);
  };

  function handleDeleteProduct(prodId: number) {
    setProductsSelect(oldRows => {
      if (oldRows.length > 1) {
        return [
          ...oldRows.filter(oldRow => oldRow.stockProductId != prodId),
        ];
      }
      else {
        return [new ProductSaleInsert(0, 0, 0, 'string')];
      }
    });
  };

  return (
    <FlexBox width={"100%"} minHeight={100} bgcolor={"rgba(222, 229, 239, 0.4)"} borderRadius={4.7} padding={2} paddingTop={1} boxSizing={"border-box"}
      column gap={10}
    >
      <FlexBox row jcBetween marginBottom={1}>
        <Typography>Produtos</Typography>
        <Button variant='outlined' size='small'
          onClick={openModal}
          endIcon={<Icon>add</Icon>}
        >
          Adicionar
        </Button>
      </FlexBox>
      {productsSelect[0].stockProductId != 0 && (
        <FlexBox row
          gap={4}
        >
          <FlexBox width={340} gap={5} paddingLeft={1}>
            <Typography color={"rgba(0, 0, 0, 0.2)"} fontWeight={700} fontSize={13}>Descrição</Typography>
          </FlexBox>
          <FlexBox width={50} jcCenter>
            <Typography color={"rgba(0, 0, 0, 0.2)"} fontWeight={700} fontSize={13}>Qntde.</Typography>
          </FlexBox>
          <FlexBox width={150} jcCenter paddingLeft={3}>
            <Typography color={"rgba(0, 0, 0, 0.2)"} fontWeight={700} fontSize={13}>Valor</Typography>
          </FlexBox>
        </FlexBox >
      )}

      {productsSelect[0].stockProductId !== 0 &&
        productsSelect.map(product =>
          <CardProduto
            name={product.name}
            value={product.value}
            quantity={product.quantity}
            key={product.stockProductId}
            onClick={() => handleDeleteProduct(product.stockProductId)}
          />
        )
      }

      {productsSelect[0].stockProductId === 0 &&
        <Typography fontWeight={200} color='#333' fontSize='15px' marginTop={2.4} fontStyle='italic'>
          Nenhum produto selecionado
        </Typography>
      }

      <ModalAddProdutos
        isLoading={isLoading}
        handleClose={handleClose}
        setIsLoading={setIsLoading}
        productsSelect={productsSelect}
        openModalProduct={openModalProduct}
        setProductsSelect={setProductsSelect}
      />
    </FlexBox>
  )
};






interface ModalAddProdutosProps {
  isLoading: boolean;
  handleClose: () => void;
  openModalProduct: boolean;
  productsSelect: IProductSaleInsert[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsSelect: React.Dispatch<React.SetStateAction<IProductSaleInsert[]>>
};


const ModalAddProdutos: React.FC<ModalAddProdutosProps> = (props) => {
  const { openModalProduct, handleClose, isLoading, setIsLoading, productsSelect, setProductsSelect } = props;


  const [stockProductId, setStockProductId] = useState<number>();
  const [productSelect, setProductSelect] = useState<IProduct>();
  const [errorQuantity, setErrorQuantity] = useState(false);
  const [valorSugerido, setValorSugerido] = useState(0);
  const [valueProd, setValueProd] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [err, setErr] = useState(false);


  function handleAoClicarEmSalvar() {

    const isContains = {
      quantity: quantity !== 0,
      valueProd: valueProd !== 0,
      stockProductId: stockProductId !== undefined && stockProductId !== 0,
    };

    if (isContains.quantity && isContains.valueProd && isContains.stockProductId) {

      let realFormatedValue = valueProd / 100;     // Aqui fica a formatação do resultado do "InputCoin".

      const prodMapper = new ProductSaleInsert(
        stockProductId as number,
        quantity,
        realFormatedValue * quantity,
        productSelect?.name
      );

      let isNotContainsProduct = (productsSelect[0].stockProductId == 0);

      if (isNotContainsProduct) {
        setProductsSelect([prodMapper]);
      } else {
        setProductsSelect(old => [...old, prodMapper]);
      };

      handleCloseState();
    }
    else {
      setErr(true);
    };
  };

  const handlerStockProduct = (id: number) => {
    const stock = productSelect?.stockProducts.find(x => x.id == id);
    setValorSugerido(stock?.value ?? 0)
    setStockProductId(id);
  }

  function handleCloseState() {
    setErrorQuantity(false);
    setQuantity(0);
    setValueProd(0);
    setErr(false);
    handleClose();
  };

  function handleChangeQuantity(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setQuantity(Number(e.target.value));
    setErrorQuantity(false);
    setErr(false);
  }

  return (
    <BaseModal
      handleClose={handleCloseState}
      open={openModalProduct}
      title={'Adicionar Produto'}
      minWidth={320}
    >
      <FlexBox column gap={15} marginTop={7}>

        <AutoCompleteProdutos
          setProductSelect={setProductSelect}
        />

        {(productSelect != undefined) ? (

          // <AutoCompleteColorAdd
          //   colorSelect={}
          //   setColorSelect={}
          // />
          
          <TextField
            select
            required
            label="Cor"
            name="color"
            size="small"
            value={stockProductId}
            onChange={e => handlerStockProduct(parseInt(e.target.value))}
          >
            {productSelect.stockProducts.filter(x => x.isActive).map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
              >
                <FlexBox row gap={10} jcBetween>
                  <ShowColor
                    colorCode={option.colorProduct?.code}
                    colorName={option.colorProduct?.name}
                    isColor={option.colorProduct?.name}
                  />
                  {option.colorProduct?.name ?? 'Sem cor'}
                </FlexBox>
              </MenuItem>
            ))}
          </TextField>

        ) : (
          <TextField
            size="small"
            name="color"
            label="Cor"
            required
            disabled
          />
        )}

        <InputNumericState
          value={quantity}
          label="Quantidade"
          error={err || errorQuantity}
          helperText={errorQuantity && 'Quantidade Indisponível no Estoque'}
          onChange={handleChangeQuantity}
        />

        <InputCurrency
          label="Valor"
          errorValue={err}
          value={valueProd}
          setErrorValue={setErr}
          setValue={setValueProd}
        />

        <FlexBox fullWidth aiEnd column>
          <Typography>Valor Sugerido</Typography>
          <Typography fontWeight={700}>R$ {valorSugerido}</Typography>
        </FlexBox>

        {err &&
          <FlexBox fullWidth jcStart>
            <Typography color={'red'}>Todos os campos Obrigatórios!</Typography>
          </FlexBox>
        }

        <Stack
          marginTop={2}
          direction='row'
          justifyContent='space-between'
        >
          <Button onClick={handleCloseState}>Fechar</Button>
          <Button variant='contained' onClick={handleAoClicarEmSalvar}>Salvar</Button>
        </Stack>
      </FlexBox>
    </BaseModal>
  );
};
