import { Api } from "../axios-config";
import { IResponseAmount } from "../../../models";
import { IMarkProduct } from "../../../models/MarkProduct";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IMarkProductInsert, IMarkProductUpdate } from "../../../models/Dtos/cadastrosDtos/MarkProductDto";


const insert = async (markProduct: IMarkProductInsert): Promise<IResponseAPI<IMarkProduct[]>> => {

    const { data } = await Api.post('/otica/MarkProduct', markProduct).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (markProduct: IMarkProductUpdate): Promise<IResponseAPI<IMarkProduct>> => {

    const { data } = await Api.put('/otica/MarkProduct', markProduct).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (): Promise<IResponseAPI<IResponseAmount<IMarkProduct[]>>> => {

    const { data } = await Api.get("/otica/MarkProduct?pageSize=50").catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IMarkProduct[]>>> => {

    const { data } = await Api.get(`/otica/MarkProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const MarkProductService = {
    insert,
    update,
    getAll,
    getById,
};