import { Menu, MenuItem, Icon, Typography, useTheme } from '@mui/material';
import React, { SetStateAction } from 'react';
import { SaleService } from '../../../shared/services/api/sale/SaleService';
import { ISale, SaleStatusUpdate } from '../../../shared/models/Sale';
import FlexBox from '../../../shared/components/layouts/FlexBox';

interface MenuAlterarStatusProps {
  open: boolean;
  saleId: number;
  anchorEl: HTMLElement | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
  toStatus?: number[];
};

export const MenuAlterarStatus: React.FC<MenuAlterarStatusProps> = (props) => {
  const { anchorEl, open, saleId, setSale, setIsLoading, setAnchorEl, toStatus } = props;

  function handleChangeStatus(event: React.MouseEvent<HTMLLIElement, MouseEvent>, statusType: number) {

    if (statusType === 4) {

      const userConfirmation = window.confirm(
        "Alterar o Status para AGUARDANDO PAGAMENTO impedirá alterações " +
        "em PRODUTOS !!! Deseja alterar?"
      );

      if (userConfirmation) {
        fetchChangeStatus(event, statusType);
        return
      }
      else
        return;
    };

    fetchChangeStatus(event, statusType);
  };

  function fetchChangeStatus(event: React.MouseEvent<HTMLLIElement, MouseEvent>, statusType: number) {
    event.preventDefault();
    SaleService.updateStatus(
      new SaleStatusUpdate(saleId, statusType)
    ).then((result) => {

      setIsLoading(true);
      if (result.success) {
        setSale(result.data);
      } else {
        alert(result.errors[0]);
      }
      setIsLoading(false);
    });
  };

  // Lista de objetos de status
  const menuItems = [
    { icon: 'edit', title: 'Orçamento', statusType: 0 },
    { icon: 'clear', title: 'Orçamento Cancelado', statusType: 3 },
    { icon: 'pending', title: 'Aguardando Pagamento', statusType: 4 },
    { icon: 'download_done', title: 'Concluído', statusType: 1 },
    { icon: 'clear', title: 'Cancelado', statusType: 2 },
  ];

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 100,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <FlexBox column gap={5}>
        {menuItems.filter(x => toStatus?.includes(x.statusType)).map((menuItem, index) => (
          <MenuItemStatusChange
            key={index}
            icon={menuItem.icon}
            title={menuItem.title}
            setAnchorEl={setAnchorEl}
            handle={handleChangeStatus}
            statusType={menuItem.statusType}
          />
        ))}
      </FlexBox>
    </Menu>
  );
};






interface MenuItemStatusChangeProps {
  icon: string;
  title: string;
  statusType: number;
  setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
  handle: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, statusType: number) => void;
};

const MenuItemStatusChange: React.FC<MenuItemStatusChangeProps> = (props) => {
  const { icon, title, statusType, setAnchorEl, handle } = props;

  const theme = useTheme();

  const colorSituation = () => {
    switch (statusType) {
      case 0:
        return theme.palette.secondary.main;
      case 1:
        return theme.palette.success.light;
      case 2:
        return theme.palette.error.main;
      case 3:
        return theme.palette.info.main;
      case 4:
        return theme.palette.warning.main;
      default:
        return theme.palette.grey[500];
    }
  };

  return (
    <MenuItem
      onClick={(e) => { handle(e, statusType); setAnchorEl(null); }}
    >
      <FlexBox gap={10}>
        <Icon sx={{ color: colorSituation() }} >{icon}</Icon>
        <Typography>{title}</Typography>
      </FlexBox>
    </MenuItem>
  );
};
