import { IResponseAmount, IResponseAPI } from "../../../models";
import { IStockMovement, IStockMovementInsert } from "../../../models/StockMovement";
import { Api } from "../axios-config";


const getAll = async (type?: number, dateInit?: string, dateEnd?: string, page?: number, pageSize?: number): Promise<IResponseAPI<IResponseAmount<IStockMovement[]>> | IResponseAPI<IResponseAmount<IStockMovement[]>>> => {
  const { data } = await Api.get(`/otica/StockMovement?type=${type}&dateInit=${dateInit}&dateEnd=${dateEnd}&page=${page}&pageSize=${pageSize}`).catch(data => {
    return data.response;
  })
  return data;
};

const getById = async (id: number): Promise<IResponseAPI<IStockMovement>> => {
  const { data } = await Api.get(`/otica/StockMovement/${id}`).catch(data => {
    return data.response;
  })
  return data;
};

const create = async (stock: IStockMovementInsert): Promise<IResponseAPI<IStockMovement[]>> => {
  const { data } = await Api.post(`/otica/StockMovement`, stock).catch((data) => {
    return data.response;
  });
  return data;
};
export const StockMovementService = {
  getAll,
  getById,
  create
};