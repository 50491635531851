import { ChipProps } from '@mui/material';
import { blue, green, grey, orange, red } from '@mui/material/colors';

interface StatusBar {
  label: string;
  color: ChipProps["color"]; // Usa o tipo color do Chip diretamente
  bgColor: string;
  noChipColor: string;
}

export const statusBar = (value: number | undefined, smDown?: boolean): StatusBar => {
  
  switch (value) {
    case 0:
      return { label: "Orçamento", bgColor: blue[50], noChipColor: blue[500], color: "secondary" };
    case 1:
      return { label: "Concluída", bgColor: green[100], noChipColor: green[500], color: "success" };
    case 2:
      return { label: "Cancelada", bgColor: red[100], noChipColor: red[500], color: "error" };
    case 3:
      return { label: "Orçamento Cancelado", bgColor: grey[100], noChipColor: grey[500], color: "default" };
    case 4:
      return { label: smDown ? "(...)Pagmnt." :"(...)Pagamento", bgColor: orange[100], noChipColor: orange[500], color: "warning" };
    default:
      return { label: "----", bgColor: grey[100], noChipColor: grey[500], color: "default" };
  };
};