import { useParams } from "react-router-dom";
import { ReactNode, useContext, createContext, useState, useEffect } from "react";

import { IReason } from "../../models/Reason";
import { IColorProduct } from "../../models/ColorProduct";
import { ReasonService } from "../../services/api/cadastros/ReasonService";
import { ColorProductService } from "../../services/api/colorProduct/ColorProductService";

interface IStockContextData {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  pageName: string;
  colors: IColorProduct[];
  reasons: IReason[];
};

export const StockProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);
  const { pageName = "entradas" } = useParams();
  const [colors, setColors] = useState<IColorProduct[]>([]);
  const [reasons, setReasons] = useState<IReason[]>([]);

  useEffect(() => {
    getColor();
    getReason();
  }, []);
  
  const getColor = () => {
    startLoading();
    ColorProductService.getAll().then((result) => {
      if (result.success) {
        setColors(result.data.data);
      }
      stopLoading();
    })
  }

  const getReason = () => {
    startLoading();
    ReasonService.getAll().then((result) => {
      if (result.success) {
        setReasons(result.data.data);
      }
      stopLoading();
    })
  }

  return (
    <StockContext.Provider value={{
      isLoading,
      stopLoading,
      startLoading,
      pageName,
      colors,
      reasons
    }}>
      {children}
    </StockContext.Provider>
  );
};

const StockContext = createContext({} as IStockContextData);

export const useStockContext = () => {
  return useContext(StockContext);
};

