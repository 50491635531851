import { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material"
import { SectionTableVendas } from "./sections";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "../../../../shared/hooks";
import { ISale } from "../../../../shared/models/Sale";
import { SaleService } from "../../../../shared/services/api/sale/SaleService";
import { IClient } from "../../../../shared/models/Clients/client";

interface VendasProps {
  client: IClient | undefined
};

export const Vendas: React.FC<VendasProps> = ({ client }) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce(800, true);

  const [rows, setRows] = useState<ISale[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);


  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      if(client?.id){
        SaleService.getSalesClient(client.id, pagina)
          .then((result) => {
            setIsLoading(false);
            if (result.success) {
              setTotalCount(result.data.amount);
              setRows(result.data.data);
            }
          });
        }
    });
  }, [client, pagina]);

  return (
    <Stack direction='column'>
      <SectionTableVendas
        rows={rows}
        setRows={setRows}
        busca={busca}
        pagina={pagina}
        setSearchParams={setSearchParams}
        totalCount={totalCount}
        isLoading={isLoading}
      />
    </Stack>
  );
};