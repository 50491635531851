import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ILaboratory } from '../../../../shared/models/Laboratory';
import { EstoquesService } from '../../../../shared/services/api/estoques/EstoquesService';
import { useDebounce } from '../../../../shared/hooks';
import { LaboratoryService } from '../../../services/api/laboratory/LaboratoryService';

interface Option {
  label: string,
  id: number
};

interface AutoCompleteLaboratoryProps {
  laboratory?: ILaboratory | undefined;
  setLaboratorySelect: Dispatch<SetStateAction<ILaboratory | undefined>>;
}

export const AutoCompleteLaboratory: React.FC<AutoCompleteLaboratoryProps> = (props) => {
  const { setLaboratorySelect, laboratory } = props;

  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [laboratoryName, setLaboratoryName] = useState<string| undefined>(laboratory?.name ?? '');
  const [dbLaboratory, setDbLaboratory] = useState<ILaboratory[]>([]);
  const [options, setOptions] = useState<Option[]>([])


  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      LaboratoryService.getFiltered().then((result) => {
        if (result.success) {
          const laboratory = result!.data.data;
          setDbLaboratory(laboratory);
          setOptions(laboratory.filter(x => x.isActive).map(p => {
            return {
              id: p.id,
              label: p.name
            }
          }))
        } else {
          alert(result.errors[0]);
        };
        setIsLoading(false);
      });
    });
  }, [laboratoryName, debounce]);


  return (
    <Autocomplete
      disablePortal
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      options={options}
      sx={{ width: '100%' }}
      onChange={(_, value) => {
        if (value) {
          setLaboratorySelect(dbLaboratory.find(x => x.id == value.id))
        };
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          //  label="."
          placeholder='Selecionar um Laboratório'
          variant='outlined'
          size='small'
          value={ laboratoryName }
          onChange={(e) => {
            setLaboratoryName(e.target.value);
          }}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
};
