import { TableCell, TextField, Typography } from "@mui/material";
import FlexBox from "../../layouts/FlexBox";

interface CellSectionProps {
  addMode?: boolean;
  cellValue: string;
  viewMode?: boolean;
  setCellValue?: (value: React.SetStateAction<string>) => void;
};

export const CellSection: React.FC<CellSectionProps> = (props) => {
  const { addMode, viewMode, cellValue, setCellValue } = props;

  return (
    <TableCell>
      {(addMode && setCellValue) && (
        <TextField size="small"
          value={cellValue}
          onChange={e => setCellValue(e.target.value)}
        />
      )}

      {(viewMode || !setCellValue) && (
        <FlexBox jcCenter>
          <Typography fontWeight={600} color="black" fontSize={16}>
            {cellValue != '' ? cellValue : '-'}
          </Typography>
        </FlexBox>
      )}

    </TableCell>
  );
};
