import { Chip, TextField, Tooltip } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import FlexBox from '../../../../../shared/components/layouts/FlexBox';
import { useListagemDeCadastrosContext } from '../../../../../shared/contexts';
import { ICategoryProduct } from '../../../../../shared/models/CategoryProduct';
import { BaseModal } from '../../../../../shared/components/modals/BaseModal';
import { CategoryProductService } from '../../../../../shared/services/api/cadastros/CategoryProductService';
import { CategoryProduct, CategoryProductInsert, CategoryProductUpdate } from '../../../../../shared/models/classes/categoryProducts/CategoryProduct';
import { CategoriaMenuAlterarStatus } from '../../components/Categorias-MenuAlterarStatus';
import { ButtonStatusUpdate } from '../../../../../shared/components/buttons/ButtonStatusUpdate';


interface ModalAddEditCategoryProps {
  isOpenAdd: boolean;
  rowEdit: CategoryProduct;
  setIsOpenAdd: Dispatch<SetStateAction<boolean>>;
  setRows: Dispatch<SetStateAction<ICategoryProduct[]>>
}

export const ModalAddEditCategory: React.FC<ModalAddEditCategoryProps> = (props) => {
  const { isOpenAdd, setIsOpenAdd, setRows, rowEdit } = props;
  const { isLoading, startLoading, stopLoading } = useListagemDeCadastrosContext();

  const modelEmpty = new CategoryProduct({});

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isUpdate = rowEdit.id !== 0;


  useEffect(() => {
    if (isUpdate) {
      setName(rowEdit.name);
      setStatus(rowEdit.isActive);
    } else {
      setName(modelEmpty.name);
      setStatus(modelEmpty.isActive);
    }
  }, [rowEdit]);


  function handleSave() {
    const modelInsert = new CategoryProductInsert({ name: name });
    const modelUpdate = new CategoryProductUpdate({ name: name, isActive: status, id: rowEdit.id });
    startLoading();

    if (isUpdate) {
      CategoryProductService.update(modelUpdate).then(result => {  // Update
        stopLoading();

        if (result.success) {
          setRows(prev => [...prev.map(item => item.id === result.data.id ? result.data : item)]);
          handleClose();
        }
        else {
          alert(result.errors[0]);
        }
      });

    }
    else {
      CategoryProductService.insert(modelInsert).then(result => {  // Insert
        stopLoading();

        if (result.success) {
          setRows(result.data);
          handleClose();
        }
        else {
          alert(result.errors[0]);
        }
      });

    }
  };

  function handleClose() {
    setIsOpenAdd(false);
  };

  return (
    <BaseModal
      viewButtons
      minWidth={300}
      open={isOpenAdd}
      isLoading={isLoading}
      handleSave={handleSave}
      handleClose={handleClose}
      title={isUpdate? 'Editar Categoria' : 'Adicionar Categoria'}
    >
      <FlexBox column gap={10}>
        <TextField
          fullWidth
          label='Nome'
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />


        {isUpdate &&
          <FlexBox fullWidth jcEnd>
            <ButtonStatusUpdate setStatus={setStatus} status={status} />
          </FlexBox>
        }

      </FlexBox>
    </BaseModal>
  )
}
