import {  Grid, Typography, Avatar, Divider, Stack, useTheme, CircularProgress, Box } from '@mui/material';
import React from 'react';
import PhoneNumberFormat from '../../../shared/helps/PhoneNumberFormat';
import { IContactLeveEnum } from '../../../shared/models/enums/ContactTypeEnum';
import { IDocumentTypeEnum } from '../../../shared/models/enums/DocumentTypeEnum';
import { ISale } from '../../../shared/models/Sale';
import { DadosUsuarioSection } from '../../clients/components';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { CardBox } from '../../../shared/components/layouts/CardBox';

interface ClientContentProps {
  isLoading: boolean;
  sale: ISale | undefined;
}

export const ClientContent: React.FC<ClientContentProps> = (props) => {
  const { sale, isLoading } = props;

  const client = sale?.client;
  const theme = useTheme();



  return (
    <FlexBox padding={1}>
      <Grid item 
        minHeight={150}
        minWidth='100%'
      >
        <CardBox paddingBottom={3}
          padding={3}
          jcCenter
          aiCenter
          gap={4}>
          {isLoading && <FlexBox fullWidth> <CircularProgress size={90} /> </FlexBox>}

          {!isLoading &&
            <FlexBox column aiCenter jcCenter
              gap={20} fullWidth
            >
              <FlexBox column aiCenter jcCenter minWidth={150}>
                <Avatar src={client?.imageUrl} sx={{ width: '80px', height: '80px', backgroundColor: 'whitesmoke' }}>
                </Avatar>

                <Typography fontWeight={600}>
                  {client?.name}
                </Typography>
              </FlexBox>

              <Stack
                gap={1}
                width='100%'
                direction="column"
                divider={<Divider orientation="horizontal" flexItem />}
              >

                <Box />

                {client?.contacts?.filter(x => x.isMain)?.length ?? 0 > 0 ?
                  <DadosUsuarioSection
                    title={IContactLeveEnum[client?.contacts?.filter(x => x.isMain)[0].type ?? 0] + ":"}
                    description={(client?.contacts?.filter(x => x.isMain)[0].type == 0 || client?.contacts?.filter(x => x.isMain)[0].type == 1 ?
                      PhoneNumberFormat(client?.contacts?.filter(x => x.isMain)[0].value) :
                      client?.contacts?.filter(x => x.isMain)[0].value) as string
                    }
                  /> : null}

                {client?.address != undefined ?
                  <DadosUsuarioSection
                    title="Endereço:"
                    description={client?.address.street + ", " + client?.address.number + " - " + client?.address.district}
                  /> : null}
                {client?.contacts?.filter(x => !x.isMain)?.length ?? 0 > 0 ?
                  <DadosUsuarioSection
                    title={IContactLeveEnum[client?.contacts?.filter(x => !x.isMain)[0].type ?? 0] + ":"}
                    description={client?.contacts?.filter(x => !x.isMain)[0].value as string}
                  /> : null}

                {client?.documents?.length ?? 0 > 0 ?
                  client?.documents.map(document =>
                    <DadosUsuarioSection
                      title={IDocumentTypeEnum[document.type ?? 0] + ":"}
                      description={document.value as string}
                    />) : null}
              </Stack>
            </FlexBox>
          }
        </CardBox>
      </Grid>
    </FlexBox>
  )
};


