import React, { Dispatch, SetStateAction, useState } from 'react';
import { Icon, Stack, Typography, useTheme } from "@mui/material";

import FlexBox from "../../../shared/components/layouts/FlexBox";
import { ProductSaleService } from "../../../shared/services/api/productSale/ProductSaleService";
import { ISale } from "../../../shared/models/Sale";
import { IProductSale } from '../../../shared/models/ProductSale';
import imgSemFoto from '../../../shared/assets/images/sem-imagem.jpg';
import GenderFormat from '../../../shared/helps/GenderFormat';
import { CardBox } from '../../../shared/components';
import { IProduct } from '../../../shared/models/Product';


interface CardProductAllDatasProps {
  product: IProduct;
};

export const CardProductAllDatas: React.FC<CardProductAllDatasProps> = (props) => {
  const { product } = props;


  return (
    <CardBox
      padding={1}
      minHeight={4}
      boxShadow="15px 15px 35px -26px rgba(0, 0, 0, 0.437)"
      row
    >

      <FlexBox
        width="7px"
        marginRight="15px"
        jcStart aiCenter column borderRadius={2}
        // bgcolor={"#4178ac7f"}
        bgcolor={useTheme().palette.primary.main}
      >
      </FlexBox>

      <FlexBox flex={1} row gap={1} marginRight="5px" aiStart jcBetween
      >

        <FlexBox fullWidth column>

          <FlexBox fullWidth aiCenter jcBetween gap={5} paddingY={1}>

            <FlexBox>
              {product.imageProducts?.at(0)?.imageUrl != undefined ? (
                <img width={50} src={product.imageProducts?.at(0)?.imageUrl} />
              ) : (
                <img width={50} src={imgSemFoto} />
              )}
            </FlexBox>

            {product.id !== 0 ? (
              <FlexBox fullWidth column gap={1}>
                <Typography fontSize={11}><b>Nome:</b> {product.name}</Typography>
                <Typography fontSize={11}><b>Categoria:</b> {product.categoryProduct?.name}</Typography>
                {product.gender != undefined && <Typography fontSize={11}><b>Gênero:</b> {GenderFormat(product.gender)}</Typography>}
              </FlexBox> 
            ) : (
              <Typography>
                Nenhum Produto Selecinado
              </Typography>
            )
            }

            <FlexBox fullWidth column gap={1}>
              {product.modelProduct != undefined && <Typography fontSize={11}><b>Modelo:</b> {product.modelProduct.name}</Typography>}
              {product.materialProduct != undefined && <Typography fontSize={11}><b>Material:</b> {product.materialProduct.name}</Typography>}
              {product.markProduct != undefined && <Typography fontSize={11}><b>Marca:</b> {product.markProduct.name}</Typography>}
              
            </FlexBox>
          </FlexBox>

        </FlexBox>

        {/* <FlexBox column jcBetween aiEnd>

          <FlexBox>
            <Typography color='#40b31c'>R$ {product.value}</Typography>
          </FlexBox>

          {deleteButton && (
            <FlexBox width={25} height={25} borderRadius={20} bgcolor={'#d259592a'}
              jcCenter aiCenter
              onClick={() => handleDelete(product.id)}
            >
              <Icon
                color="error"
                sx={{ fontSize: "22px", '&:hover': { transform: 'scale(1.3)', transition: 'all 0.15s ease-in-out', cursor: 'pointer', } }}
              >
                delete
              </Icon>
            </FlexBox>
          )}
        </FlexBox> */}
      </FlexBox>
    </CardBox>
  )
};
