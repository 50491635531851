import { Button, MenuItem, MobileStepper, Switch, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useProductsContext } from "../../../shared/contexts/pages/ProductsContext";
import { FormEvent, useEffect, useState } from "react";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { IGenderEnum } from "../../../shared/models/enums/GenderTypeEnum";
import { EstoquesService } from "../../../shared/services/api/estoques/EstoquesService";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { InputFiles } from "../../../shared/components/inputs/input-file/InputFiles";
import { IImageProduct } from "../../../shared/models/ImageProduct";
import { useNavigate } from "react-router-dom";
import { ButtonStatusUpdate } from "../../../shared/components/buttons/ButtonStatusUpdate";
import ImgNoProduct from "../../../shared/assets/images/sem-imagem.jpg";
import { CardBox } from "../../../shared/components";

export const EditProduct: React.FC = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { categories, brands, models, materials, setProducts, editProductId } = useProductsContext()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState<number>(0);
  const [markId, setMarkId] = useState<number>(0);
  const [modelId, setModelId] = useState<number>(0);
  const [materialId, setMaterialId] = useState<number>(0);
  const [gender, setGender] = useState(0);
  const [images, setImages] = useState<IImageProduct[]>([]);
  const [isActive, setIsActive] = useState(true);
  const [imagesString, setImagesString] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getProduct()
  }, [editProductId])

  useEffect(() => {
    updateImage()
  }, [imagesString])

  const updateImage = () => {
    let imagesProduct: IImageProduct[] = []
    imagesString.forEach(element => {
      imagesProduct.push({ id: 0, imageUrl: element })
    });
    setImages(imagesProduct)
  }

  const getProduct = () => {
    EstoquesService.getById(editProductId).then((result) => {
      if (result.success) {
        setName(result.data.name)
        setCategoryId(result.data.categoryProduct?.id ?? 0)
        setMarkId(result.data.markProduct?.id ?? 0)
        setModelId(result.data.modelProduct?.id ?? 0)
        setMaterialId(result.data.materialProduct?.id ?? 0)
        setGender(Number(result.data.gender))
        setImages(result.data.imageProducts ?? [])
        setIsActive(result.data.isActive ?? true)
      }
    })
  }

  const handleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const productUpdate = {
      id: editProductId,
      name: name,
      categoryProductId: categoryId,
      gender: gender,
      modelProductId: modelId != 0 ? modelId : undefined,
      markProductId: markId != 0 ? markId : undefined,
      materialProductId: materialId != 0 ? materialId : undefined,
      imageProducts: images,
      isActive: isActive,
    };

    setIsLoading(true);
    EstoquesService.updateById(productUpdate).then(
      result => {
        setIsLoading(false);
        if (result.success && result.data) {
          alert("Produto alterado com sucesso!");
          setProducts(prev => {
            const response = prev.filter(x => x.id != result.data.id)
            response.push(result.data);
            return response
          })
          navigate(-1);
        } else {
          alert(result.errors[0]);
        }
      }
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const genders = [
    { id: 0, name: IGenderEnum[0] },
    { id: 1, name: IGenderEnum[1] },
    { id: 2, name: IGenderEnum[2] }
  ]

  return (
    <LayoutBaseDePagina
      titulo="Editar Produto"
      buttonToBack
    >
      <FlexBox
        padding={1}
        column gap={10}
      >
        <FlexBox jcEnd  >
          <Button variant="contained"
            type="submit"
            size="small"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? '...' : 'Salvar'}
          </Button>
        </FlexBox>

        <FlexBox gap={10} column={smDown} boxSizing={"border-box"}>

          <CardBox fullWidth column padding={3} boxSizing={"border-box"}>

            <FlexBox fullWidth jcCenter minHeight={185}>

              {(images && (images.length > 0)) ? (
                <img
                  width={300}
                  src={images[activeStep].imageUrl}
                />
              ) : (
                <img
                  width={300}
                  src={ImgNoProduct}
                />
              )}

            </FlexBox>

            <FlexBox fullWidth>
              <MobileStepper
                variant="dots"
                steps={images.length}
                position="static"
                activeStep={activeStep}
                sx={{ flexGrow: 1, backgroundColor: useTheme().palette.background.paper }}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === (images.length === 0 ? images.length : images.length - 1)}>
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                  </Button>
                }
              />
            </FlexBox>
            <InputFiles isLoading={isLoading} setIsLoading={setIsLoading} setImages={setImagesString} buttonText={'Fotos'} />
          </CardBox>


          <FlexBox fullWidth column gap={10}>
            <TextField
              size="small"
              type="text"
              name="name"
              sx={{ width: '100%' }}
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <TextField
              select
              size="small"
              name="category"
              sx={{ width: '100%' }}
              label="Categoria"
              value={categoryId}
              onChange={e => setCategoryId(parseInt(e.target.value))}
              required
            >
              {categories.filter(x => x.isActive).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {brands.length > 0 ?
              <TextField
                select
                sx={{ width: '100%' }}
                size="small"
                name="mark"
                label="Marca"
                value={markId}
                onChange={e => setMarkId(parseInt(e.target.value))}
              >
                {brands.filter(x => x.isActive).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> :
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Marca"
                disabled
              />
            }
            {models.length > 0 ?
              <TextField
                select
                size="small"
                name="model"
                sx={{ width: '100%' }}
                label="Modelo"
                value={modelId}
                onChange={e => setModelId(parseInt(e.target.value))}
              >
                {models.filter(x => x.isActive).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> :
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Modelo"
                disabled
              />
            }
            {materials.length > 0 ?
              <TextField
                select
                size="small"
                name="material"
                sx={{ width: '100%' }}
                label="Material"
                value={materialId}
                onChange={e => setMaterialId(parseInt(e.target.value))}
              >
                {materials.filter(x => x.isActive).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              :
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Material"
                disabled
              />
            }
            <TextField
              select
              size="small"
              name="gender"
              sx={{ width: '100%' }}
              label="Gênero"
              value={gender}
              onChange={e => setGender(parseInt(e.target.value))}
            >
              {genders.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <FlexBox aiCenter jcEnd>
              <ButtonStatusUpdate status={isActive} setStatus={setIsActive} />
            </FlexBox>
          </FlexBox>
        </FlexBox>

      </FlexBox>
    </LayoutBaseDePagina>
  )
}