


/**
  * Formata o CPF.
  * @param rawValue Valor bruto (string contendo apenas números).
  * @returns CPF formatado (XXX.XXX.XXX-XX).
*/

export function formatCPF(rawValue: string): string {

  return rawValue.replace(
    /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/,
    (_, p1, p2, p3, p4) =>
      `${p1}${p2 ? `.${p2}` : ""}${p3 ? `.${p3}` : ""}${p4 ? `-${p4}` : ""
      }`
  );
};