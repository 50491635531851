import { Box, Button, Icon, TableContainer, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Environment } from "../../../environment";
import { CardBox } from "../../layouts/CardBox";
import FlexBox from "../../layouts/FlexBox";

interface IFerramentasDaListagemProps {
    placeBusca?: string;
    textoDaBusca?: string;
    mostrarInputBusca?: boolean;
    aoMudarTextDeBusca?: (novoTexto: string) => void;
    textoBotaoNovo?: string;
    mostrarBotaoNovo?: boolean;
    aoClicarEmNovo?: () => void;
}



export const FerramentasDaListagem: React.FC<IFerramentasDaListagemProps> = ({
    placeBusca = Environment.INPUT_DE_BUSCA,
    textoDaBusca = '',
    mostrarInputBusca = false,
    aoMudarTextDeBusca,
    aoClicarEmNovo,
    textoBotaoNovo = 'Novo',
    mostrarBotaoNovo = true,
}) => {

    const theme = useTheme();
    const smDown = useMediaQuery(() => theme.breakpoints.down('sm'));
    return (
        <TableContainer component={CardBox}>
            <FlexBox
                fullWidth
                jcBetween
                aiBetween
                minHeight={theme.spacing(5)}
                marginX={0}
                padding={1}
                paddingX={2}
                gap={5}
            >
                {mostrarInputBusca && (
                    <TextField
                        size='small'
                        value={textoDaBusca}
                        onChange={(e) => aoMudarTextDeBusca?.(e.target.value)}
                        placeholder={placeBusca}
                    />
                )}
                <Box flex={1} display={'flex'} justifyContent={'end'}>
                    {mostrarBotaoNovo && (
                        <Button
                            color="primary"
                            disableElevation
                            variant="contained"
                            onClick={aoClicarEmNovo}
                            startIcon={<Icon>add</Icon>}
                        >
                            {textoBotaoNovo}
                        </Button>
                    )}
                </Box>
            </FlexBox>
        </TableContainer>
    );
}