import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import FlexBox from '../../layouts/FlexBox';
import { Button, Icon } from '@mui/material';


export interface IInputBaseAutoCompleteAddProps {
  modal?: ReactNode;
  isOpenAdd: boolean;
  children: ReactNode;
  setIsOpenAdd: Dispatch<SetStateAction<boolean>>;
};

export const InputBaseAutoCompleteAdd: React.FC<IInputBaseAutoCompleteAddProps> = (props) => {
  const { children, setIsOpenAdd, modal } = props;

  return (
    <FlexBox row fullWidth gap={5}>

      {children}

      <Button
        variant='outlined'
        size='small'
        onClick={() => setIsOpenAdd(true)}
      >
        <Icon>
          add
        </Icon>
      </Button>

     
      {modal}

    </FlexBox>
  )
};

