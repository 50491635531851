import React from 'react';
import FlexBox from './FlexBox';
import { Box, Tooltip, Typography } from '@mui/material';


interface IShowColorProps {
  colorCode: string | undefined;
  colorName: string | undefined;
  isColor?: string | undefined;
};

export const ShowColor: React.FC<IShowColorProps> = (props) => {
  const { colorCode, colorName, isColor } = props;

  const isUndefined = isColor !== undefined;

  return (
    <Tooltip title={colorName ? colorName : 'Não possui cor'}>
      <Box>
        {isUndefined ? (

          <FlexBox
            width={20}
            height={20}
            borderRadius={50}
            bgcolor={colorCode}
            border='1px solid black'
          />

        ) : (
          <Typography fontWeight={400}>
            -
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};