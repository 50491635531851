import { Chip, Switch, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import FlexBox from '../../../../../shared/components/layouts/FlexBox';
import { IMarkProduct } from '../../../../../shared/models/MarkProduct';
import { useListagemDeCadastrosContext } from '../../../../../shared/contexts';
import { BaseModal } from '../../../../../shared/components/modals/BaseModal';
import { MarkProductService } from '../../../../../shared/services/api/cadastros/MarkProductService';
import { MarkProduct, MarkProductInsert, MarkProductUpdate } from '../../../../../shared/models/classes/categoryModels/markProducts/MarkProduct';
import { ButtonStatusUpdate } from '../../../../../shared/components/buttons/ButtonStatusUpdate';


interface ModalAddEditMarkProps {
  isOpenAdd: boolean;
  rowEdit: IMarkProduct;
  setIsOpenAdd: Dispatch<SetStateAction<boolean>>;
  setRows: Dispatch<SetStateAction<IMarkProduct[]>>
}

export const ModalAddEditMark: React.FC<ModalAddEditMarkProps> = (props) => {
  const { isOpenAdd, setIsOpenAdd, setRows, rowEdit } = props;
  const { isLoading, startLoading, stopLoading } = useListagemDeCadastrosContext();

  const modelEmpty = new MarkProduct({});

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);

  const isUpdate = rowEdit.id !== 0;


  useEffect(() => {
    if (isUpdate) {
      setName(rowEdit.name);
      setStatus(rowEdit.isActive);
    } else {
      setName(modelEmpty.name);
      setStatus(modelEmpty.isActive);
    }
  }, [rowEdit]);


  function handleSave() {
    const modelInsert = new MarkProductInsert({ name: name });
    const modelUpdate = new MarkProductUpdate({ name: name, isActive: status, id: rowEdit.id });
    startLoading();

    if (isUpdate) {
      MarkProductService.update(modelUpdate).then(result => {  // Update
        stopLoading();

        if (result.success) {
          setRows(prev => [...prev.map(item => item.id === result.data.id ? result.data : item)]);
          handleClose();
        }
        else {
          alert(result.errors[0]);
        }
      });
    }
    else {
      MarkProductService.insert(modelInsert).then(result => {  // Insert
        stopLoading();

        if (result.success) {
          setRows(result.data);
          handleClose();
        }
        else {
          alert(result.errors[0]);
        }
      });
    }

  };

  function handleClose() {
    setIsOpenAdd(false);
  };


  return (
    <BaseModal
      viewButtons
      minWidth={300}
      open={isOpenAdd}
      isLoading={isLoading}
      handleSave={handleSave}
      handleClose={handleClose}
      title={isUpdate ? 'Editar Marca' : 'Adicionar Marca'}
    >
      <FlexBox column gap={10}>
        <TextField
          fullWidth
          label='Nome do Marca'
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        {isUpdate && (
          <FlexBox fullWidth jcEnd>
            <ButtonStatusUpdate setStatus={setStatus} status={status} />
          </FlexBox>
        )}
      </FlexBox>
    </BaseModal>
  )
}
