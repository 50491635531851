import { IResponseAmount } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ICategoryProduct } from "../../../models/CategoryProduct";
import { ICategoryProductInsert, ICategoryProductUpdate } from "../../../models/Dtos/CategoryProductDto";
import { ISale } from "../../../models/Sale";
import { Api } from "../axios-config";


const insert = async (categoryProduct: ICategoryProductInsert): Promise<IResponseAPI<ICategoryProduct[]>> => {

    const { data } = await Api.post('/otica/CategoryProduct', categoryProduct).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (categoryProduct: ICategoryProductUpdate): Promise<IResponseAPI<ICategoryProduct>> => {

    const { data } = await Api.put('/otica/CategoryProduct', categoryProduct).catch(data => {
        return data.response;
    });

    return data;
};

const deleteById = async (id: number): Promise<IResponseAPI<ISale>> => {

    const { data } = await Api.delete(`/otica/CategoryProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (): Promise<IResponseAPI<IResponseAmount<ICategoryProduct[]>>> => {

    const { data } = await Api.get("/otica/CategoryProduct?pageSize=50").catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<ICategoryProduct[]>>> => {

    const { data } = await Api.get(`/otica/CategoryProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const CategoryProductService = {
    insert,
    update,
    getAll,
    getById,
    deleteById,
};