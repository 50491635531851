import dayjs from "dayjs";
import { SetURLSearchParams, useNavigate } from "react-router-dom";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Icon, Typography, LinearProgress, Pagination, Chip, useTheme, useMediaQuery, Theme } from "@mui/material";
import { ISale } from "../../../shared/models/Sale";
import { statusBar } from "../components/StatusBar";
import { Environment } from "../../../shared/environment";
import CurrencyFormat from "../../../shared/helps/CurrencyFormat";
import { CardBox } from "../../../shared/components/layouts/CardBox";
import FlexBox from "../../../shared/components/layouts/FlexBox";


interface SectionTableProps {
  rows: ISale[];
  setRows: React.Dispatch<React.SetStateAction<ISale[]>>;
  isLoading: boolean;
  totalCount: number;
  setSearchParams: SetURLSearchParams;
  busca: string;
  pagina: number;
};


export const SectionTableVendas: React.FC<SectionTableProps> = ({ rows, setRows, isLoading, totalCount, setSearchParams, busca, pagina }) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const handleDetail = (id: number) => {
    navigate(`/vendas/detalhe/${id}`)
  };

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (

    <TableContainer component={CardBox} column>
      <Table size={"medium"}>
        <TableHead>

          <TableRow>
            {smDown ? null : <TableCell align="center"><Icon sx={{ fontSize: '15px' }}>sell</Icon>Codigo</TableCell>}
            <TableCell align="center">Data</TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>

        </TableHead>


        <TableBody>
          {rows && rows.map(row => (
            <TableRow key={row.id}
              onClick={() => handleDetail(row.id)}
              sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d1a' }, transition: '.3s' }}
            >
              {smDown ? null : <TableCell align="center">
                <Typography>
                  {row.id}
                </Typography>
              </TableCell>}
              <TableCell align="center">
                <Typography>
                  {dayjs(row.dateCreate).format(smDown ? 'DD/MM/YY' : 'DD / MM / YYYY')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color={theme.palette.success.light} fontWeight={500}>
                  {CurrencyFormat(row.total)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Chip
                  variant="outlined"
                  label={statusBar(row.status, smDown).label}
                  sx={{

                    border: `1px solid ${statusBar(row.status, smDown).noChipColor}`,
                    // backgroundColor: statusBar(row.status, smDown).bgColor,
                    color: statusBar(row.status, smDown).noChipColor,
                    fontWeight: 600,
                  }}
                  size={"small"}
                />
              </TableCell>
            </TableRow>
          ))}

        </TableBody>

        {totalCount == 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
      </Table>
      <FlexBox padding={2}>
        {isLoading && (
          <TableRow>
            <LinearProgress variant='indeterminate' />
          </TableRow>
        )}
        {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
          />
        )}
      </FlexBox>
    </TableContainer>
  )
}
