import { useEffect, useState } from "react"
import { BaseModal } from "../../../../shared/components/modals/BaseModal";
import { Box, Button, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import { IBranch } from '../../../../shared/models/Branch';
import { Env } from '../../../../shared/environment';
import { AddressService } from "../../../../shared/services/api/Address/AddressService";
import { LocationService } from "../../../../shared/services/api/location/LocationService";


interface BaseModalProps {
  branch: IBranch,
  handleClose: () => void,
  open: boolean,
};

export const ModalEditAddress: React.FC<BaseModalProps> = (props) => {
  const { branch, handleClose, open } = props;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [complement, setComplement] = useState("");
  const [reference, setReference] = useState("");
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    setIsLoading(true)
    if (branch != undefined) {
      setCep(branch.address?.cep)
      setStreet(branch.address?.street)
      setNumber(branch.address?.number)
      setDistrict(branch.address?.district)
      setCity(branch.address?.city)
      setState(branch.address?.state)
      setComplement(branch.address?.complement)
      setReference(branch.address?.reference)
      setCountry(branch.address?.country)
      setLatitude(branch.address?.latitude)
      setLongitude(branch.address?.longitude)
    }
    setIsLoading(false)
  },[])
  useEffect(() => {
    getCep()
  }, [cep])


  const getCep = () => {
    if (cep?.length > 7) {
      LocationService.getCep(cep).then((data) => {
        if (data.success && !!data.data) {
          setStreet(data.data[0].street)
          setDistrict(data.data[0].district)
          setCity(data.data[0].city)
          setState(data.data[0].state)
          setLatitude(data.data[0].latitude)
          setLongitude(data.data[0].longitude)
          setCountry(data.data[0].country)
        }
      })
    }
  }
  const handlerSubmit = () => {
    setIsLoading(true)
    const address = {
      Id: branch?.address.id,
      cep: cep,
      street: street,
      district: district,
      city: city,
      state: state,
      country: country,
      number: number,
      complement: complement,
      reference: reference,
      latitude: latitude,
      longitude: longitude
    }
    AddressService.updateAddress(address).then((data)=>{
        if(data.success)
        {
          alert("Endereço inserido com sucesso!")
          handleClose()
        }
    })
    setIsLoading(false)
  }


  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={"Endereço: " + branch?.name}
      minWidth={300}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600} marginTop={2}
        paddingTop={1}
        paddingBlock={1}
      >
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <TextField size="small"
              label='Cep'
              value={cep}
              onChange={e => setCep(e.target.value)}
            />
            <TextField size="small"
              label='Endereço'
              value={street}
              onChange={e => setStreet(e.target.value)}
            />
            <TextField size="small"
              label='Numero'
              value={number}
              onChange={e => setNumber(e.target.value)}
            />
            <TextField size="small"
              label='Bairro'
              value={district}
              onChange={e => setDistrict(e.target.value)}
            />
            <TextField size="small"
              label='Cidade'
              value={city}
              onChange={e => setCity(e.target.value)}
            />
            <TextField size="small"
              label='Estado'
              value={state}
              onChange={e => setState(e.target.value)}
            />
            <TextField size="small"
              label='Complemento'
              value={complement}
              onChange={e => setComplement(e.target.value)}
            />
            <TextField size="small"
              label='Referencia'
              value={reference}
              onChange={e => setReference(e.target.value)}
            />
          </Stack>
        </Box>
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={1}
      >
        <Button onClick={handleClose} variant='outlined'>Voltar</Button>
        <Button disabled={isLoading} variant='contained' onClick={() => handlerSubmit()}>Salvar</Button>
      </Stack>
    </BaseModal>
  )
};
