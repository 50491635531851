import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ChangeEvent, useState } from "react";
import FlexBox from "../../layouts/FlexBox";

interface InputFilesProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setImages: React.Dispatch<React.SetStateAction<string[]>>;
    isLoading: boolean;
    buttonText: string;
    disable?: boolean;
}

export const InputFiles = ({ buttonText, isLoading, setIsLoading, setImages, disable }: InputFilesProps) => {
    const [size, setSize] = useState<number>(0);


    const handleImage = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event.target.files;
        if (files != null) {
            let amount = 0
            for (var i = 0; i < files.length; ++i)
                amount += (files[i].size ?? 0)
            setSize(Math.ceil(amount / 1000))
        }
        getImg(files)

    }



    function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (event) => {
                img.src = event.target?.result as string;
            };

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                // Calcular nova largura e altura mantendo a proporção
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                // Ajustar o tamanho do canvas
                canvas.width = width;
                canvas.height = height;

                // Desenhar a imagem redimensionada no canvas
                ctx?.drawImage(img, 0, 0, width, height);
                // Converter o canvas para Base64
                const base64String = canvas.toDataURL('image/png').split(',')[1];
                resolve(`data:image/png;base64,${base64String}`)
            };

            reader.onerror = () => {
                reject(new Error("Erro ao ler o arquivo."));
            };

            reader.readAsDataURL(file);

        });
    }

    const getImg = async (files: FileList | null) => {
        setImages([])
        if (files != null && files != undefined) {
            setIsLoading(true)
            for (let i = 0; i < files.length; ++i) {
                const img = await resizeImage(files[i], 600, 600);
                setImages(prev => [...prev, img])
            }
            setIsLoading(false);
        }
    }

    return (
        <FlexBox gap={4} column>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                disabled={isLoading || disable}
                startIcon={<CloudUploadIcon />}
            >
                {buttonText}
                <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleImage(event)}
                    multiple
                />
            </Button>
            <FlexBox jcBetween>
                <Typography fontSize={12}>{size} Kb</Typography>
                <Typography fontSize={12}>.png .jpeg .jpg</Typography>
            </FlexBox>
        </FlexBox>
    )
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});