import { cyan } from '@mui/material/colors';
import { eduFlex } from './colors';

export const createPalette = () => {
  return {


    primary: {
      main: eduFlex.titleColor,
      dark: eduFlex.activeColor,
      light: eduFlex.bodyColor,
      contrastText: "#ffffff",
    },

    secondary: {
      main: cyan[500],
      dark: cyan[400],
      light: cyan[300],
      contrastText: "#ffffff",
    },

    background: {
      paper: "#ffffff",
      default: eduFlex.bodyColor,
      secundary: eduFlex.secundaryBgColor,
    },
  };
}
