import { Autocomplete, TextField, CircularProgress, Icon, Button } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ModalAutoCompleteAddLaboratory } from './sections/ModalAutoCompleteAddLaboratory';
import { LaboratoryService } from '../../../services/api/laboratory/LaboratoryService';
import { ILaboratory } from '../../../../shared/models/Laboratory';
import { useDebounce } from '../../../../shared/hooks';
import FlexBox from '../../layouts/FlexBox';
import { InputBaseAutoCompleteAdd } from './InputBaseAutoComplete';


export interface IOptionLaboratory {
  label: string,
  id: number
};

interface AutoCompleteLaboratoryAddProps {
  setLaboratorySelect: Dispatch<SetStateAction<ILaboratory | undefined>>;
};

export const AutoCompleteLaboratoryAdd: React.FC<AutoCompleteLaboratoryAddProps> = (props) => {
  const { setLaboratorySelect } = props;

  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [laboratoryName, setLaboratoryName] = useState('');
  const [dbLaboratory, setDbLaboratory] = useState<ILaboratory[]>([]);
  const [options, setOptions] = useState<IOptionLaboratory[]>([]);

  const [isOpenAdd, setIsOpenAdd] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      LaboratoryService.getFiltered().then((result) => {
        if (result.success) {
          const laboratory = result!.data.data;
          setDbLaboratory(laboratory);
          setOptions(laboratory.filter(x => x.isActive).map(p => {
            return {
              id: p.id,
              label: p.name
            }
          }))
        } else {
          alert(result.errors[0]);
        };
        setIsLoading(false);
      });
    });
  }, [laboratoryName, debounce]);


  return (
    <InputBaseAutoCompleteAdd
      setIsOpenAdd={setIsOpenAdd}
      isOpenAdd={isOpenAdd}
      
      modal={isOpenAdd &&
        <ModalAutoCompleteAddLaboratory
          isOpenAdd={isOpenAdd}
          isLoading={isLoading}
          setOptions={setOptions}
          setRows={setDbLaboratory}
          setIsOpenAdd={setIsOpenAdd}
          setIsLoading={setIsLoading}
        />
      }
    >
      <Autocomplete
        disablePortal
        openText="Abrir"
        closeText="Fechar"
        noOptionsText="Sem opções"
        loadingText="Carregando..."
        options={options}
        sx={{ width: '100%' }}
        onChange={(_, value) => {
          if (value) {
            setLaboratorySelect(dbLaboratory.find(x => x.id == value.id))
          };
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            //  label="."
            placeholder='Selecionar um Laboratório'
            variant='outlined'
            size='small'
            value={laboratoryName}
            onChange={(e) => {
              setLaboratoryName(e.target.value);
            }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </InputBaseAutoCompleteAdd>
  )
};
