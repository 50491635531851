import React, { useState } from 'react';
import { TextField, Typography, Button, CircularProgress } from '@mui/material';
import FlexBox from '../../shared/components/layouts/FlexBox';
import ImgCheck from '../../shared/assets/icons/icon-check.png';
import ImgClose from '../../shared/assets/icons/icon-close.png';
import Logo from "../../shared/assets/images/OticaSystems_roxo.png";
import { ValidatePasswordContent } from './components';
import { AuthService } from '../../shared/services/api/auth/AuthService';
import { useLocation } from 'react-router-dom';

export const Register = () => {

  const [isLoading, setIsLoading] = useState(false);

  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [nameCompanyError, setNameCompanyError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const path = useLocation()
  const hash = path.search.replace("?hash=", "")
  const [validadeInput, setValidateInput] = useState({
    case: false,
    number: false,
    character: false,
    length: false
  });

  const secureText = (password: string) => {

    const regexSpecialCharacter = /.*[!@#$%^&*(),.?":{}|<>].*/;
    const length = password.length >= 8;
    const regexUppercase = /[A-Z]/;
    const regexLowercase = /[a-z]/;
    const regexNumber = /[0-9]/;

    setValidateInput({
      case: regexUppercase.test(password) && regexLowercase.test(password),
      character: regexSpecialCharacter.test(password),
      number: regexNumber.test(password),
      length: length,
    })
  };

  const handleResetStates = () => {

    setConfirmPasswordError('');
    setConfirmPassword('');
    setPasswordError('');
    setPassword('');
    setEmailError('');
    setEmail('');
    setPhone('')
    setName('');
    setNameCompany('');
  };

  function aoClicarEmRegistrar() {
    setIsLoading(true);
    confirmPassword != password && setConfirmPasswordError('As senhas devem coincidir!');
    var regexEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if (confirmPassword == '' ||
      password == '' ||
      name == '' ||
      nameCompany == '' ||
      phone == '' ||
      email == '' ||
      !regexEmail.test(email)) {
      if (!regexEmail.test(email)) {
        setEmailError('Email invalido!')
      } else {
        setConfirmPasswordError('Todos os campos são obrigatório!');
      }
    } else {
      if (validadeInput.case && validadeInput.character && validadeInput.length && validadeInput.number) {
        AuthService.register(name, email, nameCompany, password, phone, hash)
          .then((result) => {
            if (result.success) {
              setIsLoading(false);
              alert("Conta criada com sucesso! Por favor confirmar em seu email.");
              handleResetStates();
            } else {
              setConfirmPasswordError(result.errors[0])
            }
          });
      } else {
        setConfirmPasswordError('Senha fraca!');
      }
    }
    setIsLoading(false);
  };

  return (
    <FlexBox
      jcCenter aiCenter height="100vh" fullWidth
      sx={{ background: "#F4F7FE" }}
    >
      <FlexBox aiCenter jcCenter column gap={20} paddingX={4}>
        <img style={{ marginBottom: 10 }} width={200} src={Logo} />

        <TextField
          fullWidth
          type='text'
          size='small'
          value={name}
          label='Seu nome'
          error={!!nameError}
          disabled={isLoading}
          //helperText={nameError}
          onKeyDown={() => setNameError('')}
          onChange={e => setName(e.target.value)}
        />

        <TextField
          fullWidth
          type='text'
          size='small'
          value={phone}
          label='Telefone'
          error={!!phoneError}
          disabled={isLoading}
          //helperText={nameError}
          onKeyDown={() => setPhoneError('')}
          onChange={e => setPhone(e.target.value)}
        />

        <TextField
          fullWidth
          type='text'
          size='small'
          value={nameCompany}
          error={!!nameCompanyError}
          disabled={isLoading}
          label='Nome da Ótica'
          //helperText={nameCompanyError}
          onKeyDown={() => setNameCompanyError('')}
          onChange={e => setNameCompany(e.target.value)}
        />

        <TextField
          fullWidth
          type='email'
          size='small'
          label='Email'
          value={email}
          disabled={isLoading}
          error={!!emailError}
          helperText={emailError}
          onKeyDown={() => setEmailError('')}
          onChange={e => setEmail(e.target.value)}
        />

        <TextField
          fullWidth
          label='Senha'
          type='password'
          size='small'
          value={password}
          disabled={isLoading}
          error={!!passwordError}
          //  helperText={ValitatePasswordErrorFunction()}
          onKeyDown={() => setPasswordError('')}
          onChange={e => { secureText(e.target.value); setPassword(e.target.value); }}
        />

        <ValidatePasswordContent>
          <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: 1 }}>
            Sua senha deve ter:
          </p>
          <div>
            <img src={validadeInput.length ? ImgCheck : ImgClose} />
            <p className="lines">8 caracteres</p>
          </div>

          <div>
            <img src={validadeInput.number ? ImgCheck : ImgClose} />
            <p className="lines">Números</p>
          </div>

          <div>
            <img src={validadeInput.case ? ImgCheck : ImgClose} />
            <p className="lines">Letra maiúscula e minúscula</p>
          </div>

          <div>
            <img src={validadeInput.character ? ImgCheck : ImgClose} />
            <p className="lines">Caracteres Especiáis</p>
          </div>
        </ValidatePasswordContent>

        <TextField
          fullWidth
          label='Confirmar Senha'
          type='password'
          size='small'
          value={confirmPassword}
          disabled={isLoading}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
          onKeyDown={() => setConfirmPasswordError('')}
          onChange={e => setConfirmPassword(e.target.value)}
        />

        <Button
          type="submit"
          variant="contained"
          onClick={e => { e.preventDefault(); aoClicarEmRegistrar() }}
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
          size="large"
        >
          Registrar
        </Button >
        <FlexBox column gap={15}>

          <Typography fontSize='14px' textAlign='center' marginBottom={1}>
            Já tenho uma conta. <Typography component='a' href="/login" marginLeft={5}>Fazer Login</Typography>
          </Typography>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
};
