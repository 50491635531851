import { Box, Button, CircularProgress, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";

import { ModalAddPrescrition } from "../../../pages/clients/components/detalheDeClientes/modals/ModalAddPrescrition";
import { AutoCompleteLaboratory } from "./components/AutoCompleteLaboratory";
import { SaleService } from "../../services/api/sale/SaleService";
import { IPrescription } from "../../models/Prescription";
import { ISale, ISaleInsert } from "../../models/Sale";
import { Clientes } from "../addVendasModal/sections";
import { ILaboratory } from "../../models/Laboratory";
import { InputFile } from "../inputs/input-file/InputFile";
import { BaseModal } from "../modals/BaseModal";
import { Env } from "../../environment";
import { OrderServiceService } from "../../services/api/orderService/OrderServiceService";
import { IOrderServiceInsert } from "../../models/OrderServiceModel";
import { AutoCompleteLaboratoryAdd } from "../inputs/autoComplete/AutoCompleteLaboratoryAdd";

interface BaseModalProps {
  setSale: Dispatch<SetStateAction<ISale | undefined>>;
  handleClose: () => void;
  open: boolean;
  sale: ISale;
};


export const ModalAddOrder: React.FC<BaseModalProps> = (props) => {
  const { handleClose, setSale, sale, open } = props;

  const [laboratorySelect, setLaboratorySelect] = useState<ILaboratory | undefined>();
  const [forecast, setForecast] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [prescrition, setPrescrition] = useState<IPrescription>();
  const [openPrescrition, setOpenPrescrition] = useState(false);
  const [description, setDescription] = useState('');
  const [base64, setBase64] = useState<string>('');
  const [cost, setCost] = useState('0');

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);


  const handleClosePrecristion = () => {
    setOpenPrescrition(false)
  };


  function insertOS() {

    if (laboratorySelect !== undefined) {

      const OS: IOrderServiceInsert = {
        prescription: prescrition,
        laboratoryId: laboratorySelect.id,
        description: description,
        imageUrl: base64,
        forecast: new Date(forecast),
        saleId: sale?.id,
        cost: parseFloat(cost),
      }

      setIsLoading(true);

      OrderServiceService.insert(OS).then(data => {
        if (data.success) {
          alert("O.S cadastrada com sucesso!");
          setSale(data.data);
          closeModal();
        }
        else {
          alert(data.errors[0]);
        }
        setIsLoading(false);
      });
    }
    else {
      alert("Obrigatório inserir Laboratório!");
    }
  };

  const handlerAddPrescrition = (prescriptionAdd: IPrescription | undefined) => {
    setPrescrition(prescriptionAdd);
  };

  function handleAoClicarEmSalvar() {

    if (prescrition != undefined) {
      insertOS();
    }
    else {
      alert("Obrigatório inserir Receita!")
    }
  };

  const closeModal = () => {
    setLaboratorySelect(undefined);
    setDescription('');
    setForecast(dayjs(new Date()).format('YYYY-MM-DD'));
    setCost('');
    setPrescrition(undefined);
    handleClose();
  };

  return (
    <BaseModal
      handleClose={closeModal}
      open={open}
      title={'Adicionar O.S.'}
      minWidth={350}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600} marginTop={2}
        paddingTop={1}
        paddingBlock={1}
      >
        <Stack
          direction='column'
          overflow='auto'
          width={"100%"}
          marginBottom={2}
          alignItems={"end"}
        >

        </Stack>
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <TextField size="small"
              type="Date"
              label='Previsão'
              value={forecast}
              onChange={e => setForecast(e.target.value)}
            />
            <TextField size="small"
              label='Descrição'
              multiline
              rows={4}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />

            {/* <AutoCompleteLaboratory
              setLaboratorySelect={setLaboratorySelect}
            /> */}

            <AutoCompleteLaboratoryAdd
              setLaboratorySelect={setLaboratorySelect}
            />

            <InputFile buttonText="Adicionar imagem" setImage={setBase64} />
          </Stack>
        </Box>

      </Stack>
      <Box display='flex' justifyContent='start' width='100%'>
        <Button
          variant="outlined"
          onClick={() => setOpenPrescrition(true)}
        >{prescrition ? 'Editar receita' : 'Adicionar receita'}</Button>
      </Box>

      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={1}
      >
        <Button onClick={closeModal}>Fechar</Button>
        <Button
          variant='contained'
          disabled={isLoading}
          onClick={handleAoClicarEmSalvar}
          endIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : null}
        >
          Salvar
        </Button>
      </Stack>
      <ModalAddPrescrition
        addMode
        open={openPrescrition}
        prescrition={prescrition}
        handleClose={handleClosePrecristion}
        setPrescrition={handlerAddPrescrition}
      />
    </BaseModal>
  )
};
