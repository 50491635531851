import * as React from 'react';
import Button from '@mui/material/Button';
import { FormControl, FormControlLabel, Icon, Menu, Radio, RadioGroup } from '@mui/material';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SetURLSearchParams } from 'react-router-dom';

interface FilterButtonProps {
    setSearchParams: SetURLSearchParams;
    params: string
    busca: string;
    dateInit: string;
    dateEnd: string;
    handleClick: () => void;
}

export function FilterStatusSale({ dateInit, dateEnd, busca, params, setSearchParams, handleClick }: FilterButtonProps) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleChange = (status: string) => {
        setSearchParams({ busca, pagina: '1', status: status, dateInit: dateInit, dateEnd: dateEnd }, { replace: true });
        handleClose()
    };

    function handleMenuItemClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
        handleClick()
    }

    return (
        <>
            <Button
                size="medium"
                variant='text'
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuItemClick}
            >
                <FilterAltOutlinedIcon
                    sx={{ marginRight: 1 }}
                />
                Filtros
                <Icon>arrow_drop_down</Icon>
            </Button>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            ><FlexBox fullWidth padding={1} aiCenter jcCenter>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={params}
                            onChange={(_, status) => handleChange(status)}
                        >
                            <FormControlLabel value={0} control={<Radio />} label={"Todos"} />
                            <FormControlLabel value={1} control={<Radio />} label={"Leads"} />
                            <FormControlLabel value={2} control={<Radio />} label={"Orçamento"} />
                            <FormControlLabel value={3} control={<Radio />} label={"Inadimplencia"} />
                            <FormControlLabel value={4} control={<Radio />} label={"Cliente"} />
                        </RadioGroup>
                    </FormControl>
                </FlexBox>
            </Menu>
        </>
    );
}