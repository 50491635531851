import { ICategoryProduct } from "../../CategoryProduct";
import { ICategoryProductInsert, ICategoryProductUpdate } from "../../Dtos/CategoryProductDto";

export class CategoryProduct implements ICategoryProduct {
  id: number;
  name: string;
  isActive: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<ICategoryProduct> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};


export class CategoryProductInsert implements ICategoryProductInsert {
  name: string;

  constructor({ name = "" }: Partial<ICategoryProductInsert> = {}) {
    this.name = name;
  }
};


export class CategoryProductUpdate implements ICategoryProductUpdate {
  id: number;
  name?: string;
  isActive?: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<ICategoryProductUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};