import { Modal, Box, Typography, Theme, SxProps, Button, Stack, CircularProgress } from "@mui/material"
import { Dispatch, FormEvent, ReactNode, SetStateAction } from "react";

interface BaseModalProps {
  open: boolean;
  title?: string;
  textFechar?: string;
  textSalvar?: string;
  isLoading?: boolean;
  children: ReactNode;
  viewButtons?: boolean;
  handleSave?: (e: FormEvent) => void;
  handleClose?: () => void;
  minWidth: number | string;
};

export const BaseModal: React.FC<BaseModalProps> = (props) => {
  const { open, viewButtons = false, handleClose, handleSave,
    children, title, minWidth, isLoading, textFechar, textSalvar
  } = props;


  const style: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: minWidth,
    bgcolor: 'background.paper',
    boxShadow: 0,
    p: 4,
    borderRadius: 8,
  };


  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Box id="modal-modal-description" sx={{ mt: 2 }}>
          {children}
        </Box>


        {viewButtons && (
          <Stack
            direction='row'
            justifyContent='space-between'
            marginTop={3}
          >
            <Button
              onClick={handleClose}
              variant='outlined'
              size="small"
            >
              {textFechar ? textFechar : "Voltar"}
            </Button>

            <Button
              disabled={isLoading}
              variant='contained'
              onClick={handleSave}
              size="small"
              endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
            >
              {textSalvar ? textSalvar : "Salvar"}
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>

  )
};