import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ClientesService } from '../../../services/api/clientes/ClientesService';
import { useDebounce } from '../../../hooks';

interface Option {
  label: string,
  id: number
};

interface AutoCompleteClientes {
  isLoading: boolean;
  setClientId: React.Dispatch<React.SetStateAction<number>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AutoCompleteClientes: React.FC<AutoCompleteClientes> = ({ isLoading, setIsLoading, setClientId }) => {

  const options: Option[] = [
    { label: 'Pesquisar Cliente', id: 0 },
  ];

  const { debounce } = useDebounce();
  const [clientName, setClientName] = useState('');
  const [clienOptions, setClienOptions] = useState<Option[]>(options);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ClientesService.get(1, clientName)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            const validClients = result!.data.data
              .filter(cliente => cliente.id != undefined)
              .map(cliente => ({ id: cliente.id!, label: cliente.name }));

            setClienOptions(validClients);
          }
        });
    });
  }, [clientName, debounce])


  return (
      <Autocomplete
        disablePortal
        openText="Abrir"
        closeText="Fechar"
        noOptionsText="Sem opções"
        loadingText="Carregando..."

        options={clienOptions}
        sx={{ width: 300, marginTop: 2 }}
        onChange={(_, value) => {
          if (value) {
            setClientId(value.id);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            //  label="."
            placeholder='Pesquisar...'
            variant='standard'
            size='small'
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
  )
};
