import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, useMediaQuery } from "@mui/material";
import FlexBox from "../../shared/components/layouts/FlexBox";
import ImgCheck from '../../shared/assets/icons/icon-check.png';
import ImgClose from '../../shared/assets/icons/icon-close.png';
import ImgFundo from "../../shared/assets/images/fundo-fumaca.png";
import ImgResetPassword from "../../shared/assets/icons/icon-reset-password.png";
import { useState } from "react";
import styled from "styled-components";
import { AuthService } from "../../shared/services/api/auth/AuthService";


export const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const smDown = useMediaQuery('(max-width:600px)');

  const location = useLocation();
  const hash = location.search.replace("?hash=", "");

  const navigate = useNavigate();

  const handlerBack = () => {
    navigate("/login");
  }

  const handlerSubmit = () => {
    setPasswordError('')
    setConfirmPasswordError('')
    if (!validadeInput.case ||
      !validadeInput.character ||
      !validadeInput.length ||
      !validadeInput.number) {
      setPasswordError("Senha fraca!")
    } else if (password != confirmPassword) {
      setConfirmPasswordError("As senhas devem coincidirem!")
    } else {
      setIsLoading(true)
      AuthService.resetPassword(password, hash).then((data) => {
        if (data.success) {
          alert("Senha alterada com sucesso!")
          navigate("/login");
        } else {
          setPasswordError(data.errors[0])
        }
      })
      setIsLoading(false)
    }
  }
  const [validadeInput, setValidateInput] = useState({
    case: false,
    number: false,
    character: false,
    length: false
  })

  const secureText = (password: string) => {

    const regexSpecialCharacter = /.*[!@#$%^&*(),.?":{}|<>].*/;
    const length = password.length >= 8;
    const regexUppercase = /[A-Z]/;
    const regexLowercase = /[a-z]/;
    const regexNumber = /[0-9]/;

    setValidateInput({
      case: regexUppercase.test(password) && regexLowercase.test(password),
      character: regexSpecialCharacter.test(password),
      number: regexNumber.test(password),
      length: length,
    })
  }

  return (
    <Box // Body
      width='100vw' height='100vh'
      display='flex' alignItems='center' justifyContent='center'
      sx={{ background: '#F4F7FE', }}
    >
      <Box  // Container Father(pai)
        borderRadius={7}
        sx={{
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.35)', width: '90%', maxWidth: '900px', transition: 'all 0.6s ease-in-out',

          ...(!smDown && {
            backgroundImage: `url(${ImgFundo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: smDown ? '230px' : '330px',
            backgroundPositionX: '',
          }
          )
        }}
        display='flex' flexDirection='row' alignItems='center' justifyContent='center'
        height='570px'

        position='relative'
      >
        <FlexBox column jcCenter
          width={`${smDown ? '80%' : '70%'}`} height='100%'
        >

          <FlexBox column aiCenter gap="1rem"
            width="100%" height="70%" sx={{ marginTop: smDown ? '-7rem' : '-10rem' }}
          >

            <img
              src={`${ImgResetPassword}`}
              style={{ width: smDown ? '70px' : '110px' }}
            />



            <Typography variant="h3" fontSize={`${smDown ? '20px' : '20px'}`} fontWeight="bold" color="#6b6b6b">
              Redefinição de senha:
            </Typography>

            <Typography fontSize={`${smDown ? '14px' : '15px'}`} color="#6b6b6b">
              Digite sua nova senha:
            </Typography>


            <FlexBox width={smDown ? "100%" : "50%"} column gap="1rem"
              sx={{ margin: '.1rem 0 0 0' }}
            >
              <TextField
                fullWidth
                type='password'
                label='Senha'
                size="small"
                variant="filled"
                value={password}
                disabled={isLoading}
                error={!!passwordError}
                helperText={passwordError}
                onKeyDown={() => setPasswordError('')}
                onChange={e => { secureText(e.target.value); setPassword(e.target.value); }}
              />
              <TextField
                fullWidth
                type='password'
                label='Confirme sua senha'
                size="small"
                variant="filled"
                value={confirmPassword}
                disabled={isLoading}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                onKeyDown={() => setConfirmPasswordError('')}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              <ValidatePasswordContent>
                <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '5px' }}>
                  Sua senha deve ter:
                </p>
                <div>
                  <img src={validadeInput.length ? ImgCheck : ImgClose} />
                  <p className="lines">8 caracteres</p>
                </div>

                <div>
                  <img src={validadeInput.number ? ImgCheck : ImgClose} />
                  <p className="lines">Números</p>
                </div>

                <div>
                  <img src={validadeInput.case ? ImgCheck : ImgClose} />
                  <p className="lines">Letra maiúscula e minúscula</p>
                </div>

                <div>
                  <img src={validadeInput.character ? ImgCheck : ImgClose} />
                  <p className="lines">Caracteres Especiáis</p>
                </div>
              </ValidatePasswordContent>

              <FlexBox
                fullWidth
                row
                gap="1rem"
                sx={{ justifyContent: 'space-between' }}
              >
                <Button variant="outlined"
                  sx={{ borderRadius: "10px" }}
                  onClick={handlerBack}
                >
                  Voltar
                </Button>
                <Button variant="contained"
                  sx={{ borderRadius: "10px" }}
                  onClick={handlerSubmit}
                >
                  Enviar
                </Button>
              </FlexBox>
            </FlexBox>

          </FlexBox>

        </FlexBox>

      </Box>
    </Box >

  );
};
const ValidatePasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-bottom: 10px;

  div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    margin: -12px 0 -12px 0;
  }

  img{
    width: 11px;
    height: 11px;
  }

  .lines{
    font-size: 15px;
    color: #616161;
  }
`;