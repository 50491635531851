import { FormControl,  Icon, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IContact } from "../../../../shared/models/Clients/contact";
import { IContactLeveEnum } from "../../../../shared/models/enums/ContactTypeEnum";
import FlexBox from "../../../../shared/components/layouts/FlexBox";

interface CardContactProps {
  indice: number;
  handleDeleteCellphone: (indice: number) => void;
  rowsCellphones: IContact[];
  setRowsCellphones: React.Dispatch<React.SetStateAction<IContact[]>>
}

export const CardContact: React.FC<CardContactProps> = ({
  indice,
  handleDeleteCellphone,
  setRowsCellphones,
  rowsCellphones,
}) => {
  const [value, setValue] = useState<string>('');
  const [type, setType] = useState<number>(0);

  useEffect(() => {
    getValues()
  }, [])

  const handleChangeNumero = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setRowsCellphones(prevListTel => {
      const novaArray = prevListTel.map((celular, index) => {
        if (index == indice) {
          celular.value = e.target.value;
          celular.type = type as number;
          return { ...celular };
        } else {
          return celular;
        }
      });
      return novaArray;
    });
  }

  const getValues = () => {
    setValue(rowsCellphones[indice].value)
    setType(rowsCellphones[indice].type)
  }

  const handleChangeType = (e: string) => {
    setType(parseInt(e))
    setRowsCellphones(prevListTel => {
      const novaArray = prevListTel.map((celular, index) => {
        if (index == indice) {
          celular.value = value as string;
          celular.type = parseInt(e);
          return { ...celular };
        } else {
          return celular;
        }
      });
      return novaArray;
    });
  }

  return (
    <FlexBox fullWidth>
        <FlexBox fullWidth aiCenter jcBetween>
          <TextField
            value={value}
            onChange={handleChangeNumero}
            size="small"
            fullWidth
            label={type == undefined ? 'Valor' : IContactLeveEnum[type]}
            required
          />
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="simple-select-label">Tipo</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              fullWidth
              value={type}
              onChange={(e) => handleChangeType(e.target.value.toString())}
            >
              <MenuItem value={0}>{IContactLeveEnum[0]}</MenuItem>
              <MenuItem value={1}>{IContactLeveEnum[1]}</MenuItem>
              <MenuItem value={2}>{IContactLeveEnum[2]}</MenuItem>
              <MenuItem value={3}>{IContactLeveEnum[3]}</MenuItem>
              <MenuItem value={4}>{IContactLeveEnum[4]}</MenuItem>
              <MenuItem value={5}>{IContactLeveEnum[5]}</MenuItem>
            </Select>
          </FormControl>
          <div className='button-div'>
            {rowsCellphones.length > 1 ?
              <IconButton size='small'
                onClick={() => handleDeleteCellphone(indice)}
                sx={{ color: "#d62727", background: '#ffe8cf99', '&:hover': { color: '#a7a7a7', transition: '.2s' } }}
              >
                <Icon>delete</Icon>
              </IconButton> : null}
          </div>
        </FlexBox>
    </FlexBox>
  )
}