import { Breadcrumbs, Link, Typography } from '@mui/material'
import React from 'react'

export const LinksNavigations: React.FC = () => {
    return (
        <Breadcrumbs sx={{ margin: 1 }} aria-label="breadcrumb" >

            <Link underline="hover" color="inherit" href="/pagina-inicial">
                Página inicial
            </Link>

            <Link
                underline="hover"
                color="inherit"
                href="/vendas"
            >
                Vendas
            </Link>

            <Typography color="text.primary">
                Detalhe da venda
            </Typography>

        </Breadcrumbs>
    )
}