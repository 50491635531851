import { Button, Divider, Icon, IconButton, MenuItem, MobileStepper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useProductsContext } from "../../../shared/contexts/pages/ProductsContext";
import { FormEvent, useState } from "react";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { IGenderEnum } from "../../../shared/models/enums/GenderTypeEnum";
import { EstoquesService } from "../../../shared/services/api/estoques/EstoquesService";
import { IStockProductInsert } from "../../../shared/models/StockProduct";
import CurrencyFormat from "../../../shared/helps/CurrencyFormat";
import { ModalInsertStockProduct } from "../components/Modal-InsertStockProduct";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { InputFiles } from "../../../shared/components/inputs/input-file/InputFiles";
import { IImageProductInsert } from "../../../shared/models/ImageProduct";
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { InputDataSection } from "../sections/NewProduct-InputData";
import { EstoqueDataSection } from "../sections/NewProduct-EstoqueData";
import { FerramentasDeDetalhe } from "../../../shared/components";
import { CardBox } from "../../../shared/components/layouts/CardBox";

export const NewProduct: React.FC = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { categories, brands, models, materials, setProducts, colors } = useProductsContext()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState<number>(categories[0]?.id ?? 0);
  const [markId, setMarkId] = useState<number | undefined>();
  const [modelId, setModelId] = useState<number | undefined>();
  const [materialId, setMaterialId] = useState<number | undefined>();
  const [gender, setGender] = useState(0);
  const [isOpenStock, setIsOpenStock] = useState(false);
  const [stock, setStock] = useState<IStockProductInsert[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const [errName, setErrName] = useState(false);
  const [errCategory, setErrCategory] = useState(false);


  function handleSubmit(e: FormEvent) {


    if (name.length <= 3) {
      setErrName(true);
      return;
    };

    if (categoryId === 0) {
      setErrCategory(true);
      return;
    };

    e.preventDefault();
    let imagesProduct: IImageProductInsert[] = []
    images.forEach(element => {
      imagesProduct.push({ imageUrl: element })
    });
    const insertProduto = {
      name: name,
      categoryProductId: categoryId,
      gender: gender,
      modelProductId: modelId,
      markProductId: markId,
      materialProductId: materialId,
      stockProducts: stock,
      imageProducts: imagesProduct
    };

    setIsLoading(true);
    EstoquesService.create(insertProduto).then(
      result => {
        setIsLoading(false);
        if (result.success && result.data) {
          alert("Produto cadastrado com sucesso!");
          setProducts(prev => {
            prev.push(result.data);
            return prev
          })
          navigate(-1);
        } else {
          alert(result.errors[0]);
        }
      }
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const removeStock = (index: number) => {
    setStock(prev => {
      return prev.filter((item, i) => { if (i != index) return item })
    })
  }

  const handleOpenStock = () => {
    setIsOpenStock(true);
  }

  const genders = [
    { id: 0, name: IGenderEnum[0] },
    { id: 1, name: IGenderEnum[1] },
    { id: 2, name: IGenderEnum[2] }
  ]

  return (
    <LayoutBaseDePagina
      titulo="Novo Produto"
      buttonToBack
    >

      <CardBox jcEnd paddingY={1.5} paddingX={3}>
        <Button variant="contained"
          type="submit"
          size="small"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? '...' : 'Salvar'}
        </Button>
      </CardBox>


      <FlexBox
        marginTop={5}
        padding={1}
        gap={lgDown ? 70 : 20}
        row={lgDown ? false : true}
        column={lgDown ? true : false}
        jcBetween
      >

        <InputDataSection
          setErrCategory={setErrCategory}
          setMaterialId={setMaterialId}
          setCategoryId={setCategoryId}
          setIsLoading={setIsLoading}
          errCategory={errCategory}
          categories={categories}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          categoryId={categoryId}
          materialId={materialId}
          setModelId={setModelId}
          setErrName={setErrName}
          setMarkId={setMarkId}
          isLoading={isLoading}
          setImages={setImages}
          setGender={setGender}
          materials={materials}
          setName={setName}
          genders={genders}
          modelId={modelId}
          errName={errName}
          models={models}
          brands={brands}
          images={images}
          markId={markId}
          gender={gender}
          name={name}
        />


        {/* <Divider /> */}


        <EstoqueDataSection
          handleOpenStock={handleOpenStock}
          removeStock={removeStock}
          colors={colors}
          smDown={smDown}
          stock={stock}
        />

      </FlexBox>

      <ModalInsertStockProduct
        isOpen={isOpenStock}
        setIsOpen={setIsOpenStock}
        stock={stock}
        setStock={setStock}
      />
    </LayoutBaseDePagina>
  )
}