import { IResponseAPI } from "../../../models";
import { IPlan } from "../../../models/Plan";
import { Api } from "../axios-config";

const getPlans = async (): Promise<IResponseAPI<IPlan[]> | IResponseAPI<IPlan[]>> => {
  const { data } = await Api.get(`/otica/PlanSubscription`).catch((data) => {
    return data.response;
  });
  return data;

};

export const PlanService = {
  getPlans
};