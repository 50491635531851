import { useEffect, useState } from "react"
import { BaseModal } from "../../../../shared/components/modals/BaseModal";
import { Box, Button, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import { IBranch } from '../../../../shared/models/Branch';
import { Env } from '../../../../shared/environment';
import { LocationService } from "../../../../shared/services/api/location/LocationService";
import { BranchService } from "../../../../shared/services/api/branch/BranchService";


interface BaseModalProps {
  branch: IBranch | undefined,
  handleClose: () => void,
  setBranch: React.Dispatch<React.SetStateAction<IBranch | undefined>>
  open: boolean,
};

export const ModalAddAddress: React.FC<BaseModalProps> = (props) => {
  const { branch, handleClose, open, setBranch } = props;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("BR");
  const [complement, setComplement] = useState("");
  const [reference, setReference] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    getCep()
  }, [cep])

  const getCep = () => {
    if (cep.length > 7) {
      LocationService.getCep(cep).then((data) => {
        if (data.success && !!data.data) {
          setStreet(data.data[0].street)
          setDistrict(data.data[0].district)
          setCity(data.data[0].city)
          setState(data.data[0].state)
          setLatitude(data.data[0].latitude)
          setLongitude(data.data[0].longitude)
          setCountry(data.data[0].country)
        }
      })
    }
  }

  const handlerSubmit = () => {
    setIsLoading(true)
    const address = {
      branchId: branch?.id,
      cep: cep,
      street: street,
      district: district,
      city: city,
      state: state,
      country: country,
      number: number,
      complement: complement,
      reference: reference,
      latitude: latitude,
      longitude: longitude
    }
    BranchService.insertAddress(address).then((data) => {
      if (data.success) {
        setBranch(prev => {
          if (prev != undefined)
            prev.address = data.data
          return prev
        })
        alert("Endereço inserido com sucesso!")
        handleClose()
      }
    })
    setIsLoading(false)
  }

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={"Endereço: " + branch?.name}
      minWidth={300}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600} marginTop={2}
        paddingTop={1}
        paddingBlock={1}
      >
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <TextField size="small"
              label='Cep'
              value={cep}
              onChange={e => setCep(e.target.value)}
            />
            <TextField size="small"
              label='Endereço'
              value={street}
              onChange={e => setStreet(e.target.value)}
            />
            <TextField size="small"
              label='Numero'
              value={number}
              onChange={e => setNumber(e.target.value)}
            />
            <TextField size="small"
              label='Bairro'
              value={district}
              onChange={e => setDistrict(e.target.value)}
            />
            <TextField size="small"
              label='Cidade'
              value={city}
              onChange={e => setCity(e.target.value)}
            />
            <TextField size="small"
              label='Estado'
              value={state}
              onChange={e => setState(e.target.value)}
            />
            <TextField size="small"
              label='Complemento'
              value={complement}
              onChange={e => setComplement(e.target.value)}
            />
            <TextField size="small"
              label='Referencia'
              value={reference}
              onChange={e => setReference(e.target.value)}
            />
          </Stack>
        </Box>
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={1}
      >
        <Button onClick={handleClose} variant='outlined'>Voltar</Button>
        <Button disabled={isLoading} variant='contained' onClick={() => handlerSubmit()}>Salvar</Button>
      </Stack>
    </BaseModal>
  )
};
