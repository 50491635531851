import { Api } from "../axios-config";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ISearchReportDto } from "../../../models/Dtos/SearchReportDto";
import { IReport, IReportProduct, IReportUser } from "../../../models/Report";


const getBranch = async (search: ISearchReportDto): Promise<IResponseAPI<IReport> | IResponseAPI<IReport>> => {
  const { data } = await Api.post(`/otica/Report/Branch`, search).catch((data) => {
    return data.response;
  });
  return data;
};

const getUsers = async (dateInit: string, dateEnd: string): Promise<IResponseAPI<IReportUser[]>> => {
  const { data } = await Api.get(`/otica/Report/User?dateInit=${dateInit}&dateEnd=${dateEnd}`).catch((data) => {
    return data.response;
  });
  return data;
};

const getProducts = async (dateInit: string, dateEnd: string): Promise<IResponseAPI<IReportProduct[]>> => {
  const { data } = await Api.get(`/otica/Report/StockMovement?dateInit=${dateInit}&dateEnd=${dateEnd}`).catch((data) => {
    return data.response;
  });
  return data;
};

export const ReportService = {
  getProducts,
  getBranch,
  getUsers,
};