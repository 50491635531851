import { Typography } from "@mui/material";
import CurrencyFormat from "./CurrencyFormat";

interface IPriceDisplayProps {
  value: number;
  color: string;
}

export const PriceDisplay: React.FC<IPriceDisplayProps> = (props) => {
  const { value, color } = props;

  // Formata o valor como moeda e remove o símbolo "R$"
  const formattedValue = CurrencyFormat(value).replace('R$', '').trim(); // Exemplo: "1.234,56"
  const [integerPart, decimalPart] = formattedValue.split(','); // Divide na parte inteira e decimal

  return (
    <Typography color={color}>
      <span style={{ fontSize: '24px', fontWeight: 'bold' }}>

        <span style={{ fontSize: '18px', marginRight: '3px', fontWeight: 500 }}>
          R$
        </span>

        {integerPart}

      </span>

      <span style={{ fontSize: '16px', marginLeft: '2px' }}>
        ,{decimalPart}
      </span>

    </Typography>
  );
};
