import { Theme, useMediaQuery } from "@mui/material";
import { ReactNode, useContext, createContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


interface IListagemDeRelatoriosContextData {
  smDown: boolean;
  pageName: string;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title?: string;
};

export const ListagemDeRelatoriosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { pageName } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Relatórios");
  const [isLoading, setIsLoading] = useState(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  
  useEffect(() => {
    if (pageName !== "produtos" && pageName !== "vendas") {
      navigate("/relatorios");
    }

  }, [pageName, navigate]);


  return (
    <ListagemDeRelatoriosContext.Provider value={{
      smDown,
      isLoading,
      stopLoading,
      startLoading,
      pageName: pageName ?? "produtos",
      title,
      setTitle
    }}>
      {children}
    </ListagemDeRelatoriosContext.Provider>
  );
};

const ListagemDeRelatoriosContext = createContext({} as IListagemDeRelatoriosContextData);

export const useListagemDeRelatoriosContext = () => {
  return useContext(ListagemDeRelatoriosContext);
};

