import { Api } from "../axios-config";
import { IResponseAmount } from "../../../models";
import { IModelProduct } from "../../../models/ModelProduct";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { IModelProductInsert, IModelProductUpdate } from "../../../models/Dtos/cadastrosDtos/ModelProductDto";


const insert = async (modelProduct: IModelProductInsert): Promise<IResponseAPI<IModelProduct[]>> => {

    const { data } = await Api.post('/otica/ModelProduct', modelProduct).catch(data => {
        return data.response;
    });

    return data;
};

const update = async (modelProduct: IModelProductUpdate): Promise<IResponseAPI<IModelProduct>> => {

    const { data } = await Api.put('/otica/ModelProduct', modelProduct).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (): Promise<IResponseAPI<IResponseAmount<IModelProduct[]>>> => {

    const { data } = await Api.get("/otica/ModelProduct?pageSize=50").catch(data => {
        return data.response;
    });

    return data;
};

const getById = async (id: number): Promise<IResponseAPI<IResponseAmount<IModelProduct[]>>> => {

    const { data } = await Api.get(`/otica/ModelProduct/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const ModelProductService = {
    insert,
    update,
    getAll,
    getById,
};