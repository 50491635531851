import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ICashRegisterViewModel } from "../../../models/CashRegister";
import { Api } from "../axios-config";


const get = async (dateInit: string, dateEnd: string): Promise<IResponseAPI<ICashRegisterViewModel> | IResponseAPI<ICashRegisterViewModel>> => {
  const { data } = await Api.get(`/otica/CashRegister?dateInit=${dateInit}&dateEnd=${dateEnd}`).catch((data) => {
    return data.response;
  });
  return data;
};

const insert = async (value: number): Promise<IResponseAPI<any> | IResponseAPI<any>> => {
  const { data } = await Api.post(`/otica/CashRegister`,{value: value}).catch((data) => {
    return data.response;
  });
  return data;
};

export const CashRegisterService = {
  get,
  insert
};