import React from 'react';
import FlexBox from '../../layouts/FlexBox';
import { Icon, IconButton, Typography, useTheme } from '@mui/material';
import CurrencyFormat from '../../../helps/CurrencyFormat';

interface CardProdutoProps {
  name?: string;
  value?: number;
  small?: boolean;
  quantity?: number;
  noSelect?: boolean;
  onClick?: () => void;
}

export const CardProduto: React.FC<CardProdutoProps> = ({ name, value, quantity, onClick, small = false, noSelect = false }) => {
  const theme = useTheme();

  return (
    <FlexBox row
      bgcolor={'white'} padding={1} borderRadius={2} aiCenter
      boxShadow={"0px 0px 10px 1px #45454518"}
      sx={{ ...(small && { width: '290px' }), ...(noSelect && { backgroundColor: "#e3e3e3" }) }}
      gap={4}
    >
      <FlexBox fullWidth>
        <FlexBox width={340} gap={5}>
          <Icon sx={{ color: theme.palette.primary.main }}>sell</Icon>
          {!noSelect && <Typography fontWeight={500}>{name}</Typography>}
          {noSelect && <Typography fontWeight={200}>Nenhum produto selecionado</Typography>}
        </FlexBox>

        {small == false &&
          <FlexBox width={50} jcCenter>
            <Typography fontWeight={500}>{quantity}</Typography>
          </FlexBox>}

        {small == false &&
          <FlexBox width={150} jcEnd>
            <Typography fontWeight={500}>{value ? CurrencyFormat(value) : ('R$ '+value)}</Typography>
          </FlexBox>}
      </FlexBox>

      {small == false &&
        <FlexBox marginX={1}>
          <IconButton sx={{ margin: -1 }}
            onClick={() => onClick?.()}
          >
            <Icon sx={{ fontSize: '20px', fontWeight: 600 }}>clear</Icon>
          </IconButton>
        </FlexBox>}
    </FlexBox >
  );
};
