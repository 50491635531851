


/**
 * Formata o CNPJ.
 * @param rawValue Valor bruto (string contendo apenas números).
 * @returns CNPJ formatado (XX.XXX.XXX/XXXX-XX).
 */

export function formatCNPJ(rawValue: string): string {
  return rawValue.replace(
    /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})$/,
    (_, p1, p2, p3, p4, p5) =>
      `${p1}${p2 ? `.${p2}` : ""}${p3 ? `.${p3}` : ""}${p4 ? `/${p4}` : ""
      }${p5 ? `-${p5}` : ""}`
  );
};