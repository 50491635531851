import { Button, InputAdornment, MenuItem, Switch, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import React from "react";

import { useProductsContext } from "../../../shared/contexts/pages/ProductsContext";
import { BaseModal } from "../../../shared/components/modals/BaseModal";
import { IStockProductInsert } from "../../../shared/models/StockProduct";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { InputNumericState } from "../../../shared/components/inputs/InputNumericState";
import { InputCurrency } from "../../../shared/components/inputs/InputCurrency";

interface ModalInsertStockProdutoProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setStock: Dispatch<SetStateAction<IStockProductInsert[]>>;
  stock: IStockProductInsert[];
}

export const ModalInsertStockProduct: React.FC<ModalInsertStockProdutoProps> = (props) => {
  const { isOpen, setIsOpen, setStock, stock } = props;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [isProduct, setIsProduct] = React.useState(true);


  function handleAlignment(_: React.MouseEvent<HTMLElement>, newAlignment: boolean | null) {

    if (newAlignment !== null)
      setIsProduct(newAlignment);
  };

  function handleClose() {
    setIsOpen(false);
  };

  return (
    <BaseModal
      minWidth={smDown ? 290 : 350}
      open={isOpen}
      handleClose={handleClose}
      title="Inserir estoque"
    >
      <FlexBox column fullWidth gap={10}>
        <FlexBox jcEnd fullWidth marginTop={-5}>
          <ToggleButtonGroup
            value={isProduct} // Passa o estado booleano
            exclusive
            onChange={(e, value) => handleAlignment(e, value)}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value={true} aria-label="Produto">
              <Typography fontWeight={600} fontSize={13}>
                Produto
              </Typography>
            </ToggleButton>

            <ToggleButton value={false} aria-label="Lente">
              <Typography fontWeight={600} fontSize={13}>
                Lente
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </FlexBox>

        {isProduct ? (
          <ProdutoContent
            handleClose={handleClose}
            setStock={setStock}
            stock={stock}
          />
        ) : (
          <LenteContent
            handleClose={handleClose}
            setStock={setStock}
          />
        )}
      </FlexBox>
    </BaseModal>
  );
};




interface IProdutoContentProps {
  setStock: Dispatch<SetStateAction<IStockProductInsert[]>>;
  stock: IStockProductInsert[];
  handleClose(): void;
};
const ProdutoContent: React.FC<IProdutoContentProps> = (props) => {
  const { setStock, handleClose, stock } = props;

  const { colors } = useProductsContext()

  const [cor, setCor] = useState<number | undefined>();
  const [notStock, setNotStock] = useState(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [cost, setCost] = useState<number>(0);
  const [value, setValue] = useState<number>(0);


  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const insertStockProduto = {
      colorProductId: cor,
      notStock: notStock,
      quantity: quantity,
      cost: cost / 100,
      value: value / 100,
    };

    setStock(prev => {
      prev.push(insertStockProduto)
      return prev
    })

    setCor(undefined);
    setNotStock(false);
    setQuantity(1);
    setCost(0);
    setValue(0);
    handleClose();
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotStock(event.target.checked);
    setQuantity(1);
  };


  return (
    <FlexBox
      minHeight="100px"
      column gap={10} marginTop={2}
    >
      <TextField
        select
        size="small"
        name="color"
        label="Cor"
        value={cor}
        onChange={e => setCor(parseInt(e.target.value))}
      >
        {colors.filter(x => x.isActive && !stock.map(x => x.colorProductId).includes(x.id)).map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      {!notStock &&
        <InputNumericState
          required
          name="quantity"
          label="Quantidade"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
        />

      }

      <InputCurrency 
        value={cost}
        label="Custo"
        setValue={setCost}
      />
      
      <InputCurrency 
        value={value}
        label="Valor de Venda"
        setValue={setValue}
      />

      <FlexBox aiCenter jcBetween>
        <Typography>Venda sem estoque: </Typography>
        <Switch
          size="small"
          checked={notStock}
          onChange={handleCheck}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </FlexBox>


      <FlexBox row jcBetween marginTop={3} >
        <Button variant="outlined"
          size="small"
          onClick={handleClose}
        >
          Fechar
        </Button>
        <Button variant="contained"
          type="submit"
          size="small"
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </FlexBox>
    </FlexBox>
  );
};



interface ILenteContentProps {
  handleClose(): void;
  setStock: Dispatch<SetStateAction<IStockProductInsert[]>>;
};
const LenteContent: React.FC<ILenteContentProps> = (props) => {
  const { handleClose, setStock } = props;

  const [cost, setCost] = useState<number>(0);
  const [value, setValue] = useState<number>(0);



  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const insertStockProduto = {
      colorProductId: undefined,
      notStock: true,
      value: value / 100,
      quantity: 0,
      cost: cost / 100,
    };

    setStock(prev => {
      prev.push(insertStockProduto)
      return prev
    })

    setCost(0);
    setValue(0);
    handleClose();
  };

  return (
    <FlexBox
      minHeight="100px"
      column gap={10} marginTop={2}
    >

      <InputCurrency 
        value={cost}
        label="Custo"
        setValue={setCost}
      />
      
      <InputCurrency 
        value={value}
        label="Valor de Venda"
        setValue={setValue}
      />


      <FlexBox row jcBetween marginTop={3} >
        <Button variant="outlined"
          size="small"
          onClick={handleClose}
        >
          Fechar
        </Button>
        <Button variant="contained"
          type="submit"
          size="small"
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </FlexBox>
    </FlexBox>
  );
};
