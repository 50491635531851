import Box from '@mui/material/Box';
import { useState } from "react"
import { BaseModal } from "../../../../shared/components/modals/BaseModal";
import { Button, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import { Env } from '../../../../shared/environment';
import styled from 'styled-components';
import ImgCheck from '../../../../shared/assets/icons/icon-check.png';
import ImgClose from '../../../../shared/assets/icons/icon-close.png';
import { AuthService } from '../../../../shared/services/api/auth/AuthService';
import { useAuthContext } from '../../../../shared/contexts';

interface BaseModalProps {
  setConcluido: React.Dispatch<React.SetStateAction<boolean>>,
  setForShow: React.Dispatch<React.SetStateAction<number>>,
  handleClose: () => void,
  concluido: boolean,
  forShow: number,
  open: boolean,
  title: string,
};

export const ModalNewUser: React.FC<BaseModalProps> = (props) => {
  const { handleClose, open } = props;
  const { user } = useAuthContext();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState('');
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handlerSubmit = () => {
    setErrorName('')
    setErrorEmail('')
    setErrorPassword('')
    setErrorConfirmPassword('')
    if (!validadeInput.case ||
      !validadeInput.character ||
      !validadeInput.length ||
      !validadeInput.number) {
      setErrorPassword("Senha fraca!")
    } else if (password != confirmPassword) {
      setErrorConfirmPassword("As senhas devem coincidirem!")
    } else {
      setIsLoading(true)
      if (user.admin) {
        AuthService.insertUser(name, email, user.companyId as number, password).then((data) => {
          if (data.success) {
            alert("Usuario cadastrado com sucesso!")
            handleClose()
          } else {
            if (data.errors[0].includes("Email"))
              setErrorEmail(data.errors[0])
            else
              setErrorName(data.errors[0])
          }
        })
      } else {
        setErrorName("Apenas usuarios adiministradores podem inserir novos usuarios!")
      }
      setIsLoading(false)
    }
  }

  const handleChangeSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (name.length < 3) setErrorName("Nome muito curto!")
  };

  const [validadeInput, setValidateInput] = useState({
    case: false,
    number: false,
    character: false,
    length: false
  })

  const secureText = (password: string) => {

    const regexSpecialCharacter = /.*[!@#$%^&*(),.?":{}|<>].*/;
    const length = password.length >= 8;
    const regexUppercase = /[A-Z]/;
    const regexLowercase = /[a-z]/;
    const regexNumber = /[0-9]/;

    setValidateInput({
      case: regexUppercase.test(password) && regexLowercase.test(password),
      character: regexSpecialCharacter.test(password),
      number: regexNumber.test(password),
      length: length,
    })
  }

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={'Adicionar usuario'}
      minWidth={300}
    >
      <Stack
        direction='column'
        minWidth={smDown ? 300 : 600}
        paddingTop={1}
      >
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={2}
            padding={2}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <TextField
              label='Nome'
              value={name}
              onChange={handleChangeSetName}
              error={!!errorName}
              onKeyDown={() => setErrorName('')}
              required
              helperText={errorName}
            />
            <TextField
              label='Email'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={() => setErrorEmail('')}
              error={!!errorEmail}
              required
              helperText={errorEmail}
            />
            <TextField
              label='Senha'
              type='password'
              value={password}
              onChange={e => { secureText(e.target.value); setPassword(e.target.value); }}
              error={!!errorPassword}
              onKeyDown={() => setErrorPassword('')}
              required
              helperText={errorPassword}
            />
            <TextField
              label='Confirmar senha'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyDown={() => setErrorConfirmPassword('')}
              error={!!errorConfirmPassword}
              required
              helperText={errorConfirmPassword}
            />
            <ValidatePasswordContent>
              <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '5px' }}>
                Sua senha deve ter:
              </p>
              <div>
                <img src={validadeInput.length ? ImgCheck : ImgClose} />
                <p className="lines">8 caracteres</p>
              </div>
              <div>
                <img src={validadeInput.number ? ImgCheck : ImgClose} />
                <p className="lines">Números</p>
              </div>
              <div>
                <img src={validadeInput.case ? ImgCheck : ImgClose} />
                <p className="lines">Letra maiúscula e minúscula</p>
              </div>
              <div>
                <img src={validadeInput.character ? ImgCheck : ImgClose} />
                <p className="lines">Caracteres Especiáis</p>
              </div>
            </ValidatePasswordContent>
            <Stack
              direction='row'
              justifyContent='space-between'
            >
              <Button onClick={handleClose}>Fechar</Button>
              <Button disabled={isLoading} onClick={handlerSubmit}>Salvar</Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </BaseModal>
  )
};

const ValidatePasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-bottom: 10px;

  div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    margin: -12px 0 -12px 0;
  }

  img{
    width: 11px;
    height: 11px;
  }

  .lines{
    font-size: 15px;
    color: #616161;
  }
`;