import { ChipProps } from '@mui/material';

interface StatusBar {
  label: string;
  color: ChipProps["color"]; // Usa o tipo color do Chip diretamente
}

export const statusBar = (value: number | undefined): StatusBar => {
  switch (value) {
    case 0:
      return { label: "Envio Pendente", color: 'secondary' };
    case 1:
      return { label: "Em Andamento", color: 'warning' };
    case 2:
      return { label: "Concluída", color: 'success' };
    case 3:
      return { label: "Cancelada", color: 'error' };
    default:
      return { label: "----", color: 'default' };
  };
};