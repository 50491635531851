import { Button, Icon, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ButtonToBackProps {
    row?: boolean;
    jcEnd?: boolean;
    aiEnd?: boolean;
    column?: boolean;
    jcStart?: boolean;
    aiStart?: boolean;
    jcEvenly?: boolean;
    jcAround?: boolean;
    jcCenter?: boolean;
    aiCenter?: boolean;
  
    aiBetween?: boolean;
    aiAround?: boolean;
    aiEvenly?: boolean;
  
    jcBetween?: boolean;
    fullWidth?: boolean;
  
    sx?: React.CSSProperties;
    sx1?: React.CSSProperties;
  
    gap?: number | string;
};


export const ButtonToBack: React.FC<ButtonToBackProps> = ({
    fullWidth,
    gap,
    sx,
    sx1,
    row,
    aiEnd,
    jcEnd,
    column,
    jcStart,
    aiStart,
    aiCenter,
    jcCenter,
    jcBetween,
    jcEvenly,
    jcAround,
    aiBetween,
    aiAround,
    aiEvenly,
    ...boxProps
  }) => {
    var style: React.CSSProperties = {
        display: 'flex',
    
        ...(fullWidth && { width: '100%' }),
        ...(!!gap && { gap: gap }),
    
        ...(row && { flexDirection: 'row' }),
        ...(column && { flexDirection: 'column' }),
    
        ...(aiEnd && { alignItems: 'end' }),
        ...(aiStart && { alignItems: 'start' }),
        ...(jcEnd && { justifyContent: 'end' }),
        ...(aiCenter && { alignItems: 'center' }),
        ...(jcStart && { justifyContent: 'start' }),
        ...(jcCenter && { justifyContent: 'center' }),
    
        ...(jcBetween && { justifyContent: 'space-between' }),
        ...(jcAround && { justifyContent: 'space-around' }),
        ...(jcEvenly && { justifyContent: 'space-evenly' }),
    
        ...(aiBetween && { alignItems: 'space-between' }),
        ...(aiAround && { alignItems: 'space-around' }),
        ...(aiEvenly && { alignItems: 'space-evenly' }),
    
        ...(sx),
        ...(sx1)
    };
    const navigate = useNavigate();
    return (
        <Box style={style} {...boxProps}>
            <Button
                onClick={() => navigate(-1)}
                variant="text" sx={{ width: '120px' }}
                endIcon={<Icon sx={{ transform: 'rotate(90deg)' }}>u_turn_left</Icon>}>
                Voltar
            </Button>
        </Box>
    )
};