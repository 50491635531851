import React from 'react';
import { Typography } from '@mui/material';
import FlexBox from '../../../shared/components/layouts/FlexBox';

export const SectionExportarClientes: React.FC<{ amount: number }> = ({ amount }) => {
    return (
        <FlexBox padding={1} jcEnd>
            <Typography variant='body2'>
                Foram encontrados um total de {amount} clientes
            </Typography>
        </FlexBox>
    )
}
