import { useEffect, useState } from "react"
import { BaseModal } from "../../../../shared/components/modals/BaseModal";
import { Button, Icon, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IUser } from '../../../../shared/models/User';
import { Environment } from '../../../../shared/environment';
import { IRoleLeveEnum } from '../../../../shared/models/enums/RoleLeveEnum';
import { ModalRoleEdit } from './ModalRoleEdit';
import { IRole } from '../../../../shared/models/Role';
import { ModalRoleAdd } from './ModalRoleAdd';
import { RoleService } from '../../../../shared/services/api/role/RoleService';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { CardBox } from '../../../../shared/components/layouts/CardBox';

interface BaseModalProps {
  user: IUser | undefined,
  handleClose: () => void,
  open: boolean,
};

export const ModalEditUser: React.FC<BaseModalProps> = (props) => {
  const { user, handleClose, open } = props;

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px
  const [totalCount, setTotalCount] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [role, setRole] = useState<IRole>();
  useEffect(() => {
    if (user?.roles != null)
      setTotalCount(user?.roles.length)
  }, [open])

  const handleEditClose = () => {
    setOpenEdit(false);
    handleClose();
  };

  const handleAddClose = () => {
    setOpenAdd(false);
    handleClose();
  };

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleDelete = (id: number) => {
    if (window.confirm('Realmente deseja apagar?')) {
      RoleService.deleteRole(id).then((data) => {
        if (data.success) {
          alert("Perfil removido com sucesso!")
          handleClose()
        } else {
          alert(data.errors[0])
        }
      })
    }
  };

  const theme = useTheme();

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title={"Perfil de acesso:"}
      minWidth={320}
    >
      <FlexBox row jcEnd
        marginTop={-6}
      >
        <Button variant='contained'
          onClick={handleAddOpen}
          startIcon={<Icon>add</Icon>}
        >
          Adicionar
        </Button>
      </FlexBox>

      <FlexBox row jcStart
        marginTop={2}
      >
        <Typography variant='h6' fontWeight={500}
          sx={{ borderRadius: '5px', padding: '5px', backgroundColor: theme?.palette?.background.default }}
          color="#ffffff"
        >
          {user?.name}
        </Typography>
      </FlexBox>
      <Stack
        direction='column'
        minWidth={320} marginTop={2}
        maxHeight={300}
        paddingTop={1}
        paddingBlock={1}
      >
        <TableContainer component={CardBox} sx={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ponto de venda</TableCell>
                <TableCell>Nível de acesso</TableCell>
                <TableCell width={70}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (user?.roles != null && user.roles.length > 0) && (
                  user?.roles.map(row => (
                    <TableRow key={row.id} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d3d' }, transition: '.3s' }}
                    >
                      <TableCell>
                        <FlexBox row>
                          {smDown ? null : <Icon>location_city</Icon>}
                          <Typography>
                            {row.branch.name}
                          </Typography>
                        </FlexBox>
                      </TableCell>

                      <TableCell>
                        <FlexBox row>
                          {smDown ? null : <Icon>device_hub</Icon>}
                          <Typography>
                            {IRoleLeveEnum[row.level]}
                          </Typography>
                        </FlexBox>
                      </TableCell>

                      <TableCell>
                        <IconButton size='small' onClick={() => { setRole(row); setOpenEdit(true) }}>
                          <Icon>edit</Icon>
                        </IconButton>
                        <IconButton size='small' onClick={() => handleDelete(row.id)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))
              }
            </TableBody>
            {totalCount == 0 && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
          </Table>
        </TableContainer>
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        marginTop={3}
      >
        <Button onClick={handleClose} variant='outlined'>Fechar</Button>
        <Button variant='contained'>Salvar</Button>
      </Stack>
      <ModalRoleEdit
        handleClose={handleEditClose}
        open={openEdit}
        role={role}
        user={user}
      />
      <ModalRoleAdd
        handleClose={handleAddClose}
        open={openAdd}
        userSelect={user}
      />
    </BaseModal>
  )
};
