import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Icon, Typography, Box, IconButton, LinearProgress, Pagination, useMediaQuery, Theme } from "@mui/material";
import { SetURLSearchParams } from "react-router-dom";
import { Environment } from "../../../../shared/environment";
import { IUser } from "../../../../shared/models/User";
import { ModalEditUser } from "./ModalEditUser";
import { useState } from "react";
import { CardBox } from "../../../../shared/components/layouts/CardBox";
import FlexBox from "../../../../shared/components/layouts/FlexBox";

interface SectionTableProps {
  rows: IUser[];
  setRows: React.Dispatch<React.SetStateAction<IUser[]>>;
  openEdit: boolean;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  totalCount: number;
  setSearchParams: SetURLSearchParams;
  busca: string;
  pagina: number;
}

export const UserTable: React.FC<SectionTableProps> = ({ rows, openEdit, setOpenEdit, isLoading, totalCount, setSearchParams, busca, pagina }) => {
  const [userSelect, setUserSelect] = useState<IUser>()
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  const handleEdit = (user: IUser) => {
    setUserSelect(user)
    setOpenEdit(true);
  }
  const handleEditClose = () => {
    setOpenEdit(false)
  };

  return (
    <TableContainer component={CardBox} column sx={{ width: 'auto', overflow: 'auto' }}>
      {smDown ? <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell width={70}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {!!rows && rows.filter(x => x.name?.toLowerCase()?.includes(busca.toLowerCase())).map(row => (
            <TableRow key={row.id} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d3d' }, transition: '.3s' }}
              component={Box}>
              <TableCell>
                <Typography>{row.name}</Typography>
              </TableCell>
              <TableCell>
                {/* <IconButton size='small' onClick={() => handleDelete(row.id)}>
                  <Icon>delete</Icon>
                </IconButton> */}
                <IconButton size='small' onClick={() => handleEdit(row)}>
                  <Icon>edit</Icon>
                </IconButton>
              </TableCell>

            </TableRow>
          ))}

        </TableBody>
        {totalCount == 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
      </Table> :
        <Table>

          <TableHead>
            <TableRow>
              <TableCell width={10}></TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              rows?.map(row => (
                <TableRow key={row.id} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#8d8d8d1a' }, transition: '.3s' }}
                  onClick={() => handleEdit(row)}
                  component={Box}
                >
                  <TableCell>
                    <Avatar>
                      <Icon>people</Icon>
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.name}</Typography>
                  </TableCell>
                  <TableCell>
                    {row.email}
                  </TableCell>

                </TableRow>
              ))
            }
          </TableBody>
          {totalCount == 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
        </Table>
      }
      <FlexBox padding={2}>
        {isLoading && (
          <LinearProgress variant='indeterminate' />
        )}
        {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
          />
        )}
      </FlexBox>
      <ModalEditUser
        handleClose={handleEditClose}
        open={openEdit}
        user={userSelect}
      />
    </TableContainer>
  )
}
