import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction } from "react";
import { Icon, IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";

import { IDocumentTypeEnum } from "../../models/enums/DocumentTypeEnum";
import { formatCNPJ, formatCPF, formatRG } from "../formatters";


interface IInputDocumentsProps {
  label: string;
  value: string;
  typeDoc: number;
  required?: boolean;
  helperText?: ReactNode;
  error?: boolean | undefined;
  setValue: Dispatch<SetStateAction<string>>;
  handleChange: (value: string) => void
};

export const InputDocuments: React.FC<IInputDocumentsProps> = (props) => {
  const { setValue, value, label, required, typeDoc, error, helperText, handleChange } = props;


  const isCNPJ = IDocumentTypeEnum['CNPJ'] === typeDoc;
  const isCPF = IDocumentTypeEnum['CPF'] === typeDoc;
  const isCNH = IDocumentTypeEnum['CNH'] === typeDoc;
  const isRG = IDocumentTypeEnum['RG'] === typeDoc;


  const inputProps = (
    isCPF ? 14 :
      isCNPJ ? 18 :
        isCNH ? 11 :
          isRG ? 12 : 18
  );


  function formatSelector(): string {
    if (isCNPJ) return formatCNPJ(value)
    if (isCPF) return formatCPF(value)
    if (isRG) return formatRG(value)
    if (isCNH) return value
    else return value;
  };


  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value;
    const numbersOnly = input.replace(/\D/g, "");
    handleChange(numbersOnly)
    setValue(numbersOnly);
  };


  return (

    <TextField
      fullWidth
      size="small"
      label={label}
      error={error}
      variant="outlined"
      helperText={helperText}
      value={formatSelector()}
      required={required ?? false}
      onChange={handleInputChange}


      slotProps={{
        input: {
          inputMode: "numeric",
          inputProps: { maxLength: inputProps },
          endAdornment: (
            <IconButton
              onClick={() => setValue("")}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
              size="small"
            >
              <InputAdornment position="end">
                <Icon sx={{ fontSize: "20px" }}>
                  clear
                </Icon>
              </InputAdornment>
            </IconButton>
          ),
        },
      }}
    />

  );
};
