function PhoneNumberFormat(phoneNumberString: string) {

    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
    if (match) {
      return ['(', match[1], ') ', match[2], '-', match[3]].join('');
    }
  return phoneNumberString;
}

export default PhoneNumberFormat;