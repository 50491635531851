import { Routes, Route, Navigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";


import {
    ListagemDeCadastrosProvider, useAuthContext, useDrawerContext, DashboardProvider,
    StockProvider, ListagemDeRelatoriosProvider
} from "../shared/contexts";

import {
    Dashboard, ListagemDeClientes, DetalheDeClientes, ListagemDeVendas, DetalheDeVendas,
    ConfirmedEmail, ForgotPassword, ResetPassword, UserList, DashboardAdmin, BranchList,
    Login, Register, EditarClientes, ListagemDeCadastros, ListagemDeRelatorios,
    Caixa, Estoque, NotRole, Branch, CreateBranch, NotRoleSelect, 
    ListagemDeProdutos, SubscriptionList
} from "../pages";

import { MenuLateral } from "../shared/components";
import { ProductsProvider } from "../shared/contexts/pages/ProductsContext";
import { BranchService } from "../shared/services/api/branch/BranchService";


export const AppRouters = () => {

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [containsBranch, setContainsBranch] = useState<boolean>(false);
    const { setDrawerOptions, setDrawerOptionsAdmin } = useDrawerContext();
    const { isAuthenticated, user, roles, setGlobalLoading } = useAuthContext();

    useEffect(() => {
        setGlobalLoading(true);

        setDrawerOptionsAdmin([
            {
                icon: 'groups',
                path: '/admin/users',
                label: 'Usuarios'
            },
            {
                icon: 'location_city',
                path: '/admin/filiais',
                label: 'Pontos de venda'
            },
            {
                icon: 'add_business',
                path: '/admin/subscriptions',
                label: 'Assinaturas'
            }
        ]);

        setDrawerOptions([
            {
                icon: 'home',
                path: '/painel',
                label: 'Página Inicial',
                level: 0,
            },
            {
                icon: 'people',
                path: '/clientes',
                label: 'Clientes',
                level: 0,
            },
            {
                icon: 'local_mall',
                path: '/vendas',
                label: 'Vendas',
                level: 0,
            },
            {
                icon: 'point_of_sale',
                path: '/caixa',
                label: 'Caixa',
                level: 1,
            },
            {
                icon: 'app_registration',
                path: '/cadastros/categorias',
                label: 'Cadastros',
                level: 2,
            },
            {
                icon: 'shopping_bag',
                path: '/produtos',
                label: 'Produtos',
                level: 2,
            },
            {
                icon: 'sync_alt',
                path: '/estoque',
                label: 'Estoque',
                level: 2,
            },
            {
                icon: 'assessment',
                path: '/relatorios',
                label: 'Relatórios',
                level: 2,
            }
        ]);

        if (isAuthenticated)
            ContainsBranch()
        setGlobalLoading(false);

    }, [isAuthenticated]);


    const ContainsBranch = async () => {
        if (user.admin) {
            BranchService.getBranchs().then((response) => {
                if (response.success)
                    return setContainsBranch(response.data.length > 0);
            })
        } else { setContainsBranch(true) }
    }

    return (
        <>
            {
                !isAuthenticated && (
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/login/:hash" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/confirmedEmail" element={<ConfirmedEmail />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/ResetPassword" element={<ResetPassword />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                )
            }
            {
                isAuthenticated && (
                    <MenuLateral>
                        <Routes>
                            {
                                user.admin &&
                                <>
                                    <Route path="/admin" element={<DashboardAdmin />} />
                                    <Route path="/admin/users" element={<UserList />} />
                                    <Route path="/admin/filiais" element={<BranchList />} />
                                    <Route path="/admin/filial/:id" element={<Branch />} />
                                    <Route path="/admin/subscriptions" element={<SubscriptionList />} />

                                </>
                            }
                            {
                                (roles.length > 0 && roles.filter(x => x.isSelected).length > 0) &&
                                <>
                                    <Route path="/painel" element={<Provider_Dashboard />} />

                                    {/* <Route path="/clientes/:id" element={<NovoCliente />} /> */}
                                    <Route path="/clientes" element={<ListagemDeClientes />} />
                                    <Route path="/clientes/detalhe/:id" element={<DetalheDeClientes />} />
                                    <Route path="/clientes/editar/:id" element={<EditarClientes />} />

                                    <Route path="/vendas" element={<ListagemDeVendas />} />
                                    <Route path="/vendas/detalhe/:id" element={<DetalheDeVendas />} />

                                    {(roles.find(x => x.isSelected)?.level ?? 0) > 0 &&
                                        <Route path="/caixa" element={<Caixa />} />
                                    }

                                    {(roles.find(x => x.isSelected)?.level ?? 0) > 1 && (
                                        <>
                                            <Route path="/cadastros" element={<Navigate to="/cadastros/categorias" />} />
                                            <Route path="/cadastros/*" element={<Navigate to="/cadastros/categorias" />} />
                                            <Route path="/cadastros/:pageName" element={<Provider_ListagemDeCadastros />} />

                                            <Route path="/relatorios" element={<Navigate to="/relatorios/produtos" />} />
                                            <Route path="/relatorios/*" element={<Navigate to="/relatorios/produtos" />} />
                                            <Route path="/relatorios/:pageName" element={<Provider_ListagemDeRelatorios />} />

                                            <Route path="/estoque" element={<Navigate to="/estoque/entradas" />} />
                                            <Route path="/estoque/*" element={<Navigate to="/estoque/entradas" />} />
                                            <Route path="/estoque/:pageName" element={<Provider_Estoque />} />

                                            <Route path="/produtos" element={<Provider_ListagemDeProdutos />} />
                                        </>
                                    )}

                                    <Route path="*" element={<Navigate to="/painel" />} />
                                </>
                            }
                            {
                                (user.admin && !containsBranch) &&
                                <Route path="*" element={<CreateBranch />} />
                            }
                            {
                                (roles.length > 0 && roles.filter(x => x.isSelected).length == 0) &&
                                <Route path="*" element={<NotRoleSelect />} />
                            }
                            {
                                (roles.length == 0 && containsBranch) &&
                                <Route path="*" element={<NotRole />} />
                            }
                        </Routes>
                    </MenuLateral>
                )
            }
        </>
    );
};


const Provider_ListagemDeCadastros: React.FC = () => (
    <ListagemDeCadastrosProvider>
        <ListagemDeCadastros />
    </ListagemDeCadastrosProvider>
);

const Provider_ListagemDeProdutos: React.FC = () => (
    <ProductsProvider>
        <ListagemDeProdutos />
    </ProductsProvider>
);

const Provider_Estoque: React.FC = () => (
    <StockProvider>
        <Estoque />
    </StockProvider>
);

const Provider_ListagemDeRelatorios: React.FC = () => (
    <ListagemDeRelatoriosProvider>
        <ListagemDeRelatorios />
    </ListagemDeRelatoriosProvider>
);

const Provider_Dashboard: React.FC = () => (
    <DashboardProvider>
        <Dashboard />
    </DashboardProvider>
);