import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, Icon, Chip, LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { CardBox } from '../../../../../shared/components/layouts/CardBox';
import { formatPhone } from '../../../../../shared/components/formatters';
import { ILaboratory } from '../../../../../shared/models/Laboratory';
import FlexBox from '../../../../../shared/components/layouts/FlexBox';
import { Environment } from '../../../../../shared/environment';


interface ILaboratoryTableSectionProps {
  handleIsOpenEdit(row: ILaboratory): void;
  rows: ILaboratory[] | null | undefined;
  totalCount: number | null | undefined;
  isLoading: boolean;
};

export const LaboratoryTableSection: React.FC<ILaboratoryTableSectionProps> = (props) => {
  const { handleIsOpenEdit, rows, totalCount, isLoading } = props;

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer component={CardBox} column sx={{ width: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Nome</TableCell>
            <TableCell>{!smDown ? "Telefone" : "Tel."}</TableCell>
            <TableCell width={50}>Status</TableCell>
            <TableCell width={50}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(rows) && (
            rows.map(row => (
              <TableRow key={row.id}
              >
                <TableCell align='center'><Typography fontWeight={500} >{row.name}</Typography></TableCell>
                <TableCell><Typography fontWeight={500} >{formatPhone(row.phoneNumber)}</Typography></TableCell>
                <TableCell width={50}>
                  <Chip
                    variant="outlined"
                    size='small'
                    label={row.isActive ? "Ativo" : "Desativo"}
                    color={row.isActive ? "success" : "error"}
                    sx={{ marginX: "-7px" }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton size='small' onClick={() => handleIsOpenEdit(row)}>
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))

          )}
        </TableBody>
        {totalCount == 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
      </Table>

      <FlexBox padding={2}>
        {isLoading && (
          <TableRow>
            <LinearProgress variant='indeterminate' />
          </TableRow>
        )}
      </FlexBox>
    </TableContainer>
  )
}
