import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import TextField from "@mui/material/TextField";
import { Icon, IconButton, InputAdornment } from "@mui/material";
import { formatPhone } from "../formatters";


interface IInputCellphoneProps {
  label: string;
  value: string;
  required?: boolean;
  setValue: Dispatch<SetStateAction<string>>;
  handleChangeNumero?: (e: ChangeEvent<HTMLInputElement>) => void;

}

export const InputCellphone: React.FC<IInputCellphoneProps> = (props) => {
  const { setValue, value, label, required, handleChangeNumero } = props;


  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value;
    const numbersOnly = input.replace(/\D/g, ""); // Remove caracteres não numéricos
    setValue(numbersOnly);

    if (handleChangeNumero)
      handleChangeNumero(e);
  };

  return (
    <TextField
      fullWidth
      size="small"
      label={label}
      variant="outlined"
      value={formatPhone(value)}
      required={required ?? false}
      onChange={handleInputChange}
      
      slotProps={{
        input: {
          inputMode: "numeric",
          inputProps: { maxLength: 15 },
          endAdornment: (
            <IconButton
              onClick={() => setValue("")}
              onMouseDown={(event) => event.preventDefault()} // Evita perder o foco ao clicar
              edge="end"
              // sx={{fontSize: "20px"}}
              size="small"
            >
              <InputAdornment position="end">
                <Icon sx={{ fontSize: "20px" }}>
                  clear
                </Icon>
              </InputAdornment>
            </IconButton>
          ),
        },
      }}
    />
  );
};
