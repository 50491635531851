



/**
   * Formata o número de telefone como fixo ou celular.
   * @param rawValue Valor bruto (string contendo apenas números).
   * @returns Número formatado como telefone.
   */

export function formatPhone(rawValue: string): string {
  if (rawValue.length <= 10) {

    // Telefone fixo (DDD + número de 8 dígitos)

    return rawValue.replace(
      /^(\d{0,2})(\d{0,4})(\d{0,4})$/,
      (_, p1, p2, p3) =>
        `${p1 ? `(${p1}) ` : ""}${p2}${p3 ? `-${p3}` : ""}`
    );

  } else {

    // Celular (DDD + número de 9 dígitos)
    
    return rawValue.replace(
      /^(\d{0,2})(\d{0,5})(\d{0,4})$/,
      (_, p1, p2, p3) =>
        `${p1 ? `(${p1}) ` : ""}${p2}${p3 ? `-${p3}` : ""}`
    );
  };
};