import React, { useState } from 'react';
import { Divider } from '@mui/material';

import { ReasonsPage } from './reasons/ReasonsPage';
import { ButtonCell } from './components/ButtonCell';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import { useListagemDeCadastrosContext } from '../../../shared/contexts';
import { CategoriasPage, CoresPage, LaboratoriosPage, MarcasPage, MateriaisPage, ModelosPage } from '.';


interface ListagemDeCadastrosProps {

}

export const ListagemDeCadastros: React.FC<ListagemDeCadastrosProps> = (props) => {
  const { } = props;
  const { pageName } = useListagemDeCadastrosContext();

  return (
      <LayoutBaseDePagina
        titulo="Cadastros"
        buttonToBack
      >
        <CardBox column>
          <FlexBox paddingY={1} paddingX={2} gap={10} flexWrap={"wrap"} aiCenter>
            <ButtonCell value={pageName} defaultValue={'categorias'}  title='Categorias' />|
            <ButtonCell value={pageName} defaultValue={'marcas'}  title='Marcas' />|
            <ButtonCell value={pageName} defaultValue={'modelos'}  title='Modelos' />|
            <ButtonCell value={pageName} defaultValue={'materiais'}  title='Materiais' />|
            <ButtonCell value={pageName} defaultValue={'cores'}  title='Cores' />|
            {/* <ButtonCell value={pageName} defaultValue={'fornecedores'}  title='Fornecedores' /> */}
            <ButtonCell value={pageName} defaultValue={'motivos'}  title='Motivos' />|
            <ButtonCell value={pageName} defaultValue={'laboratorios'}  title='Laboratórios' />
          </FlexBox>

          <Divider variant='fullWidth' />

          <FlexBox paddingTop={1}>
            {pageName === 'categorias' && <CategoriasPage />}
            {pageName === 'marcas' && <MarcasPage />}
            {pageName === 'modelos' && <ModelosPage />}
            {pageName === 'materiais' && <MateriaisPage />}
            {pageName === 'cores' && <CoresPage />}
            {/* {pageName === 'fornecedores' && <FornecedoresPage />} */}
            {pageName === 'motivos' && <ReasonsPage />}
            {pageName === 'laboratorios' && <LaboratoriosPage />}
          </FlexBox>

        </CardBox>
      </LayoutBaseDePagina>
  );
};