import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Theme, useMediaQuery } from "@mui/material";
import { SectionCardTableMobile, SectionExportarClientes, SectionTable } from "./sections";
import { ModalNovoCLiente2 } from "./components/listagemDeCLientes/Modal-NovoCLiente2";
import { ClientesService } from "../../shared/services/api/clientes/ClientesService";
import { FerramentasDaListagem } from "../../shared/components";
import { IClient } from "../../shared/models/Clients/client";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { useAuthContext } from "../../shared/contexts";
import { useDebounce } from "../../shared/hooks";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FilterStatusSale } from "./components/FilterStatusSale";

export const ListagemDeClientes: React.FC = () => {

  const { user } = useAuthContext();
  const { debounce } = useDebounce(800, true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [rows, setRows] = useState<IClient[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const dateInit = useMemo(() => {
    return searchParams.get('dateInit') || '';
  }, [searchParams]);

  const dateEnd = useMemo(() => {
    return searchParams.get('dateEnd') || '';
  }, [searchParams]);

  const status = useMemo(() => {
    return searchParams.get('status') || '0';
  }, [searchParams]);

  function getClientAdmin() {
    debounce(() => {
      ClientesService.get(pagina, busca, status, dateInit, dateEnd)
        .then((result) => {
          setIsLoading(false);
          if (result?.success && result.data != undefined && result.data != null) {
            setAmount(result.data.amount);
            setRows(result.data.data);
          }
          else {
            alert(result.message);
          }
        });
    });
  };

  function getClientComum() {
    debounce(() => {
      ClientesService.getComum(pagina, busca, status, dateInit, dateEnd).then(
        result => {
          setIsLoading(false);

          if (result.success) {
            setAmount(result!.data!.amount);
            setRows(result!.data!.data);
          } else {
            alert(result.errors + result.message);
          };
        }
      );
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const userRole = user.roles.filter(role => role.level == 0);
    !userRole && getClientComum();
    userRole && getClientAdmin();

  }, [busca, pagina, status, dateInit, dateEnd, debounce]);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //   sm = 600px  //  md = 900px  //  lg = 1200px

  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false)
  };

  const handleFilter = () => {

  }


  return (
    <LayoutBaseDePagina
      titulo="Clientes"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          placeBusca="Nome ou CPF"
          textoDaBusca={busca}
          textoBotaoNovo="Cliente"
          aoClicarEmNovo={handleOpen}
          aoMudarTextDeBusca={texto => setSearchParams({ busca: texto, pagina: '1', status: status, dateInit: dateInit, dateEnd: dateEnd }, { replace: true })}
        />
      }
    >
      <FlexBox padding={2} gap={20} row={smDown ? false : true} column={smDown ? true : false} jcEnd aiCenter>
        <LocalizationProvider dateFormats={{ fullDate: "DD/MM/YYYY" }} adapterLocale='pt-BR' dateAdapter={AdapterDayjs}>
          <FlexBox maxWidth={350} minWidth={300} gap={20}>
            <DatePicker
              label="Inicio"
              value={dateInit == '' ? null : dayjs(dateInit)}
              format='DD/MM/YYYY'
              onChange={(dateInit) => setSearchParams({ busca, pagina: '1', status: status, dateInit: dateInit?.format('YYYY-MM-DD') ?? '', dateEnd: dateEnd }, { replace: true })}

            />
            <DatePicker
              label="Fim"
              value={dateEnd == '' ? null : dayjs(dateEnd)}
              format='DD/MM/YYYY'
              onChange={(dateEnd) => setSearchParams({ busca, pagina: '1', status: status, dateInit: dateInit, dateEnd: dateEnd?.format('YYYY-MM-DD') ?? '' }, { replace: true })}
            />
          </FlexBox>
        </LocalizationProvider>
        <FilterStatusSale
          dateEnd={dateEnd}
          dateInit={dateInit}
          handleClick={handleFilter}
          busca={busca}
          params={status}
          setSearchParams={setSearchParams} />
      </FlexBox>

      <ModalNovoCLiente2
        handleClose={handleClose}
        setRows={setRows}
        open={open}
      />

      {!smDown && (
        <SectionTable
          pagina={pagina}
          busca={busca}
          status={status}
          isLoading={isLoading}
          rows={rows}
          setSearchParams={setSearchParams}
          totalCount={amount}
          dateEnd={dateEnd}
          dateInit={dateInit}
        />
      )}

      {smDown && (
        <SectionCardTableMobile
          pagina={pagina}
          busca={busca}
          status={status}
          isLoading={isLoading}
          rows={rows}
          setSearchParams={setSearchParams}
          totalCount={amount}
          dateEnd={dateEnd}
          dateInit={dateInit}
        />
      )}

      <SectionExportarClientes
        amount={amount}
      />
    </LayoutBaseDePagina>
  )
}