import React, { useEffect, useState } from 'react'
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Typography, IconButton, Icon, LinearProgress, Button, Chip,
  Popover
} from '@mui/material';

import { Environment } from '../../../../shared/environment';
import FlexBox from '../../../../shared/components/layouts/FlexBox';
import { ModalAddEditMaterial } from './modals/ModalAddEditMaterial';
import { IMaterialProduct } from '../../../../shared/models/MaterialProduct';
import { CardBox } from '../../../../shared/components/layouts/CardBox';
import { useListagemDeCadastrosContext } from '../../../../shared/contexts';
import { MaterialProductService } from '../../../../shared/services/api/cadastros/MaterialProductService';
import { MaterialProduct } from '../../../../shared/models/classes/categoryModels/materialProducts/MaterialProduct';

export const MateriaisPage: React.FC = () => {

  const { isLoading, startLoading, stopLoading } = useListagemDeCadastrosContext();

  const modelEmpty = new MaterialProduct({});

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [rows, setRows] = useState<IMaterialProduct[]>([modelEmpty]);
  const [rowEdit, setRowEdit] = useState<IMaterialProduct>(modelEmpty);
  const totalCount = rows.length;




  useEffect(() => {
    startLoading();
    MaterialProductService.getAll().then(result => {
      stopLoading();
      if (result.success) {
        setRows(result.data.data);
      }
      else {
        alert(result.errors[0]);
      }
    });
  }, [])


  function handleIsOpenInsert() {
    setRowEdit(modelEmpty);
    setIsOpenAdd(true);
  };

  function handleIsOpenEdit(row: IMaterialProduct) {
    setRowEdit(row);
    setIsOpenAdd(true);
  };


  return (
    <FlexBox fullWidth column gap={20} padding={1}>
      <FlexBox row jcEnd fullWidth>
        <Button
          variant='contained'
          size='small'
          onClick={handleIsOpenInsert}
        >
          <Icon>add</Icon>Material
        </Button>
      </FlexBox>
      <FlexBox>
        <TableContainer component={CardBox} column sx={{ width: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Nome</TableCell>
                <TableCell width={50}>Ações</TableCell>
                <TableCell width={100}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(rows) && (
                rows.map(row => (
                  <TableRow key={row.id}>

                    <TableCell align='center'>
                      <Typography fontWeight={500} >
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <IconButton size='small' onClick={() => handleIsOpenEdit(row)}>
                        <Icon>edit</Icon>
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Chip
                        variant="outlined"
                        size='small'
                        label={row.isActive ? "Ativo" : "Desativado"}
                        color={row.isActive ? "success" : "error"}
                      />
                    </TableCell>

                  </TableRow>
                ))

              )}
            </TableBody>
            {totalCount == 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
          </Table>
          <FlexBox padding={2}>
            {isLoading && (
              <TableRow>
                <LinearProgress variant='indeterminate' />
              </TableRow>
            )}
          </FlexBox>
        </TableContainer>
      </FlexBox>

      {isOpenAdd &&
        <ModalAddEditMaterial
          rowEdit={rowEdit}
          setRows={setRows}
          isOpenAdd={isOpenAdd}
          setIsOpenAdd={setIsOpenAdd}
        />
      }
    </FlexBox>
  )
}