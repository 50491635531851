import { ReactNode, useContext, createContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


interface IListagemDeCadastrosContextData {
  pageName: string;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};

export const ListagemDeCadastrosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { pageName } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);
  
  

  useEffect(() => {
    const validPages = ["cores", "marcas", "modelos", "materiais", "categorias", "laboratorios", "motivos"];
  
    if (!validPages.includes(pageName as string)) {
      navigate("/cadastros");
    }

  }, [pageName, navigate]);
      

  return (
    <ListagemDeCadastrosContext.Provider value={{
      pageName: pageName ?? "categorias",
      isLoading,
      stopLoading,
      startLoading,
    }}>
      {children}
    </ListagemDeCadastrosContext.Provider>
  );
};

const ListagemDeCadastrosContext = createContext({} as IListagemDeCadastrosContextData);

export const useListagemDeCadastrosContext = () => {
  return useContext(ListagemDeCadastrosContext);
};


