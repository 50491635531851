import { Button, Icon, Typography, useMediaQuery, Theme, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

import { ModalAddPrescrition } from "../../clients/components/detalheDeClientes/modals/ModalAddPrescrition";
import { OrderServiceService } from "../../../shared/services/api/orderService/OrderServiceService";
import { ModalEditOrder } from "../../../shared/components/addOrderServiceModal/ModalEditOrder";
import { DetalheOSModal } from "../../../shared/components/modals/modalDetalheOS/DetalheOSModal";
import { PrevisaoSection, StatusSection, DescriptionSection } from "./sections";
import { BaseModal } from "../../../shared/components/modals/BaseModal";
import { IOrderService } from "../../../shared/models/OrderServiceModel";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { ISale } from "../../../shared/models/Sale";
import { CardBox } from "../../../shared/components";


interface OrderServiceCardProps {
  setSale: React.Dispatch<React.SetStateAction<ISale | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  order: IOrderService;
  sale: ISale;
};

export const OrderServiceCard: React.FC<OrderServiceCardProps> = (props) => {
  const { order, sale, setSale, setIsLoading } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const open = Boolean(anchorEl);

  function handleOpenEditOrder() {
    setOpenEditOrder(true);
  };
  function handleCloseEditOrder() {
    setOpenEditOrder(false);
  };

  function handleOpenModal() {
    setIsOpen(true);
  };

  function handleClose() {
    setIsOpen(false);
  };

  function handleDelete() {
    let message = "Deseja remover esta ordem de serviço?";

    if (window.confirm(message)) {
      OrderServiceService.remove(order.id).then((result) => {
        if (result.success && result?.data != undefined) {
          setSale(result.data)
        }
      })
    }
  };

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  };


  return (
    <>
      <CardBox column fullWidth boxSizing='border-box'
        padding={2} borderRadius={5}
        width={499}
        boxShadow="15px 15px 35px -26px rgba(0, 0, 0, 0.437)"
        gap={10}
      >

        <FlexBox row jcBetween>
          <StatusSection
            order={order}
            setSale={setSale}
            setIsLoading={setIsLoading}
          />
          <FlexBox aiStart >
            {sale?.status == 0 && <><IconButton size='medium'
              onClick={handleClick}
            >
              <Icon>more_vert_sharp</Icon>
            </IconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => { handleOpenEditOrder(); setAnchorEl(null) }}>
                  <Icon>edit</Icon>Editar
                </MenuItem>
                <MenuItem onClick={() => { handleDelete(); setAnchorEl(null) }}>
                  <Icon>delete</Icon>Excluir
                </MenuItem>
              </Menu></>}

          </FlexBox>
        </FlexBox>

        <PrevisaoSection
          order={order}
        />
        <FlexBox row gap={5}>
          <Typography fontSize='13px' fontWeight={700}>
            Laboratório:
          </Typography>
          <Typography fontSize='13px'>
            {order.laboratory?.name}
          </Typography>
        </FlexBox>

        <DescriptionSection
          order={order}
        />

        <FlexBox row jcBetween aiCenter gap={10}>
          <Button variant='outlined'
            startIcon={<Icon>assignment</Icon>}
            onClick={handleOpenModal}
          >
            Receita
          </Button>
          {order?.imageUrl &&
            <Button variant='outlined'
              startIcon={<Icon>photo_camera</Icon>}
              onClick={() => setIsOpenImage(true)}
            >
              Imagem
            </Button>
          }
          <DetalheOSModal
            order={order}
            sale={sale}
          />
        </FlexBox>
      </CardBox>

      <ModalAddPrescrition
        open={isOpen}
        viewMode
        handleClose={handleClose}
        prescrition={order.prescription}
      />

      <ModalVerImage
        isOpen={isOpenImage}
        setIsOpen={setIsOpenImage}
        imageUrl={order.imageUrl}
      />

      <ModalEditOrder
        sale={sale}
        order={order}
        setSale={setSale}
        open={openEditOrder}
        handleClose={handleCloseEditOrder}
      />
    </>
  );
};


interface ModalVerImageProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  imageUrl?: string;
}

export const ModalVerImage: React.FC<ModalVerImageProps> = (props) => {
  const { isOpen, setIsOpen, imageUrl } = props;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  function handleClose() {
    setIsOpen(false);
  }

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      minWidth={300}
    >
      <FlexBox fullWidth column>
        <FlexBox>
          {imageUrl &&
            <img width={smDown ? 300 : 600} alt="Imagem em OS" src={imageUrl} />
          }
        </FlexBox>
        <FlexBox row jcStart aiCenter gap={10} marginTop={3}>
          <Button variant='outlined'
            onClick={handleClose}
          >
            Fechar
          </Button>
        </FlexBox>
      </FlexBox>
    </BaseModal>
  );
};
