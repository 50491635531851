import React, { useEffect, useMemo, useState } from 'react'
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Typography, Icon, LinearProgress, Button,
  useMediaQuery,
  Theme,
  Pagination
} from '@mui/material';
import dayjs from 'dayjs';

import { StockMovementService } from '../../../shared/services/api/stockMovement/StockMovementService';
import { useStockContext } from '../../../shared/contexts/pages/StockContext';
import { IStockMovement } from '../../../shared/models/StockMovement';
import { ShowColor } from '../../../shared/components/layouts/ShowColor';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import { ModalAddEstoque } from './components/ModalAddEstoque';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { Environment } from '../../../shared/environment';
import { useSearchParams } from 'react-router-dom';


export const EntradasEstoque: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const dateInit = useMemo(() => {
    return searchParams.get('dateInit') || '';
  }, [searchParams]);

  const dateEnd = useMemo(() => {
    return searchParams.get('dateEnd') || '';
  }, [searchParams]);

  const { isLoading, startLoading, stopLoading } = useStockContext();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [rows, setRows] = useState<IStockMovement[]>();
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    startLoading();
    getStock();
  }, [searchParams])

  const getStock = () => {
    StockMovementService.getAll(0, dateInit, dateEnd, pagina, 10).then(result => {
      if (result.success) {
        setRows(result.data.data);
        setTotalAmount(result.data.amount)
      }
      else {
        alert(result.errors[0]);
      }
      stopLoading();
    });
  }


  return (
    <FlexBox fullWidth column gap={20} padding={1}>
      <FlexBox row jcEnd fullWidth >
        <Button
          variant='contained'
          size='small'
          onClick={() => setIsOpenAdd(true)}
        >
          <Icon>add</Icon> Estoque
        </Button>
      </FlexBox>

      <FlexBox>
        <TableContainer component={CardBox} column sx={{ width: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={500}>
                    Produto
                  </Typography>
                </TableCell>

                <TableCell align='center' sx={{paddingX:0}}>
                  <Typography fontWeight={500}>
                    Cor
                  </Typography>
                </TableCell>

                {smDown ? null :
                  <TableCell align='center'>
                    <Typography fontWeight={500}>
                      Data
                    </Typography>
                  </TableCell>
                }

                <TableCell align='center' sx={{paddingX:0}}>
                  <Typography fontWeight={500}>
                    Motivo
                  </Typography>
                </TableCell>

                <TableCell align='center' sx={{paddingX:0}}>
                  <Typography fontWeight={500}>
                    {smDown ? 'Qnt' : 'Quantidade'}
                  </Typography>
                </TableCell>

                {smDown ? null :
                  <TableCell align='center'>
                    <Typography fontWeight={500}>
                      Custo
                    </Typography>
                  </TableCell>
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {rows &&
                rows.map(row => (
                  <TableRow key={row.id}
                    sx={{ '&:hover': { backgroundColor: '#bbbbbb3c' }, transition: '.3s' }}
                  >
                    <TableCell>
                      <Typography fontWeight={400}>
                        {row.product?.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="center" sx={{ justifyItems: 'center', paddingX:0 }}>

                      <ShowColor
                        colorCode={row?.stockProduct?.colorProduct?.code}
                        colorName={row?.stockProduct?.colorProduct?.name}
                        isColor={row?.stockProduct?.colorProduct?.name}
                      />

                    </TableCell>

                    {smDown ? null :
                      <TableCell align='center'>
                        <Typography fontWeight={400}>
                          {dayjs(row.date).format("DD/MM/YYYY")}
                        </Typography>
                      </TableCell>
                    }

                    <TableCell align='center' sx={{paddingX:0}}>
                      <Typography fontWeight={400}>
                        {row.reason}
                      </Typography>
                    </TableCell>

                    <TableCell align='center' sx={{paddingX:0}}>
                      <Typography fontWeight={400}>
                        {row.stockProduct?.notStock ? (
                          "-"
                        ) : (
                          row.quantity
                        )}
                      </Typography>
                    </TableCell>

                    {smDown ? null :
                      <TableCell align='center'>
                        <Typography fontWeight={400}>
                          {CurrencyFormat(row.value)}
                        </Typography>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
            {totalAmount == 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
          </Table>
          <FlexBox padding={2}>
            {isLoading && (
              <TableRow>
                <LinearProgress variant='indeterminate' />
              </TableRow>
            )}
            {(totalAmount > 0 && totalAmount > Environment.LIMITE_DE_LINHAS) && (
              <Pagination
                page={pagina}
                count={Math.ceil(totalAmount / Environment.LIMITE_DE_LINHAS)}
                onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
              />
            )}
          </FlexBox>
        </TableContainer>
      </FlexBox>

      <ModalAddEstoque
        isOpen={isOpenAdd}
        setIsOpen={setIsOpenAdd}
        setStock={setRows}
        type={0}
      />
    </FlexBox>
  )
}