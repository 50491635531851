import { IMarkProduct } from "../../../MarkProduct";
import { IMarkProductInsert, IMarkProductUpdate } from "../../../Dtos/cadastrosDtos/MarkProductDto";

export class MarkProduct implements IMarkProduct {
  id: number;
  name: string;
  isActive: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<IMarkProduct> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};


export class MarkProductInsert implements IMarkProductInsert {
  name: string;

  constructor({ name = "" }: Partial<IMarkProductInsert> = {}) {
    this.name = name;
  }
};


export class MarkProductUpdate implements IMarkProductUpdate {
  id: number;
  name?: string;
  isActive?: boolean;

  constructor({ id = 0, name = "", isActive = true }: Partial<IMarkProductUpdate> = {}) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
};