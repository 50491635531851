import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography,
  TableFooter, LinearProgress, Pagination, useMediaQuery, Theme, CircularProgress, Chip,
  Grid
} from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { ModalTransactionDesc } from '../../../shared/components/modals/transactionModal/TransactionDescModal';
import { ITransactionTypeEnum } from '../../../shared/models/enums/TransactionType';
import { statusBarPayment } from '../../vendas/components/StatusBarPayment';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import { ITransaction } from '../../../shared/models/Transaction';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import { Env, Environment } from '../../../shared/environment';
import { IReport } from '../../../shared/models/Report';


interface TransactionProps {
  page: number;
  report: IReport | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const Transaction: React.FC<TransactionProps> = (props) => {
  const { report, page, setPage } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [totalValue, setTotalValeu] = useState(0);
  const [totalCostValue, setTotalCostValeu] = useState(0);
  const [transaction, setTransaction] = useState<ITransaction>();

  const [openDesc, setOpenDesc] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    setTotalCount(report?.totalAmount ?? 0)
    setTotalValeu(report?.totalRevenue ?? 0);
    setTotalCostValeu(report?.totalExpense ?? 0);
    setIsLoading(false)
  }, [report])


  function handleClickNavigate(id: number | undefined) {
    if (id != undefined && id != 0) {
      navigate(`/vendas/detalhe/${id}`);
    } else {
      alert("Este valor não esta vinculado a uma venda!")
    }
  };

  function editPage(newPage: number) {
    setPage(newPage);
  };

  const handleCloseDesc = () => {
    setOpenDesc(false)
  }

  const handleOpenDesc = (transaction: ITransaction) => {
    setTransaction(transaction)
    setOpenDesc(true)
  }

  return (
    <CardBox column minWidth={'100%'} boxSizing={'border-box'}>

      {isLoading && (
        <LinearProgress variant="indeterminate" />
      )}

      <TableContainer
        sx={{ width: 'auto', overflow: 'auto' }}
      >
        <Table size={'medium'}>
          <TableHead>
            <TableRow>
              {!smDown && <TableCell width={120} align='center'>Tipo</TableCell>}
              <TableCell align='center'>Vencimento</TableCell>
              <TableCell align='center'>Valor</TableCell>
              <TableCell align='center'>Status</TableCell>
            </TableRow>
          </TableHead>


          <TableBody >
            {report?.transactions?.map(row => (
              <TableRow
                key={row.id}
                component={Box}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f3f3f3' }, transition: '.3s' }}
                onClick={() => row.saleId != null ? handleClickNavigate(row.saleId) : handleOpenDesc(row)}
              >
                {!smDown && (
                  <TableCell align='center'>
                    <FlexBox row jcStart aiCenter gap={5}>
                      {row.type == 0 && <TrendingUpIcon color='success' />}
                      {row.type == 1 && <TrendingDownIcon color='error' />}
                      {row.type == 2 && <CompareArrowsIcon color='warning' />}
                      <Typography fontSize={13} fontWeight={500}>
                        {ITransactionTypeEnum[row.type]}
                      </Typography>
                    </FlexBox>
                  </TableCell>
                )}

                <TableCell align='center'>
                  <Typography>
                    {dayjs(row.date).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography color={row.type == 0 ? 'success' : row.type == 1 ? 'error' : 'warning'}>
                    {row.type == 0 ? '' : '-'}{CurrencyFormat(row.value)}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>
                    <div>
                      <Chip
                        variant='outlined'
                        label={statusBarPayment(row?.status).label}
                        size={'small'}
                        sx={{
                          border: `1px solid ${statusBarPayment(row?.status).color}`,
                          // backgroundColor: statusBarPayment(row?.status).bgColor,
                          color: statusBarPayment(row?.status).color,
                          // boxShadow: Env.BX_SHADOW,
                          fontWeight: 600,
                        }}
                      />
                      {isLoading && <CircularProgress size={20} />}
                    </div>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {(totalCount == 0 && !isLoading) && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          {/* 
            <TableFooter>
              {totalCount > 0 &&
                <>
                  <TableRow>
                    <TableCell align='right' colSpan={smDown ? 3 : 4}><Typography color='info'>Receita: {CurrencyFormat(totalValue)}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' colSpan={smDown ? 3 : 4}><Typography color='error'>Despesa: {CurrencyFormat(totalCostValue)}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' colSpan={smDown ? 3 : 4}><Typography color='success'>Lucro: {CurrencyFormat(totalValue - totalCostValue)}</Typography></TableCell>
                  </TableRow>
                </>
              }
            </TableFooter>
             */}
        </Table>
        <FlexBox padding={2}>
          {isLoading && (
            <TableRow>
              <LinearProgress variant='indeterminate' />
            </TableRow>
          )}
          {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
            <Pagination
              page={page}
              count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
              onChange={(_, newPage) => editPage(newPage)}
            />
          )}
        </FlexBox>
      </TableContainer>

      <ModalTransactionDesc
        transaction={transaction}
        open={openDesc}
        handleClose={handleCloseDesc}
      />
    </CardBox>
  )
};