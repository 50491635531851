import * as React from 'react';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, Icon, Menu, Theme, useMediaQuery } from '@mui/material';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IStatusSaleEnum } from '../../../shared/models/enums/StatusSaleEnum';

interface FilterButtonProps {
    setStatusFilter: React.Dispatch<React.SetStateAction<number[]>>;
    statusFilter: number[];
    handleClick: () => void;
}

export function SaleFilterButton({ setStatusFilter, statusFilter, handleClick }: FilterButtonProps) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [status, setStatus] = React.useState(statusFilter);
    const [reloadEffect, setReloadEffect] = React.useState(0);
    const open = Boolean(anchorEl);
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    React.useEffect(() => {
        setStatusFilter(status)
    }, [reloadEffect])


    function handleMenuItemClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
        setStatusFilter(status)
        handleClick()
    }

    const handleChangeOption = (index: number) => {
        setStatus(prev => {
            if (prev.includes(index)) {
                return prev.filter(x => x != index)
            } else {
                prev.push(index)
                return prev
            }
        })
        setReloadEffect(i => ++i)
    };



    return (
        <>
            <Button
                size="medium"
                variant='text'
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuItemClick}
            >
                <FilterAltOutlinedIcon
                    sx={{ marginRight: 1 }}
                />
                Filtros
                <Icon>arrow_drop_down</Icon>
            </Button>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FlexBox
                    row={!smDown && true}
                    column={smDown && true}
                    aiCenter jcCenter padding={2} gap={smDown ? 0 : 25}
                >
                    <FlexBox fullWidth column jcStart marginLeft={2}>
                        <FormControlLabel checked={status.includes(0)} onChange={() => handleChangeOption(0)} control={<Checkbox defaultChecked />} label={IStatusSaleEnum[0]} />
                        <FormControlLabel checked={status.includes(1)} onChange={() => handleChangeOption(1)} control={<Checkbox defaultChecked />} label={IStatusSaleEnum[1]} />
                        <FormControlLabel checked={status.includes(2)} onChange={() => handleChangeOption(2)} control={<Checkbox defaultChecked />} label={IStatusSaleEnum[2]} />
                        <FormControlLabel checked={status.includes(3)} onChange={() => handleChangeOption(3)} control={<Checkbox defaultChecked />} label={IStatusSaleEnum[3]} />
                        <FormControlLabel checked={status.includes(4)} onChange={() => handleChangeOption(4)} control={<Checkbox defaultChecked />} label={IStatusSaleEnum[4]} />
                        <Button variant='contained' onClick={handleClose}>Salvar</Button>
                    </FlexBox>
                </FlexBox>
            </Menu>
        </>
    );
}