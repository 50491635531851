import { Theme, useMediaQuery } from '@mui/material';
import { Dayjs } from 'dayjs';
import React from 'react';

import { ButtonFilterDate } from '../../../shared/components/buttons/ButtonFilterDate';
import FlexBox from '../../../shared/components/layouts/FlexBox';

interface FilterSectionProps {
    handleClick: () => void;
    valueDateFilter: number;
    setValueDateFilter: React.Dispatch<React.SetStateAction<number>>
    dateRange: Dayjs[];
    setDateRange: React.Dispatch<React.SetStateAction<Dayjs[]>>;
};

export const FilterSection: React.FC<FilterSectionProps> = (props) => {
    const { handleClick, valueDateFilter, setValueDateFilter, setDateRange, dateRange } = props;

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <FlexBox column paddingY={0} minWidth={'100%'} boxSizing={'border-box'}>
            <FlexBox row
                jcEnd
                gap={0} paddingTop={3}
                marginTop={smDown ? "-75px" : mdDown ? "-80px" : "-100px"}
                paddingRight={smDown ? 1 : 0}
            >
                <ButtonFilterDate
                    valueDateFilter={valueDateFilter}
                    setValueDateFilter={setValueDateFilter}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    handleClick={handleClick}
                />
            </FlexBox>
        </FlexBox>
    )
}
