import { LayoutBaseDePagina } from "../../../shared/layouts";
import FlexBox from "../../../shared/components/layouts/FlexBox";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, CardContent, Icon, IconButton, Stack, styled, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { IBranch } from "../../../shared/models";
import { BranchService } from "../../../shared/services/api/branch/BranchService";
import { Env } from "../../../shared/environment";
import { ModalAddAddress } from "../branchs/sections/ModalAddAddress";
import { ModalEditAddress } from "../branchs/sections/ModalEditAddress";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IContact } from "../../../shared/models/Clients/contact";
import { CardContact } from "./components/CardContact";
import { useAuthContext } from "../../../shared/contexts";
import { InputFile } from "../../../shared/components/inputs/input-file/InputFile";

export const Branch = () => {
  const [branch, setBranch] = useState<IBranch>();
  const [isLoading, setIsLoading] = useState(false);
  const [nameBranch, setNameBranch] = useState("");
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [logo, setLogo] = useState<FileList | null>();
  const [base64, setBase64] = useState<string>('');
  const [contacts, setContacts] = useState<IContact[]>([]);
  const { setRoles } = useAuthContext();

  const { id } = useParams<'id'>();
  const navigate = useNavigate();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (id != undefined)
      BranchService.getBranch(parseInt(id))
        .then((result) => {
          if (result != undefined && result?.success && result?.data != undefined) {
            setBranch(result.data);
            setNameBranch(result.data.name)
            setBase64(result.data.logoUrl)
            if (result.data.contacts.length > 0) {
              setContacts(result.data.contacts)
            } else if (contacts.length == 0) {
              handleAddCellphone()
            }
          }
        });
  }, []);

  const handleAddAddressClose = () => {
    setOpenAddAddress(false)
  }
  const handleEditAddressClose = () => {
    setOpenEditAddress(false)
  }

  const handlerSubmit = () => {
    setIsLoading(true)
    const branchUpdate = {
      id: branch?.id,
      name: nameBranch,
      logoUrl: base64,
      contacts: contacts
    }
    BranchService.update(branchUpdate).then((result) => {
      if (result.success) {
        setRoles(prev => {
          prev.forEach(x => {
            if (x.branch.id == result.data.id)
              x.branch = result.data
          })
          return prev
        })
        alert("Ponto de venda atualizado com sucesso!")
        navigate(-1)
      }
    })
    setIsLoading(false)
  }

  const handleAddCellphone = () => {
    let noCell: IContact = { id: 0, value: '', type: 0, isMain: contacts.length == 0 }
    setContacts(prevListTel => [...prevListTel, noCell]);
  }

  const handleDeleteCellphone = (indice: number) => {
    if (contacts.length > 1) {
      setContacts(prevListTel => prevListTel.filter((_, index) => index != indice));
    }
    else {
      return;
    };
  };

  return (
    <LayoutBaseDePagina
      titulo={branch?.name ?? "Ponto de venda"}
      buttonToBack
    >
      <FlexBox column>
        <Box sx={Env.FLEX_COLUMN}
          marginTop={1}
        >
          <Stack
            gap={1}
            padding={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            <FlexBox padding={2} gap={30} sx={{ flexDirection: smDown ? 'column-reverse' : 'row', backgroundColor: 'white', borderRadius: 20 }} jcBetween>
              <FlexBox column fullWidth paddingY={2} gap={20}>
                <TextField size="medium"
                  value={nameBranch}
                  label='Nome'
                  onChange={(e) => setNameBranch(e.target.value)}
                />
                <InputFile buttonText="Logo marca" setImage={setBase64} />
              </FlexBox>
              <FlexBox fullWidth jcCenter>{base64 ? <img width={300} src={base64} /> : null}</FlexBox>
            </FlexBox>
          </Stack>
        </Box>
        <FlexBox column={smDown || mdDown} aiCenter>
          <Box
            width='100%' minHeight='200px'
            paddingX={1}
            sx={Env.FLEX_COLUMN} boxSizing='border-box'
          >
            <FlexBox sx={{ backgroundColor: 'white' }} width='100%' borderRadius={5}
            >
              <FlexBox
                minHeight='100px'
                boxSizing='border-box'
                column
                aiCenter
                fullWidth
                padding={2}
              >
                <FlexBox
                  fullWidth
                  minHeight='50px'
                  borderRadius='10px'
                  jcBetween
                  aiCenter
                  row
                >
                  <Typography>Contatos</Typography>
                  <Button
                    startIcon={<Icon>add</Icon>}
                    onClick={handleAddCellphone}
                    variant="contained"
                  >
                    Contato
                  </Button>
                </FlexBox>
                <FlexBox
                  fullWidth
                  minHeight='50px'
                  boxSizing='border-box'
                  borderRadius='10px'
                  jcCenter
                  aiCenter
                  column
                >
                  {contacts.map((_, indice) => (
                    <CardContact
                      handleDeleteCellphone={handleDeleteCellphone}
                      setRowsCellphones={setContacts}
                      rowsCellphones={contacts}
                      indice={indice}
                    />
                  ))}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Box>
          <Stack
            gap={1}
            paddingX={1}
            width='100%'
            direction='column'
            boxSizing='border-box'
            justifyContent='center'
          >
            {!!branch?.address ?
              <Card sx={{ boxShadow: 'none' }}>
                <CardContent>
                  <Typography sx={{ marginTop: 2 }} id="modal-modal-title" component="div">
                    Endereço
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <div>
                      <Typography sx={{ fontSize: 12, maxWidth: 150 }} id="modal-modal-title" component="text">
                        {branch.address.street}, {branch.address.number} - {branch.address.district} - {branch.address.city} - {branch.address.state}
                      </Typography>
                    </div>
                    <div>
                      <Typography sx={{ fontSize: 12, maxWidth: 150 }} id="modal-modal-title" component="text">
                        <IconButton size='small' onClick={() => setOpenEditAddress(true)}>
                          <Icon>edit</Icon>
                        </IconButton>
                      </Typography>
                    </div>
                  </Box>
                </CardContent>
              </Card> :
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <Button disabled={isLoading} sx={{ maxWidth: 250 }} variant='contained' onClick={() => setOpenAddAddress(true)}>Adicionar endereço</Button>
              </Box>}
          </Stack>
        </FlexBox>
        <Stack
          paddingX={2}
          direction='row'
          justifyContent='flex-end'
        >
          <Button disabled={isLoading} variant='contained' onClick={() => handlerSubmit()}>Salvar</Button>
        </Stack>
        <ModalAddAddress
          handleClose={handleAddAddressClose}
          open={openAddAddress}
          setBranch={setBranch}
          branch={branch}
        />
        {branch &&
          <ModalEditAddress
            handleClose={handleEditAddressClose}
            open={openEditAddress}
            branch={branch}
          />}
      </FlexBox>
    </LayoutBaseDePagina >
  )
};

