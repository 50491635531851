import React, { ReactNode } from 'react';
import FlexBox from './FlexBox';
import { BoxProps } from '@mui/material';

interface ICardBox extends BoxProps {

  row?: boolean;
  jcEnd?: boolean;
  aiEnd?: boolean;
  column?: boolean;
  jcStart?: boolean;
  aiStart?: boolean;
  jcEvenly?: boolean;
  jcAround?: boolean;
  jcCenter?: boolean;
  aiCenter?: boolean;

  aiBetween?: boolean;
  aiAround?: boolean;
  aiEvenly?: boolean;

  jcBetween?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;

  sx?: React.CSSProperties;
  sx1?: React.CSSProperties;

  gap?: number | string;
}

export const CardBox: React.FC<ICardBox> = ({
  fullWidth,
  gap,
  sx,
  sx1,
  row,
  aiEnd,
  jcEnd,
  column,
  jcStart,
  aiStart,
  aiCenter,
  jcCenter,
  jcBetween,
  jcEvenly,
  jcAround,

  aiBetween,
  aiAround,
  aiEvenly,

  children,
  ...boxProps
}) => {

  return (
    <FlexBox
      fullWidth
       bgcolor='#ffffffec'
      boxShadow="0px 5px 22px -10px rgba(0,0,0,0.1)" borderRadius={6}
      boxSizing={'border-box'}
      gap={gap}
      sx={sx}
      sx1={sx1}
      row={row}
      aiEnd={aiEnd}
      jcEnd={jcEnd}
      column={column}
      jcStart={jcStart}
      aiStart={aiStart}
      aiCenter={aiCenter}
      jcCenter={jcCenter}
      jcBetween={jcBetween}
      jcEvenly={jcEvenly}
      jcAround={jcAround}
      {...boxProps}
    >
      {children}
    </FlexBox>
  )
};


