import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CurrencyFormat from '../../../shared/helps/CurrencyFormat';
import { CardBox } from '../../../shared/components/layouts/CardBox';
import dayjs from 'dayjs';
import { ITransactionTypeEnum } from '../../../shared/models/enums/TransactionType';
import FlexBox from '../../../shared/components/layouts/FlexBox';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Box, TableFooter, Theme, useMediaQuery } from '@mui/material';
import { ICashRegister } from '../../../shared/models/CashRegister';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useNavigate } from 'react-router-dom';
import { ITransaction } from '../../../shared/models/Transaction';
import { ModalTransactionDesc } from '../../../shared/components/modals/transactionModal/TransactionDescModal';


interface CashReportProps {
    cashRegister: ICashRegister;
};

function Row({ cashRegister }: CashReportProps) {
    const [open, setOpen] = React.useState(false);
    const [openDesc, setOpenDesc] = React.useState(false);
    const [transection, setTransaction] = React.useState<ITransaction>();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const receita = cashRegister.transactions.filter(x => x.type == 0).reduce((sum, nr) => sum + nr.value, 0);
    const despesa = cashRegister.transactions.filter(x => x.type == 1).reduce((sum, nr) => sum + nr.value, 0);
    const retirada = cashRegister.transactions.filter(x => x.type == 2).reduce((sum, nr) => sum + nr.value, 0);

    const navigate = useNavigate();

    function handleClickNavigate(id: number | undefined) {
        if (id != undefined && id != 0) {
            navigate(`/vendas/detalhe/${id}`);
        } else {
            alert("Este valor não esta vinculado a uma venda!")
        }
    };

    const handleOpenDesc= (transaction: ITransaction) => {
        setTransaction(transaction)
        setOpenDesc(true)
    }

    const handleCloseDesc = () => {
        setOpenDesc(false)
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
                <TableCell align="center">
                    <IconButton
                        size="small"
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <Typography fontSize={14}>{dayjs(cashRegister.dateOpen).format("DD/MM/YYYY")}</Typography>
                    <Typography fontSize={14}>{dayjs(cashRegister.dateOpen).format("HH:mm")}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontSize={14}>{cashRegister.dateClose != null ? dayjs(cashRegister.dateClose).format("DD/MM/YYYY") : '-'}</Typography>
                    <Typography fontSize={14}>{cashRegister.dateClose != null ? dayjs(cashRegister.dateClose).format("HH:mm") : '-'}</Typography>
                </TableCell>
                {!smDown ? <>
                    <TableCell align="center">
                        <Typography fontSize={14}>{CurrencyFormat(cashRegister.valueOpen)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography fontSize={14}>{CurrencyFormat(cashRegister.valueClose)}</Typography>
                    </TableCell> </> : null}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <FlexBox sx={{ backgroundColor: '#f6f6f6' }} borderRadius={3} column marginX={smDown ? -2 : 0} marginY={2}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Data</TableCell>
                                        {!smDown ? <TableCell>Usuario</TableCell> : null}
                                        <TableCell align="right">Valor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cashRegister.transactions != undefined && cashRegister.transactions.map((historyRow) => (
                                        <TableRow component={Box}
                                            key={historyRow.id}
                                            onClick={() => historyRow.saleId != null ? handleClickNavigate(historyRow.saleId) : handleOpenDesc(historyRow)}>
                                            <TableCell component="th" scope="row">
                                                <FlexBox row jcStart aiCenter gap={6}>
                                                    {historyRow.type == 0 && <TrendingUpIcon color='success' />}
                                                    {historyRow.type == 1 && <TrendingDownIcon color='error' />}
                                                    {historyRow.type == 2 && <CompareArrowsIcon color='warning' />}
                                                    <Typography fontSize={13} fontWeight={500}>
                                                        {ITransactionTypeEnum[historyRow.type]}
                                                    </Typography>
                                                </FlexBox>
                                            </TableCell>
                                            <TableCell>{dayjs(historyRow.date).format("DD/MM/YYYY")}</TableCell>
                                            {!smDown ? <TableCell>{historyRow.user?.name}</TableCell> : null}
                                            <TableCell align="right">
                                                {CurrencyFormat(historyRow.value)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <FlexBox marginY={2} fullWidth jcEnd>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell><Typography fontSize={13} fontWeight={600}>Receita</Typography></TableCell>
                                        <TableCell><Typography fontSize={13} fontWeight={600}>Despesa</Typography></TableCell>
                                        <TableCell><Typography fontSize={13} fontWeight={600}>Retirada</Typography></TableCell>
                                        <TableCell align="right"><Typography fontSize={13} fontWeight={600}>Lucro</Typography></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Typography fontSize={13} fontWeight={500} color='info'>{CurrencyFormat(receita)}</Typography></TableCell>
                                        <TableCell><Typography fontSize={13} fontWeight={500} color='error'>{CurrencyFormat(despesa)}</Typography></TableCell>
                                        <TableCell><Typography fontSize={13} fontWeight={500} color='info'>{CurrencyFormat(retirada)}</Typography></TableCell>
                                        <TableCell align="right"><Typography fontSize={13} fontWeight={500} color='success'>{CurrencyFormat(receita - despesa)}</Typography></TableCell>
                                    </TableRow>
                                </TableFooter>
                            </FlexBox>
                        </FlexBox>
                    </Collapse>
                </TableCell>
            </TableRow>
            <ModalTransactionDesc transaction={transection} open={openDesc} handleClose={handleCloseDesc} />
        </React.Fragment>
    );
}

interface CashReportsProps {
    cashRegisters: ICashRegister[] | undefined;
};

export default function CollapsibleTable({ cashRegisters }: CashReportsProps) {
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const receita = cashRegisters?.map(x => x.transactions?.filter(x => x.type == 0).reduce((sum, nr) => sum + nr.value, 0)).reduce((sum, nr) => sum + nr, 0);
    const despesa = cashRegisters?.map(x => x.transactions?.filter(x => x.type == 1).reduce((sum, nr) => sum + nr.value, 0)).reduce((sum, nr) => sum + nr, 0);
    const retirada = cashRegisters?.map(x => x.transactions?.filter(x => x.type == 2).reduce((sum, nr) => sum + nr.value, 0)).reduce((sum, nr) => sum + nr, 0);
    return (
        <TableContainer component={CardBox}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">Abertura</TableCell>
                        <TableCell align="center">Fechamento</TableCell>
                        {!smDown ? <><TableCell align="center">Valor Abertura</TableCell>
                            <TableCell align="center">Valor Fechamento</TableCell></> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cashRegisters != undefined && cashRegisters.map((cashRegister) =>
                        <Row cashRegister={cashRegister} />)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align='right' colSpan={smDown ? 3 : 6}><Typography color='info'>Receita: {CurrencyFormat(receita ?? 0)}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='right' colSpan={smDown ? 3 : 6}><Typography color='error'>Despesa: {CurrencyFormat(despesa ?? 0)}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='right' colSpan={smDown ? 3 : 6}><Typography color='info'>Retirada: {CurrencyFormat(retirada ?? 0)}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='right' colSpan={smDown ? 3 : 6}><Typography color='success'>Lucro: {CurrencyFormat((receita ?? 0) - (despesa ?? 0))}</Typography></TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}