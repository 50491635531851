import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import React from "react";

import { ReportService } from "../../shared/services/api/report/ReportService";
import { ISearchReportDto } from "../../shared/models/Dtos/SearchReportDto";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { setTimeVerification } from "../../shared/components";
import { TotalCard } from "./components/Dashboard-TotalCard";
import { useDashboardContext } from "../../shared/contexts";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { FilterSection, Transaction } from "./sections";
import { OverViewCard } from "./sections/OverViewCard";
import { Environment } from "../../shared/environment";


export const Dashboard = () => {

  const { valueDateFilter, dateRange, statusFilter, situations, report, reload,
    page, setPage, setReport, setValueDateFilter, setSituations,
    setDateRange, serReload, setStatusFilter,
  } = useDashboardContext();

  const lgDown = useMediaQuery(useTheme().breakpoints.down('lg'));
  const smDown = useMediaQuery(useTheme().breakpoints.down('sm'));


  useEffect(() => {
    getInfos(valueDateFilter)

  }, [page, reload]);


  function getInfos(timeType: number) {

    const timeVerification = setTimeVerification(timeType, dateRange);

    const request: ISearchReportDto = {
      dateInit: timeVerification.first,
      dateEnd: timeVerification.last,
      status: statusFilter,
      page: page,
      pageSize: Environment.LIMITE_DE_LINHAS
    };

    ReportService.getBranch(request).then((result) => {
      if (result.success) {
        setSituations(result.data.situations)
        setReport(result.data)
      }
    });
  };

  function handleCosole() {
    serReload(prev => ++prev);
  };


  return (
    <LayoutBaseDePagina
      titulo="Página inicial"
    >

      <FilterSection
        handleClick={handleCosole}
        valueDateFilter={valueDateFilter}
        setValueDateFilter={setValueDateFilter}
        setDateRange={setDateRange}
        dateRange={dateRange}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
      />

      <FlexBox marginTop={smDown ? 0 : -5}>
        <Grid container>
          
          <FlexBox paddingX={2} row jcBetween marginBottom={2}>
            <Typography variant="h5">
              Transações
            </Typography>
          </FlexBox>
          
          <Grid item container spacing={2}>
            {situations && (
              situations
                .sort((a, b) => b.value - a.value)
                .map((situation, index) => (
                  index < 4 && (

                    <Grid item xs={6} sm={6} lg={3}>
                      <OverViewCard
                        sx={{ height: '100%' }}
                        situation={situation}
                        setStatusFilter={setStatusFilter}
                      />
                    </Grid>

                  )
                ))
            )}
          </Grid>

          <FlexBox column fullWidth marginY={5}>

            <Grid container rowSpacing={2} spacing={4}
              marginBottom={2}
              //width={"100%"}
              paddingX={2}
            //  bgcolor={"#13121152"}
            >
              <Grid item xs={12} sm={12} lg={9} xl={9} // bgcolor={"#6f584150"}
              >
                <Typography variant="h5">
                  Extrato
                </Typography>
              </Grid>

              {!lgDown &&
                <Grid item xs={12} sm={6} lg={3} xl={3} // bgcolor={"#244cdf5c"}
                >
                  <Typography variant="h5">
                    Total:
                  </Typography>
                </Grid>
              }
            </Grid>


            <Grid container spacing={2} // bgcolor={"#131211ad"}
            >
              <Grid item xs={12} sm={12} lg={9} xl={9} // bgcolor={"#244ddfad"}
              >
                <Transaction
                  page={page}
                  setPage={setPage}
                  report={report}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3} xl={3} // bgcolor={"#816957ae"}
              >
                {lgDown &&
                <FlexBox marginLeft={2} marginTop={3}>
                  <Typography variant="h5">
                    Total:
                  </Typography>
                </FlexBox>
                }
                <FlexBox
                  column={(!lgDown || smDown) ? true : false}
                  marginTop={1}
                  gap={15}
                >

                  <TotalCard
                    report={report}
                    variant="success"
                  />
                  <TotalCard
                    report={report}
                    variant="error"
                  />
                  <TotalCard
                    report={report}
                    variant="totalValue"
                  />

                </FlexBox>
              </Grid>
            </Grid>

          </FlexBox>

        </Grid>

      </FlexBox>
    </LayoutBaseDePagina>
  );
}
