import { Environment } from "../../../environment";
import { IResponseAmount } from "../../../models";
import { IResponseAPI } from "../../../models/base/IResponseAPI";
import { ISale, ISaleInsert, ISaleStatusUpdate } from "../../../models/Sale";
import { Api } from "../axios-config";


const insert = async (sale: ISaleInsert): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.post('/otica/Sale', sale).catch(data => {
        return data.response;
    });

    return data;
};

const updateStatus = async (sale: ISaleStatusUpdate): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.put('/otica/Sale', sale).catch(data => {
        return data.response;
    });

    return data;
};

const getAll = async (busca: string,dateInit: string, dateEnd: string, status: number[], pagina: number): Promise<IResponseAPI<IResponseAmount<ISale[]>> | IResponseAPI<IResponseAmount<ISale[]>>> => {
    let statusString = '';
    for(let i = 0; i< status.length; ++i)
    {
        statusString = statusString + `&status=${status[i]}`
    }
    const { data } = await Api.get(`/otica/Sale?busca=${busca}${statusString}&dateInit=${dateInit}&dateEnd=${dateEnd}&page=${pagina}&pageSize=${Environment.LIMITE_DE_LINHAS}`).catch(data => {
        return data.response;
    });

    return data;
};

const getSalesClient = async (clientId: number, page: number): Promise<IResponseAPI<IResponseAmount<ISale[]>> | IResponseAPI<IResponseAmount<ISale[]>>> => {

    const { data } = await Api.get(`/otica/Sale?clientId=${clientId}&page=${page}&pageSize=${Environment.LIMITE_DE_LINHAS}`).catch(data => {
        return data.response;
    });

    return data;
};

const getSale = async (id: number): Promise<IResponseAPI<ISale> | IResponseAPI<ISale>> => {

    const { data } = await Api.get(`/otica/Sale/${id}`).catch(data => {
        return data.response;
    });

    return data;
};

export const SaleService = {
    insert,
    getAll,
    updateStatus,
    getSalesClient,
    getSale
};