import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";

import { SalesDetailToolbarContent } from "./sections/SalesDetailToolbarContent";
import { SaleService } from "../../shared/services/api/sale/SaleService";
import { IRoleLeveEnum } from "../../shared/models/enums/RoleLeveEnum";
import { SalesContentSections } from "./sections/SalesContentSections";
import PhoneNumberFormat from "../../shared/helps/PhoneNumberFormat";
import { ClientCardContent } from "./sections/ClientCardContent";
import { LinksNavigations } from "./components/LinksNavigations";
import FlexBox from "../../shared/components/layouts/FlexBox";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { useAuthContext } from "../../shared/contexts";
import { ISale } from "../../shared/models/Sale";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { DetalheSaleModal } from "../../shared/components/modals/modalDetalheSale/DetalheSaleModal";
import { CardBox } from "../../shared/components";


export const DetalheDeVendas: React.FC = () => {
  const { id } = useParams<'id'>();

  const { roles } = useAuthContext();
  const role = roles.find(x => x.isSelected);
  const navigate = useNavigate();

  const [description, setDescription] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sale, setSale] = useState<ISale>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const smDown = useMediaQuery(useTheme().breakpoints.down("sm"));


  useEffect(() => {
    setIsLoading(true);

    SaleService.getSale(Number(id))
      .then((result) => {
        setIsLoading(false);
        if (result.success) {
          setSale(result.data)
          handleGetContact();
        } else {
          alert(result.message);
          navigate(-1);
        }
      });
  }, [id, navigate]);


  function handleOpenDialog(event: React.MouseEvent<HTMLElement>) {
    if (role?.level != IRoleLeveEnum.Comum)
      setAnchorEl(event.currentTarget);
  };

  function handleGetContact() {
    const client = sale?.client;

    if (client?.contacts.filter(x => x.isMain)[0].type == 0 || client?.contacts.filter(x => x.isMain)[0].type == 1) {
      setDescription(PhoneNumberFormat(client?.contacts.filter(x => x.isMain)[0]?.value));

    } else {
      setDescription(null);
      // client?.contacts.filter(x => x.isMain)[0]?.value
    };
  };


  return (
    <LayoutBaseDePagina
      titulo={"Venda: " + id?.toString()}
      buttonToBack
    >

      <SalesDetailToolbarContent
        handleOpenDialog={handleOpenDialog}
        setIsLoading={setIsLoading}
        setAnchorEl={setAnchorEl}
        isLoading={isLoading}
        anchorEl={anchorEl}
        setSale={setSale}
        sale={sale}
        open={open}
        id={id}
      />


      <FlexBox
        jcBetween
        marginY={2}
        aiStart={smDown}
        aiCenter={!smDown}
        column={smDown ? true : false}
      >

        <LinksNavigations />

        <FlexBox gap={10}>
          <ClientCardContent
            sale={sale}
            description={description}
          />

          <CardBox
            width={"10px"}
            jcCenter
          >
            {sale && <DetalheSaleModal sale={sale} />}
          </CardBox>
        </FlexBox>
        
      </FlexBox>

      <SalesContentSections
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setSale={setSale}
        sale={sale}
      />

    </LayoutBaseDePagina >
  );
};