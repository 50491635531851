import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { IOrderService } from '../../../../shared/models/OrderServiceModel';
import FlexBox from '../../../../shared/components/layouts/FlexBox';

interface PrevisaoSectionSectionProps {
  order: IOrderService;
};

export const PrevisaoSection: React.FC<PrevisaoSectionSectionProps> = (props) => {
  const { order } = props;

  return (
    <FlexBox aiCenter gap={5}>
      <Typography fontSize='13px' fontWeight={700}>
        Previsão:
      </Typography>
      <Typography fontSize='13px' fontWeight={400}>
        {dayjs(order.forecast).format('DD/MM/YYYY')}
      </Typography>
    </FlexBox>
  );
};